import React, {Component} from "react";
import AppContext from "../AppContext";
import $ from "jquery";

export default class UserRolePopup extends Component{
    static contextType = AppContext;

    constructor(props) {
        super(props);
        let role = this.props.currentUser !== null ? this.props.currentUser.role : "student";
        this.state = {
            role: role,
            showEmailLink: false
            };
        this.handleContinue = this.handleContinue.bind(this);
        this.updateUserRole = this.updateUserRole.bind(this);
        this.changeUserRole = this.changeUserRole.bind(this);
        this.resetUserRole = this.resetUserRole.bind(this);
    }

    handleContinue(event) {
        if (this.state.showEmailLink) {
            // Email tab was showing
            this.closeModal();
            this.setState({ showEmailLink: false });
        } else {
            // Role-choice tab was displaying
            this.context.updateAccountType(this.state.role);
            if (this.state.role === 'teacher') {
                // Teacher -> ask about email before closing
                this.setState({ showEmailLink: true });
            } else {
                this.closeModal();
            }
        }
    }

    changeUserRole(event){
        // Change the local state of which radio button is checked
        console.debug('changeUserRole', event, event.target, event.target.value);
        let role = event.target.value;
        this.setState({role: role});
    }

    updateUserRole(){
        // Actually change the user's role in the database
        this.context.updateAccountType(this.state.role);
        this.closeModal();
    }

    updateRole(role){
        this.setState({role: role});
    }

    resetUserRole(){
        let role = this.props.currentUser !== null ? this.props.currentUser.role : "student";
        this.setState({role: role});
    }

    closeModal() {
        $('#modalUserType0').CFW_Modal('hide');
        $('#modalUserType1').CFW_Modal('hide');
    }

    render(){
        let currentUser = this.props.currentUser;
        let displayName = currentUser !== null ? currentUser.displayName : "";

        let roleChoiceBody = <div className="modal-body">
            <fieldset>
                <legend className="form-label font-weight-bold">Are you a student or a teacher?</legend>
                <div onChange={this.changeUserRole}>
                    <div className="form-check form-checkradio">
                        <input className="form-check-input" type="radio" id="level0-0" value="student"  defaultChecked={this.state.role === "student"}  name="level0"/>
                        <label className="form-check-label font-weight-bold" htmlFor="level0-0">Student</label>
                    </div>
                    <div className="form-check form-checkradio">
                        <input className="form-check-input" type="radio" id="level0-1" value="teacher"  defaultChecked={this.state.role === "teacher"} name="level0"/>
                        <label className="form-check-label font-weight-bold" htmlFor="level0-1">Teacher</label>
                    </div>
                </div>
            </fieldset>
            <div className="text-end">
                <button type="submit" className="btn btn-success" disabled={this.state.role === ''} onClick={this.handleContinue}>Continue</button>
            </div>
        </div>;

        let emailBody = <div className="modal-body">
            <p>Join our mailing list for occasional updates to the Corgi platform.</p>
            <a onClick={this.closeModal} href="http://eepurl.com/h_p6J1" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Sign up</a>
            <div className="text-end">
                <button type="button" className="btn btn-success" onClick={this.closeModal}>No thanks</button>
            </div>
        </div>;

        return(
            <React.Fragment>
                <button type="button" className="btn d-none" data-cfw="modal" data-cfw-modal-target="#modalUserType0" data-cfw-modal-backdrop="static">Acct Type - Corgi</button>
                <div id="modalUserType0" className="modal atype-modal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="h3 modal-title">Welcome to Corgi, {displayName}!</h2>
                            </div>
                            { this.state.showEmailLink ? emailBody : roleChoiceBody }
                        </div>
                    </div>
                </div>
                <div id="modalUserType1" className="modal atype-modal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="h1 modal-title">Change account type?</h2>
                            </div>
                            <div className="modal-body">
                                <fieldset>
                                    <legend className="form-label"><strong>Are you a student or a teacher?</strong></legend>
                                    <div >
                                        <div className="form-check form-checkradio">
                                            <input className="form-check-input" type="radio" id="level1-0" checked={this.state.role === "student"} onChange={this.changeUserRole} value="student" name="level1"/>
                                            <label className="form-check-label" htmlFor="level1-0">Student</label>
                                        </div>
                                        <div className="form-check form-checkradio">
                                            <input className="form-check-input" type="radio" id="level1-1" checked={this.state.role === "teacher"} onChange={this.changeUserRole} value="teacher" name="level1"/>
                                            <label className="form-check-label" htmlFor="level1-1">Teacher</label>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="text-end">
                                    <button type="button" className="btn btn-link" data-cfw-dismiss="modal" onClick={this.resetUserRole}>Cancel</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.role === ''} onClick={this.updateUserRole}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

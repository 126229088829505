import React, {Component} from 'react';
import PropTypes from 'prop-types';
import uniqueId from '../uniqueId';
import $ from 'jquery';

export default class DeleteButton extends Component {


    render() {
        this.id = uniqueId();
        let icon = this.props.icon? this.props.icon: "fa-trash-o";

        return (
            this.props.canDelete ? 
                <button id={this.id} type="button" className="btn btn-icon btn-link" title={this.props.tooltip}
                        data-cfw="modal" data-cfw-modal-target={'#'+this.props.modalId}
                        disabled={!this.props.canDelete} >
                    <span className={"fa "+icon} aria-hidden="true"></span>
                    <span className="sr-only">{this.props.tooltip}</span>
                </button>
                : null
        );
    }

    componentDidMount() {
        const button = $('#' + this.id);
        if (button.CFW_Modal) {
            button.CFW_Modal();
        }
    }
}

DeleteButton.propTypes = {
    modalId: PropTypes.string.isRequired,
    tooltip : PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired
};

import OrganizerComponent from "../organizers/OrganizerComponent";
import React from "react";
import PropTypes from "prop-types";
import DocLink from "./DocLink";
import AppContext from "../AppContext";

export default class Steps extends OrganizerComponent {
    static contextType = AppContext;

  constructor() {
        super();

        this.complete = this.complete.bind(this);
        this.trackEvent = this.trackEvent.bind(this);
    }

    trackEvent(step){
        window.speechstream.speechTools.stop();
        window.tracker.trackEvent("Navigation", "toc link", step)
    }
    render() {

        return (
            <React.Fragment>
                <h1><span className="fa fa-list-ol me-0_25" aria-hidden="true"></span> {this.props.steps[0].title}</h1>
                <ol className={"toc"}>
                    {this.props.steps.slice(1,this.props.steps.length).map((step,i) => {
                        const key = step.key;
                        const value = step.title;
                        const complete = <span className="fa fa-table me-0_25" aria-hidden="true"></span>
                        const createShare = <span className="fa fa-file-export me-0_25" aria-hidden="true"></span>

                        return (
                            !this.complete(key) && !this.isCreateShare(key)?
                            <li key={key}>
                                <DocLink section={key} onClick={() => this.trackEvent(i + 1)}>
                                    {value}
                                </DocLink>
                            </li>
                            :
                                this.complete(key) ?
                                    <li key={key} className="list-nostyle mx-0">
                                        <DocLink section={key} onClick={() => this.trackEvent('review')}>
                                            {complete}{value}
                                        </DocLink>
                                    </li>
                                : this.isCreateShare(key)?
                                    <li key={key} className="list-nostyle mx-0">
                                        <DocLink section={key} onClick={() => this.trackEvent('create-share')}>
                                            {createShare}{value}
                                        </DocLink>
                                    </li>
                                : null
                        );}
                    )}
                </ol>
            </React.Fragment>
        );
    }

    complete(stepKey) {
        return stepKey === "complete" ? true : false;
    }
    isCreateShare(stepKey){
        return stepKey === "create-share" ? true : false;
    }

}
Steps.propTypes = {
    doc: PropTypes.object.isRequired,
    steps: PropTypes.array.isRequired,
};

import React from "react";
import AppContext from "../AppContext";
import initUI from "../ui";
import AboutContent from "./AboutContent";
import Breadcrumb from "../Breadcrumb";
import YouTubeVideo from "./YouTubeVideo";
import {doHashScroll} from "./HashScroll";

export default class About extends React.Component{
    static contextType = AppContext;

    componentDidMount() {
        initUI();
        doHashScroll();
    }

    render() {

        let playlist = [
            {
                id: 1,
                name: 'An introduction to Project Corgi',
                video: 'wsuItKMaEBY',
                poster: 'user-support-video-poster-intro.png',
                description: 'Jamie Basham and Jose Blackorby discuss the origin and evolution of the Corgi tool.'
            },
            {
                id: 2,
                name: 'Meet Corgi',
                video: 'ViQjOM3NK7c',
                poster: 'user-support-video-poster-meet.png',
                description: 'Corgi is a set of digital graphic organizers that helps you co-organize your learning.'
            }
        ];

        return(
            <div className="page-wrapper">
                <div id="content" className="content row">
                    <div className="content-main col">
                        <div className="content-main-inner">
                            <Breadcrumb name="About Corgi" />
                            <main id="main_container">

                                <h1>Corgi: Co-organize your learning</h1>

                                <ul className="list-unstyled">
                                    <li className="list-item mb-0_5"><a href="#background">Background</a></li>
                                    <li className="list-item mb-0_5"><a href="#why">The Corgi Process</a></li>
                                    <li className="list-item mb-0_5"><a href="#research">Research</a></li>
                                    <li className="list-item mb-0_5"><a href="#features">Key Features</a></li>
                                    <li className="list-item mb-0_5"><a href="#teach">Teach with Corgi</a></li>
                                    <li className="list-item mb-0_5"><a href="#technology">Technology</a></li>
                                </ul>

                                <div className="pt-1 py-md-1_5">
                                    <h2 id="background" className="h1">Background</h2>
                                    <div className="container px-0 px-md-1">
                                        <div className="row gx-0 gx-md-1">
                                            <div className="col-md-6 px-1">
                                                <YouTubeVideo video_id={playlist[0].video} name={playlist[0].name} />
                                                <a className="link-icon" target="_blank" href={"https://youtu.be/" + playlist[0].video} rel="noopener noreferrer">
                                                    {playlist[0].description}
                                                    <span className="fas fa-external-link-alt" title="(external site)"></span>
                                                </a>
                                            </div>
                                            <div className="col-md-6 px-1">
                                                <YouTubeVideo video_id={playlist[1].video} name={playlist[1].name} />
                                                <a className="link-icon" target="_blank" href={"https://youtu.be/" + playlist[1].video} rel="noopener noreferrer">
                                                    {playlist[1].description}
                                                    <span className="fas fa-external-link-alt" title="(external site)"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <AboutContent/>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import T from "i18n-react";
import React from "react";
import VideoModalButton from "./VideoModalButton";
import OrganizerComponent from "../organizers/OrganizerComponent";

// Wilcard import due to dynamic string builds below
// See: https://parceljs.org/features/dependency-resolution/#glob-specifiers
import * as exampleSvg from 'url:/public/example/*.svg';

/**
 * Displays a card for a CER type supported by the application.
 * It provides:
 *   - a link to video about the CER type
 *   - a text description (blurb) of the CER type
 *   - a link to a completed example of the CER type (PDF)
 *   - a link to a newly created document of that type
 */


export default class Card extends OrganizerComponent {

    render() {
        const abbr = T.translate(this.props.docType+".abbr");

        // Unused
        //const tour = this.props.docType === "question-exp"? "question-exploration-tour": this.props.docType+"-tour";
        //const mp4 = tour === "unit-org-2-tour"? null: tour; // "/video/"+tour+".mp4";
        //const example = this.props.docType === "unit-org-2"? null: "/example/"+abbr+"_Sci.pdf";

        const flowChart = this.props.docType === "unit-org-2" ? null : exampleSvg[abbr + "_flowChart"];

        const blurb = T.translate(this.props.docType+".blurb");
        const title = T.translate(this.props.docType+".title");
        // TODO: make /:docType/new work
        const newDocType = "#new"+this.props.docType.charAt(0).toUpperCase() + this.props.docType.slice(1);
        return (
            <div className="col">
                <div className="card card-newguide">
                    {false ? Card.videoLink(title,mp4) : null}
                    <div className="card-img">
                        <img src={flowChart} className="img-fluid" alt={title + " flowchart"}/>
                    </div>
                    <div className="card-body">
                        <h3><a href={newDocType} onClick={this.props.creator}>{title}</a></h3>
                        <p className="card-text">{blurb}</p>
                        {false? Card.exampleLink(example): null}
                    </div>
                </div>
            </div>
        )
    }

    static exampleLink(example) {
        return example?
            <p className="card-text">
                <a href={example} target="_blank" rel="noopener noreferrer" className="link-chevron">See preview</a>
            </p>
            : null;
    }

    static videoLink(title,mp4) {
        return mp4?
            <VideoModalButton title={title} video={mp4} caption={""} label={""}/>
            : null;
    }
}

import React, {Component, Fragment} from 'react';
import DisplayImage from "./DisplayImage";
import DisplayVideo from "./DisplayVideo";
import DisplayFile from "./DisplayFile";
import RemoveImageButton from "./RemoveImageButton";
import AddImageButton from "./AddImageButton";
import {join, dataPathSep} from "./DataPath";
import AppContext from "../AppContext";
import {storageBucket} from '../hosting';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

var isUtf8 = require('is-utf8');

export default class OptionalImage extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.noImage = {src: ""};

        this.state = {image: this.noImage,fileName:""};
        this.remove = this.remove.bind(this);
        this.persist = this.persist.bind(this);
        this.storeDriveImage = this.storeDriveImage.bind(this);
        this.storeUploadedImage = this.storeUploadedImage.bind(this);
        this.upload = this.upload.bind(this);
        this.isDrive = this.isDrive.bind(this);
        this.isLocal = this.isLocal.bind(this);
        this.isStored = this.isStored.bind(this);
        this.isRemoved = this.isRemoved.bind(this);
        this.isStored = this.isStored.bind(this);
        this.getMetadata = this.getMetadata.bind(this);
        this.saveFile = this.saveFile.bind(this);

        this.imagePath = null;
        this.imageRef = null;
        this.fileNameRef = null;
        this.altPath = null;
        this.altRef = null;
        this.storageRef = null;

        this.updateContent = this.props.updateContent;
    }

    componentWillMount() {
        if (!this.imagePath) {

            const doc = this.context;
            const dp = doc.fileType ? ["", doc.fileType, doc.fileId, this.props.identifier].join(dataPathSep) : null;
            this.imagePath = dp ? join(dp, "image") : null; // id?
            this.imageRef = window.firebase.database().ref(this.imagePath);
            let fileNamePath = dp ? join(dp, "fileName") : null;
            this.fileNameRef = window.firebase.database().ref(fileNamePath);
            this.storageRef = window.firebase.storage().ref();
            this.imageWatcher = this.imageRef.on('value', (snapshot) => {
                    var value = snapshot.val();
                    if (this.state.image.src !== value) {
                        // TODO: also set alt text metadata on uploaded file
                        this.setState({ image: {src: value }});
                    }
            });

            this.fileNameWatcher = this.fileNameRef.on('value', (snapshot) => {
                var value = snapshot.val();
                if (null !== value && undefined !== value) {
                    this.setState({ fileName: value});
                }
            });
        }
    }

    componentWillUnmount() {
        this.imageRef.off('value', this.imageWatcher);
        this.fileNameRef.off('value', this.fileNameWatcher);
    }

    getMetadata(image) {
        return {contentType: image.mimeType, contentEncoding: 'binary',
            contentDisposition: "inline; filename*=binary''"+image.name,
            customMetadata: {original: image.src, description: image.description}
        }
    }

    upload(image) {
        let storedImage = image;
        const imageRef = this.imageRef;
        const userImage = "uc/"+this.context.currentUser.uid+"/"+image.name;
        const storedImageRef = this.storageRef.child(userImage)
        const storedMeta = this.getMetadata(image);

        return new Promise(resolve => {
            // Important! must to pass ArrayBuffer or resulting image is corrupted
            storedImageRef.put(image, storedMeta).then(function (fileSnapshot) {
                // 3 - Generate a public URL for the file.
                return fileSnapshot.ref.getDownloadURL().then((url) => {
                    storedImage.src = url;
                    console.log('Image %s upload Done', storedImage.name);
                    window.tracker.trackEvent("Content", "add image", "upload");
                    imageRef.set(url);
                    resolve(storedImage);
                });
            });
        });
    }


    storeUploadedImage(image) {
        this.upload(image);
    }

    storeDriveImage(image) {
        let storedImage = image;
        const imageRef = this.imageRef;
        const userImage = "uc/"+this.context.currentUser.uid+"/"+storedImage.name;
        const storedImageRef = this.storageRef.child(userImage);
        const storedMeta = this.getMetadata(image);

        // download image from Drive
        return new Promise(resolve => {
            window.gapi.client.drive.files.get({
                fileId: image.id,
                responseType: 'bytearray',
                alt: 'media'
                }).then((response) => {
                    console.debug('drive file data is array %s, buffer %s, length %s, UTF8 %s',
                        Array.isArray(response.body), Buffer.isBuffer(response.body),response.body.length, isUtf8(response.body));

                    let buf = Buffer.from(response.body,'binary');

                    console.debug('Image %s download %d Done - buf[1] = 0x%s, buf[%d] = 0x%s',
                        image.mimeType, buf.length, buf.slice(1,2).toString('hex').toUpperCase(),
                        buf.length-1,buf.slice(buf.length-1,buf.length).toString('hex').toUpperCase()
                    );
                        // Important! must to pass ArrayBuffer or resulting image is corrupted
                    storedImageRef.put(buf.buffer,storedMeta).then(function(fileSnapshot) {
                        // 3 - Generate a public URL for the file.
                        return fileSnapshot.ref.getDownloadURL().then((url) => {
                            storedImage.src = url;
                            console.log('Image %s upload Done',storedImage.name);
                            window.tracker.trackEvent("Content", "add image", "drive");
                            imageRef.set(url);
                            resolve(storedImage);
                        });
                    });

                },
                (err_reason) => {
                    const err = "Could not get Drive Image: " + err_reason.result.error.message;
                    console.log(err);
                    alert(err);
                    resolve(storedImage);
                })
        });
    }

    isLocal(image) {
        return image.src.startsWith("blob:");
    }
    isDrive(image) {
        return image.src.includes('drive.google.com');
    }
    isStored(image) {
        return image.src.includes(storageBucket);
    }
    isRemoved(image) {
        return this.noImage === image;
    }

    isFileAttack(fileName){
        if(fileName !== null && fileName !== "")
            return true;
        else return false;
    }
    isExternal(image) {
    let url = null;
        try {
            url = new URL(image.src);
        } catch (e) {
            if (null === url)
            return false;
        }

        return !this.isDrive(image) && !this.isLocal(image)
            && !this.isStored(image) && !this.isRemoved(image);
    }

    saveFile(file){
        console.log(file);
        let image = this.state.image;
        let imageRef = this.imageRef;
        let fileNameRef = this.fileNameRef;

        if (null === this.imagePath || null === this.imageRef) {
            return null;
        }

        if (null != file && image.src !== file.src) {
            image = file;
        }

        imageRef.set(image.src);
        if(image.src === "") fileNameRef.set("");

        if(null !== file.name && undefined !== file.name)
            fileNameRef.set(file.name);

        this.context.contentUpdated();
        if(undefined !== this.updateContent) this.updateContent();
        return image;
    }

    persist(newImage) {
        console.log(newImage);
        let image = this.state.image;
        let imageRef = this.imageRef;
        let fileNameRef = this.fileNameRef;

        if (null === this.imagePath || null === this.imageRef) {
            return null;
        }

        if (null != newImage && image.src !== newImage.src) {
            image = newImage;

            if (this.isDrive(image)) {
                this.storeDriveImage(image).then(storedImage => {
                    image = storedImage;
                });
            } else if (this.isLocal(image)) {
                this.upload(image).then(storedImage => {
                    image = storedImage;
                    URL.revokeObjectURL(image.src); // allow browser to free File
                });
            }
        }

        if (this.isStored(image)
         || this.isExternal(image)
         || this.isRemoved(image)) {
            imageRef.set(image.src);
            if(image.src === "") fileNameRef.set("");
        }

        this.context.contentUpdated();
        if(undefined !== this.updateContent) this.updateContent();
        return image;
    }

    remove() {
        const type = isEmbedYoutube(this.state.image.src) ? "video" : "image";
        console.debug("Remove " + type);
        window.tracker.trackEvent("Content", "remove " + type, "");
        if(undefined !== this.updateContent) this.updateContent();
        this.persist(this.noImage);
    }

    render() {
        const image = this.state.image;
        const fileName = this.state.fileName;
        const isYoutubeVideo = isEmbedYoutube(image.src);
        const isFileAttack = this.isFileAttack(fileName);

        return (
            (null === image || undefined === image.src || null === image.src  || "" === image.src || (image.src instanceof Object) ) ?
                <AddImageButton identifier={this.imagePath} saveMethod={this.persist} saveFile={this.saveFile}></AddImageButton>
                :
                <Fragment>
                    <div className="attachment">
                        {!isFileAttack?
                            isYoutubeVideo? <DisplayVideo video={image}/> : <DisplayImage image={image}/>
                            :<DisplayFile file={image} fileName={fileName}/>
                        }
                        <RemoveImageButton identifier={this.imagePath} saveMethod={this.persist} removeMethod={this.remove}/>
                    </div>
                    {isFileAttack ? <span className="directions">Make sure your file is shared</span>: null}
                </Fragment>
        );
    }
}

export function isEmbedYoutube(image){
    if(image !== null)
        return image.includes("youtube.com/embed");
    else return false;
}

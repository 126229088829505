import React from 'react';
import SttInput from "./SttInput";

export default class Description extends React.Component {

    render() {
        return (<div className={"form-group"}>
                <SttInput identifier={this.props.identifier} inputType={"text-area"} addImage={true} />
            </div>);
    }
}

import React, {Component} from 'react';
import AppContext from "../AppContext";
import {bpvAsideMain} from "./layout";
import SlidesButton from "./SlidesButton";

import imgGoogleClassroom from 'url:/public/img/google_classroom-square.svg';
import imgGoogleSlidesIcon from 'url:/public/img/google-slides-icon-x16.png';

export default class CreateShare extends Component{
    static contextType = AppContext;
    constructor(props){
        super(props);
        this.state = {
            slides:{}
        }
        this.slideRef = null;
    }

    getRef(identifier) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + identifier;
        return window.firebase.database().ref(path);
    }

    openClassroomWindow = () => {
        // See: https://developers.google.com/classroom/guides/sharebutton#customization
        const driveUrl = 'https://drive.google.com/file/d/' + this.context.fileId + '/view';
        const classroomShare = "https://classroom.google.com/share?url=" + encodeURI(driveUrl);
        window.open(classroomShare, "Popup", "noopener, noreferrer, popup=yes, toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=400, top=50, left=50");
    }

    render(){
        const printUrl = ["", this.context.fileType, this.context.fileId, "print"].join("/");
        let slideList = this.state.slides;
        const slideItems = [];
        if(slideList){
            for(let key in slideList){
                if(slideList.hasOwnProperty(key)){
                    slideItems.push(<Slide key={key} url={slideList[key].url} id={slideList[key].id}/>);
                }
            }
        }
        return(
            <React.Fragment>
                <div className="container">
                    <main id="main_container" className="main">
                        <h1 id="content" className="h1">Create &amp; Share</h1>
                        <p>When your Corgi Guide is complete, present, print, save, or share it.</p>

                        <div className="section">
                            <div className="row row-cols-2 row-cols-md-4">
                                <div className="col text-center mb-1">
                                    <SlidesButton/>
                                    <div className="h2">
                                        Create a Slide Presentation
                                    </div>
                                </div>
                                <div className="col text-center mb-1">
                                    <a href="#share" onClick={this.props.shareMethod} className="btn btn-large btn-create">
                                        <div className="btn-create-img">
                                            <span className="fa fa-share-square" aria-hidden="true"></span>
                                        </div>
                                        <div className="sr-only">
                                            Share this Guide
                                        </div>
                                    </a>
                                    <div className="h2">
                                        Share this Guide
                                    </div>
                                </div>
                                <div className="col text-center mb-1">
                                    <a href={printUrl} className="btn btn-large btn-create">
                                        <div className="btn-create-img">
                                            <span className="fa fa-print" style={{marginRight: '.125rem'}} aria-hidden="true"></span>
                                            <span className="fa fa-file-pdf" style={{marginLeft: '.125rem'}} aria-hidden="true"></span>
                                        </div>
                                        <div className="sr-only">
                                            Print / Save to PDF
                                        </div>
                                    </a>
                                    <div className="h2">
                                        Print / Save to PDF
                                    </div>
                                </div>
                                <div className="col text-center mb-1">
                                    {/* <div id="gcPostButton"></div> */}
                                    <button className="btn btn-create" onClick={this.openClassroomWindow}>
                                        <div className="btn-create-img">
                                            <img src={imgGoogleClassroom} alt="" />
                                        </div>
                                        <div className="sr-only">Post to Google Classroom</div>
                                    </button>

                                    <div className="h2">
                                        Post to Google Classroom
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section section-border-top">
                            <h2>Slide Presentations you have created:</h2>

                            {this.state.slides != null ?
                                <ul className="list-unstyled">
                                    {slideItems}
                                </ul>
                                : null
                            }

                        </div>
                    </main>
                </div>

            </React.Fragment>
        )
    }

    componentDidMount() {
        bpvAsideMain();
        const driveUrl = 'https://drive.google.com/file/d/' + this.context.fileId + '/view';
        window.gapi.sharetoclassroom.render(
            'gcPostButton',
            {
                'url': driveUrl,
                'title': this.context.fileName,
                'size': 80
            }
        );

        this.slideRef = this.getRef("slide");
        this.slideRef.once('value', data => {
            if (data.exists()) {
                this.setState({slides:data.val()});
            }
        });
    }
}

class Slide extends Component{
    constructor(props){
        super(props);
        this.state = {
            slideId: this.props.id,
            fileName: null,
            lastModified: null,
            fileError: false
        }
    }

    componentDidMount(){
        this.loadFile(this.state.slideId);
    }

    loadFile(id) {
        this.getMetadata(id).then(metadata => {
            if(null !== metadata && undefined !== metadata){
                let mTime = new Date(metadata.modifiedTime).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit',second:'2-digit'});
                this.setState({fileName:metadata.name, lastModified:mTime})
            }
        });
    }

    getMetadata(id) {
        return new Promise(resolve => {
            window.gapi.client.request({
                path: "https://www.googleapis.com/drive/v3/files/" + id,
                method: "GET",
                params: {
                    fields: "id, name, modifiedTime"
                }
            }).then((response) => {
                    resolve(response.result);
                },
                (err_reason) => {
                    if (err_reason.result && err_reason.result.error) {
                        console.warn("Could not get file metadata: " + err_reason.result.error.message);
                    } else {
                        console.info("Could not get file metadata: ", err_reason);
                    }
                    this.setState({
                        fileError: true
                    });
                    if(err_reason.result.error.code === 401) {
                        this.context.handleAccessTokenExpired();
                    }
                });
        });
    }

    render(){
        return(
            <React.Fragment>
                {!this.state.fileError ?
                    <li className="media mb-0_25">
                        <span className="slide-img me-0_5">
                            <img src={imgGoogleSlidesIcon} alt="Google Slide document"/>
                        </span>
                        <div className="media-body">
                            <div className="hinline">
                                <h3 className="h6"><a href={this.props.url}>{this.state.fileName}</a></h3>
                                <span className="fs-small">last modified {this.state.lastModified}</span>
                            </div>
                        </div>
                    </li>
                    : null
                }

            </React.Fragment>
        )
    }
}

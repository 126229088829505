import React, {Component} from 'react';

const AppContext = React.createContext({});
export default AppContext;

export class AppContextProvider extends Component {

    render() {
        return (
            <AppContext.Provider value={this.props.value}>
                {this.props.children}
            </AppContext.Provider >
        );
    }
}

import $ from "jquery";
window.jQuery = window.$ = $;
require("figuration/dist/js/figuration");  // eslint-disable-line import/no-webpack-loader-syntax

/* Should be called after page load to initialize UI elements */
export default function initUI() {
    if ($(document).CFW_Init)
        $(document).CFW_Init();
}

// Refresh any CodeMirror instances in a modal after it is shown.
$('body').on('afterShow.cfw.modal', '.modal', function(e) {
    $(this).find('.CodeMirror').each(function(e) {
        this.CodeMirror.refresh();
    });
});

// Adjust positioning of some elements when window scrollbar updated for modal/offcanvas
$(document)
    .on('beforeShow.cfw.modal beforeShow.cfw.offcanvas', function() {
        var rect = document.body.getBoundingClientRect();
        var bodyIsOverflowing = Math.round(rect.left + rect.right) < window.innerWidth;
        var scrollbarWidth = $.CFW_measureScrollbar();

        var $tts = $('.header-tts');
        var $tour = $('.footer-pages-tour');

        if ($tts.length) {
            var ttsTransform = $tts.css('transform');
            if (bodyIsOverflowing && window.innerWidth < 1600) {
                $tts
                    .data('corgi.transform', ttsTransform)
                    .css('transform', ttsTransform + ' translateX(-' + scrollbarWidth / 2 + 'px)');
            }
        }

        if ($tour.length) {
            var tourMargin = $tour.css('margin-right');
            var tourComputed = parseFloat(window.getComputedStyle($tour[0]).getPropertyValue('margin-right'));
            if (bodyIsOverflowing && window.innerWidth < 1600) {
                $tour
                    .data('corgi.margin', tourMargin)
                    .css('margin-right', tourComputed + scrollbarWidth + 'px');
            }
        }
    })
    .on('afterHide.cfw.modal afterHide.cfw.offcanvas', function() {
        var $tts = $('.header-tts');
        var $tour = $('.footer-pages-tour');

        if ($tts.length) {
            var ttsTransform = $tts.data('corgi.transform');
            if (typeof ttsTransform !== 'undefined') {
                $tts.css('transform', ttsTransform);
                $tts.removeData('corgi.transform');
            }
        }

        if ($tour.length) {
            $tour.css('margin-right', '');
            $tour.removeData('corgi.margin');
        }
    });
import React, {useContext} from "react";
import AppContext from "../AppContext";

export default function PrintButton (props) {
    const doc = useContext(AppContext);
    const btnClass = props.buttonClassName ? props.buttonClassName
        : "btn btn-primary btn-small";
    const closeClass = props.closeClassName ? props.closeClassName
        : "btn btn-link me-0_25";

    return (
        "print" !== doc.fileSection ? null :
            <div className="header-btns">
                <button type="button" className={closeClass}
                    onClick={(event)=> {
                        window.history.back();
                    }}>
                    <span className="fa fa-xmark me-0_25 fs-xlarge valign-middle" aria-hidden="true"></span>
                    Close
                </button>
                <button type="button" className={btnClass}
                    onClick={(event)=> {
                        window.print();
                    }}>
                    <span className="fa fa-print me-0_25" aria-hidden="true"></span>
                    Print
                </button>
            </div>
    );
}


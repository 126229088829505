import React, {Fragment} from "react";
import StepHeader from "../StepHeader";
import ReadOnlyField from "../../ReadOnlyField";
import LikeCharacteristics from "../../components/LikeCharacteristics";

// Step 4 Similar Characteristics
export default class SimilarCharacteristics extends React.Component {

    constructor(props) {
        super(props);

        this.sourceA = "characteristics1";
        this.sourceB = "characteristics2";
        this.showLabel = (undefined !== this.props.showLabel)? this.props.showLabel: false;

        this.childRef1 = React.createRef();
    }

    changeMakePairStatus = () =>{
        this.childRef1.current.changeMakePairStatus();
    }

    render() {
        const doc = this.props.doc;
        const label='print' !== doc.section && (undefined !== this.props.section.likeLabel) ? this.props.section.likeLabel : null;
        const showCategory = undefined !== this.props.showCategory && this.props.showCategory;
        const like = <LikeCharacteristics ref={this.childRef1} doc={doc} showCategory={showCategory} showBoth={true} showLabel={this.showLabel}
                                        editing={this.props.editing}
                                        label={label}
                                        header={(undefined !== this.props.header) ? this.props.header : null}
                                        directions={this.props.editing ? this.props.section.description : null}
                                        unpairedCharacteristics1={this.props.data.unpairedCharacteristics1}
                                        unpairedCharacteristics2={this.props.data.unpairedCharacteristics2}
                                        pairedCharacteristics={this.props.data.likePairedCharacteristics}/>
        ;
        return (
            ('complete' !== this.props.section.key && 'create-share' !== this.props.section.key)
            ?   <Fragment>
                    <StepHeader tooltipTitle={true} section={this.props.section} editing={this.props.editing}/>
                    <h2>Essential Question</h2>
                    <ReadOnlyField identifier="essentialquestion" className="box bg-autofill" />
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Concept A</h2>
                            <ReadOnlyField identifier="concept1" className="box bg-autofill"/>
                        </div>
                        <div className="col-md-6">
                            <h2>Concept B</h2>
                            <ReadOnlyField identifier="concept2" className="box bg-autofill"/>
                        </div>
                    </div>
                    {like}
                </Fragment>
                :
                <Fragment>
                    {like}
                </Fragment>
        )
    };

}

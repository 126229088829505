import DisplayVideo from "../DisplayVideo";
import React from "react";
import DynamicComponent from "../../DynamicComponent";
import $ from "jquery";

async function validEmbedYouTubeUrl(url){
    console.debug('Testing youtube url: ', url);
    let urlLink = url.href;
    var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    let videoId = null;
    if(urlLink.match(regExp)){
        videoId = urlLink.match(regExp)[1];
        let embedUrl = "https://www.youtube.com/embed/" + videoId + "?enablejsapi=1";
        return await checkVideo(embedUrl,videoId);
    }
    return false;
}

function checkVideo(url,videoId) {
    console.debug('checking: ', url);
    return new  Promise(resolve => {
        const image = new Image();
        image.addEventListener('load', () => {
            console.debug('loaded', image, image.width);
            //if the video does not exist(therefore thumbnail don't exist), a default thumbnail of 120 width is returned.
            if(image.width !== 120)
                resolve(url);
            else resolve(false);
        });
        image.addEventListener('error', () => {
            console.debug('errored');
            resolve(false);
        });
        image.src =  "https://img.youtube.com/vi/" + videoId + "/mqdefault.jpg";
    })
}


export default class AddYouTubeModal extends DynamicComponent {

    constructor(props) {
        super(props);

        this.state = {
            'video': null,
            'error': null
        }

        this.resetInput = this.resetInput.bind(this);
    }

    componentWillMount() {
        this.addImgUrlInputId = 'addyoutubemodalinput';
    }

    render() {
        return  <div id={this.props.id} className="modal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="h1 modal-title">Embed a YouTube Video</h2>
                        <button type="button" className="close" data-cfw-dismiss="modal" aria-label="Close"
                                onClick={(event)=> {
                                    event.preventDefault();
                                    this.resetInput();
                                }}>
                            <span className="fa fa-times" aria-hidden="true"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="row gx-0_5" role="search"
                              onSubmit={(event)=> {
                                  event.preventDefault();
                                  try {
                                      let url = new URL(event.target[0].value);
                                      validEmbedYouTubeUrl(url).then((value) =>{
                                          if (!value){
                                              this.setState({
                                                  'error': 'YouTube Video not found or not supported.',
                                                  'video': null
                                              });
                                          } else {
                                              this.setState( {
                                                  'error': null,
                                                  'video': value
                                              });
                                          }
                                      });
                                  } catch (e) {
                                      this.setState( {
                                          'error': "Sorry, " + e.message,
                                          'video': null
                                      })
                                  }
                              }}>
                            <div className="col">
                                <label htmlFor={this.addImgUrlInputId} className="fs-small sr-only">YouTube URL</label>
                                <input id={this.addImgUrlInputId} type="url" className="form-control"
                                       placeholder="Paste a YouTube URL here"
                                       required={true}
                                       onClick={(event)=> {
                                           event.target.value = null
                                       }}/>
                            </div>
                            <div className="col-sm-auto mt-0_5 mt-sm-0">
                                <button type="submit" className="btn btn-primary">Preview</button>
                            </div>
                        </form>

                        {/* <!-- When error is indicated --> */}
                        {this.state.error ?
                            <div className="mt-1 font-weight-bold">
                                <span className="fa fa-exclamation-circle text-danger fs-xlarge me-0_25"
                                      aria-hidden="true"></span>
                                {' '} {this.state.error}
                            </div>
                            : null
                        }

                        {/* <!-- When video is available --> */}
                        {this.state.video ?
                            <div className="attachment mt-1">
                                <DisplayVideo video={{'src': this.state.video}} />
                            </div>
                            : null
                        }
                    </div>
                    {this.state.video ?
                        <div className="modal-footer">
                            <button type="button" className="btn btn-link" data-cfw-dismiss="modal"
                                onClick={(event)=> {
                                    event.preventDefault();
                                    this.resetInput();
                                }}>Cancel</button>
                            <button type="button" className="btn btn-primary"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.props.saveMethod({'src': this.state.video});
                                    this.resetInput();
                                }}>Add Video</button>
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>;
    }

    resetInput() {
        $('#' + this.addImgUrlInputId).val(null);

        this.setState({
            'error': null,
            'video': null
        });
    }

}

import React, {Fragment} from 'react';
import StepHeader from "../StepHeader";
import SttInput from "../../components/SttInput";
import ReadOnlyField from "../../ReadOnlyField";
import AppContext from "../../AppContext";

export default class Extensions extends React.Component {
    static contextType = AppContext;

    constructor() {
        super();
        this.identifier = "extensions";
    }

    render() {
        return (
            <Fragment>
                <StepHeader section={this.props.section}/>
                <h2>Summary</h2>
                <ReadOnlyField identifier="answer" className="box bg-autofill" />
                { this.context.canEdit ?
                    <Fragment>
                        <div className="box box-primary">
                            <SttInput {...this.props} inputType={"text-area"} identifier={this.identifier} addImage={true} singleLine={false}/>
                        </div>
                    </Fragment>
                 :
                    <Fragment>
                        <div className={"box bg-autofill"}>
                            <div className={"row"}>
                                <ReadOnlyField identifier={this.identifier} className={"grouping-text"} />
                                <ReadOnlyField identifier={this.identifier + "/image"} className={"grouping-img"} />
                            </div>
                        </div>
                    </Fragment>
                }
            </Fragment>
        );
    }
}
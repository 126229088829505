import RelatedQuestionList from "../../components/RelatedQuestionList";
import React, {Fragment} from "react";
import StepHeader from "../StepHeader";
import ReadOnlyField from "../../ReadOnlyField";

export default class RelatedQuestionsResponse extends React.Component {

    constructor(props) {
        super(props);
        this.identifier = "relatedquestionresponse";
    }

    render() {
        return (
            <Fragment>
                <StepHeader section={this.props.section} />
                <h2>Essential Question</h2>
                <ReadOnlyField identifier="essentialquestion" className="box bg-autofill" />
                <RelatedQuestionList doc={this.props.doc} identifier="relatedquestion" haveResponse={true} canBeEmpty={false} data={this.props.data}/>
            </Fragment>
        );
    }

}
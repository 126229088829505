import React,{Fragment} from 'react';
import KeyTermsButton from "./KeyTermsButton";
import $ from "jquery";
import AppContext from "../AppContext";
import uniqueId from "../uniqueId";

export default class StepHeader extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.headerId = uniqueId();

        this.openKeyTermModal = this.openKeyTermModal.bind(this);
        this.logLink = this.logLink.bind(this);
    }

    openKeyTermModal(){
        window.tracker.trackEvent("Interface", "open key terms", "link");
        $('#buttonKeyTerms').click();
    }

    logLink(name){
        window.tracker.trackEvent("Navigation", "sample link", 'sample ' + this.context.fileType);
    }

    componentDidMount() {
        $('#'+this.headerId).on('afterShow.cfw.tooltip', function(e) {
            window.tracker.trackEvent("Support", "glossary", e.target.text);
        });
    }

    render() {
        const editing = (undefined === this.props.editing) || this.props.editing;
        const defineKeyTerm = undefined !== this.props.defineKeyTerm ? this.props.defineKeyTerm : false;
        const tooltipTitle = undefined !== this.props.tooltipTitle ? this.props.tooltipTitle : false;
        const title = this.props.section.stepnum?
            this.props.section.stepnum+". "+this.props.section.title
            : this.props.section.title? this.props.section.title
            : this.props.section.label;
        const terms = "Key Terms" !== this.props.section.label && "terms" !== this.props.section.key?
            <KeyTermsButton editing={editing}/>
            : null;

        let mTitle = "";
        if(tooltipTitle){
            let key = this.props.section.tooltip.key;
            let splitArray = this.props.section.title.match('(.*)'+key+'(.*)');
            let prefix = "";
            let subfix = "";
            if(null !== splitArray && splitArray.length >= 2){
                prefix = splitArray[1];
                subfix = splitArray[2];
            }

            mTitle = <h1>{this.props.section.stepnum}. {prefix} <a href="# " role="button" className="vocab-word" data-cfw="tooltip" data-cfw-tooltip-trigger="click"  data-cfw-tooltip-placement="bottom auto" data-cfw-tooltip-custom-class="vocab-tooltip" title={this.props.section.tooltip.definition}>{key}</a> {subfix}</h1>
        }

        // We link to the sample guides from each numbered step (not TOC, Review, or Share).
        const showSampleLink = !!this.props.section.stepnum;
        const sampleStyle = (this.context.currentUser.role==='teacher') ? 'annotated' : 'plain';
        let sampleUrl = showSampleLink ? '/sample/' + sampleStyle + '/' + this.context.fileType + '#sec_' + this.props.section.key : '';

        return (
            <div id={this.headerId}>
                <div className="hinline">
                    {!tooltipTitle && <h1>{title}</h1>}
                    {tooltipTitle && <React.Fragment>{mTitle}</React.Fragment>}
                    {/* {editing && 'complete' !== this.props.section.title?
                    <StepInfoButton step={this.props.section} isInModal={this.props.isInModal}/>
                    :null} */}
                </div>
                {editing?
                    <React.Fragment>
                        <p className="directions">{this.props.section.description}
                            { defineKeyTerm ? <Fragment> Define your <a href="# " onClick={this.openKeyTermModal}>Key Terms</a></Fragment> : null}
                        </p>
                        { showSampleLink ? <p><a href={sampleUrl} onClick={this.logLink}>Sample guide</a></p> : null}
                        {terms}
                    </React.Fragment>
                : null}

            </div>
        );
    }
}

import React from 'react';
import { Component } from 'react';

import Breadcrumb from "../Breadcrumb";
import AppContext from "../AppContext";
import T from 'i18n-react';

export default class Attributions extends Component {
    static contextType = AppContext;

    componentDidMount() {
        window.tracker.trackPageView(this.context.currentUser, 'attributions');
    }

    render() {
        return (
            <div className="container-max">
                <div className="page-wrapper">
                    <div id="content" className="content row">
                        <div className="content-main col">
                            <div className="content-main-inner">
                                <Breadcrumb name="Attributions" />
                                <main id="main_container">
                                    <h1 id="content">Attributions</h1>
                                    <h2 className="h3">Corgi Application</h2>
                                    <T.p text={{ key: "utility.footer" }}></T.p>

                                    <h2 className="h3">{T.translate("cause-effect.title")}</h2>
                                    <T.p text={{ key: "cause-effect.footer" }}></T.p>

                                    <h2 className="h3">{T.translate("question-exp.title")}</h2>
                                    <T.p text={{ key: "question-exp.footer" }}></T.p>

                                    <h2 className="h3">{T.translate("comparison.title")}</h2>
                                    <T.p text={{ key: "comparison.footer" }}></T.p>

                                    <h2 className="h3">{T.translate("cera.title")}</h2>
                                    <T.p text={{ key: "cera.footer" }}></T.p>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

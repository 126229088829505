import React, {useContext} from 'react';
import AppContext from "../AppContext";
import ReactDOMServer from "react-dom/server";

export const dataPathSep = '/';

export function join(parent,child) {
    return [parent,child].join(dataPathSep);
}

export default function DataPath(props) {
        const doc = useContext(AppContext);
        //const {id,setId} = useState(props.id);
        let path = null;
        if (doc.fileType && doc.fileId && props.identifier) {
            path = dataPathSep+join(doc.fileType,doc.fileId);
            return join(path,props.identifier);
        } else {
            console.log("dataPath called with no context");
        }
        return path;
}

export function dataPath(identifier) {
    return ReactDOMServer.renderToString(<DataPath identifier={identifier} />);
}

import PairedCharacteristics from './PairedCharacteristics';

export default class LikeCharacteristics extends PairedCharacteristics {

    constructor(props) {
        super(props);

        this.charIdentifier = "likecharacteristics";
        this.catIdentifier = "likecategories";
        this.myStatus = "like";
        this.buttonTextKey = "comparison.likecharacteristics.buttonText";
        this.modalTitleKey = "comparison.likecharacteristics.modalTitle";
        this.showBoth = undefined !== props.showBoth? props.showBoth: false;
        this.showLabel = undefined !== props.showLabel? props.showLabel: true;
    }

}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Video from './Video';
import {empty} from "./textutil";
import $ from "jquery";
import uniqueId from "../uniqueId";
import {hosting} from '../hosting';

export default class VideoModalButton extends Component {

    constructor(props) {
        super(props);

        this.videoButtonId = uniqueId();
    }

    componentDidMount() {
        // ensure modal wiring
        const button = $('#' + this.videoButtonId);
        if (button.CFW_Modal) {
            button.CFW_Modal();
        }
    }

    render() {
        const caption = empty(this.props.caption) ? null : <span>{this.props.caption}</span>;
        const posterURL = hosting + "/video/" + this.props.video + "-lg.jpg";

        return (
            <div className="p-1 modal-open">
                <button id={this.videoButtonId} type={"button"} className="btn link-video" data-cfw="modal" data-cfw-modal-target={"#"+this.props.video}>
                    <img src={posterURL} alt="Watch video" className="img-fluid card-img-top"/>
                </button>
                {caption}
                <div id={this.props.video} className="modal video-modal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title">Video player</h2>
                                <button type="button" className="close" data-cfw-dismiss="modal" aria-label="Close">
                                    <span className="fa fa-times" aria-hidden="true"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Video video={this.props.video}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

VideoModalButton.propTypes = {
    label: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired
};

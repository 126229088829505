import {Component} from 'react';
import React from "react";
import AppContext from "../AppContext";
import { onboardInit} from "../steps/TourGuide";

let storedRange = {
    collapsed: true
};
let timerRef = null;

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export default class TTS extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            playing: false
        };

        this.ttsPlay = this.ttsPlay.bind(this);
        this.ttsStop = this.ttsStop.bind(this);
        this.ttsIsPlaying = this.ttsIsPlaying.bind(this);
        this.ttsIsPaused = this.ttsIsPaused.bind(this);
        this.ttsDictionary = this.ttsDictionary.bind(this);
        this.ttsTranslate = this.ttsTranslate.bind(this);
        this.ttsCheckForPause = this.ttsCheckForPause.bind(this);
        this.handleSelectionEnd = this.handleSelectionEnd.bind(this);
    }

    componentDidMount() {
        onboardInit("#ttsId");
        //document.addEventListener('ttsDoStart', this.ttsIsPlaying);
        document.addEventListener('ttsDoEnd', this.ttsIsPaused);
        this.startSelectListen();
    }

    componentWillUnmount() {
        this.endSelectListen();
    }

    startSelectListen() {
        document.addEventListener('selectionchange', this.handleSelectionChange);
        document.addEventListener('selectionEnd', this.handleSelectionEnd); // Custom event
    }

    endSelectListen() {
        document.removeEventListener('selectionchange', this.handleSelectionChange);
        document.removeEventListener('selectionEnd', this.handleSelectionEnd); // Custom event
        timerRef = null;
    }

    handleSelectionChange() {
        // Delay after the last selection change event
        if (timerRef) {
            clearTimeout(timerRef);
        }
        timerRef = setTimeout(function () {
            const eventSelectionEnd = new Event('selectionEnd');
            document.dispatchEvent(eventSelectionEnd);
        }, 100);
    }

    handleSelectionEnd() {
        timerRef = null;
        if (window.getSelection().rangeCount) {
            storedRange =  window.getSelection().getRangeAt(0);
        } else {
            this.resetStoredRange();
        }
    }

    render() {
        const doc = this.context;
        return ("print" === doc.fileSection? null:
            <div className={`header-tts ${this.state.playing ? 'playing': ''}`} role="region" aria-label="Read Aloud Toolbar">
                <button onClick={this.ttsPlay} type="button" className="btn btn-round btn-tts" title="Play/Pause">
                    <span className="fa fa-fw fa-play tts-play" aria-hidden="true"></span>
                    <span className="fa fa-fw fa-pause tts-pause" aria-hidden="true"></span>
                </button>
                <button onClick={this.ttsStop} type="button" className="btn btn-round btn-tts" title="Stop">
                    <span className="fa fa-fw fa-stop tts-stop" aria-hidden="true"></span>
                </button>
                <span id="ttsId" data-cfw-popover-placement="bottom" data-cfw-popover-target="#onboard1"></span>
                <button id="ttsGlossaryBtn" onClick={this.ttsDictionary} type="button" className="btn btn-round btn-tts" title="Select a word to lookup definition">
                    <span className="fa fa-fw fa-book tts-glossary" aria-hidden="true"></span>
                </button>
                <button id="ttsTranslateBtn" onClick={this.ttsTranslate} type="button" className="btn btn-round btn-tts" title="Select a word/phrase to translate">
                    <span className="fa fa-fw fa-language tts-translate" aria-hidden="true"></span>
                </button>
            </div>
        );
    }

    isIOS() {
        // Second test is needed for iOS 13+
        return (
            navigator.userAgent.match(/iPhone|iPad|iPod/i) != null ||
            (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
        );
    }

    resetStoredRange() {
        storedRange = {
            collapsed: true
        };
    }

    recreateSelectionFromRange() {
        const selectionReset = document.getSelection();
        selectionReset.addRange(storedRange);
        this.resetStoredRange();
        document.addEventListener('selectionEnd', this.handleSelectionEnd);
    }

    recreateSelection() {
        var that = this;
        return new Promise((resolve) => {
            if (this.isIOS() && !storedRange.collapsed) {
                document.removeEventListener('selectionEnd', this.handleSelectionEnd);
                const selectionReset = document.getSelection();
                selectionReset.removeAllRanges();
                clearTimeout(timerRef);
                setTimeout(function() {
                    that.recreateSelectionFromRange();
                    resolve();
                }, 10);
            } else {
                resolve();
            }
        })
    }

    async hasSelection() {
        clearTimeout(timerRef);
        await this.recreateSelection();
        let selection = window.getSelection();
        return !(selection.type === 'None' || selection.type === 'Caret');
    }

    async ttsPlay() {
        // Check for a visible modal - borrowed from jQuery's `:visible` pseudo-selector
        let modalVis = [...(document.querySelectorAll('.modal'))].filter((elem) => {
            return !!((elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length) && window.getComputedStyle(elem).visibility !== "hidden");
        });
        let offcanvasVis = [...(document.querySelectorAll('.offcanvas'))].filter((elem) => {
            return !!((elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length) && window.getComputedStyle(elem).visibility !== "hidden");
        });

        window.speechstream.speechTools.setSpeechRenderedCallback(this.ttsCheckForPause);

        if (window.speechstream.speechTools.isPaused()) {
            console.debug('Speechstream is paused: resuming');
            window.speechstream.speechTools.pause();
            this.ttsIsPlaying();
            return;
        }

        if (window.speechstream.speechTools.isSpeaking()) {
            console.debug('Speechstream is playing: pause');
            window.speechstream.speechTools.pause();
            this.ttsIsPaused();
            return;
        }

        let action;
        if (await this.hasSelection()) {
            action = 'read selection';
            window.speechstream.speechTools.play();
        } else if (modalVis.length) {
            action = 'read modal';
            window.speechstream.speechTools.speakElement(modalVis[0], false);
            } else if (offcanvasVis.length) {
            action = 'read offcanvas';
            window.speechstream.speechTools.speakElement(offcanvasVis[0], false);
        } else {
            action = 'read page';
            let mainContainer = document.querySelector('#content');
            if (mainContainer) {
                window.speechstream.speechTools.speakElement(mainContainer, false);
            } else {
                window.speechstream.speechTools.play();
            }
        }
        console.debug('Speechstream starting: ', action);
        window.tracker.trackEvent('Support', 'read aloud', action);
        this.ttsIsPlaying();
    }

    ttsStop() {
        window.speechstream.speechTools.stop();
    }

    async ttsDictionary() {
        if (await this.hasSelection()) {
            window.tracker.trackEvent('Support', 'dictionary');
            window.speechstream.textTools.dictionaryLookup();
        } else {
            window.tracker.trackEvent('Support', 'dictionary', 'no selection');
            alert('Select a word then click this button to look it up');
        }
    }

    async ttsTranslate() {
        if (await this.hasSelection()) {
            window.tracker.trackEvent('Support', 'translate');
            window.speechstream.textTools.translate();
        } else {
            alert('Select a word then click this button to see the Spanish translation');
            window.tracker.trackEvent('Support', 'translate', 'no selection');
        }
    }

    ttsIsPlaying() {
        this.setState({
            playing: true
        });
    };

    ttsIsPaused() {
        this.setState({
            playing: false
        });
    };

    ttsCheckForPause = debounce(function() {
        // Check for user clicking pause when moving between passages
        if (!this.state.playing && window.speechstream.speechTools.isSpeaking()) {
            console.log('Speechstream: queued pause');
            window.speechstream.speechTools.pause();
        }
    }, 200);
}

import SttInput from "../../components/SttInput";
import ReadOnlyField from "../../ReadOnlyField";
import React, {Fragment} from "react";
import StepHeader from "../StepHeader";
import AppContext from "../../AppContext";

export default class Question extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.identifier = "question";
    }

    render() {
        return (
            <Fragment>
                <StepHeader doc={this.props.doc} section={this.props.section}  tooltipTitle={true} editing={true}/>
                { this.context.canEdit ?
                    <Fragment>
                        <div className="box box-primary">
                            <SttInput {...this.props} inputType={"text-area"} identifier={"topic"} addImage={false} singleLine={false} />
                        </div>
                        <div className="box box-primary">
                            <SttInput {...this.props} inputType={"text-area"} identifier={this.identifier} addImage={true} singleLine={false} />
                        </div>
                    </Fragment>
                :
                    <Fragment>
                        <ReadOnlyField identifier={"topic"} className={"box bg-autofill"} />
                        <div className={"box bg-autofill"}>
                            <div className={"row"}>
                                <ReadOnlyField identifier={this.identifier} className={"grouping-text"} />
                                <ReadOnlyField identifier={this.identifier + "/image"} className={"grouping-img"} />
                            </div>
                        </div>
                    </Fragment>
                }
            </Fragment>
        );
    }

}

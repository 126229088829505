import React, {Component, Fragment} from "react";

import AppContext from "../AppContext";
import ComparisonSteps from "../steps/Comparison/ComparisonSteps";
import CauseEffectSteps from "../steps/Cause-Effect/CauseEffectSteps";
import QuestionExpSteps from "../steps/Question-Exploration/QuestionExpSteps";
import CERGuideSteps from "../steps/CER-Guide/CERGuideSteps";
import texts from "../texts";
import Title from "./Title";
// import SlidesButton from "./SlidesButton";
import {bpvAsideMain} from "./layout";

export default class BigPicture extends Component {
    static contextType = AppContext;

    review(doc) {
        const textSections = texts[doc.type].sections;
        const last = textSections.length-2;
        const section = textSections[last];
        return(
            "complete" === doc.section?
                <Fragment>
                    <Title headerLevel={"h1"} title={section.title} />
                    <p>{section.description}</p>

                    {/* <SlidesButton doc={doc}/> */}
                </Fragment>
                : null
        )
    }

    render() {
        const doc = {
            title: this.context.fileName,
            section: this.props.section,
            type: this.context.fileType,
            id: this.context.fileId,
            editing: this.context.canEdit
        };

        const content =
            "comparison" === doc.type?
                <ComparisonSteps {...this.props} doc={doc} editing={false} inCompleteStep={true}/>
            : "cause-effect" === doc.type?
                <CauseEffectSteps {...this.props} doc={doc} editing={false} inCompleteStep={true}/>
            :"question-exp" === doc.type?
                <QuestionExpSteps {...this.props} doc={doc} editing={false} inCompleteStep={true}/>
            :"cera" === doc.type?
                <CERGuideSteps {...this.props} doc={doc} editing={false} inCompleteStep={true}/>
            : null;

        return (
            <div className="container">
                <main id="main_container" className="main">
                    {this.review(doc)}
                    {content}
                </main>
                {this.props.children}
            </div>
        )
    }

    componentDidMount() {
        // Check/reset class on 'main'
        bpvAsideMain();
    }

}

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ListComponent from './ListComponent';
import uniqueId from '../uniqueId';
import T from 'i18n-react';
import SttInput from "./SttInput";
import AppContext from "../AppContext";
import ReadOnlyField from "../ReadOnlyField";
import DeleteModal from "./DeleteModal";
import DeleteButton from "./DeleteButton";
import AutocompleteArrow from "./AutocompleteArrow";
import ReadOnlyText from "./ReadOnlyText";
import EmptyField from './EmptyField';

export default class EvidenceList extends ListComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.identifier = this.props.identifier;
        this.identifierRef = null;

        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const editing = (undefined !== this.props.editing)? this.props.editing: true;
        const qualityStep = (undefined !== this.props.qualityStep)? this.props.qualityStep: false;
        const inCompleteStep = (undefined !== this.props.inCompleteStep)? this.props.inCompleteStep: false;
        const qualityTypeMaps  = (undefined !== this.props.qualityTypeMaps)? this.props.qualityTypeMaps: null;
        const qualityNoteMaps  = (undefined !== this.props.qualityNoteMaps)? this.props.qualityNoteMaps: null;
        const print = (undefined !== this.props.print)? this.props.print: false;
        const itemList = this.getContentList();
        let itemsHTML = [];
        let itemCount = 0;

        if (itemList) {
            for (let key in itemList) {
                if (itemList.hasOwnProperty(key)){
                    itemCount++;
                    itemsHTML.push(<Evidence key={key}
                                              id={key}
                                              itemCount={itemCount}
                                              hLevel={this.props.hLevel}
                                              hClassName={this.props.hClassName}
                                              identifier={this.identifier}
                                              doc={this.props.doc}
                                              deleteMethod={this.handleDeleteItem}
                                              canDelete={editing}
                                              editing={editing}
                                              qualityStep={qualityStep}
                                              print={print}
                                              data={this.props.data}
                                              inCompleteStep={inCompleteStep}
                                              qualityTypeMaps={qualityTypeMaps}
                                              qualityNoteMaps={qualityNoteMaps}
                    />);
                }
            }
        }
        let addButton = editing ?
            <button onClick={this.handleAddItem} type="button" className="btn btn-primary">
                <span className="fa fa-plus me-0_25" aria-hidden="true"></span>
                Add evidence
            </button>
        : null;

        if(!this.context.canEdit) addButton = null;

        return (
            <div>
                {itemsHTML}
                { !qualityStep ? addButton : null}
            </div>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            evidence: "",
            citation: "",
            qualitytype: "",
            qualitynote: ""
        });
    }

    getValuePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/evidence";
        return path;
    }

    getImagePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/evidence/image";
        return path;
    }
}

class Evidence extends Component{
    static contextType = AppContext;
    constructor(props) {
        super(props);

        this.id = props.id;
        this.identifier = props.identifier;
        this.deleteButtonId = uniqueId();
        this.deleteModalId = uniqueId();
        this.getBaseNode = this.getBaseNode.bind(this);
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
    }

    getBaseNode() {
        return this.props.doc.type + "/" + this.props.doc.id;
    }

    hasContent(text) {
        return undefined !== text && null !== text && text.trim();
    }

    render() {
        const fileType= this.context.fileType;
        const fileId= this.context.fileId;

        const mevidence = this.identifier + "/" + this.id+"/evidence";
        const mcitation = this.identifier + "/" + this.id+"/citation";

        const mqualityTypeParentNote = fileType + "/" + fileId + "/" +  this.identifier + "/" + this.id;
        const mqualitytype = this.identifier + "/" + this.id + "/qualitytype";
        const mqualitynote = this.identifier + "/" + this.id+"/qualitynote";
        const deleteMessage = "Do you want to delete evidence?";
        const deleteModal = <DeleteModal id={this.deleteModalId} method={this.handleDeleteButton} text={deleteMessage} yes="Remove"/>
        const deleteIcon = this.context.canEdit ? <DeleteButton icon={"fa-times"} tooltip={"Remove Evidence"} modalId={this.deleteModalId}
                             canDelete={this.props.canDelete}/> : null;

        const inCompleteStep = (undefined !== this.props.inCompleteStep)? this.props.inCompleteStep: false;
        const qualityTypeMaps  = (undefined !== this.props.qualityTypeMaps)? this.props.qualityTypeMaps: null;
        const qualityNoteMaps  = (undefined !== this.props.qualityNoteMaps)? this.props.qualityNoteMaps: null;
        let hasQuality = false;
        if(qualityTypeMaps !== null && qualityNoteMaps !== null){
            if(this.hasContent(qualityTypeMaps.get(this.id)) && this.hasContent(qualityNoteMaps.get(this.id))){
                hasQuality = true;
            }
        }

        const evidenceTypeCount =  `${this.identifier === 'evidencesupport' ? 'Supporting' : 'Opposing'} Evidence ${this.props.itemCount}`;
        let evidenceHeading = undefined;
        let HeadingTag = `div`;
        let SubHeadingTag = `div`;

        if (this.props.hLevel !== undefined) {
            const hLevel = parseInt(this.props.hLevel, 10);
            HeadingTag = `h${hLevel}`;
            SubHeadingTag = `h${hLevel + 1}`;
            evidenceHeading = <HeadingTag className={this.props.hClassName}>{evidenceTypeCount}</HeadingTag>;
        }

        const qualityTypeHtml = this.context.canEdit ?
                                <AutocompleteArrow className="pb-0"
                                        identifier="qualitytype"
                                        parentNode={mqualityTypeParentNote}
                                        placeholder={T.translate(this.props.doc.type + ".qualitytype.placeholder")}
                                        label="Evidence Quality"
                                        autocompleteOptions={
                                            [ 'The quality of the evidence is strong'
                                            , 'The quality of the evidence is weak']}
                                /> : <div className={"box bg-autofill mt-0"} >
                                        <div className={"row"}>
                                            <ReadOnlyText identifier={mqualitytype} className={"col"}/>
                                        </div>
                                    </div>;

        return(this.props.editing && this.context.canEdit ?
            <Fragment>
                {!this.props.qualityStep ?
                    <div className="box bg-primary">
                        <div className="box-row-close">
                            {deleteModal}
                            {deleteIcon}
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <SttInput {...this.props} inputType={"text-area"}
                                        placeholder={T.translate(this.props.doc.type + "." + this.identifier + ".placeholder")}
                                        identifier={mevidence} addImage={true} singleLine={false} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <SttInput {...this.props} inputType={"text-area"}
                                        placeholder={T.translate(this.props.doc.type + ".citation.placeholder")}
                                        identifier={mcitation} addImage={false} singleLine={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col-md-6">
                            <div className={"box bg-autofill"} >
                                <div className={"row"}>
                                    <ReadOnlyField identifier={mevidence} className={"grouping-text"} />
                                    <ReadOnlyField identifier={mevidence + "/image"} className={"grouping-img"} />
                                </div>
                            </div>
                            <ReadOnlyField identifier={mcitation} className="box bg-autofill mb-0_5" />
                        </div>
                        <div className="col-md-6">
                            <div className="box bg-primary">
                                <div className="form-group">
                                    {qualityTypeHtml}
                                </div>
                                <div className="mt-0">
                                    <SttInput {...this.props} inputType={"text-area"} inputClassName=""
                                              label={T.translate(this.props.doc.type + '.qualitynote.label')}
                                              placeholder={T.translate(this.props.doc.type + '.qualitynote.placeholder')}
                                              identifier={mqualitynote} addImage={false} singleLine={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
            :
            <Fragment>
                {evidenceHeading}
                <div className="box box-autofill">
                    <div className="row mb-1">
                        <ReadOnlyField identifier={mevidence} className={"grouping-text"} />
                        {!this.props.print ? <ReadOnlyField identifier={mevidence+"/image"} className={"grouping-img"} /> : null}
                    </div>

                    {!inCompleteStep ?
                        <Fragment>
                            <div className="mb-1">
                                <SubHeadingTag className="h3 mb-0">Strength of Evidence</SubHeadingTag>
                                <ReadOnlyText identifier={mqualitytype} />
                            </div>
                            <div className="mb-1">
                                <SubHeadingTag className="h3 mb-0">Rationale</SubHeadingTag>
                                <ReadOnlyField identifier={mqualitynote} />
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            {hasQuality ?
                                <Fragment>
                                    <div className="mb-1">
                                        <SubHeadingTag className="h3 mb-0">Strength of Evidence</SubHeadingTag>
                                        <ReadOnlyText identifier={mqualitytype} />
                                    </div>
                                    <div className="mb-1">
                                        <SubHeadingTag className="h3 mb-0">Rationale</SubHeadingTag>
                                        <ReadOnlyField identifier={mqualitynote} />
                                    </div>
                                </Fragment>
                                : <EmptyField identifier={"qualitynote"} className="box bg-unfilled"/>
                            }
                        </Fragment>
                    }
                    <div className="row">
                        <SubHeadingTag className="h3 mb-0">Source</SubHeadingTag>
                        <ReadOnlyField identifier={mcitation} className={"grouping-text"} />
                    </div>
                </div>
            </Fragment>

        )
    }

    handleDeleteButton() {
        this.props.deleteMethod(this.id);
    }
}

Evidence.propTypes = {
    id: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    doc: PropTypes.object.isRequired,
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired
};

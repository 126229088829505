import React, { Fragment, useContext } from 'react';
import Question from '../steps/Cause-Effect/Question';
import Answer from '../steps/Cause-Effect/Answer';
import Routine from "./Routine";
import texts from "../texts";
import Event from "../steps/Cause-Effect/Event";
import Causes from "../steps/Cause-Effect/Causes";
import Effects from "../steps/Cause-Effect/Effects";
import Extensions from "../steps/Cause-Effect/Extensions";
import ReadOnlyField from "../ReadOnlyField";
import StepHeader from "../steps/StepHeader";
import {dataPathSep} from "../components/DataPath";
import ReadOnlyText from "../components/ReadOnlyText";
import AutocompleteArrow from "../components/AutocompleteArrow";
import CreateShare from "../components/CreateShare";
import T from "i18n-react";
import AppContext from "../AppContext";
import uniqueId from '../uniqueId';
import Terms from '../components/Terms';

function trackPageView(context,section){
    if('toc' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:toc');
    if('question' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:1');
    if('terms' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:2');
    if('event' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:3');
    if('causes' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:4');
    if('causes-event' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:5');
    if('effects' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:6');
    if('event-effects' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:7');
    if('summary' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:8');
    if('complete' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:review');
    if('print' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:print-preview');
    if('create-share' === section) window.tracker.trackPageView(context.currentUser, 'cause-effect:create-share');
}

export default function CauseEffect(props) {
        const context = useContext(AppContext);
        const textSection = texts["cause-effect"].sections;

        const question =
            <section className="section-step">
                <Question doc={props.doc} section={textSection[1]} editing={true}  />
            </section>;

        const events =
            <section className="section-step">
                <Event doc={props.doc} section={textSection[3]} editing={true} />
            </section>;

        const causes =
            <Fragment>
                <StepHeader key={uniqueId()} section={textSection[4]} editing={true} tooltipTitle={true}/>
				<h2>Essential Question</h2>
                <ReadOnlyField identifier="question" className="box box-autofill" />

				<h2>Causes of the Main event</h2>
                <Causes doc={props.doc} editing={true} section={textSection[4]} >
                    <div className="grouping grouping-1">
                        <h2 className="grouping-title">The Main Event</h2>
                        <div className="grouping-item">
                            <ReadOnlyField identifier={"event"} className={"box box-autofill"} />
                        </div>
                    </div>
                </Causes>
            </Fragment>;

    const cause_connector = (doc,canEdit) => {
        const path = ["",doc.type,doc.id,doc.section].join(dataPathSep);
        return (
            canEdit ?
                <Fragment>
                    <div className="box box-primary connector">
                        <AutocompleteArrow // className="col-md-4 pb-0_5"
                            identifier="connector"
                            placeholder={T.translate(doc.type + ".connector.placeholder")}
                            parentNode={path}
                            label="Relationship"
                            autocompleteOptions={
                                [ 'caused'
                                    , 'aligned with'
                                    , 'associated with'
                                    , 'contributed to'
                                    , 'resulted in'
                                    , 'lead to'
                                    , 'produced']}
                        />

                    </div>
                    <div className="bracket">
                        <div className="bracket-arrow-vertical"></div>
                    </div>
                </Fragment>
            :
                <Fragment>
                    <ReadOnlyText identifier={"causes-event/connector"} className={"box connector box-autofill"}/>
                    <div className="bracket">
                        <div className="bracket-arrow-vertical"></div>
                    </div>
                </Fragment>
        )
    }

    const effect_connector = (doc,canEdit) => {
        const path = ["",doc.type,doc.id,doc.section].join(dataPathSep);
        return (
            canEdit ?
                <Fragment>
                    <div className="box box-primary connector">
                        <AutocompleteArrow // className="col-md-4 pb-0_5"
                            identifier="connector"
                            placeholder={T.translate(doc.type + ".connector.placeholder")}
                            parentNode={path}
                            label="Relationship"
                            autocompleteOptions={
                                [ 'aligned with'
                                , 'associated with'
                                , 'contributed to'
                                , 'resulted in'
                                , 'lead to'
                                , 'produced']}
                        />
                    </div>
                </Fragment>
            :
                <Fragment>
                    <ReadOnlyText identifier={"event-effects/connector"} className={"box connector box-autofill"}/>
                </Fragment>
        )
    }

        const causes_event =
            <Fragment>
                <StepHeader key={uniqueId()} section={textSection[5]} editing={true} tooltipTitle={true}/>
                <h2>Essential Question</h2>
                <ReadOnlyField identifier="question" className="box box-autofill" />

                <h2>Causes of the Main event</h2>
                <Causes inRoutine={true} doc={props.doc} editing={false} section={textSection[4]} >
                    {cause_connector(props.doc,props.canEdit)}
                    <div className="grouping grouping-1">
                        <h2 className="grouping-title">The Main Event</h2>
                        <div className="grouping-item">
                            <ReadOnlyField identifier={"event"} className={"box box-autofill"} />
                        </div>
                    </div>
                </Causes>
            </Fragment>;

        const effects =
            <Fragment>
                <StepHeader key={uniqueId()} section={textSection[6]} editing={true} tooltipTitle={true}/>
                <h2>Essential Question</h2>
                <ReadOnlyField identifier="question" className="box box-autofill" />

                <Effects doc={props.doc} section={textSection[6]} editing={true}>
                    <div className="grouping grouping-1">
                        <h2 className="grouping-title">The Main Event</h2>
                        <div className="grouping-item">
                            <ReadOnlyField identifier={"event"} className={"box box-autofill"} />
                        </div>
                    </div>
                    <div className="bracket">
                        <div className="bracket-arrow-vertical"></div>
                    </div>
                </Effects>
            </Fragment>;

        const event_effects =
            <Fragment>
                <StepHeader key={uniqueId()} section={textSection[7]} editing={true} tooltipTitle={true}/>
                <h2>Essential Question</h2>
                <ReadOnlyField identifier="question" className="box box-autofill" />
                <Effects inRoutine={true} doc={props.doc} editing={false} section={textSection[4]}>
                    <div className="grouping grouping-1">
                        <h2 className="grouping-title">The Main Event</h2>
                        <div className="grouping-item">
                                <ReadOnlyField identifier={"event"} className={"box box-autofill"} />
                        </div>
                    </div>
                    <div className="bracket">
                        <div className="bracket-arrow-vertical"></div>
                    </div>
                    {effect_connector(props.doc,props.canEdit)}
                </Effects>
            </Fragment>

        const answer =
            <Fragment>
                <StepHeader key={uniqueId()} section={textSection[8]} editing={true} tooltipTitle={true}/>
                <h2>Essential Question</h2>
                <ReadOnlyField identifier={"question"} className={"box box-autofill"}/>
                <div className={props.canEdit ? "box bg-primary" : ""}>
                    <Answer doc={props.doc} section={textSection[8]} editing={true} />
                </div>
            </Fragment>

        const extensions = <Extensions doc={props.doc} section={textSection[9]}/>;

        const createShare = <CreateShare shareMethod={props.shareMethod}/>;

        const terms = <Terms doc={props.doc} section={textSection[2]} tooltipTitle={true}/>;

        const section = props.doc.section;
        trackPageView(context,section);

        const content =
            'question' === section ? question
            : 'terms' === section ? terms
            : 'event' === section ? events
            : 'causes' === section ? causes
            : 'causes-event' === section ? causes_event
            : 'effects' === section ? effects
            : 'event-effects' === section ? event_effects
            : 'summary' === section ? answer
            : 'extensions' === section ? extensions
            : 'create-share' === section ? createShare
            : null;

        return <Routine content={content} {...props} />;
}



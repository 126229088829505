import React, {Fragment} from "react";
import StepHeader from "../StepHeader";
import EvidenceList from "../../components/EvidenceList";

export default class EvidenceQuality extends React.Component {

    constructor(props) {
        super(props);
        this.identifier = "evidencequality";
    }

    render() {
        return (
            <Fragment>
                <StepHeader section={this.props.section} />
                <h2>Supporting Evidence</h2>
                <EvidenceList doc={this.props.doc} identifier="evidencesupport" qualityStep={true} canBeEmpty={false} data={this.props.data} hLevel={3} />
                <hr />
                <h2>Opposing Evidence</h2>
                <EvidenceList doc={this.props.doc} identifier="evidenceopposes" qualityStep={true} canBeEmpty={false} data={this.props.data} hLevel={3} />
            </Fragment>
        );
    }

}

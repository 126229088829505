import React, { useContext, useEffect, useState} from "react";
// import {Link} from "react-router-dom";
import AppContext from "../AppContext";
import {navOffcanvas} from "./BrandBar";
import $ from "jquery";

export default function ParentFolder(props) {
    const doc = useContext(AppContext);
    const [parentFolder, setParentFolder] = useState(null);

    useEffect(() => {
        if (doc.parentFolderId && !parentFolder) {
            setParentFolder("https://drive.google.com/drive/folders/"+doc.parentFolderId);
        }
        if (!doc.parentFolderId && doc.fileId && !parentFolder) {
            const url = "https://www.googleapis.com/drive/v2/files/" + doc.fileId + "/parents";
            window.gapi.client.request({
                path: url,
                method: "GET",
                fields: "parents"
            }).then((driveResponse) => {
                if (undefined !== driveResponse.result.items
                    && 0 < driveResponse.result.items.length) {
                    const folderId = driveResponse.result.items[0].id;
                    doc["parentFolderId"] = folderId;
                    setParentFolder("https://drive.google.com/drive/folders/" + folderId);
                } else {
                    console.log("%d, failed to GET %s",driveResponse.status,url);
                }
            },  (err_reason) => {
                if(err_reason.result.error.code === 401) {
                    this.context.handleAccessTokenExpired();
                }
            });
        }
    },[doc, parentFolder]);

    return parentFolder ? (
        <a href={parentFolder} target="_blank" rel="noopener noreferrer"
           onClick={(evt) => $('#'+navOffcanvas).CFW_Offcanvas('hide')}>
            {props.children}
        </a>
    ): null;
}

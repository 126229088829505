import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class VideoPlayer extends Component {
    constructor() {
        super();

        this.onPlay = this.onPlay.bind(this);
    }

    render() {
        const ccURL = this.props.ccURL?
            <track src={this.props.ccURL} label="English Subtitles" kind="subtitles" srcLang="en" default />
            : null;
        return (
            <div data-cfw="player" className="video-wrapper">
                <div className="embed-fluid" >
                    <video onPlay={this.onPlay}
                           poster={this.props.posterURL}
                           controls
                           crossOrigin={"anonymous"}
                    >
                        <source src={this.props.videoURL} type="video/mp4" />
                        {ccURL}
                        <p>HTML5 video not supported</p>
                    </video>
                </div>
                <div className="player-wrapper">
                    <div className="player" role="region" aria-label="video player" data-cfw-player="player">
                            <span className="player-control " data-cfw-player="control">
                                <button type="button" className="btn btn-icon" data-cfw-player="play" title="Play"
                                        aria-label="Play"><span className="fa fa-fw fa-play" aria-hidden="true"></span></button>
                                <button type="button" className="btn btn-icon" data-cfw-player="pause" title="Pause"
                                        aria-label="Pause"><span className="fa fa-fw fa-pause" aria-hidden="true"></span></button>
                                <button type="button" className="btn btn-icon" data-cfw-player="stop" title="Stop"
                                        aria-label="Stop"><span className="fa fa-fw fa-stop" aria-hidden="true"></span></button>
                            </span>
                        <span className="player-time" data-cfw-player="time">
                                <span className="player-time-current" data-cfw-player="time-current"></span>
                                <span className="player-seek" data-cfw-player="seek">
                                    <input type="range" className="form-range" aria-label="Seek" />
                                </span>
                                <span className="player-time-duration" data-cfw-player="time-duration"></span>
                            </span>
                        <button type="button" className="btn btn-icon" data-cfw-player="caption" title="Closed captions"
                                aria-label="Closed captions"><span className="fa fa-fw fa-closed-captioning"
                                                                   aria-hidden="true"></span></button>
                        <span className="player-fullscreen" data-cfw-player="fullscreen">
                                <button type="button" className="btn btn-icon player-fullscreen-on"
                                        title="Exit fullscreen" aria-label="Exit fullscreen"><span
                                    className="fa fa-fw fa-compress-arrows-alt" aria-hidden="true"></span></button>
                                <button type="button" className="btn btn-icon player-fullscreen-off" title="Fullscreen"
                                        aria-label="Fullscreen"><span className="fa fa-fw fa-expand-arrows-alt"
                                                                      aria-hidden="true"></span></button>
                            </span>
                    </div>
                </div>
            </div>
        );
    }

    onPlay (event) {
        // window.tracker.trackEvent("video-player", "play", this.props.video);
        window.tracker.trackEvent("Video", "play video", this.props.video);
    }
}


VideoPlayer.propTypes = {
    videoURL: PropTypes.string.isRequired,
    posterURL: PropTypes.string.isRequired,
    ccURL: PropTypes.string.isRequired
};

// Show 'rotate to landsacpe' image for 'mobile' devices when in portait mode

import React, {Component, Fragment} from 'react';
import uniqueId from '../uniqueId';
import $ from 'jquery';

import imgRotateDevice from 'url:/public/img/rotate_device.svg';

function debounce(fn, ms) {
    let timer;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}

export default class MobileRotateModal extends Component {

    constructor(props) {
        super(props);

        this.isMobilePortrait = this.isMobilePortrait.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.debouncedHandleResize = debounce(this.handleResize, 150);
    }

    componentWillMount() {
        this.modalId = uniqueId();
        this.buttonId = uniqueId();
    }

    isMobilePortrait() {
        const breakpointSm = getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-sm');
        const isMobile = window.matchMedia('(max-width: ' + breakpointSm + ')').matches;

        return isMobile && window.matchMedia("screen").matches && window.matchMedia("(orientation: portrait)").matches;
    }

    handleResize() {
        if (this.isMobilePortrait()) {
            const $openModal = $('.modal:visible:not("#' + this.modalId + '")');
            const btnId = this.buttonId;

            var showModal = function() {
                $openModal.off('afterHide.cfw.modal.rotate');
                $('#' + btnId).CFW_Modal('show');
            };

            if ($openModal.length > 0) {
                $openModal.on('afterHide.cfw.modal.rotate', showModal);
                $openModal.CFW_Modal('hide');
            } else {
                showModal();
            }
        } else {
            $('#' + this.modalId).CFW_Modal('hide');
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.debouncedHandleResize);
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedHandleResize);
    }

    render() {
        return(
            <Fragment>
                <button id={this.buttonId} type="button" className="btn d-none" data-cfw="modal" data-cfw-modal-target={"#" + this.modalId} data-cfw-modal-backdrop="static">Rotate message dialog</button>
                <div id={this.modalId} className="modal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <div className="modal-title text-center">
                                    <div className="row mt-0_5 mb-1">
                                        <div className="col-6 offset-3">
                                            <img src={imgRotateDevice} className="img-fluid" alt="Rotating a mobile device" />
                                        </div>
                                    </div>
                                    <strong>Rotate your device <span className="sr-only">to landscape orientation</span></strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

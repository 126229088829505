import React from 'react';
import { Component } from 'react';
import texts from '../texts';
import T from 'i18n-react';
import PageLink from "./PageLink";

import imgIconCEG from 'url:/public/example/CEG_icon.png';
import imgIconQEG from 'url:/public/example/QEG_icon.png';
import imgIconCT from 'url:/public/example/CT_icon.png';
import imgIconCERA from 'url:/public/example/CERA_icon.png';


// Shown on the home page, this generates a list of example content.
// Content is drawn from texts.js, under the 'library' key.

export default class UnitLibrary extends Component {

    render() {
        let units = [];
        let unitList = texts['library'];
        console.log(unitList);
        for (let u in unitList) {
            if (unitList.hasOwnProperty(u)) {
                units.push(<UnitCard key={u} data={unitList[u]}></UnitCard>);
            }
        }
        return (
            <div className="page-wrapper">
                <div id="content" className="content row">
                    <div className="content-main col">
                        <div className="content-main-inner">
                            <nav aria-label="breadcrumb">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><PageLink page={"docs"}>Home</PageLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">Lesson Library</li>
                                </ul>
                            </nav>
                            <main id="main_container">
                                <h1>Lesson Library</h1>
                                <p>
                                    Corgi can be used to reduce barriers and increase access to the Next Generation Science Standards (NGSS).
                                    Once you identify the Standard and the learning goal of the lesson or unit, you can select the Corgi guide or guides that best align.
                                    Here are some sample Units that are connected to the NGGS.
                                    The units are not encompassing of every option, but examples of options.
                                    We invite teachers to use all of the units or parts of the units and to customize according to their unique goals and contexts.
                                </p>
                                {units}
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

class UnitCard extends Component {

    render() {
        let u = this.props.data;
        let tags = u.tags;
        let tagHtml = [ <Badge key="level" text={u.level} /> ];
        for(let t in tags) {
            if(tags.hasOwnProperty(t)) {
                tagHtml.push(<Badge key={t} text={tags[t]} />);
            }
        }

        let lessons = u.lessons;
        let lessonHtml = [];
        for(let l in lessons) {
            if(lessons.hasOwnProperty(l)) {
                lessonHtml.push(<Lesson key={l} data={lessons[l]}/>);
            }
        }

        return(
            <div className="card card-library">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <div className="card-title">Unit plan</div>
                        </div>
                        <LibraryIcons lessons={lessons}/>
                    </div>
                </div>
                <div className="card-body">
                    <div className="h-inline">
                        <h2 className="d-inline">
                            <a href={u.overview}>{u.title}</a>{' '}
                        </h2>
                        <span className="fs-small text-muted">(Google Docs)</span>
                    </div>
                    <p>{u.description}</p>
                    <h3 className="mb-0">Lesson topics</h3>
                    <ul className="list list-unstyled">
                        {lessonHtml}
                    </ul>
                    <p>
                        {tagHtml}
                    </p>
                </div>
            </div>
        );
    }
}

class LibraryIcons extends Component {

    render() {
        let types = [];
        for(let l in this.props.lessons) {
            if(this.props.lessons.hasOwnProperty(l)) {
                if(!types.includes(this.props.lessons[l]['type'])) {
                    types.push(this.props.lessons[l]['type']);
                }
            }
        }
        return(
            <div className="library-icons">
                { types.includes('cause-effect') &&
                    <span className="library-icon" tabIndex="0" title="Cause and Effect"
                          data-cfw="tooltip" data-cfw-tooltip-container="body">
                        <img src={imgIconCEG} className="img-fluid" alt="Cause and Effect"/></span>
                }
                { types.includes('question-exp') &&
                    <span className="library-icon" tabIndex="0" title="Question Exploration"
                          data-cfw="tooltip" data-cfw-tooltip-container="body">
                        <img src={imgIconQEG} className="img-fluid" alt="Question Exploration"/></span>
                }
                { types.includes('comparison') &&
                    <span className="library-icon" tabIndex="0" title="Comparison"
                          data-cfw="tooltip" data-cfw-tooltip-container="body">
                        <img src={imgIconCT} className="img-fluid" alt="Comparison"/></span>
                }
                { types.includes('cera') &&
                    <span className="library-icon" tabIndex="0" title="Claim, Evidence, Reasoning"
                          data-cfw="tooltip" data-cfw-tooltip-container="body">
                        <img src={imgIconCERA} className="img-fluid" alt="Claim, Evidence, Reasoning"/></span>
                }
            </div>
        );
    }
}

class Lesson extends Component {

    render() {
        let data = this.props.data;
        return(
            <li className="list-item">
                {data.title} - <a target={data.id} href={'https://corgi2.cast.org/' + data.type + '/' + data.id + '/toc'}>
                    <span className="sr-only">{data.title}</span>
                    {T.translate(data.type+'.title')}
                </a>
            </li>
        )
    }
}

class Badge extends Component {

    render() {
        return(
            <React.Fragment>
                <span className="badge badge-tag">{this.props.text}</span>
                {' '}
            </React.Fragment>
        );
    }
}

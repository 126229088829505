import React, {Component} from "react";
import YouTube from 'react-youtube';

export default class YouTubeVideo extends Component {

    constructor(props, context) {
        super(props, context);

        this.onPlayerStateChange = this.onPlayerStateChange.bind(this);
    }

    onPlayerStateChange(event) {
        let videoName = this.props.video_name;

        // https://developers.google.com/youtube/iframe_api_reference
        // 1 = playing

        if(event.data === 1){
            if(videoName === null) videoName = this.player.getVideoData().title;
            window.tracker.trackEvent("Video", "play video", videoName);
        }
    };

    render() {
        const YouTubeOpts = {
            height: '100%',
            width: '100%'
        };

        // Note `react-youtube` component is much older than the current documentation to support React v16
        // See the older `README.md` for correct info:
        // https://github.com/tjallingt/react-youtube/blob/v7.14.0/README.md
        return (
            <YouTube
                containerClassName="embed-fluid"
                opts={YouTubeOpts}
                videoId={this.props.video_id}
                title={"YouTube Video: " + this.props.video_name}
                onStateChange={this.onPlayerStateChange}
            />
        );
    }
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListComponent from '../components/ListComponent';
import uniqueId from '../uniqueId';
import SttInput from "../components/SttInput";
import {empty} from "../components/textutil";
import AppContext from "../AppContext";
import ReadOnlyField from "../ReadOnlyField";
import texts from "../texts";
import StepHeader from "./StepHeader";
import $ from 'jquery';

/**
 * List of terms and definitions.
 */
export default class KeyTerms extends ListComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.canBeEmpty = false;
        this.editing = this.props.editing;
        this.fileType = !empty(this.props.docType)? this.props.docType: null;
        this.fileId = !empty(this.props.docId)? this.props.docId: null;
        this.identifier = "terms";
        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        $('#modalKeyTerms').on('afterHide.cfw.modal', () => {
            window.tracker.trackEvent("Interface", "close key terms", "");
        });
    }

    closeKeyTermsModal(){
        window.speechstream.speechTools.stop();
    }

    render() {
        let canEdit = this.context.canEdit;
        let printing = undefined !== this.props.printing ? this.props.printing : false;
        const termList = this.getContentList();
        const section = texts[this.context.fileType].terms;
        if (!this.fileType) {
            this.fileType = this.context.fileType;
        }
        if (!this.fileId) {
            this.fileId = this.context.fileId;
        }
        let termsHTML = [];


        if (termList) {
            if(this.context.canEdit){
                for (let key in termList) {
                    if (termList.hasOwnProperty(key)) {
                        termsHTML.push(<Term key={key}
                                             id={key}
                                             deleteMethod={this.handleDeleteItem}
                                             canDelete={this.state.canDelete}
                                             fileType={this.fileType}
                                             fileId={this.fileId}
                                             editing={this.editing}
                                             section={section}
                        />);
                    }
                }
            }else{
                for (let key in termList) {
                    if (termList.hasOwnProperty(key)) {
                        termsHTML.push(<Term key={key}
                                             id={key}
                                             deleteMethod={this.handleDeleteItem}
                                             canDelete={false}
                                             fileType={this.fileType}
                                             fileId={this.fileId}
                                             editing={false}
                                             section={section}
                        />);
                    }
                }
            }

        }

        const addButton = this.editing ?
            <div className="row mb-1">
                <div className="col-12">
                    <button type="button" onClick={this.handleAddItem} className="btn btn-primary">
                        <span className="fa fa-plus me-0_25" aria-hidden="true"></span>
                        Add Key Term
                    </button>
                </div>
            </div>
            : null;
        const termLabel = section.term? section.term.label: "Word or Phrase";
        const defLabel = section.def? section.def.label: "Definition";
        const title = section.title ? section.title : section.label;

        return (
            canEdit ?
                this.editing ?
                    <div id="modalKeyTerms" className="modal keyterm-modal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="h1 modal-title">{title}</h2>
                                    <button type="button" className="close" data-cfw-dismiss="modal" aria-label="Close"
                                        onClick={() => this.closeKeyTermsModal()}>
                                        <span className="fa fa-times" aria-hidden="true"></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row d-sm-down-none" aria-hidden="true">
                                        <div className="col-md-6">
                                            <div className="h2">{termLabel}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="h2">{defLabel}</div>
                                        </div>
                                    </div>
                                    {termsHTML}
                                    {addButton}
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <div>
                    <div className="row d-sm-down-none" aria-hidden="true">
                        <div className="col-md-6">
                            <div className="h3">{termLabel}</div>
                        </div>
                        <div className="col-md-6">
                            <div className="h3">{defLabel}</div>
                        </div>
                    </div>
                    {termsHTML}
                </div>
            :
            this.editing ?
                <div id="modalKeyTerms" className="modal keyterm-modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-cfw-dismiss="modal" aria-label="Close">
                                    <span className="fa fa-times" aria-hidden="true"></span>
                                </button>
                            </div>
                                <div className="modal-body">
                                    <StepHeader section={section} editing={this.editing} isInModal={true}/>
                                    <div className="row" aria-hidden="true">
                                        <div className="col-md-6">
                                            <div className="h2">{termLabel}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="h2">{defLabel}</div>
                                        </div>
                                    </div>
                                    {termsHTML}
                                </div>
                            </div>
                    </div>
                </div>
            :
                <div>
                    {!printing ?
                        <div className="row d-sm-down-none" aria-hidden="true">
                            <div className="col-md-6">
                                <div className="h3">{termLabel}</div>
                            </div>
                            <div className="col-md-6">
                                <div className="h3">{defLabel}</div>
                            </div>
                        </div>
                        : null
                    }
                    {termsHTML}
                </div>
        );
    }

    getCurrentNode() {
        return this.fileType + "/" + this.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            term: "",
            def: ""
        })
    }

    getValuePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/term";
        return path;
    }

    getImagePath(key) {
        return "";
    }
}

KeyTerms.propTypes = {
    stepnum: PropTypes.string, //.isRequired,
};

/**
 *  Component for single Term-Definition pair
 */
class Term extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.deleteModalId = uniqueId();
        this.termId = uniqueId();
        this.defId = uniqueId();
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
    }

    render() {
        return (
            this.props.editing ?
                <div className="box bg-primary">
                    <div role="group" aria-label="Vocabulary item">
                        { this.props.canDelete?
                            <div className="box-row-close">
                                <button type="button" className={"btn btn-icon btn-link"} aria-label="Remove key term"
                                        onClick={this.handleDeleteButton}
                                        >
                                    <span className="fa fa-times text-primary" aria-hidden="true"></span>
                                </button>
                            </div>
                            : null
                        }
                        <div className="row">
                            <div className="col-md-6">
                                <SttInput addImage={false}
                                          inputClassName=" "
                                          inputType={"text-area"}
                                          domId={this.termId} {...this.props}
                                          label="term"
                                          placeholder={this.props.section.term.placeholder}
                                          identifier={"terms/" + this.props.id + "/term"}
                                />
                            </div>
                            <div className="col-md-6">
                                <SttInput addImage={false}
                                          inputClassName=" "
                                          inputType={"text-area"}
                                          domId={this.defId} {...this.props}
                                          label="definition"
                                          placeholder={this.props.section.def.placeholder}
                                          identifier={"terms/" + this.props.id + "/def"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
           :
                <div className="row" role="group" aria-label="Vocabulary item">
                    <div className="col-md-6">
                        <div className={"box bg-autofill"}>
                            <ReadOnlyField identifier={"terms/" + this.props.id + "/term"} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={"box bg-autofill"}>
                            <ReadOnlyField identifier={"terms/" + this.props.id + "/def"}/>
                        </div>
                    </div>
                </div>
        );
    }

    handleDeleteButton(event) {
        if (!this.props.canDelete) {
            window.alert("There must be at least one entry in this list");
        } else
        if (window.confirm("Really delete this term and definition?")) {
            this.props.deleteMethod(this.props.id);
        }
        // this.props.deleteMethod(this.props.id+"/image");

    }

}

Term.propTypes = {
    id: PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired
};

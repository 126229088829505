import React from 'react';
import { Component } from 'react';

export default class DisplayFile extends Component {

    render(){
        const fileName = this.props.fileName;
        const url = this.props.file.src;
        return fileName?
            <div className = {this.props.containerClassName? this.props.containerClassName: "attachment-media"} >
                <a href={url} target="_blank" rel="noopener noreferrer"><span className="fab fa-fw fa-google-drive me-0_25" aria-hidden="true"></span>{fileName}</a>
               </div>: null
    }

}

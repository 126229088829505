import React from "react";
import DynamicComponent from "../DynamicComponent";
import {firebaseConfig} from '../realtimedb-utils';
import GooglePicker  from "react-google-picker";
import AppContext from "../AppContext";
import {to} from "./Go";
import {Redirect} from "react-router-dom";
var util = require('util');

export default class DocPicker extends DynamicComponent {
    static contextType = AppContext;

    // Type of the selected file is recognized by scanning for these keywords in the description.
    // FIXME replace this method with something more robust and straightforward.
    static supportedDocTypes = [
        {key: 'comparison', display: 'Comparison'}, // Table?
        {key: 'cause-effect', display: 'Cause & Effect Guide'},
        {key: 'question-exp', display: 'Question Exploration'},
        {key: 'cera', display: 'Claim, Evidence, Reasoning Guide'}
        // TODO add unit-organizer when implemented
        ];

    constructor(props) {
        super(props);

        this.picked = this.picked.bind(this);
    }

    picked(data) {
        let doc = this.context;
        var location = {pathname: "/docs"};
        if ('picked' === data.action) {

            DocPicker.supportedDocTypes.some(function (docType, index, _arr) {
                if (data.docs[0].description.includes(docType.display)) {
                    doc.fileIdLoaded = false; // ???
                    location = to(docType.key, data.docs[0].id, 'toc');
                    return true; // break
                }
                return false;
            });

            if ('/docs' === location.pathname) {
                console.warn('picker returned '+data.docs[0].description);
            }
            console.log("picker redirect to " + util.inspect(location));
            // this.context.router.history.push(docId);
            return window.location = location.pathname;

        }
        return <Redirect to={location} push={true} />;
    }

    render() {
        //const appName = window.appName? window.appName: 'CORGI';
        //const desktop = "&fd=true";
        let appId = firebaseConfig ? firebaseConfig.clientId.split('-')[0] : "42"; // for OrganizerChoice test
        let corgi_mimeType = 'application/vnd.google-apps.drive-sdk.' + appId;
        let clientId = firebaseConfig ? firebaseConfig.clientId : '42';
        let apiKey = firebaseConfig ? firebaseConfig.apiKey : '42';
        let scopes = firebaseConfig ? firebaseConfig.scopes : [];

        return (

            <GooglePicker id={"picker"}
                          clientId={clientId}
                          developerKey={apiKey}
                          scope={scopes}
                          onChange={data => this.picked(data)}
                          onAuthFailed={data => console.log('on auth failed:', data)}
                          onAuthenticate={token => this.context.checkAccessTokenBeforeInitGooglePicker(token)}
                          multiselect={false}
                          // navHidden={false}
                          authImmediate={false}
                          mimeTypes={[ // 'image/png', 'image/jpeg',
                              corgi_mimeType
                          ]}
                // DOCS_IMAGES_AND_VIDEOS?
                          viewId={'DOCS'}>
                {this.props.children}
            </GooglePicker>

        )
    }
}


export function to(docType,docId,section,hash) {
    const doc = {section: section, type: docType, id: docId};
    const sectionSep = '/'; // #?
    const type = 'unit-org-2' === docType? 'unit-organizer': docType;
    var location = "/"+type
                    +"/"+docId
                    +(section? sectionSep+section: "")
    ;

    // FIXME: temporally only support Comparison
    if (docType !== 'comparison' && docType !== 'cause-effect' && docType !== 'question-exp' && docType !== 'cera') {
        location = '/docs';
    }
    return {pathname: location, state: doc, hash: hash};
}




import React from "react";
import PageLink from "./PageLink";
import initUI from "../ui";
import Breadcrumb from "../Breadcrumb";
import {getHashElement, doHashScroll} from "./HashScroll";
import $ from "jquery";
window.jQuery = window.$ = $;

export default class FaqContent extends React.Component {

    componentDidMount() {
        const element = getHashElement();
        doHashScroll();

        // Open faq item
        if (element && element.matches('[data-cfw="collapse"]')) {
            const $element = $(element);
            $element.one('init.cfw.collapse', function() {
                $element.CFW_Collapse('show');
            });
        }

        initUI();
    }

    render() {
        return(
            <div className="page-wrapper">
                <div id="content" className="content row">
                    <div className="content-main col">
                        <div className="content-main-inner">
                            <Breadcrumb name="Corgi FAQ" />
                            <main id="main_container">

                                <h1>Find answers to frequently asked questions</h1>

                                <div className="mb-1">
                                    <a href="#faq00-answer" id="faq00" data-cfw="collapse">What's the goal of Corgi?<span className="caret ms-0_25"></span></a>
                                    <div id="faq00-answer" className="collapse">
                                        Corgi is designed to support middle school learners to develop higher-order reasoning skills and engage in challenging ways of thinking and doing. By bringing graphic organizers to a flexible, accessible online environment, we aim to reduce barriers and support all students to develop as expert learners.
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq01-answer" id="faq01" data-cfw="collapse">Why was Corgi developed?<span className="caret ms-0_25"></span></a>
                                    <div id="faq01-answer" className="collapse">
                                        Research reveals that students with learning disabilities have lower rates of participation and achievement in science courses. Further, students with learning disabilities are less likely to pursue STEM degrees and STEM careers. Corgi was developed to address these barriers to STEM participation for students with and without disabilities.
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq02-answer" id="faq02" data-cfw="collapse">Who is Corgi for?<span className="caret ms-0_25"></span></a>
                                    <div id="faq02-answer" className="collapse">
                                        <p>Corgi is designed to support the learning of all middle and high school students. Corgi embeds a range of options and supports such as a read aloud function, a dictionary, translation tools, and speech-to-text. These features reduce barriers and increase access for students with and without disabilities.</p>
                                        <p>So far, our research has focused on using Corgi in middle and high school settings, but we see potential for Corgi in elementary school and college settings as well. Further, while our research has focused on using Corgi in STEM and Social Studies classrooms, there are applications for Corgi in a range of other content areas.</p>
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq03-answer" id="faq03" data-cfw="collapse">Is Corgi free?<span className="caret ms-0_25"></span></a>
                                    <div id="faq03-answer" className="collapse">
                                        Corgi is supported by grants from the National Science Foundation and the Office of Special Education Programs. Corgi is free for users.
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq04-answer" id="faq04" data-cfw="collapse">In what kinds of teaching environments can Corgi be used?<span className="caret ms-0_25"></span></a>
                                    <div id="faq04-answer" className="collapse">
                                        Corgi can be used in nearly any classroom&mdash;online, in-person, or hybrid settings.
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq05-answer" id="faq05" data-cfw="collapse">What is the research basis for Corgi?<span className="caret ms-0_25"></span></a>
                                    <div id="faq05-answer" className="collapse">
                                        Research shows that Corgi works. Both our pilot study and independent evaluation of the Corgi app showed very positive results: Students made significant gains learning science and U.S. history content when using Corgi. Encouragingly, students with disabilities made substantial improvements in the independent evaluation. We’re excited Corgi helps students learn.
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq06-answer" id="faq06" data-cfw="collapse">Where was Corgi tested?<span className="caret ms-0_25"></span></a>
                                    <div id="faq06-answer" className="collapse">
                                        Corgi has been piloted in middle school and high school classrooms in California and Virginia.
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq07-answer" id="faq07" data-cfw="collapse">How can Corgi support me to connect to the Next Generation Science Standards (NGSS)?<span className="caret ms-0_25"></span></a>
                                    <div id="faq07-answer" className="collapse">
                                        Corgi can be used to reduce barriers and increase access to the Next Generation Science Standards. Once you identify the Standard and the learning goal of the lesson or unit, you can select the Corgi guide or guides that best align.
                                        Here is an example of a middle school science unit plan, <a href="https://docs.google.com/document/d/1dAB-lNMeE-E38YOxARfWgHytGgQ0Jq9f0H16WyvXqw8/edit">“Earth’s Place in the Universe,”</a> that we have developed around one Standard.
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq08-answer" id="faq08" data-cfw="collapse">How does Corgi relate to the Content Enhancement Routines from the Strategic Instruction Model?<span className="caret ms-0_25"></span></a>
                                    <div id="faq08-answer" className="collapse">
                                        Corgi builds upon the research of the <a href="https://sim.ku.edu/sim-content-enhancement-routines">Content Enhancement Routines</a> from <a href="https://sim.ku.edu/">Strategic Instruction Model</a>, developed by the <a href="https://kucrl.ku.edu/">University of Kansas Center for Research on Learning</a>. Many of the guides used in Corgi are aligned to the <a href="https://sim.ku.edu/hotr-routines">Higher Order Thinking and Reasoning Routines</a> from SIM.
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <PageLink page="support">Are there resources/supports to help me get started with using Corgi?</PageLink>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq09-answer" id="faq09" data-cfw="collapse">Why does Corgi ask permission to access your Google Drive?<span className="caret ms-0_25"></span></a>
                                    <div id="faq09-answer" className="collapse">
                                        Corgi saves all of your work directly to your Google Drive, so you can access it from any device with an internet connection.
                                        Google requires Corgi users to provide authorization to link up your account.
                                        Feel free to check out our how-to:{' '}
                                        <a href="https://docs.google.com/presentation/d/1z9TaKCdRZ5E5TuVvbLuBl_D-g15qaP3-_kGEy3lEZZU/present?slide=id.g110fcc7702b_0_0">How to authorize Corgi to work with your Google Account</a>{' '}
                                        to take you through the process!
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq10-answer" id="faq10" data-cfw="collapse">What permissions do I need to select when getting started?<span className="caret ms-0_25"></span></a>
                                    <div id="faq10-answer" className="collapse">
                                        <p>
                                            When setting up your account, Google will ask you to give Corgi permission to work with your Google account.
                                            It is important that you select these permissions for Corgi to run smoothly!
                                            Here are the three permissions to select:
                                        </p>
                                        <ol>
                                            <li>See and download all your Google Drive files</li>
                                            <li>Connect itself to your Google Drive</li>
                                            <li>See, edit, create, and delete only the specific Google Drive files you use with this app</li>
                                        </ol>
                                        Feel free to check out our how-to:{' '}
                                        <a href="https://docs.google.com/presentation/d/1z9TaKCdRZ5E5TuVvbLuBl_D-g15qaP3-_kGEy3lEZZU/present?slide=id.g110fcc7702b_0_0">How to authorize Corgi to work with your Google Account</a>{' ' }
                                        to take you through the process!
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq11-answer" id="faq11" data-cfw="collapse">What do I do if I receive an error message?<span className="caret ms-0_25"></span></a>
                                    <div id="faq11-answer" className="collapse">
                                        Corgi works with third party companies such as Google, Texthelp, and CAST. It is possible that these websites could be updating. We recommend refreshing your browser and trying again.
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq13-answer" id="faq13" data-cfw="collapse">What do I do if I am not able to share or open a Corgi guide?<span className="caret ms-0_25"></span></a>
                                    <div id="faq13-answer" className="collapse">
                                        First, we recommend refreshing your browser and trying again. If that does not work then log out of your Google account and make sure you only logged into the Google account associated with Corgi. Also, please allow for third party cookies and popups.
                                    </div>
                                </div>

                                <div className="mb-1">
                                    <a href="#faq12-answer" id="faq12" data-cfw="collapse">Want to work with us?<span className="caret ms-0_25"></span></a>
                                    <div id="faq12-answer" className="collapse">
                                        We are recruiting teachers to pilot Corgi in their classrooms. <a href="mailto:corgi@cast.org">Email us</a> to learn more about this opportunity.
                                    </div>
                                </div>

                                <strong>Still have questions? Get in touch! Email our team: <a href="mailto:corgi@cast.org">corgi@cast.org</a></strong>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

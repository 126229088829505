import $ from 'jquery';
// Direction for video player dropdown menus
$(document, '[data-cfw="player"]').on('ready.cfw.player', function(e) {
    // 'use strict';

    $(e.target).closest('[data-cfw="player"]').find('.player-caption-wrapper').addClass('dropup dropend');
    $(e.target).closest('[data-cfw="player"]').find('.player-script-wrapper').addClass('dropup dropend');
});

// Stick pagination nav to top of footer when it is reached,
// otherwise fix at bottom of viewport
var paginationAffix = function() {
    // 'use strict';

    var $affix = $('.footer-pages');

    $affix.CFW_Affix({
        top: 0,
        bottom: function() {
            // TODO: Why is px adjustment needed?
            return $('footer').outerHeight() + $affix.innerHeight() - 34;
        }
    });
    $(window).on('resize', function() {
        $affix.CFW_Affix('checkPosition');
    });

    $(window).scroll();
};

var playerHideStop = function() {
    // 'use strict';

    $(document).on('afterHide.cfw.modal afterHide.cfw.popover', function(e) {
        var $node = $(e.target);
        $node.find('[data-cfw="player"]').CFW_Player('pause');
    });
};

// Various supplemental functionality due to BPV sidebar
var bpvAsidePos = function() {
    //'use strict';

    var $aside = $('.bpv-side');
    var bpMd = getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-md');
    var bpMdpx = Math.floor(parseFloat(bpMd) * 16);
    var w1 = window.innerWidth;
    var h1 = $('.header-main').outerHeight();
    var h2 = $('.footer-pages').outerHeight();
    var $topbar = $('.alert-topbar');
    var isMd = w1 >= bpMdpx;

    h1 = typeof h1 === 'undefined' ? 0 : h1;
    h2 = typeof h2 === 'undefined' ? 0 : h2;

    // If portion of `.alert.topbar` is showing below header, adjust top point
    if ($topbar.length) {
        var h3 = $topbar.offset().top + $topbar.outerHeight() - window.pageYOffset;
        h1 = h3 > h1 ? h3 : h1;
    }

    $aside
        .removeClass('width')
        .css({
            'top': '',
            'bottom': '',
            'max-height': ''
        });

    if (isMd) {
        $aside
            .addClass('width')
            .css({
                'top': h1 + 'px',
                'bottom': h2 + 'px',
                'max-height': 'calc(100vh - ' + parseInt(h1 + h2, 10) + 'px)'
            });
    }
};

export const bpvAsideMain = function() {
    // 'use strict';

    var $aside = $('.bpv-side');
    var $main = $('main').first();
    var $container = $('.container').first();

    $main.removeClass('has-aside');
    $container.removeClass('has-aside');
    if ($aside.hasClass('in')) {
        $main.addClass('has-aside');
        $container.addClass('has-aside');
    }
};

// eslint-disable-next-line no-unused-vars
export const bpvAside = function() {
    // 'use strict';

    var $affix = $('.footer-pages');

    $('.bpv-side-toggle')
        .on('afterHide.cfw.collapse afterShow.cfw.collapse', function() {
            // TODO: Any way to eliminate this double call?
            // Double call due to not always updating correctly on afterShow
            $affix.CFW_Affix('checkPosition');
            setTimeout(function() {
                $affix.CFW_Affix('checkPosition');
            }, 10);
            bpvAsideMain();
        })
        .on('beforeShow.cfw.collapse', function() {
            bpvAsidePos();
        });

    $('.alert-topbar').on('afterClose.cfw.alert', function() {
        bpvAsidePos();        
    });
    $(window).on('resize', function() {
        bpvAsidePos();
    });

    bpvAsidePos();
    bpvAsideMain();
};

$(window).ready(function() {
    // 'use strict';

    paginationAffix();
    playerHideStop();

    // Need to be called from `componentDidMount()`
    // bpvAside();
});

// module.exports = bpvAside;
// exports.bpvAside = bpvAside;
// export default bpvAside;

import React, {Component} from 'react';
import T from 'i18n-react';

import texts from './texts';

// import Header from './Header';
import Footer from './Footer';

import corgiDocIcons from './corgi-doc-icon.base64.js';

import utils, {firebaseConfig, getParam} from './realtimedb-utils';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import STT from "./STT";
import Track from "./Track";
import MainContent from "./components/MainContent";
import SystemError from "./errors/SystemError";
import Page from "./components/Page";
import {AppContextProvider} from "./AppContext";
import ScrollToTop from "./ScrollToTop";
import {isValidTitle} from "./components/GuideTitle";
import UserRolePopup from './components/UserRolePopup';
import MobileRotateModal from './components/MobileRotateModal';
import $ from 'jquery';
import AddYouTubeModal from "./components/modals/AddYouTubeModal";
import SearchWebModal from "./components/modals/SearchWebModal";
import EnterURLModal from "./components/modals/EnterURLModal";
import {decodeToken} from "react-jwt";
import Cookies from 'universal-cookie';
import axios from "axios";
import { DisableConsole } from "./DisableConsole.js";

const util = require('util');
const cookies = new Cookies();
// Add all scopes to base app so that everything is added on login
const BASE_GOOGLE_SCOPE = "profile https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.install";
const BASE_GOOGLE_TOKEN_INFO_URL = "https://oauth2.googleapis.com/tokeninfo";
export default class App extends Component {

    constructor() {
        super();

        this.youTubeModalId = 'youTubeModal';
        this.searchWebModalId = 'searchWebModal';
        this.enterURLModalId = 'enterURLModal';

        // Binding is required in order for methods of the class to have access to "this".
        this.authorize = this.authorize.bind(this);
        this.authorizeWithPopUp = this.authorizeWithPopUp.bind(this);
        this.afterAuthorized = this.afterAuthorized.bind(this);
        this.loadGoogleClientApiAndAuthorize = this.loadGoogleClientApiAndAuthorize.bind(this);
        this.loadFileBasedOnUrl = this.loadFileBasedOnUrl.bind(this);
        this.loadFile = this.loadFile.bind(this);
        this.onFileLoaded = this.onFileLoaded.bind(this);
        this.closeFile = this.closeFile.bind(this);
        this.getMetadata = this.getMetadata.bind(this);
        this.createDoc = this.createDoc.bind(this);
        this.createDriveFile = this.createDriveFile.bind(this);
        this.updateDriveFile = this.updateDriveFile.bind(this);
        this.createRealtimeFile = this.createRealtimeFile.bind(this);
        this.shareFile = this.shareFile.bind(this);
        this.doNewDoc = this.doNewDoc.bind(this);
        this.handleNewDocButton = this.handleNewDocButton.bind(this);
        this.handleCopyButton = this.handleCopyButton.bind(this);
        this.copyFile = this.copyFile.bind(this);
        this.renameDoc = this.renameDoc.bind(this);
        this.updateModifiedTime = this.updateModifiedTime.bind(this);
        this.isFileTypeValid = this.isFileTypeValid.bind(this);
        this.toggleAside = this.toggleAside.bind(this);
        this.openEnterURLModal = this.openEnterURLModal.bind(this);
        this.openYouTubeModal = this.openYouTubeModal.bind(this);
        this.closeYouTubeModal = this.closeYouTubeModal.bind(this);
        this.handleEnterURLSave = this.handleEnterURLSave.bind(this);
        this.handleYouTubeSave = this.handleYouTubeSave.bind(this);
        this.handleImageSave = this.handleImageSave.bind(this);
        this.openSearchWebModal = this.openSearchWebModal.bind(this);
        this.contentUpdated = this.contentUpdated.bind(this);
        this.updateOpenedTime = this.updateOpenedTime.bind(this);
        this.updateFileName = this.updateFileName.bind(this);
        this.updateAccountType = this.updateAccountType.bind(this);
        this.addGoogleScope = this.addGoogleScope.bind(this);
        this.hasGrantedScopesAll = this.hasGrantedScopesAll.bind(this);
        this.handleAccessTokenExpired = this.handleAccessTokenExpired.bind(this);
        this.checkAccessTokenBeforeInitGooglePicker = this.checkAccessTokenBeforeInitGooglePicker.bind(this);

        this.state = {
            // Set up speech to text
            recognition: new STT(),
            loading: true,        // True until everything is loaded and ready for user interaction
            authorized: false,    // True after successful authorization with Google APIs
            loginRequired: false, // Set to true if we need to present the login screen
            createPending: null,  // Normally null; set to new filename during a file create operation
            copyPending: null,    // Normally null; set to new filename during a copy operation
            fileIdLoaded: false,
            fileId: null,         // ID of collaborative document
            newFileId: null,      // Set by "create document" operation.
            fileType: null,       // Type of CER
            fileName: null,
            fileTypeValid: false, // Whether type is actually valid
            metadata: null,       // Google Drive metadata
            currentUser: null,
            error: 0,
            hosting: undefined !== firebaseConfig.hosting ? firebaseConfig.hosting : process.env.PUBLIC_URL,
            storage: "https://storage.googleapis.com/storage/v1/b/corgi-dev.appspot.com/o/",
            asideOpen: false,
            toggleAside: this.toggleAside,
            openYouTubeModal: this.openYouTubeModal,
            openSearchWebModal: this.openSearchWebModal,
            openEnterURLModal: this.openEnterURLModal,
            contentUpdated: this.contentUpdated,
            updateOpenedTime: this.updateOpenedTime,
            updateFileName: this.updateFileName,
            updateAccountType:this.updateAccountType,
            addGoogleScope: this.addGoogleScope,
            hasGrantedScopesAll: this.hasGrantedScopesAll,
            handleAccessTokenExpired: this.handleAccessTokenExpired,
            checkAccessTokenBeforeInitGooglePicker: this.checkAccessTokenBeforeInitGooglePicker
        };
        T.setTexts(texts);
        window.tracker = new Track();
        //Init realtime db connection and relative api
        new utils.ReatimeDbUtils();

        window.firebase = firebase;
        //window.storage = new Storage();

        // possibly decouple using something like https://github.com/tylermcginnis/re-base

        this.childRef = React.createRef();
    }

    render() {
        const shareMethod = this.shareFile; // (this.state.metadata && this.state.metadata.capabilities.canShare) ? this.shareFile : null;

        //const header = <Header />;

        const footer = this.state.loading ? null :  // or copyPending?
                <Footer valid={this.state.fileTypeValid}/>;

        const main = <MainContent
            valid={this.state.fileTypeValid}
            shareMethod={shareMethod}
            loadDocMethod={this.loadFileBasedOnUrl}
            newDocButtonMethod={this.handleNewDocButton}
            createDocMethod={this.createDoc}
            copyButtonMethod={this.handleCopyButton}
            copyDocMethod={this.copyFile}
            renameDocMethod={this.renameDoc}
            authorizeWithPopUp={this.authorizeWithPopUp}
            filename={this.state.metadata ? this.state.fileName : null}
        />;

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <SystemError>Something went wrong.</SystemError>;
        }

        return (
            <AppContextProvider value={this.state}>
                <ScrollToTop/>
                <Page main={main} footer={footer}
                      shareMethod={shareMethod}
                      newDocButtonMethod={this.handleNewDocButton}
                      createDocMethod={this.createDoc}
                      copyButtonMethod={this.handleCopyButton}
                      copyDocMethod={this.handleCopyButton}
                      renameDocMethod={this.renameDoc}
                />
                <UserRolePopup ref={this.childRef} currentUser = {this.state.currentUser}/>
                <AddYouTubeModal id={this.youTubeModalId} saveMethod={this.handleYouTubeSave}/>
                <SearchWebModal id={this.searchWebModalId} saveMethod={this.handleImageSave}/>
                <EnterURLModal id={this.enterURLModalId} saveMethod={this.handleEnterURLSave}/>
                <MobileRotateModal />
            </AppContextProvider>
        );
    }

    // realtimeUtils is deprecated TODO: replace with ???
    componentDidMount() {
        // Try a quick, no-popup authorization for the case where user is already logged in.
        // If this fails, they will have to click the "Authenticate" button to launch the pop-up.
        this.authorize();
        // Remove user from collaborative user list when close window
        window.addEventListener("beforeunload", (e) => {
            window.realtimeUtils.removeCollaborativeUser(this.state.fileType, this.state.fileId, this.state.currentUser);
        });
        // Load TextHelp toolbar
        // window.TexthelpSpeechStream.addToolbar();

        if (window.developmentTools === false) {
            DisableConsole(true);
        }
    }

    componentWillUnmount() {

    }

    isIOS() {
        // Second test is needed for iOS 13+
        return (
            navigator.userAgent.match(/iPhone|iPad|iPod/i) != null ||
            (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
        );
    }

    // Attempt to auto authorize with Google Firebase.
    authorize() {
        if (this.isIOS()) {
            window.onpageshow = function(event) {
                if (event.persisted) {
                    window.location.reload();
                }
            };
        }

        const idToken = cookies.get("corgi_id_token");
        if (idToken != null) {
            if(window.google) {
                this.initGISClient(idToken);
            } else {
                document.getElementById("gsiClientScript").addEventListener("load", () => {
                    this.initGISClient(idToken);
                });
            }
        } else {
            console.log("Auto-authorize did not work, redirect to pre-login");
            this.setState({loginRequired: true, loading: false});
        }
    }

    initGISClient(idToken) {
        const access_token = cookies.get("corgi_access_token");
        if(access_token) {
            this.validateAccessToken(access_token,
                () => {
                    this.loadGoogleClientApiAndAuthorize(access_token).then(() => {
                        this.afterAuthorized();
                    })
                }, () => {
                    this.handleAccessTokenExpired();
                }
            );
        }
        else {
            this.requestNewAccessToken(idToken);
        }
    }

    requestNewAccessToken(idToken) {
        const gisClient = window.google.accounts.oauth2.initTokenClient({
                client_id: window.firebaseConfig.clientId,
                hint: decodeToken(idToken).email,
                scope: BASE_GOOGLE_SCOPE,
                prompt: '',
                callback: (tokenResponse) => {
                    cookies.set("corgi_access_token", tokenResponse.access_token, {path: '/'});

                    console.log("tokenResponse.scope", tokenResponse.scope);

                    // Save the actual reported scopes  - not the assumed ones
                    //cookies.set("corgi_granted_scopes", BASE_GOOGLE_SCOPE, {path: '/'});
                    cookies.set("corgi_granted_scopes", tokenResponse.scope, {path: '/'});

                    this.loadGoogleClientApiAndAuthorize(tokenResponse.access_token).then(() => {
                        this.afterAuthorized();
                    })
                }
            }
        );

        gisClient.requestAccessToken();
    }
    loadGoogleClientApiAndAuthorize(accessToken) {

        return new Promise((resolve) => {

            const script = document.createElement("script");
            script.src = "https://apis.google.com/js/api.js";

            script.onload = (e) => {
                window.gapi.load("client", () => {
                    window.gapi.client.init(
                        {}
                    ).then(() => {
                        window.gapi.auth.setToken({access_token: accessToken})

                        window.gapi.client.load('drive', 'v3', () => {
                            console.log("drive v3 client loaded");
                            resolve();
                        }, error => console.error("Failed to load drive client API", error));
                    })
                });

                window.gapi.load("drive-share", () => {
                    console.log("drive-share loaded")
                }, error => console.error("can not load drive-share", error));

            };
            document.body.appendChild(script);
        });
    }

    afterAuthorized() {
        const inspectedIDToken = decodeToken(cookies.get("corgi_id_token"));

        let currentUser = {
            uid: cookies.get("corgi_firebase_uid"),
            displayName: inspectedIDToken.name,
            photoURL: inspectedIDToken.picture,
            emailAddress: inspectedIDToken.email,
            role: ""
        };

        this.setState({authorized: true, loginRequired: false, loading: false, currentUser: currentUser}, () => {
            this.loadFileBasedOnUrl();
            this.checkAccountType();
        });
    }

    checkAccountType(){
        console.log("Check Account Type");
        const roleNode = "user/" + this.state.currentUser.uid ;
        let roleRef = window.firebase.database().ref(roleNode);

        let self = this;
        roleRef.once('value', function(snapshot) {
            if(!snapshot.exists()){
                console.log("Don't have role in Firebase -> show popup");
                $('#modalUserType0').CFW_Modal('show');
            }else{
                let user = self.state.currentUser;
                user.role = snapshot.val().role;
                self.setState({currentUser: user},() =>{
                    self.childRef.current.updateRole(user.role);
                });
            }
        });
    }

    updateAccountType(role){
        const roleNode = "user/" + this.state.currentUser.uid ;
        let roleRef = window.firebase.database().ref(roleNode);
        let self = this;
        roleRef.update({role: role},() =>{
            console.log("Update role success");
            let user = self.state.currentUser;
            user.role = role;
            self.setState({currentUser: user});
        })
    }

    openEnterURLModal(triggerId, saveMethod) {
        this.enterURLSaveMethod = saveMethod;

        $('#'+triggerId).CFW_Modal({
            'target': '#' + this.enterURLModalId,
            'unlink': true,
            'show': true
        });
    }

    handleEnterURLSave(url) {
        $('#'+this.enterURLModalId).CFW_Modal('hide');

        if (this.enterURLSaveMethod) {
            this.enterURLSaveMethod(url);
        }
    }

    openYouTubeModal(triggerId, saveMethod) {
        this.youTubeSaveMethod = saveMethod;
        $('#'+triggerId).CFW_Modal({
            'target': '#' + this.youTubeModalId,
            'unlink': true,
            'show': true
        });
    }

    closeYouTubeModal() {
        $('#'+this.youTubeModalId).CFW_Modal('hide');
    }

    handleYouTubeSave(url) {
        this.closeYouTubeModal();
        if (this.youTubeSaveMethod) {
            this.youTubeSaveMethod(url);
        } else {
            console.error('YouTube Video save called without a save method defined');
        }
    }

    openSearchWebModal(triggerId, saveMethod) {
        this.imageSaveMethod = saveMethod;
        $('#'+triggerId).CFW_Modal({
            'target': '#' + this.searchWebModalId,
            'unlink': true,
            'show': true
        });
    }

    handleImageSave(url) {
        $('#'+this.searchWebModalId).CFW_Modal('hide');

        if (this.imageSaveMethod) {
            this.imageSaveMethod(url);
        }
    }

    handleAccessTokenExpired() {
        cookies.remove("corgi_access_token", {path: '/'});
        cookies.remove("corgi_granted_scopes", {path: '/'});
        this.requestNewAccessToken(cookies.get("corgi_id_token"));
    }

    // Login with Google
    authorizeWithPopUp(googleAuthResponse) {
        cookies.set("corgi_id_token", googleAuthResponse.credential, {path: '/'});
        firebase.auth().signInWithCredential(firebase.auth.GoogleAuthProvider.credential(googleAuthResponse.credential)).then((userCre) => {
            let user = userCre.user;
            if (user) {
                console.log("Sign in Firebase successfully");
                cookies.set("corgi_firebase_uid", user.uid, {path: '/'});
                this.authorize();
            }
        })
    }

    loadFileBasedOnUrl() {
        const docType = getParam('type');
        const id = getParam('id');
        console.debug("App.loadFileBasedOnUrl "+id);
        if (this.isFileTypeValid(docType) && id) {
            if (id !== this.state.fileId) {
                this.loadFile(id);
            }
        } else {
            console.log("No document to load.");
        }

    }

    // Retrieve the file with the given ID from Google Drive.
    loadFile(id) {
        console.debug("App.loadFile "+id);
        if (this.state.fileIdLoaded) {
            this.setState({fileIdLoaded: false});
        }
        this.getMetadata(id).then(metadata => {
            // The "type" section of the URL can be wrong when Google Drive redirects to Corgi
            if (metadata.properties.type !== getParam('type')) {
                console.warn('Redicting because document is actually ', metadata.properties.type);
                window.location = '/' + metadata.properties.type + '/' + id;
                return;
            }
            this.onFileLoaded(id, metadata)
        });
    }

    isFileTypeValid(type) {
        return [
            'cause-effect',
            'question-exp',
            'comparison',
            'cera'
            ].includes(type);
    }

    // Called when we have connected to a file.
    // and set state variables which will connect the data model to the UI.
    onFileLoaded(id, metadata) {
        console.debug("App.onFileLoaded "+id);
        // We write the ID of the file into the file metadata.
        // This lets us detect if the file has been copied by Google Drive outside of Corgi
        // If so, we copy the original content the first time the copied file is opened.
        let origId = metadata.properties.origId;
        if (origId === id) {
            console.debug("Original ID matches current ID: " + origId);
        } else if (origId) {
            // Note: this pathway is currently unused. Google Drive won't let users copy these files.
            console.debug("Original ID does not match - file was copied outside of Corgi.");
            console.info("Copying file contents from " + origId + " into " + id);
            window.realtimeUtils.getDoc(metadata.properties.type, origId).once("value", data => {
                this.copyRealtimeFile(data.val(), id);
            })
        } else {
            console.info("Original ID is missing; adding it to the metadata");
            this.updateDriveFile(id, { properties: { origId : id}});
        }
        this.setState({
            fileIdLoaded: true,
            fileId: id,
            newFileId: null,
            fileType: metadata.properties.type,
            fileName: metadata.name,
            fileSection: getParam("section"),
            metadata: metadata,
            canEdit: metadata.capabilities.canEdit,
            fileTypeValid: this.isFileTypeValid(metadata.properties.type),
            asideOpen: true,
            loading: false,
            copyPending: null,
            createPending: null
        });
        console.log("Parents of loaded file: ", metadata.parents);
        if(metadata.capabilities.canEdit)
            window.realtimeUtils.addCollaborativeUser(metadata.properties.type, id, this.state.currentUser);
        this.updateOpenedTime();
    }

    getRoutineTitle = (prompt, oldName) => {
        const newName = window.prompt(prompt, oldName);
        if (!isValidTitle(newName)) {
            return null;
        }
        return newName.trim();
    }

    // Creates a new file and attaches to it a new realtime document of the given type.
    createDoc(page, type) {
        console.log("App.createDoc "+type);

        if (this.state.createPending) {
            this.setState({fileId: null, newFileId: null});
            this.createDriveFile(this.state.createPending, type).then((id) => {
                this.createRealtimeFile(id, type).then(() => {
                    this.setState({createPending:null,newFileId: id});
                    $('#offcanvasNavbar').CFW_Offcanvas('hide');
                });
            }).catch(error => {
                console.log("Error while creating drive file", error);
            });
            // window.tracker.trackEvent(page, "create-new", type);
            window.tracker.trackEvent("Guide", "create guide", "");
        } else {
            console.log('null %s createPending',type);
        }
        return false;
    }

    createRealtimeFile(id, type) {
        console.debug("App.createRealtimeFile "+id);

        return window.realtimeUtils.createDoc(type, id, {type: type}).then(() => {
        }).catch(error => {
            console.log("Error while creating realtime file", error);
        });
    }

    updateDriveFile(fileId, changes) {
        return new Promise((resolve, reject) => {
            window.gapi.client.request(
                {
                    path: "https://www.googleapis.com/drive/v3/files/" + fileId,
                    method: "PATCH",
                    body: changes
                }
            ).then((response) => {
                console.log("Metadata update successful " + util.inspect(changes));
                resolve(response.result);
            }, (error) => {
                console.error("Could not update metadata: " + error.result.error.message);
                if (error.result.error.code === 401) {
                    this.handleAccessTokenExpired();
                } else {
                    reject(error);
                }
            });
        });
    }

    // Creates a new Google Drive file and runs the given callback with the result.
    createDriveFile(title, type) {
        console.debug("App.createDriveFile "+type);

        return new Promise((resolve, reject) => {
            window.gapi.client.request(
                {
                    path: "https://www.googleapis.com/drive/v3/files",
                    method: "POST",
                    body: {
                        mimeType: "application/vnd.google-apps.drive-sdk",
                        name: title,
                        properties: {
                            type: type
                        },
                        contentHints: {
                            thumbnail: {
                                image: corgiDocIcons[type],
                                mimeType: "image/png"
                            }
                        },
                        description: ("CORGI document: " + T.translate(type + ".title"))
                    }
                }
            ).then(response => {
                const id = response.result.id;
                resolve(id);
            }, error => {
                if (error.result.error.code === 401) {
                    this.handleAccessTokenExpired();
                } else {
                    reject(error);
                }
            });
        });
    }

    getMetadata(id) {
        console.debug("App.getMetadata "+id);

        return new Promise(resolve => {
            window.gapi.client.request({
                path: "https://www.googleapis.com/drive/v3/files/" + id,
                method: "GET",
                params: {
                    fields: "capabilities, properties, id, name, mimeType, kind, parents"
                }
            }).then((response) => {
                    resolve(response.result);
                },
                (err_reason) => {
                    console.log("Could not get file metadata: " + err_reason.result.error.message);
                    this.setState({
                        error: 1
                    });
                    if (err_reason.result.error.code === 401) {
                        this.handleAccessTokenExpired();
                    }
                });
        });
    }

    updateFileName(newName){
        this.setState({fileName: newName});
    }

    renameDoc(newName) {
        return this.updateDriveFile(this.state.fileId, { name: newName });
    }

    contentUpdated(){
        if(undefined === this.lastUpdateTime || null === this.lastUpdateTime) {
            this.updateModifiedTime();
            this.lastUpdateTime = new Date().getTime();
        } else {
            let diffTime = new Date().getTime() - this.lastUpdateTime;
            if(diffTime > 60000){
                this.updateModifiedTime();
                this.lastUpdateTime = new Date().getTime();
            }
        }
    }

    updateModifiedTime() {
        this.updateDriveFile(this.state.fileId, { modifiedTime: new Date() });
    }

    updateOpenedTime(){
        if(undefined === this.lastOpenedTime || null === this.lastOpenedTime) {
            this.updateViewedByMeTime();
            this.lastOpenedTime = new Date().getTime();
        } else {
            let diffTime = new Date().getTime() - this.lastOpenedTime;
            if(diffTime > 60000){
                this.updateViewedByMeTime();
                this.lastOpenedTime = new Date().getTime();
            }
        }
    }

    updateViewedByMeTime() {
        this.updateDriveFile(this.state.fileId, { viewedByMeTime: new Date() });
    }

    // Stop editing document and revert to the create-a-new-doc view.
    closeFile() {
        //window.history.pushState(null, "", '?');
        window.realtimeUtils.removeCollaborativeUser(this.state.fileType, this.state.fileId, this.state.currentUser);
        this.setState({
            doc: null,
            fileId: null,
            filename: null,
            fileType: null,
            metadata: null
        });
    }

    addGoogleScope(scopes, callback) {
        const grantedScopes = cookies.get("corgi_granted_scopes");
        if(!grantedScopes.includes(scopes)) {
            const newScopes = grantedScopes + " " + scopes;
            const gisClient = window.google.accounts.oauth2.initTokenClient({
                    client_id: window.firebaseConfig.clientId,
                    hint: decodeToken(cookies.get("corgi_id_token")).email,
                    scope: newScopes,
                    prompt: '',
                    callback: (tokenResponse) => {
                        cookies.set("corgi_access_token", tokenResponse.access_token, {path: '/'});

                        // Save the actual scopes allowed as known by Google
                        //cookies.set("corgi_granted_scopes", newScopes, {path: '/'});
                        cookies.set("corgi_granted_scopes", tokenResponse.scope, {path: '/'});

                        this.loadGoogleClientApiAndAuthorize(tokenResponse.access_token).then(() => {
                            callback(window.google.accounts.oauth2.hasGrantedAnyScope(tokenResponse, "profile", scopes));
                        })
                    }
                }
            );

            gisClient.requestAccessToken();
        } else {
            callback(true);
        }
    }

    // Match *all* the scopes against the ones currently reported from Google
    // Make sure the cookie set uses the actual scopes permitted as reported from Google
    hasGrantedScopesAll(scopes) {
        const grantedScopes = cookies.get("corgi_granted_scopes");
        const scopesString = scopes.toString();
        const scopesArray = scopesString.split(/\s+/);

        console.log("GRANTEDSCOPES", grantedScopes);
        console.log("SCOPES", scopes);

        // Check if all scopes are present in grantedScopes
        for (let scopeToFind of scopesArray) {
            // If the requested scope is not found in grantedScopes
            if (!grantedScopes.includes(scopeToFind)) {
                return false;
            }
        }
        // If all scopes are found
        return true;
    }

    getDocsTitle(type,reloadIfCancel){
        const title = this.getRoutineTitle("Create guide named:",
            "Untitled " + T.translate(type + ".title"));
        if (!isValidTitle(title)) {
            console.log("Invalid title, new guide operation cancelled");
            if(null === reloadIfCancel || undefined === reloadIfCancel || true === reloadIfCancel)
                window.location = '/docs';
            else{
                $('#offcanvasNavbar').CFW_Offcanvas('hide');
                window.location = window.location.pathname;
            }
            return null;
        }
        return title;
    }

    doNewDoc(type, reloadIfCancel) {
        let title = this.getDocsTitle(type, reloadIfCancel);
        if (title != null) {
            this.setState({
                createPending: title,
                fileType: type,
                doc: null,
                fileId: null,
                filename: null,
                metadata: null,
            }, () => {
                this.createDoc("", type);
            });
        }
    }

    handleNewDocButton(type, reloadIfCancel) {
        let driveScopes = "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.install";

        if (this.hasGrantedScopesAll(driveScopes)) {
			this.doNewDoc(type, reloadIfCancel);
        } else {
            this.addGoogleScope(driveScopes, (succeed) => {
                if (succeed) {
                    this.doNewDoc(type, reloadIfCancel);
                }
            });
        }
    }

    handleCopyButton() {
        let oldName = this.state.fileName;
        const newName = this.getRoutineTitle("Copy guide to new name:", "Copy of " + oldName);
        if (!isValidTitle(newName)) {
            console.log("No new name, copy operation cancelled");
            return;
        }
        this.setState({copyPending: newName});
    }

    copyFile() {
        const newName = this.state.copyPending;
        const fileType = this.state.fileType;
        this.createDriveFile(newName, fileType).then(id => {
            window.tracker.trackEvent("Guide", "copy guide", "");
            window.realtimeUtils.getDoc(fileType, this.state.fileId).once("value", data => {
                this.copyRealtimeFile(data.val(), id);
            });
        });
    }

    // Copy the given Realtime document into a new Realtime doc model.
    copyRealtimeFile(docToCopy, id) {
        docToCopy.users = null;
        window.realtimeUtils.createDoc(docToCopy.type, id, docToCopy).then(() => {
            // All set. Redirect to edit the new file.
            this.setState({ copyPending: null, newFileId: id});
        }).catch(error => {
            console.log("Error while copying realtime file", error);
        });
    }

    shareFile() {
        const shareClient = new window.gapi.drive.share.ShareClient();
        shareClient.setOAuthToken(window.gapi.auth.getToken().access_token);
        shareClient.setItemIds(this.state.fileId);
        shareClient.showSettingsDialog();
        // window.tracker.trackEvent(this.state.fileType, "share-doc", this.state.fileName);
        window.tracker.trackEvent("Guide", "share guide", "");
    }

    toggleAside() {
        this.setState({ asideOpen: !this.state.asideOpen});
    }

    validateAccessToken(token, validTokenCallBack, invalidTokenCallback) {
        axios.get(BASE_GOOGLE_TOKEN_INFO_URL + "?access_token=" + token,
        {
            validateStatus: function (status) {
                return status === 400 || status === 200;
            }
        }).then(res => {
            if (res.data.expires_in && res.data.expires_in > 0) {
                validTokenCallBack();
            } else {
                invalidTokenCallback();
            }
        }).catch(function (error) {
            console.error(error);
        });
    }

    checkAccessTokenBeforeInitGooglePicker(access_token) {
        this.validateAccessToken(access_token,
            () => {},
            () => {
                window.location.reload();
            })
    }
}

import React from 'react';
import AppContext from "../AppContext";
import T from 'i18n-react';

export default class EmptyField extends React.Component {
    static contextType = AppContext;

    render() {
        let langKey = this.context.fileType + "." + this.props.identifier + ".empty";
        return(
            <div className={this.props.className} >
                <div className="row">
                    <p className="mb-0 font-italic">{T.translate(langKey)}</p>
                </div>
            </div>
        );
    }
}
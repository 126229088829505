import React, {Component} from "react";
import AppContext from "../AppContext";
import PropTypes from "prop-types";
import initUI from "../ui"; // call after collaborators dropdown is rendered
import Cookies from 'universal-cookie';

const cookies = new Cookies();
// Collaborators and Users dropdowns (part of the header)
export default class CollabUser extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            fileId: null,
            watchRef: null,
            collaborators: null
        };
        this.updateWatcher = this.updateWatcher.bind(this);
    }

    render() {
        if ("print" === this.context.fileSection) {
            return null;
        } else {
            let userDropdown = null;
            let collabDropdown = null;
            if (this.context.currentUser) {
                userDropdown =
                    <div className="header-user">
                        <button type="button" className="btn btn-link btn-round"
                                data-cfw="dropdown" data-cfw-dropdown-target="#dropdownUser"
                                title={"Account: " + this.context.currentUser.displayName}
                                aria-label={"Account: " + this.context.currentUser.displayName}>
                            <img src={this.context.currentUser.photoURL} className="img-fluid" alt="" role="presentation" />
                        </button>
                        <ul id="dropdownUser" className="dropdown-menu dropreverse">
                            <li className="dropdown-text">
                                <div className="h1">My Account</div>
                                <CollaboratorIcon key={this.context.currentUser.uid} collab={this.context.currentUser}
                                    email={true} wrap={false} isHeaderUser={true}/>
                            </li>
                            <li className="dropdown-divider" role="separator"></li>
                            <li>
                                <a href="/login" onClick={this.logout} className="dropdown-item"><span
                                    className="fa fa-sign-out-alt me-0_25" aria-hidden="true"></span>Sign out</a>
                            </li>
                        </ul>
                    </div>;

                const collaborators = this.state.collaborators;
                if (collaborators) {
                    let icons = [];
                    let lastestEmail = "";
                    for (let key in collaborators) {
                        if (collaborators.hasOwnProperty(key)) {
                            const c = collaborators[key];
                            lastestEmail = c.emailAddress;
                            icons.push(<CollaboratorIcon key={c.uid} collab={c} wrap={false}/>)
                        }
                    }
                    let numberUser = icons.length;
                    collabDropdown = numberUser > 1 ?
                        <div className="header-collab">
                            <button type="button" className="btn btn-link btn-collab btn-round"
                                data-cfw="dropdown" data-cfw-dropdown-target="#dropdownCollab"
                                title="Collaborators" aria-label="Collaborators">
                                    <span className="fa fa-fw fa-users" aria-hidden="true"></span>
                                    <span className="sr-only">{numberUser} Collaborators</span>
                            </button>
                            <span className="header-collab-count" aria-hidden="true">&times; {numberUser}</span>
                            <ul id="dropdownCollab" className="dropdown-menu dropreverse">
                                <li className="dropdown-text">
                                    <div className="h6">Collaborators</div>
                                    {icons}
                                </li>
                            </ul>
                        </div>
                        : lastestEmail === this.context.currentUser.emailAddress ?
                            <div className="header-collab">
                                <span className="d-inline-block" tabIndex="0" data-cfw="tooltip" data-cfw-tooltip-placement="bottom" title="No Collaborators">
                                    <button type="button" className="btn btn-link btn-collab btn-round" disabled>
                                        <span className="fa fa-fw fa-users" aria-hidden="true"></span>
                                        <span className="sr-only">No Collaborators</span>
                                    </button>
                                </span>
                            </div>
                            : null;
                }
            }
            return (
                <React.Fragment>
                    {collabDropdown}
                    {userDropdown}
                </React.Fragment>
            );
        }
    }

    componentDidUpdate() {
        if (this.state.fileId !== this.context.fileId) {
            this.updateWatcher();
        }
    }

    logout() {
        cookies.remove("corgi_id_token", {path: '/'});
        cookies.remove("corgi_firebase_uid", {path: '/'});
        cookies.remove("corgi_access_token", {path: '/'});
        cookies.remove("corgi_granted_scopes", {path: '/'});
        if (typeof window.history.pushState !== 'function') {
            window.history.pushState(null, "", window.location.href);
        }
        try {
            window.location.replace = '/login';
        } catch(e) {
            window.location = '/login';
        }
    }

    // Called whenever fileId changes.
    updateWatcher() {
        if (this.state.watchRef) {
            this.state.watchRef.off('value');
        }
        let fileId = this.context.fileId;
        if (fileId) {
            const collaborativeListUsersRef
                = window.realtimeUtils.getCollaborativeListUser(this.context.fileType, fileId);
            collaborativeListUsersRef.on('value', data => {
                const collaborators = data.val();
                this.setState({
                    fileId: fileId,
                    watchRef: collaborativeListUsersRef,
                    collaborators: collaborators
                }, () => initUI());
            });
        } else {
            // Nothing to watch right now
            this.setState({
                fileId: null,
                watchRef: null,
                collaborators: null
            });
        }
    }

    componentWillUnmount() {
        if (this.state.watchRef) {
            this.state.watchRef.off('value');
        }
    }
}

// Display for one collaborator.
// If wrap=false, returns just an avatar image.  If true, returns an <li> with image and name.
class CollaboratorIcon extends Component {

    render() {
        const email = this.props.email? this.props.collab.emailAddress: null;
        const isHeaderUser = this.props.isHeaderUser ? this.props.isHeaderUser : false;
            return (
                <div className="media">
                    <span className="collab-img me-0_5">
                        <img src={this.props.collab.photoURL} className="avatar-img" alt=""/>
                    </span>
                    <div className="media-body">
                        {this.props.collab.displayName}
                        <br/>
                        {email}
                        {isHeaderUser ?
                            <React.Fragment>
                                <br/>
                                <button type="button" className="account-change btn btn-link btn-small px-0" data-cfw="modal" data-cfw-modal-target="#modalUserType1">Change account type</button>
                            </React.Fragment>
                            : null
                        }
                    </div>
                </div>
            );
    }

}

CollaboratorIcon.propTypes = {
    collab: PropTypes.object.isRequired,
};

import React from 'react';
import DynamicComponent from '../DynamicComponent';
import PageLink from "../components/PageLink";

export default class Unsupported extends DynamicComponent {

    render() {
            return (
                <main id="main_container" className="container-fluid">
                    <div className="row">
                        <div className="col text-center my-lg-2">
                            <h1 className="h2 text-body my-lg-2">Sorry, this document type is no longer supported</h1>
                            <p>
                                <PageLink page={"docs"} hash={'#CERs'} className="link-add" role="button">
                                    New document
                                </PageLink>
                            </p>
                        </div>
                    </div>
                </main>
            );

    }

}

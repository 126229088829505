import { Component } from 'react';
import ReactDOM from 'react-dom';

export default class DynamicComponent extends Component {

    componentDidMount() {
        //console.log("Mounted: ", this, ReactDOM.findDOMNode(this));
        this.tagForTTS(ReactDOM.findDOMNode(this));
    }

    componentDidUpdate() {
        //console.log("Updated: ", this, ReactDOM.findDOMNode(this));
        this.tagForTTS(ReactDOM.findDOMNode(this));
    }

    tagForTTS(node) {
        // Have to check since this can get called before texthelp toolbar has been loaded.
        if (window.$rw_parseNewSection && window.$rw_isPageLoaded())
            window.$rw_parseNewSection(node);
    }

    // make Error Boundary https://reactjs.org/docs/error-boundaries.html
    constructor(props) {
        super(props);
        this.state = (undefined !== props && props.state)? props.state: {};
        this.state.hasError = false;
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.

        return { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error.message,errorInfo.componentStack);
    }
}

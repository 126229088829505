import React, {Fragment} from "react";
import StepHeader from "../StepHeader";
import ReadOnlyField from "../../ReadOnlyField";
import EvidenceList from "../../components/EvidenceList";

export default class EvidenceOpposes extends React.Component {

    constructor(props) {
        super(props);
        this.identifier = "evidenceopposes";
    }

    render() {
        return (
            <Fragment>
                <StepHeader section={this.props.section} tooltipTitle={true} />
                <h2>Claim</h2>
                <ReadOnlyField identifier="claim" className="box bg-autofill" />
                <EvidenceList doc={this.props.doc} identifier="evidenceopposes" canBeEmpty={false} data={this.props.data} hLevel={2} hClassName="h3" />
            </Fragment>
        );
    }

}

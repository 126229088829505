import SttInput from "../../components/SttInput";
import React from "react";

import ReadOnlyField from "../../ReadOnlyField";

export default class Answer extends React.Component {

    constructor(props) {
        super(props);
        this.identifier = "answer";
    }

    render() {
        const noImage = ['print'].includes(this.props.doc.section);
        return (this.props.editing ?
                <SttInput {...this.props} identifier={this.identifier}
                          addImage={true} inputType={"text-area"} singleLine={false}/>
                :
                <div className="grouping grouping-1">
                    <div className="grouping-item">
                        <div className="box bg-autofill">
                            <div className="row">
                                <ReadOnlyField identifier={this.identifier} className={'grouping-text'}/>
                                {noImage? null:
                                    <ReadOnlyField identifier={this.identifier+"/image"} className={'grouping-img'}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

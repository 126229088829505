import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from "./VideoPlayer";
import {hosting} from '../hosting';

export default class Video extends Component {

    constructor() {
        super();


        this.onPlay = this.onPlay.bind(this);
    }

    render() {

        const videoURL = hosting + "/video/" + this.props.video + ".mp4";
        const ccURL     = videoURL.replace("mp4", "vtt");
        const posterURL = videoURL.replace("mp4", "jpg");

        return (
            <VideoPlayer posterURL={posterURL} ccURL={ccURL} videoURL={videoURL} />
        );
    }

    onPlay (event) {
        // window.tracker.trackEvent("video-player", "play", this.props.video);
        window.tracker.trackEvent("Video", "play video", this.props.video);
    }
}


Video.propTypes = {
    video: PropTypes.string.isRequired
};

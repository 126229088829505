import React from 'react';
import AppContext from "./AppContext";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

// This is an interstitial page that is rendered briefly while a new document is being created.
// It has the responsibility to wait for the "newFileId" to be ready (the createDocMethod should handle
// that in the background) and then redirect to the URL of the new document.
export default class CreateDocument extends React.Component {
    static contextType = AppContext;

    //constructor(props) {
    //    super(props);
    //}

    render() {
        if (this.context.newFileId) {
            var newUrl = "/"+this.props.docType+"/"+this.context.newFileId;
            console.log("Redirecting to new file: " + newUrl);
            return <Redirect to={newUrl} />;
        }
        if (this.props.copyPending && this.props.copyDone) {
            let newUrl = "/" + this.props.docType + "/" + this.props.copyPending;
            console.log("Redirecting to copied file: " + newUrl);
            return <Redirect to={newUrl} />;
        }
        let message = "Creating new document...";
        return (
            <main id="main_container" className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col text-center mt-2">
                            <h1 className="h4 text-body mb-2">{message}</h1>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
CreateDocument.propTypes = {
    docType: PropTypes.string.isRequired
}

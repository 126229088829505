import DynamicComponent from "../../DynamicComponent";
import DisplayImage from "../DisplayImage";
import React from "react";
import $ from "jquery";
import uniqueId from "../../uniqueId";

const noImage = {src: '', alt: ''};
const maxMB = 50;
const maxResolution = 25; //megapixels

export default class EnterURLModal extends DynamicComponent {
    constructor(props) {
        super(props);

        this.state = {image: noImage};

        this.imageError = this.imageError.bind(this);
        this.resetInput = this.resetInput.bind(this);
        this.addImage = this.addImage.bind(this);
    }

    componentWillMount() {
        this.addImgUrlInputId = uniqueId();
    }

    imageError() {
        return (
            (undefined !== this.state.image.error) ?
                <div className="mt-1 font-weight-bold">
                                        <span className="fa fa-exclamation-circle text-danger fs-xlarge me-0_25"
                                              aria-hidden="true"></span>
                    {0 < this.state.image.error.trim().length ? this.state.image.error :
                        "There is an issue accessing this image."}
                </div>
                : null
        );
    }

    resetInput() {
        $('#' + this.addImgUrlInputId).val(null);
        this.setState({image: noImage});
    }

    addImage() {
        this.props.saveMethod(this.state.image);
        window.tracker.trackEvent("Content", "add image", "url");

        this.resetInput();
    }

    render() {
        let saveExternalButton =
            <button type="button" className="btn btn-primary" disabled={('' === this.state.image.src)}
                    onClick={(event) => {
                        event.preventDefault();
                        this.addImage();
                    }}
            >Add Image</button>
        ;

        let errorMessage = null;

        return <div id={this.props.id} className="modal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="h1 modal-title">Enter an image URL</h2>
                        <button type="button" className="close" data-cfw-dismiss="modal" aria-label="Close"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.resetInput();
                                }}>
                            <span className="fa fa-times" aria-hidden="true"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p className="directions">Supported filetypes: gif, jpg, png. Max filesize: 25mb.</p>
                        <form className="row gx-0_5" role="search"
                              onSubmit={(event) => {
                                  event.preventDefault();
                                  let url = "invalid";
                                  try {
                                      url = new URL(event.target[0].value);
                                      validUrlImage(url).then((value) => {
                                          if (!value) {
                                              errorMessage = "Image not found or not supported.";
                                          }
                                          const image = errorMessage ? {
                                              src: '',
                                              error: errorMessage
                                          } : {src: url.toString(), name: url.pathname.split('/').pop()};
                                          this.setState({image: image});
                                      })
                                  } catch (e) {
                                      errorMessage = "Sorry, " + e.message;
                                  }
                              }}>
                            <div className="col">
                                <label htmlFor={this.addImgUrlInputId} className="fs-small sr-only">Image url</label>
                                <input id={this.addImgUrlInputId} type="url" className="form-control"
                                       placeholder="Paste an image URL here"
                                       required={true}

                                       onClick={(event) => {
                                           event.target.value = null
                                       }}/>
                            </div>
                            <div className="col-sm-auto mt-0_5 mt-sm-0">
                                <button type="submit" className="btn btn-primary">Preview</button>
                            </div>
                        </form>

                        {/* <!-- When error is indicated --> */}
                        {this.imageError()}

                        {/* <!-- When image is available --> */}
                        {!this.state.image.error && this.state.image.src ? <div className="attachment mt-1">
                            <div className="attachment-media">
                                <DisplayImage containerClassName="none" image={this.state.image}/>
                            </div>
                        </div> : null}
                    </div>
                    {!this.state.image.error && this.state.image.src ?
                        <div className="modal-footer">
                            <button type="button" className="btn btn-link" data-cfw-dismiss="modal"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.resetInput();
                                }}>Cancel</button>
                            {saveExternalButton}
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
    }
}

async function validUrlImage(url) {
    let pathname = url.pathname;
    let fileType = pathname.split('.').pop();
    let listImageType = ["jpg", "jpeg", "gif", "png"];
    if (!listImageType.includes(fileType.toLowerCase())) return false;

    if (url.href.startsWith('data:')) {
        return false;
    } else {
        return await loadImage(url);
    }
}

function loadImage(url) {
    return new Promise(resolve => {
        const image = new Image();
        image.addEventListener('load', () => {
            let fileSize = getImageSizeInBytes(url);
            let fileSizeMB = fileSize / 1024 / 1024;
            let resolution = image.width * image.height / 1000000;
            if (fileSizeMB > 0 && fileSizeMB < maxMB && resolution < maxResolution)
                resolve(true);
            else resolve(false);
        });
        image.addEventListener('error', () => {
            resolve(false);
        });
        image.src = url;
    })
}

function getImageSizeInBytes(imgURL) {
    try {
        const request = new XMLHttpRequest();
        request.open("HEAD", imgURL, false);
        request.send(null);
        const headerText = request.getAllResponseHeaders();
        const re = /Content-Length\s*:\s*(\d+)/i;
        re.exec(headerText);
        return parseInt(RegExp.$1);
    } catch (e) {
        return 1;
    }
}

import React from "react";
import DynamicComponent from "../DynamicComponent";
import {firebaseConfig} from '../realtimedb-utils';
import GooglePicker  from "react-google-picker";
import AppContext from "../AppContext";
import {empty} from "./textutil";
var util = require('util');

export default class ImagePicker extends DynamicComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.picked = this.picked.bind(this);
        this.img = {src: "", id: "", alt: ""}; // TODO: store and use id and alt
    }

    picked(data) {

        if ('picked' === data.action) {

            if (data.docs[0].type === ("photo")) {
                this.img = data.docs[0];

                const alt = data.docs[0].description;
                if (!empty(alt)) {
                    this.img.alt = alt;
                } else {
                    this.img.alt = "no image description :(";
                }
                this.img.id = data.docs[0].id; // needed for download
                //this.img.name = data.docs[0].name; // needed for upload
                //this.image.mimeType = data.docs[0].mimeType; // needed for upload
                // this.img.src = "https://drive.google.com/a/domain.com/thumbnail?id="+this.img.id; //+"&sz=w320-h240";
                this.img.src = "https://drive.google.com/uc?export=view&id=" + this.img.id;
            } else {
                console.warn('picker returned ' + data.docs[0].type);
            }
            console.log("picker selected " + util.inspect(this.img));
            // this.context.router.history.push(docId);

            // what if src null?
            if (this.img.id) {
                let sizeBytes = this.img.sizeBytes;
                let sizeMB = sizeBytes / 1024 / 1024;
                if(sizeMB < 50)
                    this.props.saveMethod(this.img);
                else console.log("Image too big");
            }
        }
        // return this.img.id? this.props.saveMethod(this.img): null;
    }

    render() {
        // let doc = this.context;
        let clientId = firebaseConfig ? firebaseConfig.clientId : '42';
        let apiKey = firebaseConfig ? firebaseConfig.apiKey : '42';
        // let scopes = firebaseConfig ? firebaseConfig.scopes : [];
        let scopes = ['https://www.googleapis.com/auth/drive.readonly'];
        //let oauthToken = this.context.currentUser.getAuthResponse().id_token;

        return (

            <GooglePicker id={"picker"}
                          clientId={clientId}
                          createPicker={ (google, oauthToken) => {
                              const apiKey = firebaseConfig ? firebaseConfig.apiKey : '42';

                              const mimeTypes= "image/png, image/jpeg, image/gif";

                              const docsView = new google.picker.DocsView(google.picker.ViewId.DOCS_IMAGES)
                                  .setParent('root')
                                  .setIncludeFolders(true)
                                  .setMimeTypes(mimeTypes)
                                  .setMode(google.picker.DocsViewMode.GRID)
                              ;
                              const image_for = this.props.identifier? ' for '+this.props.identifier: "";
                              const picker = new window.google.picker.PickerBuilder()
                                  .setSelectableMimeTypes(mimeTypes)
                                  .setTitle('Select an image'+image_for)
                                  .addView(docsView)
                                  .setOAuthToken(oauthToken)
                                  .setDeveloperKey(apiKey)
                                  .setCallback(this.picked)
                              ;
                              /*
                              if (doc.parentFolderId) {
                                  docsView.setParent(doc.parentFolderId);
                                  docsView.setSelectFolderEnabled(true);
                              }
                              */
                              picker.build().setVisible(true);
                          }}
                          developerKey={apiKey}
                          scope={scopes}
                          onChange={data => this.picked(data)}
                          onAuthenticate={token => this.context.checkAccessTokenBeforeInitGooglePicker(token)}
                          onAuthFailed={data => console.log('picker auth failed:', data)}
                          multiselect={false}
                          navHidden={false}
                          authImmediate={false}
            >
                {this.props.children}
            </GooglePicker>

        )
    }
}

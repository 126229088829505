import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ListComponent from './ListComponent';
import uniqueId from '../uniqueId';
import T from 'i18n-react';
import SttInput from "./SttInput";
import AppContext from "../AppContext";
import ReadOnlyField from "../ReadOnlyField";
import DeleteModal from "./DeleteModal";
import DeleteButton from "./DeleteButton";
// let util = require('util');
const assert = require('assert');

/**
   Identifier must be supplied:  characteristics1 or 2.
 */
export default class SimpleCharacteristicList extends ListComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.identifier = this.props.identifier;
        assert(['characteristics1','characteristics2'].includes(this.identifier) );
        this.identifierRef = null;

        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const editing = (undefined !== this.props.editing)? this.props.editing: true;
        const itemList = this.getContentList();
        let itemsHTML = [];
        if (itemList) {
            for (let key in itemList) {
                if (itemList.hasOwnProperty(key)){
                    itemsHTML.push(<CharItem key={key}
                                              id={key}
                                              identifier={this.identifier}
                                              doc={this.props.doc}
                                              parentNode={this.getCurrentNode()}
                                              deleteMethod={this.handleDeleteItem}
                                              canDelete={editing && this.state.canDelete}
                                              editing={editing}
                                              data={this.props.data} // CORGI-414
                    />);
                }
            }
        }
        const addButton = editing?
            <button onClick={this.handleAddItem} type="button" className="btn btn-primary">
                <span className="fa fa-plus me-0_25" aria-hidden="true"></span>
                Add Characteristic
            </button>
        : null;

        return (
            this.context.canEdit ?
                <Fragment>
                    {itemsHTML}
                    {addButton}
                </Fragment>
            :
                <Fragment>
                    {itemsHTML}
                </Fragment>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            text: "",
            status: ""
        });
    }

    getValuePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/text";
        return path;
    }

    getImagePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/text/image";
        return path;
    }
}


/**
 *  Component for single characteristic item (text + optional image)
 */
class CharItem extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {status: ""};
        this.statusRef = null;
        this.pairedStatusRef = null;
        this.pairRef = null;
        this.id = props.id;
        this.list = props.identifier;
        this.which = this.list.substr(this.list.length-1,1); // last char "1" or "2"
        this.deleteButtonId = uniqueId();
        this.deleteModalId = uniqueId();
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
        this.isPaired = this.isPaired.bind(this);
        this.getPairedId = this.getPairedId.bind(this);
        this.getPairKey = this.getPairKey.bind(this);
        this.unpair = this.unpair.bind(this);
        this.getBaseNode = this.getBaseNode.bind(this);
    }

    componentWillUnmount() {
        this.statusRef.off('value', this.statusWatcher);
    }

    componentDidMount() {
        const status = [this.getBaseNode(),this.list,this.id,"status"].join("/");
        this.statusRef = window.firebase.database().ref(status);
        this.statusWatcher = this.statusRef.on("value", data => {
            this.setState({"status": data.val()});
        });
    }

    getBaseNode() {
        return this.props.doc.type + "/" + this.props.doc.id;
    }

    isPaired() { return undefined !== this.props.data && "" !== this.state.status; }

    unpair() {
        if (this.isPaired()) {
            const pairedId = this.getPairedId();
            const pairedList = "characteristics1" === this.list
                                     ? "characteristics2"
                                     : "characteristics1";
            const pairedStatus = [this.getBaseNode(),pairedList,pairedId,"status"].join("/");
            this.pairedStatusRef = window.firebase.database().ref(pairedStatus);
            if (null !== pairedId) {
                const pairKey = this.getPairKey(this.state.status,this.list,this.id);
                this.pairRef = window.firebase.database().ref(this.getBaseNode() + "/pairs/" + this.state.status + "characteristics/" + pairKey);
                //Set status values to empty and delete pairs database entry in realtimedb
                this.statusRef.set("", () => {
                    this.pairedStatusRef.set("", () => {
                        this.pairRef.remove();
                    });
                });
            }
        }
        // TODO: also remove from PairedCharacterictics state.pairedItems?
    }

    getPairKey(status,list,id) {
        if ( this.isPaired()
         && undefined !== this.props.data.likePairedCharacteristics
         && undefined !== this.props.data.unlikePairedCharacteristics) {
            const pairedCharacteristics = ("unlike" === status)
                ? this.props.data.unlikePairedCharacteristics
                : this.props.data.likePairedCharacteristics;
            const pairKeys = Object.keys(pairedCharacteristics);

            let found = pairKeys.find(key => pairedCharacteristics[key]["characteristic"+this.which] === id);
            if (undefined !== found) {
                return found;
            }
        }
        return null;
    }

    getPairedId() { // get the key for the characteristic paired with this.props.id
        if (!this.isPaired()) {
            return null;
        } else {
            const status = this.state.status;
            const pairKey = this.getPairKey(status,this.list,this.id);
            const which = "1" === this.which? "2": "1";
            const pairs = this.props.data[status+"PairedCharacteristics"];
            const pair = (undefined !== pairs && null !== pairKey)? pairs[pairKey]: null;
            const other = null !== pair? pair["characteristic"+which]: null;

            return other;
        }
    }
    render () {
        const characteristic = this.list + "/" + this.id+"/text";

        // CORGI-414 check if paired on delete
        const deletePairWarning = "The characteristic has been paired. Deleting it will undo the pair. Do you want to delete this characteristic?";
        const deleteMessage = this.isPaired()? deletePairWarning: T.translate(this.props.doc.type + "." + this.list + ".delete");
        const deleteModal = <DeleteModal id={this.deleteModalId} method={this.handleDeleteButton} text={deleteMessage} yes="Remove Item"/>
        const deleteIcon = <DeleteButton icon={"fa-times"} tooltip={"Remove item"} modalId={this.deleteModalId}
                             canDelete={this.props.canDelete}
        />
        return (
            this.props.editing && this.context.canEdit ?
                <div className="box bg-primary">
                    { this.props.canDelete ?
                        <div className="box-row-close">
                            {deleteModal}
                            {deleteIcon}
                        </div>
                    :
                        null
                    }
                    <div className="form-group">
                        <SttInput addImage={true}
                            placeholder={T.translate(this.props.doc.type + "." + this.list + ".placeholder")}
                            identifier={characteristic} />
                    </div>
                </div>
            :
                <div className={"box bg-autofill"} >
                    <div className={"row"}>
                        <ReadOnlyField identifier={characteristic} className={"grouping-text"} />
                        <ReadOnlyField identifier={characteristic+"/image"} className={"grouping-img"} />
                    </div>
                </div>
        );
    }

    handleDeleteButton() {
        this.unpair();    // CORGI-414 unpair before delete
        this.props.deleteMethod(this.id);
    }

}

CharItem.propTypes = {
    id: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired, // characteristics[12]
    doc: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired, // characteristicsData
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired,
    parentNode: PropTypes.string.isRequired
};

// FIXME: temporarily remove Info, per AMK CORGI-276
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GetText from './GetText';
import uniqueId from '../uniqueId';
import $ from 'jquery';
import AppContext from "../AppContext";

export default class InfoButton extends Component {
    static contextType = AppContext;

    componentWillMount() {
        this.buttonId = uniqueId();
        this.popoverId = uniqueId();
    }

    render() {
        const docType = this.props.docType? this.props.docType: this.context.fileType;
        let identifier = this.props.identifier.toLowerCase();
        const example = true? null: <a href={"/example/"+docType+".pdf"}><GetText type={docType} item={"example"} /></a>;

        const characteristics = /^characteristics/i;
        const concepts = /^concepts/i;
        identifier = identifier.replace(characteristics,'characteristics1');
        identifier = identifier.replace(concepts,'concept1');

        return (
            <span  className="print-none">

                <button type="button" className="btn btn-info btn-xsmall btn-light" title="More Information"
                        data-cfw="popover" data-cfw-popover-target={"#"+this.popoverId}  data-cfw-popover-placement="auto top">
                    <span className="fa fa-info text-primary" aria-hidden="true"></span>
                    <span className="sr-only">More Information</span>
                </button>
                <div className="popover info-popover" id={this.popoverId}>
                    <button type="button" className="close" title="Close" data-cfw-dismiss="popover">
                        <span aria-hidden="true">x</span></button>
                    <h3 className="popover-header"><GetText type={docType} identifier={identifier} item={'singleLabel'} /></h3>
                    <div className="popover-body">
                        {example}
                        <GetText tag='p' type={docType} identifier={identifier} item={"help"} />
                    </div>
                    <div className="popover-arrow"/>
                </div>
            </span>
        )
        ;
    }

    componentDidMount() {
        if ($(document).CFW_Popover) {
            $('#' + this.buttonId).CFW_Popover({
                placement: "bottom auto", title: "More Information",
                container: "body",
                toggle: "#" + this.popoverId
            });

            $(`#${this.buttonId}`).on('afterShow.cfw.popover', () => {
                // window.tracker.trackEvent(this.props.docType, "Info Box", `${this.props.identifier}`);
                window.tracker.trackEvent("Support", "info button", `${this.props.identifier}`);
            });
        }
    }

}

InfoButton.propTypes = {
    identifier: PropTypes.string.isRequired
};

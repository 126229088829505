import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from "./Card";
import AppContext from "../AppContext";
import PageLink from "./PageLink";

/**
 * Displays a choice of the different CER types that are supported by the application.
 * When the user selects one, a new document of that type is created.
 */
export default class NewDocument extends React.Component {
    static contextType = AppContext;

    constructor() {
        super();

        this.createUO = this.createUO.bind(this);
        this.createCET = this.createCET.bind(this);
        this.createQEG = this.createQEG.bind(this);
        this.createCT = this.createCT.bind(this);
        this.createCERA = this.createCERA.bind(this);
    }

    render() {
        console.log(this.context);
        return (
            <Fragment>
                <div id='new' className={"section"}>
                    <h2 className="pt-1 mb-1">Create a New Corgi Guide</h2>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                        <Card docType={"cause-effect"} creator={this.createCET} />
                        <Card docType={"question-exp"} creator={this.createQEG} />
                        <Card docType={"comparison"} creator={this.createCT} />
                        <Card docType={"cera"} creator={this.createCERA} />
                    </div>
                </div>

                <div id="modalPrivacy" className="modal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Authorizing Corgi with Google</h3>
                            </div>
                            <div className="modal-body">
                                <div id="player" className="embed-fluid embed-fluid-16x9 mt-0_25">
                                    <iframe id="ytPlayer" src={'https://www.youtube-nocookie.com/embed/9pw40R1l50g?enablejsapi=1'} frameBorder="0"
                                            title="Youtube Video" aria-label={'YouTube Video: Authorizing Corgi to work with your Google account'}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>
                                </div>
                                <p>Visit <PageLink page="support">How to Use Corgi</PageLink> for more help getting started.</p>
                            </div>
                            <div className="modal-footer">
                                <div className="text-end">
                                    <button type="button" className="btn btn-success" data-cfw-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        );

    }

    createCET(event) {
        return this.props.newDocButtonMethod("cause-effect");
    }

    createQEG() {
        return this.props.newDocButtonMethod("question-exp");
    }

    createCT() {
        return this.props.newDocButtonMethod("comparison");
    }

    createUO() {
        return this.props.newDocButtonMethod("unit-org-2");
    }

    createCERA() {
        return this.props.newDocButtonMethod("cera");
    }
}

NewDocument.propTypes = {
    newDocButtonMethod: PropTypes.func.isRequired
};

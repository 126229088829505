import React, {Component} from 'react';
import PropTypes from 'prop-types';
import uniqueId from '../uniqueId';
import $ from "jquery";

export default class RemoveImageButton extends Component {

    constructor(props) {
        super(props);
        this.buttonId = uniqueId();
        this.state = { value: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        this.buttonId = uniqueId();
        this.modalId = uniqueId();
    }

    handleChange(event) {
        this.setState({ value: event.target.src});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ value: event.target.src});
        this.props.removeMethod();
        $('#' + this.buttonId).CFW_Modal('hide');
    }

    render() {

        return (
            <div className="attachment-action" >
                <button id={this.buttonId} type ={"button"} className="btn btn-icon btn-link"
                        title={"Remove media"} onClick={this.props.removeMethod} >
                    <span className="fa fa-trash-alt" aria-hidden="true"></span>
                    <span className="sr-only">Remove media</span>
                </button>
            </div>        );
    }

}

RemoveImageButton.propTypes = {
    removeMethod: PropTypes.func.isRequired,
};

import DynamicComponent from '../DynamicComponent';

import initUI from "../ui";

export default class OrganizerComponent extends DynamicComponent {

    constructor (props) {
        super(props);
        this.cfwInit = this.cfwInit.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.cfwInit();
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        this.cfwInit();
    }

    cfwInit() {
        initUI();
    }

}
import React, {Component, Fragment} from 'react';
import AppContext from "../AppContext";
import $ from "jquery";
import {isEmbedYoutube} from "./OptionalImage";
import Cookies from 'universal-cookie';
import {findPlaceholderByContent, getCreateVideoRequests} from "../utils/SlideHelpers";

import imgGoogleSlides from 'url:/public/img/google-slides-logo-square.svg';
import imgCorgiTongue from 'url:/public/img/corgi-tongue.gif';

const cookies = new Cookies();
class SlidesButton extends Component {
	static contextType = AppContext;

	constructor(props) {
		super(props);
		this.state = {building: false};
		this.doc = this.props.doc;
		this.identifier = "slide";
	}

	getMapsPromise(docType){
		if("comparison" === docType) return this.getCharMapsPromise();
		if("cause-effect" === docType) return this.getCauseEffectMapsPromise();
		if("question-exp" === docType) return this.getQuestionExpMapsPromise();
		if("cera" === docType) return this.getCERGuideMapsPromise();
	}

	processCERGuideMapsPromise(valueMaps){
		var promises = [];
		promises.push(this.getCommandsForTextItem('topic', 'topic'));
		promises.push(this.getCommandsForTextItem('claim', 'claim'));

		for (let f of ['essentialquestion','claimcontext','reasoningnote']) {
			promises.push(this.getCommandsForTextItem(f, f));
			promises.push(this.getCommandsForImage(f));
		}

		promises.push(this.getCommandsForTerms(valueMaps));
		promises.push(this.getCommandsSupportEvidence(valueMaps,this.config.supportsEvidencePageId));
		promises.push(this.getCommandsSupportEvidenceQuality(valueMaps,this.config.supportsEvidenceQualityPageId));
		promises.push(this.getCommandsOpposesEvidence(valueMaps,this.config.opposesEvidencePageId));
		promises.push(this.getCommandsOpposesEvidenceQuality(valueMaps,this.config.opposesEvidenceQualityPageId));
		promises.push(this.getCommandsForQualityType('reasoningtype', 'reasoningtype'));
		return promises;
	}

	processComparisonMapsPromise(valueMaps){
		console.log(this.config);
		var promises = [];
		for (let f of ['concept1', 'concept2', 'description1', 'description2', 'overall', 'summary','essentialquestion']) {
			promises.push(this.getCommandsForTextItem(f, f));
			promises.push(this.getCommandsForImage(f));
		}
		promises.push(this.getCommandsForTerms(valueMaps));
		promises.push(this.getCommandsForCharacteristics(valueMaps));
		promises.push(this.getCommandsForDeletableTextItem('extensions', 'extensions', this.config.extensionsPageId));
		promises.push(this.getCommandsForImage('extensions'));
		promises.push(this.getCommandsForPairs('like', this.config.likePairsPageId, valueMaps));
		promises.push(this.getCommandsForPairs('unlike', this.config.unlikePairsPageId, valueMaps));

		return promises;
	}

	processQuestionExpMapsPromise(valueMaps){
		var promises = [];
		promises.push(this.getCommandsForTextItem('topic', 'topic'));
		for (let f of ['essentialquestion','essentialquestionresponse','contextresponse','extensions']) {
			promises.push(this.getCommandsForTextItem(f, f));
			promises.push(this.getCommandsForImage(f));
		}

		promises.push(this.getCommandsForTerms(valueMaps));
		promises.push(this.getCommandsRelatedQuestion(valueMaps,this.config.relatedQuestionPageId));
		return promises;
	}

	processCauseEffectMapsPromise(valueMaps){
		var promises = [];
		promises.push(this.getCommandsForTextItem('topic', 'topic'));
		for (let f of ['question', 'event','answer','extensions']) {
			promises.push(this.getCommandsForTextItem(f, f));
			promises.push(this.getCommandsForImage(f));
		}

		promises.push(this.getCommandsForTerms(valueMaps));
		promises.push(this.getCommandsForCauses(valueMaps,this.config.causePageId));
		promises.push(this.getCommandsForArrow('arrow_cause', 'causes-event/connector'));
		promises.push(this.getCommandsForArrow('effect_arrow', 'event-effects/connector'));
		promises.push(this.getCommandsForEffect(valueMaps,this.config.effectPageId));

		return promises;
	}

	checkScope(){
		let scope = "https://www.googleapis.com/auth/drive.readonly";
		const grantedScopes = cookies.get("corgi_granted_scopes");
		if(grantedScopes.includes(scope)){
			this.doExportSlide();
		}else{
			$('#modalWaiting').CFW_Modal('hide');

			this.context.addGoogleScope(scope, (succeed) => {
				if (succeed) {
					console.log("Add more scope success");
                    this.doExportSlide();
				} else {
					console.log("Add more scope fail");
				}
			});
		}
	}

	doExportSlide(){
		var docType = this.context.fileType;

		window.tracker.trackEvent("Guide", "create presentation", "");

		if("comparison" === docType) this.config = window.slideExportConfig.comparison;
		if("cause-effect" === docType) this.config = window.slideExportConfig.causeEffect;
		if("question-exp" === docType) this.config = window.slideExportConfig.questionExp;
		if("cera" === docType) this.config = window.slideExportConfig.cerGuide;

		if (this.state.building) {
			console.log('Already building, ignored.');
		}
		this.setState({building: true});

		let valueMaps = null;
		let presentationCopyId = null;
		let webViewLink = null;
		let slideId = null;
		let request = {
			name: this.context.fileName,
			parents: ['root']
		};

		let textCommands = [];
		let imageCommands = [];
		let videoCommands = [];

		window.gapi.client.drive.files.copy({
			fileId: this.config.templateId,
			resource: request,
			fields: 'id,webViewLink',
		})
		.then(
			(driveResponse) => {
				presentationCopyId = driveResponse.result.id;
				webViewLink = driveResponse.result.webViewLink;
				slideId = driveResponse.result.id;
				return window.gapi.client.load('slides', 'v1');
			}, (e) => {
				console.error("Error copying slideshow template:", e);
				alert("Error copying slide export template");
				this.setState({building: false});
			}
		)
		.then(
			(r) => {
				return this.getMapsPromise(docType);
			}, (e) => {
				console.error("Error loading presenation: API", e);
				alert("Error loading presentation API");
				this.setState({building: false});
			}
		)
		.then(
			(r) => {
				valueMaps = r;
				var promises = [];
				if("cera" === docType) promises = this.processCERGuideMapsPromise(valueMaps);
				if("comparison" === docType) promises = this.processComparisonMapsPromise(valueMaps);
				if("cause-effect" === docType) promises = this.processCauseEffectMapsPromise(valueMaps);
				if("question-exp" === docType) promises = this.processQuestionExpMapsPromise(valueMaps);
				return Promise.all(promises);
			},
			(e) => {
				console.error("Error building characteristic maps:", e);
				alert("Error while calculating Slides content");
				this.setState({building: false});
				return false;
			}
		)
		.then(
			async (values) => {
				console.log(values);

				let requests = values.reduce((accum, val) => {
                    return accum.concat(val);
                }, []);

				requests.push(this.makeReplaceTextCommand('currentUserName', this.context.currentUser.displayName));

				console.debug('Slides update commands: ', requests);
				for (let req of requests) {
					if (req.replaceAllShapesWithImage !== null && req.replaceAllShapesWithImage !== undefined) {
						const isValidImage = await this.checkValidImage(req.replaceAllShapesWithImage.imageUrl);
						if (isValidImage) {
							imageCommands.push(req);
						} else {
							textCommands.push(this.makeReplaceTextCommand(
								req.replaceAllShapesWithImage.containsText.text.replaceAll("{{", "").replaceAll("}}", ""),
								"Image " + req.replaceAllShapesWithImage.imageUrl + " not found"));
						}
				    } else if (req.createVideoCorgi !== null && req.createVideoCorgi !== undefined) {
				        const isValidVideo = await this.checkValidVideo(req.createVideoCorgi.videoId);
				        if (isValidVideo) {
				            videoCommands.push(req);
				        } else {
				            textCommands.push(this.makeReplaceTextCommand(
								req.videoCommands.objectId.replaceAll("{{", "").replaceAll("}}", ""),
								"Video " + transformEmbeddedYouTubeURLIntoWatchURL(req.createVideoCorgi.videoId) + " not found"));
				        }
					} else {
					    textCommands.push(req);
					}
				}
				return window.gapi.client.slides.presentations.batchUpdate({
					presentationId: presentationCopyId,
					requests: textCommands,
				});
			},
			(e) => {
				console.error("Error while building batch update list:", e);
				alert("Error while determining Slides content");
				this.setState({building: false});
				return false;
			}
		)
		.then(
			() =>{
				if(imageCommands.length > 0)
					return window.gapi.client.slides.presentations.batchUpdate({
						presentationId: presentationCopyId,
						requests: imageCommands,
					});
			},
			(e) => {
				console.error("BatchUpdate text of presentation failed:", e);
				alert("Error while filling text in Slides content");
				this.setState({building: false});
				return false;
			}
		)
		.then(
		    async () =>{
				if (videoCommands.length > 0) {
				    let currPresentation = await window.gapi.client.slides.presentations.get({presentationId: presentationCopyId});
				    let presentation = currPresentation.result;

				    for (let i=0; i < videoCommands.length; i++) {
				        let placeholderElm = null;
				        let requestsVideo = [];

				        // Find placeholder element
				        let placeholder = videoCommands[i].createVideoCorgi.placeholder;
				        let pageId = videoCommands[i].createVideoCorgi.page;
				        let placeholderItems = findPlaceholderByContent(presentation, pageId, placeholder);

				        // Should only be one result - pick first one if multiple
				        if (placeholderItems.length > 0) {
				            placeholderElm = placeholderItems[0];
				        }
                        if (placeholderElm !== null) {
                            requestsVideo = getCreateVideoRequests(videoCommands[i].createVideoCorgi.videoId, placeholderElm.slideId, placeholderElm.pageElement);
                            if (requestsVideo.length > 0) {
                                requestsVideo.push(this.makeDeleteObjectCommand(placeholderElm.pageElement.objectId));
                            }
                        }
                        if (requestsVideo.length > 0) {
                            return window.gapi.client.slides.presentations.batchUpdate({
						        presentationId: presentationCopyId,
						        requests: requestsVideo,
					        });
                        }
				    }
				}
			},
		    (e) => {
				console.error("Filling images error", e);
				alert("There was a problem adding images to your slide presentation. Your images were unable to be inserted.");
				window.tracker.trackEvent("Error", "create presentation", "image insertion failed");
				this.setState({building: false});
				return false;
			}
		)
		.then(
			() => {
				// Redirect
				console.debug('replacement done, redirecting to ' + webViewLink);
				this.getRef(this.identifier).push().set({
					id: slideId,
					url:webViewLink
				})
				window.location = webViewLink;
				return true;
			},
			(e) => {
                //console.log(e.message);
                //console.log(e.stack);
				console.error("Filling videos error -> do nothing", e);
				this.getRef(this.identifier).push().set({
					id: slideId,
					url:webViewLink
				})
				alert("There was a problem adding videos to your slide presentation. Your videos were unable to be inserted.");
				window.tracker.trackEvent("Error", "create presentation", "video insertion failed");
			    window.location = webViewLink;
				return true;
			}
		);
	}

	checkValidImage(imageURL) {
		return new  Promise(resolve => {
			const image = new Image();
			image.src =  imageURL;

			image.addEventListener('load', () => {
				resolve(true);
			});
			image.addEventListener('error', () => {
				resolve(false);
			});
		})
	}

	async checkValidVideo(videoId) {
	    const imageUrl = 'https://i.ytimg.com/vi/' + videoId + '/default.jpg';
	    const isValid = await this.checkValidImage(imageUrl);
	    return isValid;
	}

	render(){
		return(
			<Fragment>
				<button type="button" onClick={(evt) => this.checkScope()}
						className="btn btn-large btn-create"
						data-cfw="modal" data-cfw-modal-target="#modalWaiting" data-cfw-modal-backdrop="static">
                    <div className="btn-create-img">
                        <img src={imgGoogleSlides} alt=""/>
                    </div>
                    <div className="sr-only">
						Create a Slide Presentation
                    </div>
				</button>
				<div id="modalWaiting" className="modal confirm-modal">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body">
								<div className="row flex-items-center gx-0_5">
									<div className="col-3">
										<img className="img-fluid" src={imgCorgiTongue}
											 alt="Panting corgi..." />
									</div>
									<div className="col-9 text-center">
										<h2 className="modal-title h3">We're creating your presentation!</h2>
										<p>
											You are now leaving Corgi.
											<br/>
											Your presentation will open in Google Slides.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}

	//
	// Simple Data Access Methods
	//

	getRef(path) {
		let fullPath = this.context.fileType + "/" + this.context.fileId + "/" + path;
		return window.firebase.database().ref(fullPath);
	}

	// A Promise that will resolve to a DataSnapshot for the given database location.
	getValuePromise(path) {
		return this.getRef(path).once("value");
	}

	// Return a promise for the value of a Firepad text field
	getFirepadTextPromise(path) {
		return new Promise((resolve, reject) => {
		let firepadHeadless = new window.Firepad.Headless(this.getRef(path));
		firepadHeadless.getText(text => {
			firepadHeadless.dispose();
			resolve(text);
		});
		});
	}

	// Given a PATH that points to a list of data, with string values at the KEY within each
	// list item, return a Promise that will resolve to a map of keys to the string values.
	getTextMapPromise(path, key) {
		return this.getRef(path).once("value").then(list => {
		let map = new Map();
		list.forEach((item) => {
			let value = item.child(key).val();
			map.set(item.key, value);
		});
		return (map);
		});
	}

	// Given a PATH that points to a list of data, with Firepad values at the KEY within each
	// list item, return a Promise that will resolve to a map of keys to the editor contents.
	getFirepadMapPromise(path, key) {
		return new Promise((resolve, reject) => {
		let ref = this.getRef(path);
		ref.once("value").then(list => {
			let keys = [];
			let promises = [];
			list.forEach((item) => {
			keys.push(item.key);
			promises.push(this.getFirepadTextPromise(path + '/' + item.key + '/' + key));
			});
			Promise.all(promises).then((values) => {
			let map = new Map();
			for (let i=0; i<keys.length; i++) {
				map.set(keys[i], values[i]);
			}
			resolve(map);
			});
		});
		});
	}

	// A Promise that will return the "characteristic" and "categories" maps.
	getCharMapsPromise() {
		let proms = [
			this.getFirepadMapPromise('characteristics1', 'text'),
			this.getFirepadMapPromise('characteristics2', 'text'),
			this.getFirepadMapPromise('characteristics1', 'category'),

			this.getTextMapPromise('characteristics1', 'text/image'),
			this.getTextMapPromise('characteristics2', 'text/image'),
			this.getTextMapPromise('characteristics1', 'category/image'),
			this.getFirepadMapPromise('terms', 'def'),
			this.getFirepadMapPromise('terms', 'term')
		];
		return Promise.all(proms)
			.then((maps) => {
			return {
				'characteristics1' : maps[0],
				'characteristics2' : maps[1],
				'categories' : maps[2],

				'char1images' : maps[3],
				'char2images' : maps[4],
				'categoryimages' : maps[5],
				'termsdef' : maps[6],
				'termsword' : maps[7]
			};
			});
	}


	//
	// Methods that build single commands for BatchUpdate
	//

	makeReplaceTextCommand(from, to, page=null) {
		let request = {
		replaceAllText: {
			containsText: {
			text: '{{' + from + '}}',
			matchCase: true
			},
			replaceText: to,
		}
		};
		if (page) {
		request['replaceAllText']['pageObjectIds'] = [page];
		}
		return request;
	}

	makeReplaceMediaCommand(id, url, page = null) {
		let placeholder = id + '/image';
		let isImage = false;
		let urlMatch = url ? url.toLowerCase() : url;
		if(url && (urlMatch.includes(".jpg") || urlMatch.includes(".jpeg") || urlMatch.includes(".gif") || urlMatch.includes(".png"))) isImage = true;
		if (url && !url.startsWith('data:') && isImage) {
			let request = {
				replaceAllShapesWithImage: {
					containsText: {
						text: '{{' + placeholder + '}}',
						matchCase: true
					},
					"imageUrl": url,
				}
			};
			if (page) {
				request['replaceAllShapesWithImage']['pageObjectIds'] = [page];
			}
			return request;
		} else if(isEmbedYoutube(url)) {
		    // Create a custom create video request - similar, but different to Google SLides API's createVideoRequest
		    // https://developers.google.com/slides/api/reference/rest/v1/presentations/request#createvideorequest
		    let request = {
		        createVideoCorgi: {
		            "placeholder":'{{' + placeholder + '}}',
                    "page": page ? page : null,
                    "source": "YOUTUBE",
                    "videoId": transformEmbeddedYouTubeURLIntoId(url)
		        }
		    };
            return request;
		} else {
			// No media, instead remove the placeholder.
			return this.makeReplaceTextCommand(placeholder, '', page);
		}
	}

	makeDeleteObjectCommand(objectId) {
		return {
		deleteObject: {
			objectId: objectId
		}
		};
	}

	makeDuplicatePageCommand(oldid, newid) {
		let id_replacement = {};
		id_replacement[oldid] = newid;
		return {
		duplicateObject: {
			objectId: oldid,
			objectIds: id_replacement,
		}
		};
	}

	//
	// Methods that return lists commands for BatchUpdate
	//

	// Replace text placeholder with Firepad text
	getCommandsForTextItem(from, path) {
		return this.getFirepadTextPromise(path).then((text) => {
		return([ this.makeReplaceTextCommand(from, text) ]);
		});
	}

	// Replace text placeholder with Firepad text, or delete the given object if there's no text
	getCommandsForDeletableTextItem(from, path, pageId) {
		return this.getFirepadTextPromise(path).then((text) => {
		if (text) {
			return ([this.makeReplaceTextCommand(from, text)]);
		} else {
			return ([this.makeDeleteObjectCommand(pageId)]);
		}
		});
	}

	// Replace image placeholder with image
	getCommandsForImage(path, page=null) {
		return this.getValuePromise(path + '/image').then((data) => {
		return ([this.makeReplaceMediaCommand(path, data.val(), page)]);
		});
	}

	// Returns all the commands to build the like and unlike pairs slides
	getCommandsForPairs(which, main_page_id, charMaps) {
		return this.getValuePromise('pairs/' + which + 'characteristics').then((data) => {
		let allRequests = [];
		let pairCount = data.numChildren();
		if (pairCount === 0) {
			allRequests.push(this.makeDeleteObjectCommand(main_page_id));
		} else {
			let i=0, page_id = '';
			data.forEach((pairData) => {
				let pair = pairData.val();
				let pageRequests = [];
				if (i === 0) {
					page_id = main_page_id;
				} else {
					// Make a new page for each pair after the first
					page_id = data.key + "_" + i;
					pageRequests.push(this.makeDuplicatePageCommand(main_page_id, page_id))
				}
				// Header info
				pageRequests.push(this.makeReplaceTextCommand('pairPage', String(i + 1), page_id));
				pageRequests.push(this.makeReplaceTextCommand('totalPairPages', String(pairCount), page_id));

				// Text info
				pageRequests.push(this.makeReplaceTextCommand('characteristic1',
					charMaps['characteristics1'].get(pair.characteristic1), page_id));
				pageRequests.push(this.makeReplaceTextCommand('characteristic2',
					charMaps['characteristics2'].get(pair.characteristic2), page_id));
				pageRequests.push(this.makeReplaceTextCommand('category',
					charMaps['categories'].get(pair.characteristic1), page_id));

				// Images
				pageRequests.push(this.makeReplaceMediaCommand('characteristic1',
					charMaps['char1images'].get(pair.characteristic1), page_id));
				pageRequests.push(this.makeReplaceMediaCommand('characteristic2',
					charMaps['char2images'].get(pair.characteristic2), page_id));
				pageRequests.push(this.makeReplaceMediaCommand('category',
					charMaps['categoryimages'].get(pair.characteristic1), page_id));

				// Add the requests for this page to the _beginning_ of the list of requests.
				// Since each duplicate page is inserted directly after the original, we have to create
				// them starting with the last and working backward.
				allRequests = pageRequests.concat(allRequests);
				i++;
			});
		}
		return allRequests;
		});
	}

	// Get Cause Effect Maps proimse
	getCauseEffectMapsPromise() {
		let proms = [
				this.getFirepadMapPromise('terms', 'def'),
				this.getFirepadMapPromise('terms', 'term'),
				this.getFirepadMapPromise('cause', 'text'),
				this.getFirepadMapPromise('effect', 'text'),

				this.getTextMapPromise('cause', 'text/image'),
				this.getTextMapPromise('effect', 'text/image')
			];
			return Promise.all(proms)
				.then((maps) => {
				console.log(maps);
				return {
					'termsdef' : maps[0],
					'termsword' : maps[1],
					'cause' : maps[2],
					'effect' : maps[3],

					'causeimages' : maps[4],
					'effectimages' : maps[5]
				};
			});
	}

	getCommandsForCharacteristics(valueMaps){
		let pageRequests = [];

		if(valueMaps['characteristics1'] != null){
			let listTerm = [];
			valueMaps['characteristics1'].forEach((value)=>{
				listTerm.push(value);
			})

			pageRequests.push(this.makeReplaceTextCommand('characteristics1',listTerm.join('\n')));
		}

		if(valueMaps['characteristics2'] != null){
			let listTerm = [];
			valueMaps['characteristics2'].forEach((value)=>{
				listTerm.push(value);
			})

			pageRequests.push(this.makeReplaceTextCommand('characteristics2',listTerm.join('\n')));
		}

		return pageRequests;
	}

	getCommandsForTerms(valueMaps){
		let pageRequests = [];

		if(valueMaps['termsword'] != null){
			let listTerm = [];
			valueMaps['termsword'].forEach((value,key)=>{
				let termDef = "";
				let term = "";
				if(valueMaps['termsdef'] != null){
					termDef = valueMaps['termsdef'].get(key);
				}

				if(termDef !== "") term = value + " : " + termDef;
				listTerm.push(term);
			})

			let strval = listTerm.join('\n');
			pageRequests.push(this.makeReplaceTextCommand('term',strval));
		}

		return pageRequests;
	}

	getCommandsForCauses(valueMaps, mainPageId){
		let allRequests = [];
		if(valueMaps['cause'] != null){
			let index = 0;
			let pageId = "";
			valueMaps['cause'].forEach((value,key)=>{
				let causeImg = "";
				if(valueMaps['causeimages'] != null){
					causeImg = valueMaps['causeimages'].get(key);
				}
				let pageRequests = [];
				if (index === 0) {
					pageId = mainPageId;
				} else {
					pageId = mainPageId + "_" + index;
					pageRequests.push(this.makeDuplicatePageCommand(mainPageId, pageId));
				}

				pageRequests.push(this.makeReplaceTextCommand('causeIndex', String(index + 1), pageId));
				pageRequests.push(this.makeReplaceTextCommand('cause',value, pageId));
				pageRequests.push(this.makeReplaceMediaCommand('cause',causeImg, pageId));

				allRequests = pageRequests.concat(allRequests);
				index++;
			})
		}
		return allRequests;
	}

	getCommandsForEffect(valueMaps, mainPageId){
		let allRequests = [];
		if(valueMaps['effect'] != null){
			let index = 0;
			let pageId = "";
			valueMaps['effect'].forEach((value,key)=>{
				let effectImg = "";
				if(valueMaps['effectimages'] != null){
					effectImg = valueMaps['effectimages'].get(key);
				}
				let pageRequests = [];
				if (index === 0) {
					pageId = mainPageId;
				} else {
					pageId = mainPageId + "_" + index;
					pageRequests.push(this.makeDuplicatePageCommand(mainPageId, pageId));
				}

				pageRequests.push(this.makeReplaceTextCommand('effectIndex', String(index + 1), pageId));
				pageRequests.push(this.makeReplaceTextCommand('effect',value, pageId));
				pageRequests.push(this.makeReplaceMediaCommand('effect',effectImg, pageId));

				allRequests = pageRequests.concat(allRequests);
				index++;
			})
		}
		return allRequests;
	}

	getCommandsForArrow(from,path) {
		return this.getValuePromise(path).then((data) => {
		return ([this.makeReplaceTextCommand(from, data.val())]);
		});
	}

	// Question Exp

	getQuestionExpMapsPromise() {
		let proms = [
				this.getFirepadMapPromise('terms', 'def'),
				this.getFirepadMapPromise('terms', 'term'),
				this.getFirepadMapPromise('relatedquestion', 'question'),
				this.getFirepadMapPromise('relatedquestion', 'answer'),

				this.getTextMapPromise('relatedquestion', 'question/image'),
				this.getTextMapPromise('relatedquestion', 'answer/image')
			];
			return Promise.all(proms)
				.then((maps) => {
				console.log(maps);
				return {
					'termsdef' : maps[0],
					'termsword' : maps[1],
					'question' : maps[2],
					'answer' : maps[3],

					'questionimages' : maps[4],
					'answerimages' : maps[5]
				};
			});
	}

	getCommandsRelatedQuestion(valueMaps, mainPageId){
		let allRequests = [];
		if(valueMaps['question'] != null){
			let index = 0;
			let pageId = "";
			valueMaps['question'].forEach((value,key)=>{
				let questionImg = "";
				if(valueMaps['questionimages'] != null){
					questionImg = valueMaps['questionimages'].get(key);
				}
				let pageRequests = [];
				if (index === 0) {
					pageId = mainPageId;
				} else {
					pageId = mainPageId + "_" + index;
					pageRequests.push(this.makeDuplicatePageCommand(mainPageId, pageId));
				}

				pageRequests.push(this.makeReplaceTextCommand('question',value, pageId));
				pageRequests.push(this.makeReplaceMediaCommand('question',questionImg, pageId));

				if(valueMaps['answer'] != null){
					let mAnswer = "";
					let mAnswerImage = "";

					if(valueMaps['answer'].get(key) != null){
						mAnswer = valueMaps['answer'].get(key);
					}
					if(valueMaps['answerimages'] != null){
						mAnswerImage = valueMaps['answerimages'].get(key);
					}
					pageRequests.push(this.makeReplaceTextCommand('answer',mAnswer, pageId));
					pageRequests.push(this.makeReplaceMediaCommand('answer',mAnswerImage, pageId));
				}

				allRequests = pageRequests.concat(allRequests);
				index++;
			})
		}
		return allRequests;
	}

	// Get CERGuide Maps proimse
	getCERGuideMapsPromise() {
		let proms = [
				this.getFirepadMapPromise('terms', 'def'),
				this.getFirepadMapPromise('terms', 'term'),
				this.getFirepadMapPromise('evidencesupport', 'evidence'),
				this.getFirepadMapPromise('evidencesupport', 'citation'),
				this.getTextMapPromise('evidencesupport', 'qualitytype'),
				this.getFirepadMapPromise('evidencesupport', 'qualitynote'),
				this.getFirepadMapPromise('evidenceopposes', 'evidence'),
				this.getFirepadMapPromise('evidenceopposes', 'citation'),
				this.getTextMapPromise('evidenceopposes', 'qualitytype'),
				this.getFirepadMapPromise('evidenceopposes', 'qualitynote'),

				this.getTextMapPromise('evidencesupport', 'evidence/image'),
				this.getTextMapPromise('evidenceopposes', 'evidence/image')
			];
			return Promise.all(proms)
				.then((maps) => {
				console.log(maps);
				return {
					'termsdef' : maps[0],
					'termsword' : maps[1],
					'supportevidence' : maps[2],
					'supportcitation' : maps[3],
					'supportqualitytype' : maps[4],
					'supportqualitynote' : maps[5],
					'opposesevidence' : maps[6],
					'opposescitation' : maps[7],
					'opposesqualitytype' : maps[8],
					'opposesqualitynote' : maps[9],

					'supportevidenceimages' : maps[10],
					'opposesevidenceimages' : maps[11]
				};
			});
	}

	getCommandsSupportEvidence(valueMaps, mainPageId){
		let allRequests = [];
		if(valueMaps['supportevidence'] != null){
			let index = 0;
			let pageId = "";
			valueMaps['supportevidence'].forEach((value,key)=>{
				let evidenceImg = "";
				if(valueMaps['supportevidenceimages'] != null){
					evidenceImg = valueMaps['supportevidenceimages'].get(key);
				}
				let evidenceCiation = "";
				if(valueMaps['supportcitation'] != null){
					evidenceCiation = valueMaps['supportcitation'].get(key);
				}

				let pageRequests = [];
				if (index === 0) {
					pageId = mainPageId;
				} else {
					pageId = mainPageId + "_" + index;
					pageRequests.push(this.makeDuplicatePageCommand(mainPageId, pageId));
				}

				pageRequests.push(this.makeReplaceTextCommand('supportIndex', String(index + 1), pageId));
				pageRequests.push(this.makeReplaceTextCommand('supportevidence',value, pageId));
				pageRequests.push(this.makeReplaceTextCommand('supportcitation',evidenceCiation, pageId));
				pageRequests.push(this.makeReplaceMediaCommand('supportevidence',evidenceImg, pageId));

				allRequests = pageRequests.concat(allRequests);
				index++;
			})
		}
		return allRequests;
	}

	getCommandsSupportEvidenceQuality(valueMaps, mainPageId){
		let allRequests = [];
		if(valueMaps['supportqualitynote'] != null){
			let index = 0;
			let pageId = "";
			valueMaps['supportqualitynote'].forEach((value,key)=>{
				let qualityType = "";
				if(valueMaps['supportqualitytype'] != null){
					qualityType = valueMaps['supportqualitytype'].get(key);
				}

				let evidenceCiation = "";
				if(valueMaps['supportcitation'] != null){
					evidenceCiation = valueMaps['supportcitation'].get(key);
				}

				let supportEvidence = "";
				if(valueMaps['supportevidence'] != null){
					supportEvidence = valueMaps['supportevidence'].get(key);
				}

				let pageRequests = [];
				if (index === 0) {
					pageId = mainPageId;
				} else {
					pageId = mainPageId + "_" + index;
					pageRequests.push(this.makeDuplicatePageCommand(mainPageId, pageId));
				}

				pageRequests.push(this.makeReplaceTextCommand('supportQualityIndex', String(index + 1), pageId));
				pageRequests.push(this.makeReplaceTextCommand('supportevidence',supportEvidence, pageId));
				pageRequests.push(this.makeReplaceTextCommand('supportcitation',evidenceCiation, pageId));
				pageRequests.push(this.makeReplaceTextCommand('qualitytype',qualityType, pageId));
				pageRequests.push(this.makeReplaceTextCommand('qualitynote',value, pageId));

				allRequests = pageRequests.concat(allRequests);
				index++;
			})
		}
		return allRequests;
	}

	getCommandsOpposesEvidence(valueMaps, mainPageId){
		let allRequests = [];
		if(valueMaps['opposesevidence'] != null){
			let index = 0;
			let pageId = "";
			valueMaps['opposesevidence'].forEach((value,key)=>{
				let evidenceImg = "";
				if(valueMaps['opposesevidenceimages'] != null){
					evidenceImg = valueMaps['opposesevidenceimages'].get(key);
				}
				let evidenceCiation = "";
				if(valueMaps['opposescitation'] != null){
					evidenceCiation = valueMaps['opposescitation'].get(key);
				}

				let pageRequests = [];
				if (index === 0) {
					pageId = mainPageId;
				} else {
					pageId = mainPageId + "_" + index;
					pageRequests.push(this.makeDuplicatePageCommand(mainPageId, pageId));
				}

				pageRequests.push(this.makeReplaceTextCommand('opposesIndex', String(index + 1), pageId));
				pageRequests.push(this.makeReplaceTextCommand('opposesevidence',value, pageId));
				pageRequests.push(this.makeReplaceTextCommand('opposescitation',evidenceCiation, pageId));
				pageRequests.push(this.makeReplaceMediaCommand('opposesevidence',evidenceImg, pageId));

				allRequests = pageRequests.concat(allRequests);
				index++;
			})
		}
		return allRequests;
	}

	getCommandsOpposesEvidenceQuality(valueMaps, mainPageId){
		let allRequests = [];
		if(valueMaps['opposesqualitynote'] != null){
			let index = 0;
			let pageId = "";
			valueMaps['opposesqualitynote'].forEach((value,key)=>{
				let qualityType = "";
				if(valueMaps['opposesqualitytype'] != null){
					qualityType = valueMaps['opposesqualitytype'].get(key);
				}

				let evidenceCiation = "";
				if(valueMaps['opposescitation'] != null){
					evidenceCiation = valueMaps['opposescitation'].get(key);
				}

				let opposesEvidence = "";
				if(valueMaps['opposesevidence'] != null){
					opposesEvidence = valueMaps['opposesevidence'].get(key);
				}

				let pageRequests = [];
				if (index === 0) {
					pageId = mainPageId;
				} else {
					pageId = mainPageId + "_" + index;
					pageRequests.push(this.makeDuplicatePageCommand(mainPageId, pageId));
				}

				pageRequests.push(this.makeReplaceTextCommand('opposesQualityIndex', String(index + 1), pageId));
				pageRequests.push(this.makeReplaceTextCommand('opposesevidence',opposesEvidence, pageId));
				pageRequests.push(this.makeReplaceTextCommand('opposescitation',evidenceCiation, pageId));
				pageRequests.push(this.makeReplaceTextCommand('qualitytype',qualityType, pageId));
				pageRequests.push(this.makeReplaceTextCommand('qualitynote',value, pageId));

				allRequests = pageRequests.concat(allRequests);
				index++;
			})
		}
		return allRequests;
	}

	getCommandsForQualityType(from,path) {
		return this.getValuePromise(path).then((data) => {
		return ([this.makeReplaceTextCommand(from, data.val())]);
		});
	}
}

function transformYouTubeIdIntoWatchURL(videoId) {
    return "https://www.youtube.com/watch?v=" + videoId;
}

function transformEmbeddedYouTubeURLIntoId(url) {
    const regExp = /^https:\/\/www\.youtube\.com\/embed\/((\w|-){11})(?:\S+)?$/;
	return url.match(regExp)[1];
}

function transformEmbeddedYouTubeURLIntoWatchURL(url) {
    const regExp = /^https:\/\/www\.youtube\.com\/embed\/((\w|-){11})(?:\S+)?$/;
    if(url.match(regExp)) {
        const videoId = url.match(regExp)[1];
        return transformYouTubeIdIntoWatchURL(videoId);
    }
    // If the transformation failed, return the original URL
    return url;
}

export default SlidesButton;

import React, {Component} from 'react';

export default class Breadcrumb extends Component {

    render() {
        var children = null;
        if (this.props.children) {
            children =
                <li className="breadcrumb-item active" aria-current="page">
                    {this.props.children}
                </li>;
        }
        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-main">
                    <li className="breadcrumb-item"><a href={"/"}>Home</a></li>
                    <li className="breadcrumb-item">
                        {this.props.name}
                    </li>
                    {children}
                </ol>
            </nav>
        );
    }
}

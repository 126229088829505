import React, { Fragment } from 'react';
import AppContext from "./AppContext";
import DisplayVideo from "./components/DisplayVideo";
import DisplayFile from "./components/DisplayFile";
import Linkify from 'react-linkify';

/**
 * Displays a field from Firebase as a read-only div.
 *
 * Properties:
 *   identifier - field to display.
 *   className - added onto the div if supplied.
 */

export default class ReadOnlyField extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.getRef = this.getRef.bind(this);

        this.dbRef = null;
        this.headless = null;
        this.state = { text : null,fileName: null };
    }

    render() {
        let cn = this.props.className;
        let txt = this.state.text;

        let isVideo = false;
        if(txt !== null){
            isVideo = txt.includes("youtube.com/embed");
        }

        let isFileAttack = false;
        if(this.state.fileName !== null && this.state.fileName !== "")
            isFileAttack = true;

        let mediaObj = {
            src: this.state.text
        }
        return(
            this.props.identifier.includes('/image')
                ? (this.state.text?
                    <Fragment>
                        {isFileAttack ?
                            <DisplayFile containerClassName={"col"} file={mediaObj} fileName={this.state.fileName} />
                            : isVideo ?
                                <DisplayVideo containerClassName={"col"} video={mediaObj} />
                                :
                                <div className={this.props.className} >
                                    <img className="img-fluid" src={this.state.text} alt={""}/>
                                </div>
                        }
                    </Fragment>
                :null)
                : (cn ?
                    <div className={cn}>
                       <Linkify>{txt}</Linkify>
                       {this.props.children}
                    </div>
                    :
                    <Fragment>
                      <Linkify>{txt}</Linkify>
                      {this.props.children}
                    </Fragment>
                  )
    )}

    componentDidMount() {
        this.dbRef = this.getRef(this.props.identifier);
        if (this.props.identifier.includes("/image")) {
            this.dbRef.once("value").then(data => {
                const dataVal = data.val();
                if (this.state.text !== dataVal) {
                    this.setState({text: dataVal});
                }
            });
            this.callback = this.dbRef.on('value', data => {
                const dataVal = data.val();
                if (this.state.text !== dataVal) {
                    this.setState({ text: dataVal });
                }
            });

            let fileNamePath = this.props.identifier.replace("/image", "/fileName");
            this.fileNameRef = this.getRef(fileNamePath);
            this.fileNameRef.once("value").then(data => {
                const dataVal = data.val();
                if (this.state.fileName !== dataVal) {
                    this.setState({fileName: dataVal});
                }

            });
            this.callbackFileName = this.fileNameRef.on('value', data => {
                const dataVal = data.val();
                if (this.state.fileName !== dataVal) {
                    this.setState({ fileName: dataVal });
                }
            });
        } else {
            this.callback = this.dbRef.on('value', () => {
                if (null === this.headless) {
                    this.headless = new window.Firepad.Headless(this.dbRef);
                }
                this.headless.getText(text => {
                    if (this.state.text !== text) {
                        this.setState({text: text.trim()});
                    }
                })
            });
        }
    }

    componentWillUnmount() {
        if (this.headless) {
            this.headless.dispose();
            this.headless = null;
        }
        if (this.callback) {
            this.dbRef.off('value', this.callback);
            this.callback = null;
        }
        if (this.callbackFileName) {
            this.fileNameRef.off('value', this.callbackFileName);
            this.callbackFileName = null;
        }
    }

    getRef(identifier) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + identifier;
        return window.firebase.database().ref(path);
    }

}

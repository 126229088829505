import React, {Fragment} from "react";
import {comparison_process,comparison_snapshot} from "../steps/Comparison/ComparisonPrintStep";
import ComparisonTitle from "../steps/Comparison/ComparisonPrintStep";
import {cause_effect_snapshot,cause_effect_proccess} from "../steps/Cause-Effect/CauseEffectPrintStep";
import {question_exp_snapshot,question_exp_process} from "../steps/Question-Exploration/QuestionExpPrintSteps";
import {cera_snapshot,cera_process} from "../steps/CER-Guide/CERGuidePrintStep";

import AppContext from "../AppContext";
import initUI from "../ui";
import OrganizerComponent from "../organizers/OrganizerComponent";

export default class PrintPreview extends OrganizerComponent {
    static contextType = AppContext;

    componentDidMount() {
        initUI();
    }

    render() {

        const doc = this.props.doc;
        const user = this.context.currentUser;
        let displayName = user !== null ? user.displayName : "";

        const content =
            "comparison" === doc.type ?
                <Fragment>
                    <div className="h1">{displayName}</div>
                    <ComparisonTitle/>
                    {comparison_snapshot(doc)}
                    {comparison_process(doc,this.props.data)}
                </Fragment>
            : "cause-effect" === doc.type ?
                <Fragment>
                    <div className="h1">{displayName}</div>
                    <div className="h3">{this.context.fileName}</div>
                    {cause_effect_snapshot(doc)}
                    {cause_effect_proccess(doc)}
                </Fragment>
            : "question-exp" === doc.type ?
                <Fragment>
                    <div className="h1">{displayName}</div>
                    {question_exp_snapshot(doc)}
                    {question_exp_process(doc)}
                </Fragment>
            : "cera" === doc.type ?
                <Fragment>
                    <div className="h1">{displayName}</div>
                    {cera_snapshot(doc)}
                    {cera_process(doc)}
                </Fragment>
            : null;
        ;
        return (
            <main id={"main_container"} className="main">
                {content}
            </main>
        );

    }
}

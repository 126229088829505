import React, {Fragment
} from "react";

// title with optional h1 tag wrapper with class for visual formatting
export default function  Title (props) {
    const title = props.title;
    console.log("Title: level %s "+title,props.headerLevel);
    return (
        undefined !== props.headerLevel
            ? <h1 className={props.headerLevel}>{title}</h1>
            : <Fragment>{title}</Fragment>
    );
}

import React, {Fragment} from 'react';
import ListComponent from '../../components/ListComponent';
import AppContext from "../../AppContext";
import Cause from "../../components/Cause";

/**
 causes for main events
 */
export default class Causes extends ListComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.identifier = "cause";
        this.canBeEmpty = false;

        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const editing = (undefined !== this.props.editing)? this.props.editing: true;
        const noImage = (undefined !== this.props.noImage)? this.props.noImage: false;
        const inRoutine = (undefined !== this.props.inRoutine) ? this.props.inRoutine : false;
        const itemList = this.getContentList();
        const groupingCount = Object.keys(itemList).length ? Object.keys(itemList).length : 1;
        const disabled = 4 <= Object.keys(itemList).length;
        let addButton = editing?
            <button onClick={this.handleAddItem} type="button" className="btn btn-primary" disabled={disabled} >
                <span className="fa fa-plus me-0_25" aria-hidden="true"></span>
                Add Cause
            </button>
            : null;
        if(!this.context.canEdit) addButton = null;

        let itemsHTML = [];
        let i = 1;
        const last = Object.keys(itemList).length;
        if (itemList) {
            for (const key in itemList) {
                if (itemList.hasOwnProperty(key)){
                    itemsHTML.push(<Cause key={key}
                                             id={key}
                                             type={this.context.fileType}
                                             identifier={this.identifier}
                                             parentNode={this.getCurrentNode()}
                                             deleteMethod={this.handleDeleteItem}
                                             canDelete={editing && this.state.canDelete}
                                             editing={editing}
                                             noImage={noImage}
                                             inRoutine={inRoutine}
                    />);
                    if (this.props.editing && i < last) { // || "causes-event" === this.props.doc.section ) {
                        itemsHTML.push(
                            <div key={i} className="bracket bracket-short">
                                <div className="bracket-bar-vertical"></div>
                            </div>)
                    }
                    i = i+1;
                }
            }
        }

        const bracket = () => {
            if (this.props.editing) {
                return (
                    <div className="bracket bracket-tall">
                        <div className="bracket-action">
                            {addButton}
                        </div>
                        <div className="bracket-arrow-vertical"></div>
                    </div>
                );
            };

            switch(groupingCount) {
                case 4: return (
                    <div className="bracket bracket-4">
                        <div className="bracket-arrow-vertical"></div>
                        <div className="bracket-start"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-bar-start"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-arrow"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-bar-end"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-end"></div>
                    </div>
                );
                case 3: return (
                    <div className="bracket bracket-3">
                        <div className="bracket-arrow-vertical"></div>
                        <div className="bracket-start"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-bar-start"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-arrow"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-end"></div>
                    </div>
                );
                case 2: return (
                    <div className="bracket bracket-2">
                        <div className="bracket-arrow-vertical"></div>
                        <div className="bracket-start"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-arrow"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-end"></div>
                    </div>
                );
                case 1:
                default: return (
                    <div className="bracket">
                        <div className="bracket-arrow-vertical"></div>
                    </div>
                );
            }
        };

        return (
            this.props.editing ?
                <Fragment>
                    {itemsHTML}
                    {bracket()}
                    {this.props.children}
                </Fragment>
            :
                this.props.inSteps ?
                    <Fragment>
                        {itemsHTML}
                        {this.props.children}
                    </Fragment>
                :
                    <Fragment>
                        <div className={"grouping grouping-" + groupingCount}>
                            {itemsHTML}
                        </div>
                        {bracket()}
                        {this.props.children}
                    </Fragment>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            text: "",
            status: ""
        });
    }

    getValuePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/text";
        return path;
    }

    getImagePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/text/image";
        return path;
    }
}


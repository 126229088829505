import React from 'react';

// Button that opens the Key Terms modal dialog.
export default function KeyTermsButton(props) {

    return(
        <div className={"text-end"}>
            <button id="buttonKeyTerms" type="button" onClick={() =>{window.tracker.trackEvent("Interface", "open key terms", "button");}} className="btn btn-primary mb-1" data-cfw="modal" data-cfw-modal-target="#modalKeyTerms">
                Key Terms
            </button>
        </div>
    )
}

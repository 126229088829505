import React from "react";
import ReadOnlyField from "../../ReadOnlyField";
import KeyTerms from "../KeyTerms";
import ReadOnlyText from "../../components/ReadOnlyText";
import EvidenceList from "../../components/EvidenceList";

export const cera_snapshot = (doc) => {
    return (
        <React.Fragment>
            <h1 className="h3"><ReadOnlyField identifier={"topic"} className={""} /></h1>
            <div className="section">
                <h2 className="h1 text-center">Snapshot</h2>
            </div>

            <div className="section print-nobreak">
                <h3 className="h2">Claim</h3>
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"claim"} className={""} />
                </div>
            </div>

            <div className="section print-nobreak">
                <h3 className="h2">Reasoning</h3>
                <ReadOnlyText identifier={"reasoningtype"} className={"box bg-autofill"} />
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"reasoningnote"} className={""} />
                </div>
            </div>
        </React.Fragment>
    )
}

export const cera_process = (doc) => {
    return (
        <React.Fragment>
            <div className="section section-border-top-dashed">
                <h2 className="h1 text-center">Claim, Evidence, Reasoning Process</h2>
            </div>

            <div className="section print-nobreak">
                <h3 className="h2">Essential Question</h3>
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"essentialquestion"} className={""} />
                </div>
            </div>

            <div className="section print-nobreak">
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="h2">Claim</h3>
                        <div className="box bg-autofill">
                            <ReadOnlyField identifier={"claim"} className={""} />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <h3 className="h2">Backround</h3>
                        <div className="box bg-autofill">
                            <ReadOnlyField identifier={"claimcontext"} className={""} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="section print-nobreak">
                <h3 className="h2">Key Terms</h3>
                <KeyTerms doc={doc} editing={false} printing={true} secondary={true} />
            </div>

            <div className="section print-nobreak">
                <h3 className="h2">Supporting Evidence</h3>
                <EvidenceList doc={doc} editing={false} identifier="evidencesupport" canBeEmpty={false} print={true} hLevel={4} hClassName="h3" />
            </div>

            <div className="section print-nobreak">
                <h3 className="h2">Opposing Evidence</h3>
                <EvidenceList doc={doc} editing={false} identifier="evidenceopposes" canBeEmpty={false} print={true} hLevel={4} hClassName="h3" />
            </div>

            <div className="section print-nobreak">
                <h3 className="h2">Reasoning</h3>
                <ReadOnlyText identifier={"reasoningtype"} className={"box bg-autofill"} />
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"reasoningnote"} className={"col"} />
                </div>
            </div>
        </React.Fragment>
    )
}
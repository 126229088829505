import React from "react";
import ComparisonSteps from "../steps/Comparison/ComparisonSteps";
import CauseEffectSteps from "../steps/Cause-Effect/CauseEffectSteps";
import QuestionExpSteps from "../steps/Question-Exploration/QuestionExpSteps";
import CERGuideSteps from "../steps/CER-Guide/CERGuideSteps";
import StepInstruction from "../steps/StepInstruction";

import AppContext from "../AppContext";
import {bpvAside} from "./layout";
import { onboardInit} from "../steps/TourGuide";
import $ from "jquery";
// var util = require('util');

export default class Aside extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            asideExpand: false
        };
    }

    componentDidMount() {
        bpvAside();
        if (this.context.asideOpen) {
            $('#asideButton').CFW_Collapse('show');
        }
        onboardInit("#asideGuideId");
    }

    handleClick() {
        if (this.context.asideOpen) {
            window.speechstream.speechTools.stop();
            window.tracker.trackEvent("Interface", "close sidebar", "");
        } else {
            window.tracker.trackEvent("Interface", "open sidebar", "");
        }

        this.context.toggleAside();
    }

    sidebarHide() {
        this.props.setSidebarOpen(false);
        this.props.setSidebarExpand(false);
        this.props.setSidebarTab(null);

        this.setState({asideExpand: false});

        return false;
    }

    sidebarOpen(name) {
        var isExpanded = this.state.asideExpand;
        this.sidebarHide();
        if (isExpanded) {
            this.sidebarExpand();
        } else {
            this.props.setSidebarOpen(true);
        }

        this.props.setSidebarTab(name);

        return false;
    }

    sidebarExpand() {
        this.props.setSidebarOpen(false);
        this.props.setSidebarExpand(true);
        this.setState({asideExpand: true});
        return false;
    }
    sidebarCollapse() {
        this.props.setSidebarOpen(true);
        this.props.setSidebarExpand(false);
        this.setState({asideExpand: false});
        return false;
    }

    sidebarKeyDown(event) {
        const REGEX_KEYS = new RegExp('^Arrow(Up|Down|Left|Right)$');
        if (!REGEX_KEYS.test(event.key)) { return; }

        event.stopPropagation();
        event.preventDefault();

        const list = event.target.closest('[role="tablist"]');
        const items = list.querySelectorAll('[role="tab"]');
        const itemActive = list.querySelector('[aria-selected="true"]');

        const doIncrement = event.key === 'ArrowRight' || event.key === 'ArrowDown';
        const nextTab = this.getNextActiveElement([...items], itemActive, doIncrement, false));
        nextTab.click();
        nextTab.focus();
    }

    getNextActiveElement(list, activeElement, doIncrement, allowLoop, allowStartEnd) {
        var index = list.indexOf(activeElement);
        var listLength = list.length;

        if (typeof allowLoop === 'undefined') { allowLoop = false; }
        if (typeof allowStartEnd === 'undefined') { allowStartEnd = false; }

        // If currently actve element does not exist in the list
        // return an element depending on the direction and if starting at the end is allowed
        if (index === -1) {
            return list[!doIncrement && allowStartEnd ? listLength - 1 : 0];
        }

        index += doIncrement ? 1 : -1;

        if (allowLoop) {
            index = (index + listLength) % listLength;
        }

        return list[Math.max(0, Math.min(index, listLength - 1))];
    }

    render() {
        const doc = {
            title: this.context.fileName,
            section: this.context.fileSection,
            type: this.context.fileType,
            id: this.context.fileId,
            editing: false,
        };

        const content =
            "comparison" === doc.type?
                <ComparisonSteps doc={doc} editing={false} data={this.props.data} inCompleteStep={false}/>
            : "cause-effect" === doc.type?
                <CauseEffectSteps doc={doc} editing={false} data={this.props.data} inCompleteStep={false} />
            : "question-exp" === doc.type?
                <QuestionExpSteps doc={doc} editing={false} data={this.props.data} inCompleteStep={false}/>
            : "cera" === doc.type?
                <CERGuideSteps doc={doc} editing={false} data={this.props.data} inCompleteStep={false}/>
            : null;

        let section = this.props.section;
        let instructionTab = this.props.instructionTab;
        let organizerTab = this.props.organizerTab;
        let isOpen = this.props.organizerTab || this.props.instructionTab;

        let instruction = "instructions/" + section;
        let showInstruction = true;
        if("print" === section || "complete" === section || "create-share" === section)
            showInstruction = false;

        const isTeacher = this.context.currentUser.role==='teacher';
        const canEdit = this.context.canEdit;
        let disableInstructionBtn = !this.props.hasInstruction && !(isTeacher && canEdit);

        return (
            <aside className="content-sidebar col">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                    <button className={`nav-link ${instructionTab ? 'active': ''}`} id="sidebarTabInstruction" onClick={() => this.sidebarOpen('Instruction')} onKeyDown={(event) => this.sidebarKeyDown(event)} role="tab" aria-selected={`${instructionTab ? 'true': 'false'}`} tabIndex={`${isOpen && !instructionTab ? '-1': '0'}`} aria-controls="sidebarPanelInstruction">
                            <span className="fa fa-fw fa-chalkboard-user nav-link-icon" aria-hidden="true"></span>
                            <span className="nav-link-txt">Instruction</span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${organizerTab ? 'active': ''}`} id="sidebarTabOrganizer" onClick={() => this.sidebarOpen('Organizer')} onKeyDown={(event) => this.sidebarKeyDown(event)} role="tab" aria-selected={`${organizerTab ? 'true': 'false'}`} tabIndex={`${isOpen && !organizerTab ? '-1': '0'}`} aria-controls="sidebarPanelOrganizer">
                            <span className="fa fa-fw fa-table nav-link-icon" aria-hidden="true"></span>
                            <span className="nav-link-txt">Organizer</span>
                        </button>
                    </li>
                </ul>

                <div id="contentSidebarPanel" className="content-sidebar-container">
                    <div className="content-sidebar-action">
                        <button className="btn btn-link" onClick={() => this.sidebarHide()}>
                            <span className="fa fa-arrow-left me-0_25" aria-hidden="true"></span>Hide<span className="sr-only">sidebar</span>
                        </button>
                        <button id="sidebarActionExpand" className="btn btn-link" onClick={() => this.sidebarExpand()}>
                            Expand<span className="sr-only">sidebar</span><span className="fa fa-angles-right ms-0_25" aria-hidden="true"></span>
                        </button>
                        <button id="sidebarActionCollapse" className="btn btn-link" onClick={() => this.sidebarCollapse()}>
                            <span className="fa fa-angles-left me-0_25" aria-hidden="true"></span>Collapse<span className="sr-only">sidebar</span>
                        </button>
                    </div>
                    <div id="sidebarPanelInstruction" className={`content-sidebar-panel ${instructionTab ? 'in': ''}`} role="tabpanel" tabIndex="0">
                        <div className="content-sidebar-inner">
                            {showInstruction ? <StepInstruction key={instruction} identifier={instruction}/> : null}
                        </div>
                    </div>
                    <div id="sidebarPanelOrganizer" className={`content-sidebar-panel ${organizerTab ? 'in': ''}`} role="tabpanel" tabIndex="0">
                        <div className="content-sidebar-inner">
                            {content}
                        </div>
                    </div>
                </div>

            </aside>
        );
    }
}

import React from 'react';
import {Component} from "react";
import AppContext from "../AppContext";
import T from 'i18n-react';
import PageLink from "./PageLink";
import {hosting} from "../hosting";

export default class SampleGuide extends Component {
    static contextType = AppContext;

    componentDidMount() {
        // We actually want to trust the anchor for this page, and center it.
        const element = document.getElementById(window.location.hash.substring(1));
        if (element) {
            element.scrollIntoView({block: 'center'});
        }
    }

    render() {
        let annotated = (this.props.style === 'annotated');
        let guideContent = <p>Unknown guide</p>;
        if (this.props.type === 'cause-effect')
            guideContent = this.causeEffect();
        if (this.props.type === 'comparison')
            guideContent = this.comparison();
        if (this.props.type === 'cera')
            guideContent = this.cera();
        if (this.props.type === 'question-exp')
            guideContent = this.questionExp();
        return (
            <div className="page-wrapper">

                <div id="content" className={"content row" + (annotated ? " annoview" : "")} >
                    <div className="content-main col">
                        <div className="content-main-inner">
                            <nav aria-label="breadcrumb">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><PageLink page="docs">Home</PageLink></li>
                                    <li className="breadcrumb-item">Sample</li>
                                    <li className="breadcrumb-item active" aria-current="page"><T.span text={{key: this.props.type + ".title"}} /></li>
                                </ul>
                            </nav>
                            {guideContent}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    causeEffect() {
        return <main id="main_container">
            <h1>Sample Guide</h1>

            <div className="section">
                <h2>Topic</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="box box-autofill">
                            Changes in the Ocean Tides
                        </div>
                    </div>
                    <div className="review-anno">
                    </div>
                </div>
            </div>

            <div id="sec_question" className="section">
                <h2>Essential Question</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="grouping-item">
                            <div className="box box-autofill">
                                <div className="row">
                                    <div className="grouping-text">
                                        How do gravitational forces influence Earth's ocean tides?
                                    </div>
                                    <div className="grouping-img"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            An essential question supports students to engage with their existing knowledge base and
                            draw new patterns between ideas. Essential questions help students develop the thinking
                            tools to question their surroundings and make connections with real world events.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_terms" className="section">
                <h2>Key Terms</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="row d-sm-down-none" aria-hidden="true">
                            <div className="col-md-6">
                                <div className="h3">Word or Phrase</div>
                            </div>
                            <div className="col-md-6">
                                <div className="h3">Definition</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Gravity
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    The force by which a planet or other body draws objects toward its center. It is one
                                    major force that causes tides.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Inertia
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    A force that causes something to resist change in speed or direction.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Orbit
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    The curved path of an object around a star, planet, or moon. The moon orbits the
                                    Earth, and the Earth orbits the Sun.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    High tide
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    When water advances to its furthest extent onto the shoreline.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Low tide
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    When the water retreats to its lowest level, moving away from the shoreline.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Key terms can be preloaded by the teacher to highlight unique vocabulary specific to the
                            lesson or unit. Key terms can also be added by students which allows learners to highlight
                            words that are unfamiliar to them or hold specific importance. Oftentimes,
                            discipline-specific terms can present barriers to students. Defining the key terms can help
                            to reduce these barriers and support students to make meaning of the content.
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2>Causes, Main Event, &amp; Effects</h2>
                <div className="row">
                    <div className="review-main">
                        <div id="sec_causes" className="grouping grouping-4">
                            <h3 className="grouping-title">Causes of the Main Event</h3>
                            <div className="grouping-item">
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            As the Earth rotates on its axis, the moon orbits the Earth.
                                        </div>
                                        <div className="grouping-img">
                                            <img
                                                src={ hosting + '/example/cause-effect/cause-effect-1.jpg' }
                                                className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grouping-item">
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            The side of the Earth that is closest to the moon experiences a pull, which
                                            is the gravitational attraction between the Earth and the moon.
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grouping-item">
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            The water on the side of the Earth that is closest to the moon is pulled
                                            toward it, creating a bulge of water on that side.
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grouping-item">
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            On the side of the Earth that is opposite the moon, inertia creates a pull
                                            in the opposite direction, so a bulge is also created on the side farthest
                                            from the moon.
                                        </div>
                                        <div className="grouping-img">
                                            <img
                                                src={ hosting + '/example/cause-effect/cause-effect-2.gif' }
                                                className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Identifying causes of the main event provides students with an opportunity to establish a
                            clear relationship between factors that contribute to the main event, and the event itself.
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="review-main">
                        <div className="bracket bracket-4">
                            <div className="bracket-arrow-vertical"></div>
                            <div className="bracket-start"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-bar-start"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-arrow"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-bar-end"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-end"></div>
                        </div>
                    </div>
                </div>

                <div id="sec_causes-event" className="row">
                    <div className="review-main">
                        <div className="bracket-bar-vertical-stretch">
                            <div className="box box-autofill connector">
                                which results in
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            This step helps students use reasoning to explain the relationship between the causes and
                            the main event. Students analyze and explain how the causes result in, produce, or
                            contribute to the main event.
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="review-main">
                        <div className="bracket">
                            <div className="bracket-arrow-vertical"></div>
                        </div>
                    </div>
                </div>

                <div id="sec_event" className="row">
                    <div className="review-main">
                        <div className="bracket-bar-vertical-stretch">
                            <div className="grouping grouping-1">
                                <h3 className="grouping-title">The Main event</h3>
                                <div className="grouping-item">
                                    <div className="box bg-autofill">
                                        <div className="row">
                                            <div className="grouping-text">
                                                Two high tides occur simultaneously on Earth; one on the side closest to
                                                the moon, and the other on the opposite side of Earth farthest from the
                                                moon.
                                            </div>
                                            <div className="grouping-img">
                                                <img
                                                    src={ hosting + '/example/cause-effect/cause-effect-3.jpg' }
                                                    className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Describing the main event helps students understand a specific phenomenon or occurrence.
                            Explaining the event can help students distinguish the event from its contributing causes
                            and the effects it may create.
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="review-main">
                        <div className="bracket">
                            <div className="bracket-arrow-vertical"></div>
                        </div>
                    </div>
                </div>

                <div id="sec_event-effects" className="row">
                    <div className="review-main">
                        <div className="bracket-bar-vertical-stretch">
                            <div className="box box-autofill connector">
                                which leads to
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            This step helps students use reasoning skills to explain the relationship between the main
                            event and the effects. Students analyze effects to determine the impact of the main event.
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="review-main">
                        <div className="bracket bracket-down bracket-3">
                            <div className="bracket-arrow-vertical"></div>
                            <div className="bracket-start"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-bar-start"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-arrow"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-end"></div>
                        </div>
                    </div>
                </div>

                <div id="sec_effects" className="row">
                    <div className="review-main">
                        <div className="grouping grouping-start grouping-3">
                            <h3 className="grouping-title">Effects of the Main Event</h3>
                            <div className="grouping-item">
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Areas of the Earth that are not closest to the moon or opposite the closest
                                            side experience lower tides.
                                        </div>
                                        <div className="grouping-img">
                                            <img
                                                src={ hosting + '/example/cause-effect/cause-effect-4.jpg' }
                                                className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grouping-item">
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            As the Earth orbits, tides get higher and lower as different parts of the
                                            Earth come closest to or opposite the moon.
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grouping-item">
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            High and low tides happen twice daily in most coastal areas.
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Identifying the effects prompts students to analyze the main event, and use reasoning skills
                            to explain events that happen as a result. This step gives students the opportunity to both
                            apply their knowledge of the main event, and justify how it connects to other events that
                            occur as a result.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_summary" className="section">
                <h2>Summary</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="grouping-item">
                            <div className="box box-autofill">
                                <div className="row">
                                    <div className="grouping-text">
                                        The moon orbits the Earth as the Earth rotates on its axis. Because the moon is
                                        so close to the Earth, its gravitational pull on the Earth creates a bulge of
                                        water on the side closest to the moon. Inertia is the force that causes water to
                                        change direction. When water is pulled toward the moon on one side of the Earth,
                                        inertia pulls in the opposite direction, creating a pull on the opposite side of
                                        the Earth. These two bulges result in two high tides in coastal areas per day.
                                        The areas between the bulges experience lower tides, which get higher and lower
                                        as the Earth orbits.
                                    </div>
                                    <div className="grouping-img">
                                        <img
                                            src={ hosting + '/example/cause-effect/cause-effect-5.jpg' }
                                            className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            The summary offers students the opportunity to return to the essential question. Students
                            synthesize how the causes and effects are related to the main event.
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2>Extensions</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="box box-autofill">
                            Why are some tides higher than others? How does extreme weather affect water levels?
                        </div>
                    </div>
                    <div className="review-anno"></div>
                </div>
            </div>

        </main>;
    }

    cera() {
        return <main id="main_container">
            <h1>Sample Guide</h1>

            <div className="section">
                <h2>Topic</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="box box-autofill">
                            Nonrenewable Resources
                        </div>
                    </div>
                    <div className="review-anno">
                    </div>
                </div>
            </div>

            <div id="sec_essentialquestion" className="section">
                <h2>Essential Question</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="grouping-item">
                            <div className="box box-autofill">
                                <div className="row">
                                    <div className="grouping-text">
                                        Should humans use nonrenewable resources for energy?
                                    </div>
                                    <div className="grouping-img"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            An essential question supports students to engage with their existing knowledge base and
                            draw new patterns between ideas. Essential questions help students develop the thinking
                            tools to question their surroundings and make connections with real world events.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_claim"className="section">
                <h2>Claim</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="grouping-item">
                            <div className="box box-autofill">
                                <div className="row">
                                    <div className="grouping-text">
                                        Humans should decrease their use of nonrenewable resources because they are not
                                        sustainable and are harmful to the planet.
                                    </div>
                                    <div className="grouping-img"></div>
                                </div>
                            </div>
                        </div>
                        <div className="grouping-item">
                            <div className="box box-autofill">
                                <div className="row">
                                    <div className="grouping-text">
                                        Nonrenewable energy sources have a limited supply, and cannot be replenished.
                                        When we run out, we run out! Right now approximately 80 percent of the energy
                                        used on the planet comes from fossil fuels.
                                    </div>
                                    <div className="grouping-img">
                                        <div className="embed-fluid embed-fluid-16x9">
                                            <iframe src="https://www.youtube.com/embed/zaXBVYr9Ij0?enablejsapi=1"
                                                    type="text/html" title="Fossil Fuels 101"
                                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen="" frameBorder="0"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            The claim is what students hinge the skills of analysis, reasoning, and evidence collection
                            on for the topic. The claim, while arguable, should be rooted in logic and evidence. The
                            claim is important in establishing the reasoning cycle that the rest of the Corgi guide will
                            employ. The claim step supports students to develop the higher order skills of reasoning and
                            comprehension.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_terms" className="section">
                <h2>Key Terms</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="row d-sm-down-none" aria-hidden="true">
                            <div className="col-md-6">
                                <div className="h3">Word or Phrase</div>
                            </div>
                            <div className="col-md-6">
                                <div className="h3">Definition</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Natural resource
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    A material from nature that people use.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Nonrenewable resource
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    A resource that cannot be replaced or replenished in a reasonable amount of time.
                                    The four main types are coal, oil, natural gas, and nuclear energy.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Renewable resource
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    A resource that can replenish itself at a similar rate to its use by people. These
                                    resources produce clean energy, which produces less pollution and harmful emissions.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Fossil fuels
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    Oil, natural gas, and coal are fossil fuels. They were formed within the Earth from
                                    dead plants and animals over millions of years.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Key terms can be preloaded by the teacher to highlight unique vocabulary specific to the
                            lesson or unit. Key terms can also be added by students which allows learners to highlight
                            words that are unfamiliar to them or hold specific importance. Oftentimes,
                            discipline-specific terms can present barriers to students. Defining the key terms can help
                            to reduce these barriers and support students to make meaning of the content.
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2 id="sec_evidencesupport">Supporting Evidence</h2>
                <div className="row">
                    <div className="review-main">
                        <h3>Supporting Evidence 1</h3>
                        <div className="box box-autofill">
                            <div className="mb-1">
                                Nonrenewable resources are limited in supply and cannot be used in a sustainable
                                way.
                            </div>
                            <div id="sec_evidencequality" className="mb-1">
                                <h4 className="h3 mb-0">Strength of Evidence</h4>
                                The quality of the evidence is strong
                            </div>
                            <div className="mb-1">
                                <h4 className="h3 mb-0">Rationale</h4>
                                The evidence is strong because it is from a credible source which explains the
                                impact of using nonrenewable resources.
                            </div>
                            <div>
                                <h4 className="h3 mb-0_25">Source</h4>
                                <a href="https://education.nationalgeographic.org/resource/nonrenewable-resources">https://education.nationalgeographic.org/resource/nonrenewable-resources</a>
                            </div>
                        </div>

                        <h3>Supporting Evidence 2</h3>
                        <div className="box box-autofill">
                            <div className="mb-1">
                                When fossil fuels are burned they release carbon dioxide into the atmosphere, which
                                contributes to global warming.
                            </div>
                            <div id="sec_evidencequality" className="mb-1">
                                <h4 className="h3 mb-0">Strength of Evidence</h4>
                                The quality of the evidence is strong
                            </div>
                            <div className="mb-1">
                                <h4 className="h3 mb-0">Rationale</h4>
                                The evidence is strong because it is from a credible source and uses current data to
                                explain the impact of fossil fuels.
                            </div>
                            <div>
                                <h4 className="h3 mb-0">Source</h4>
                                <a href="https://education.nationalgeographic.org/resource/fossil-fuels">https://education.nationalgeographic.org/resource/fossil-fuels</a>
                            </div>
                        </div>

                        <h3>Supporting Evidence 3</h3>
                        <div className="box box-autofill">
                            <div className="mb-1">
                                Humans will most likely run out of oil in the next 40-50 years by some estimates.
                            </div>
                            <div id="sec_evidencequality" className="mb-1">
                                <h4 className="h3 mb-0">Strength of Evidence</h4>
                                The quality of the evidence is weak
                            </div>
                            <div className="mb-1">
                                <h4 className="h3 mb-0">Rationale</h4>
                                Although this website has a lot of important information, it is unclear where this
                                estimate comes from. Also, many factors will affect this estimate.
                            </div>
                            <div>
                                <h4 className="h3 mb-0">Source</h4>
                                <a href="https://sustainability-success.com/10-examples-of-non-renewable-resources/">https://sustainability-success.com/10-examples-of-non-renewable-resources/</a>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            <p>Exploring the evidence that supports the claim helps students to develop connections to
                                the claim that can be logically reasoned and observed. The supporting evidence step
                                helps students to practice the higher order skills of analyzing, reasoning, and
                                comprehension.</p>
                            <p>Evaluation of evidence is a crucial step in the CER process. It supports learners to
                                construct clearly explained, well-reasoned support for their claim in the reasoning
                                step. The key component in this evaluation is the rationale of why evidence is weak or
                                strong. The evaluation of evidence supports students to develop the higher order
                                thinking skills of evaluation, synthesizing, application and analysis.</p>
                        </div>
                    </div>
                </div>

                <hr/>

                <h2 id="sec_evidenceopposes">Opposing Evidence</h2>
                <div className="row">
                    <div className="review-main">
                        <h3>Opposing Evidence 1</h3>
                        <div className="box box-autofill">
                            <div className="mb-1">
                                Nonrenewable resources have their problems, but they are inexpensive to process,
                                and rich in energy.
                            </div>
                            <div id="sec_evidencequality" className="mb-1">
                                <h4 className="h3 mb-0">Strength of Evidence</h4>
                                The quality of the evidence is strong
                            </div>
                            <div className="mb-1">
                                <h4 className="h3 mb-0">Rationale</h4>
                                The evidence is strong, but it doesn't convince me that the benefits are worth
                                the risks that nonrenewable resources pose.
                            </div>
                            <div>
                                <h4 className="h3 mb-0">Source</h4>
                                <a href="https://education.nationalgeographic.org/resource/nonrenewable-resources">https://education.nationalgeographic.org/resource/nonrenewable-resources</a>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Exploring the opposing evidence helps students to consider differing perspectives and
                            deepen their knowledge of the topic through further analysis. The opposing evidence step
                            supports students to develop the higher order skills of analyzing, reasoning,
                            synthesizing, and evaluating.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_reasoning" className="section">
                <h2>Reasoning</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="grouping-item">
                            <div className="box box-autofill">
                                <div className="row">
                                    <div className="grouping-text">
                                        Based on the evidence, I conclude that…
                                    </div>
                                    <div className="grouping-img">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grouping-item">
                            <div className="box box-autofill">
                                <div className="row">
                                    <div className="grouping-text">
                                        nonrenewable resources have more risks than benefits. Even though they are less
                                        expensive to process and rich sources of energy, the effects of using
                                        nonrenewable resources will have many negative effects down the road. At some
                                        point, we will run out of nonrenewable resources. In addition, when nonrenewable
                                        resources are burned there are harmful emissions that contribute to climate
                                        change.
                                    </div>
                                    <div className="grouping-img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Evaluating both the supporting and opposing evidence and determining if, how, and why the
                            evidence supports the claim engages students in a challenging thought process. Students are
                            analyzing information, weighing evidence, and synthesizing information to craft an informed
                            determination. The reasoning step supports students to develop the higher order skills of
                            synthesizing, analyzing, reasoning, comprehending, application, and evaluation.
                        </div>
                    </div>
                </div>
            </div>

        </main>;
    }

    questionExp() {
        return <main id="main_container">
            <h1>Sample Guide</h1>

            <div className="section">
                <h2>Topic</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="box box-autofill">
                            Energy
                        </div>
                    </div>
                    <div className="review-anno">
                    </div>
                </div>
            </div>

            <div id="sec_essentialquestion" className="section">
                <h2>Essential Question</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="grouping-item">
                            <div className="box box-autofill">
                                <div className="row">
                                    <div className="grouping-text">
                                        How does energy move and change?
                                    </div>
                                    <div className="grouping-img">
                                        <div className="embed-fluid embed-fluid-16x9">
                                            <iframe src="https://www.youtube.com/embed/gEzvfE8xxNI?enablejsapi=1"
                                                    type="text/html" title="Energy Transfer and Transformation"
                                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen="" frameBorder="0"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            An essential question supports students to engage with their existing knowledge base and
                            draw new patterns between ideas. Essential questions help students develop the thinking
                            tools to question their surroundings and make connections with real world events.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_terms" className="section">
                <h2>Key Terms</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="row d-sm-down-none" aria-hidden="true">
                            <div className="col-md-6">
                                <div className="h3">Word or Phrase</div>
                            </div>
                            <div className="col-md-6">
                                <div className="h3">Definition</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Energy transfer
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    A movement of energy from one place or object to another.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Energy transformation
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    When energy changes from one form to another.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Thermal Energy
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    A type of energy that moves between objects with different temperatures; heat.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Sound
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    A type of energy that moves in vibrations; it is produced when a force makes an
                                    object or substance vibrate.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Light
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    A type of energy that travels in waves and is produced by vibrations of electrically
                                    charged particles.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Mechanical energy
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    A type of energy that is stored in the movement of objects; motion.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Kinetic energy
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    A form of energy, this is the power or force an object has because of its motion.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Potential energy
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    A form of energy, this is the stored force or power an object has because of its
                                    position or state.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Key terms can be preloaded by the teacher to highlight unique vocabulary specific to the
                            lesson or unit. Key terms can also be added by students which allows learners to highlight
                            words that are unfamiliar to them or hold specific importance. Oftentimes,
                            discipline-specific terms can present barriers to students. Defining the key terms can help
                            to reduce these barriers and support students to make meaning of the content.
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2>Supporting Questions & Responses</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="row d-sm-down-none" aria-hidden="true">
                            <div className="col-md-6">
                                <div className="h3">Question</div>
                            </div>
                            <div className="col-md-6">
                                <div className="h3">Responses</div>
                            </div>
                        </div>
                        <div className="row">
                            <div id="sec_relatedquestion" className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Question</span>
                                    <div className="row">
                                        <div className="grouping-text">
                                            How does energy change to benefit living things?
                                        </div>
                                        <div className="grouping-img">
                                            <div className="embed-fluid embed-fluid-16x9">
                                                <iframe src="https://www.youtube.com/embed/dD0ycPpUyM4?enablejsapi=1"
                                                        type="text/html" title="Heat and Light from the Sun"
                                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen="" frameBorder="0"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sec_relatedquestionresponse" className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Response</span>
                                    <div className="row">
                                        <div className="grouping-text">
                                            Energy from the sun comes as light and heat. These help plants grow and
                                            create oxygen, which humans breathe. The sun's heat also warms the Earth and
                                            living and non-living things on it. Changes in energy help humans live their
                                            daily lives by growing and preparing food, lighting up our spaces so we can
                                            move through the world, and powering the tools we need to live. Humans use
                                            the food we eat and grow and that becomes energy for our movement.
                                        </div>
                                        <div className="grouping-img">
                                            <img
                                                src={ hosting + '/example/question-exp/question-exp-1.jpg' }
                                                className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Question</span>
                                    <div className="row">
                                        <div className="grouping-text">
                                            What is the difference between kinetic and potential energy?
                                        </div>
                                        <div className="grouping-img">
                                            <div className="embed-fluid embed-fluid-16x9">
                                                <iframe src="https://www.youtube.com/embed/zCKeniklH_c?enablejsapi=1"
                                                        type="text/html"
                                                        title="&quot;Our World: Potential and Kinetic Energy&quot; by Adventure Academy"
                                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen="" frameBorder="0"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Response</span>
                                    <div className="row">
                                        <div className="grouping-text">
                                            Potential energy is the energy an object has because of its position or the
                                            state it is in, and kinetic energy is the energy an object has because of
                                            its motion. For example, in a roller coaster, potential energy at the top of
                                            a hill changes to kinetic energy as the cars rush down.
                                        </div>
                                        <div className="grouping-img">
                                            <img
                                                src={ hosting + '/example/question-exp/question-exp-2.jpg' }
                                                className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Question</span>
                                    <div className="row">
                                        <div className="grouping-text">
                                            What is the law of conservation of energy?
                                        </div>
                                        <div className="grouping-img">
                                            <div className="embed-fluid embed-fluid-16x9">
                                                <iframe src="https://www.youtube.com/embed/kP7q28wQ2P8?enablejsapi=1"
                                                        title="TESTED! Conservation Of Energy Principle | Earth Lab"
                                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen="" frameBorder="0"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Response</span>
                                    <div className="row">
                                        <div className="grouping-text">
                                            The law of conservation of energy states that energy cannot be created or
                                            destroyed, it can only change form.
                                        </div>
                                        <div className="grouping-img">
                                            <img
                                                src={ hosting + '/example/question-exp/question-exp-3.jpg' }
                                                className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Question</span>
                                    <div className="row">
                                        <div className="grouping-text">
                                            What are familiar examples of how energy changes form?
                                        </div>
                                        <div className="grouping-img">
                                            <img
                                                src={ hosting + '/example/question-exp/question-exp-4.png' }
                                                className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Response</span>
                                    <div className="row">
                                        <div className="grouping-text">
                                            Energy changes form all the time in our daily lives. We eat and change
                                            chemical energy in food into mechanical energy so we can move. The sun
                                            shines on Earth and changes solar energy into light and heat.
                                        </div>
                                        <div className="grouping-img">
                                            <img
                                                src={ hosting + '/example/question-exp/question-exp-5.jpg' }
                                                className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            <p>The process of breaking the essential question into smaller, more focused supporting
                                questions (questions that usually begin with ‘what’, ‘who’, or ‘where’) helps students
                                explore their curiosities and practice generating questions.</p>
                            <p>Responding to the supporting questions helps students to explore new information and make
                                meaning of the topic.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_essentialquestionresponse" className="section">
                <h2>Response to Essential Question</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="box box-autofill">
                            <div className="row">
                                <div className="grouping-text">
                                    Energy is transferred and transformed all the time. When energy moves from one place
                                    to another it is transferred. When it changes form it is an energy transformation.
                                    Energy can be transferred and transformed through light, heat, sound, and motion. It
                                    changes from potential to kinetic energy and back again. Even though energy changes
                                    and moves, it cannot be created or destroyed. This is known as the law of
                                    conservation of energy.
                                </div>
                                <div className="grouping-img"></div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Drawing from their responses to the supporting questions to develop a response to the
                            essential question helps students to practice synthesizing information and articulating a
                            main idea.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_contextresponse" className="section">
                <h2>Context for Response</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="box box-autofill">
                            <div className="row">
                                <div className="grouping-text">
                                    Everything we do requires and uses energy. It's really interesting to think about
                                    the energy at an amusement park, and how much the designers would have had to think
                                    about potential and kinetic energy when making roller coasters. I also think about
                                    the energy I need to ride my bike up a hill, and how I can get some of that energy
                                    from eating food. It's amazing to think about how different forms of energy work
                                    together to make our daily lives possible.
                                </div>
                                <div className="grouping-img"></div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Applying what they have learned in this exploration to the real world supports students to
                            discover authentic connections to their own lives and communities.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_extensions" className="section">
                <h2>Extensions</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="box box-autofill">
                            Energy is never produced or lost. It just changes form or is transferred. I would like to
                            know more about sound energy. I play the piano and I would like to learn more about where
                            loud and soft sounds come from, and what is involved in that transfer of energy.
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Providing opportunities for students to document additional questions to explore supports
                            students to continue to develop their curiosity around the topic and surfaces future areas
                            of study that feel relevant and engaging to learners.
                        </div>
                    </div>
                </div>
            </div>

        </main>;
    }

    comparison() {
        return <main id="main_container">
            <h1>Sample Guide</h1>

            <div id="sec_essentialquestion" className="section">
                <h2>Essential Question</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="grouping-item">
                            <div className="box box-autofill">
                                <div className="row">
                                    <div className="grouping-text">
                                        How do the processes of weathering and erosion affect the Earth's surface?
                                    </div>
                                    <div className="grouping-img">
                                        <div className="embed-fluid embed-fluid-16x9">
                                            <iframe src="https://www.youtube.com/embed/QJUs4e2X5Uo?enablejsapi=1"
                                                    type="text/html"
                                                    title="Weathering and Erosion | What Is the Difference between Weathering and Erosion?"
                                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen="" frameBorder="0"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            An essential question supports students to engage with their existing knowledge base and
                            draw new patterns between ideas. Essential questions help students develop the thinking
                            tools to question their surroundings and make connections with real world events.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_concepts" className="section">
                <h2>Concepts</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="row">
                            <div className="col-md-6">
                                <h3>Concept A</h3>
                                <div className="box box-autofill">
                                    Weathering
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3>Concept B</h3>
                                <div className="box box-autofill">
                                    Erosion
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <h3>Description of Concept A</h3>
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            The breaking down of rocks on Earth’s surface into smaller pieces.
                                        </div>
                                        <div className="grouping-img">
                                            <div className="embed-fluid embed-fluid-16x9">
                                                <iframe src="https://www.youtube.com/embed/mQAOe-0vxdc?enablejsapi=1"
                                                        type="text/html"
                                                        title="Weathering for Kids | What Is Weathering? Fun Introduction to Weathering for Kids"
                                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen="" frameBorder="0"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3>Description of Concept B</h3>
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            The movement of rocks or sediment from one place to another.
                                        </div>
                                        <div className="grouping-img">
                                            <div className="embed-fluid embed-fluid-16x9">
                                                <iframe src="https://www.youtube.com/embed/qqsTS67BKmA?enablejsapi=1"
                                                        type="text/html"
                                                        title="The Power of Water for Kids: How Erosion by Water Shapes Landforms for Children - FreeSchool"
                                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen="" frameBorder="0"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            The two topics for comparison fall under the umbrella of the overall concept. These concepts
                            will share some similarities, but students will also have the opportunity to distinguish
                            differences. Developing an understanding of one concept can help students better define and
                            understand the other.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_terms" className="section">
                <h2>Key Terms</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="row d-sm-down-none" aria-hidden="true">
                            <div className="col-md-6">
                                <div className="h3">Word or Phrase</div>
                            </div>
                            <div className="col-md-6">
                                <div className="h3">Definition</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Weathering
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    The process of breaking down or dissolving rocks on Earth’s surface.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Erosion
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    The process of water moving rocks or sediment from one place to another, and
                                    resulting in changes to Earth’s surface.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Mechanical (physical) weathering
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    When larger rocks break into smaller pieces, but no chemical change takes place.
                                    Water is a main cause of mechanical weathering.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Word or Phrase</span>
                                    Deposition
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box box-autofill">
                                    <span className="sr-only">Definition</span>
                                    When water drops sediment carried by erosion processes in a new location.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Key terms can be preloaded by the teacher to highlight unique vocabulary specific to the
                            lesson or unit. Key terms can also be added by students which allows learners to highlight
                            words that are unfamiliar to them or hold specific importance. Oftentimes,
                            discipline-specific terms can present barriers to students. Defining the key terms can help
                            to reduce these barriers and support students to make meaning of the content.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_overall" className="section">
                <h2>Overall Concept</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="grouping grouping-2">
                            <div className="grouping-item">
                                <h3 className="grouping-item-title">Concept A</h3>
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Weathering
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grouping-item">
                                <h3 className="grouping-item-title">Concept B</h3>
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Erosion
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bracket bracket-2">
                            <div className="bracket-arrow-vertical"></div>
                            <div className="bracket-start"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-arrow"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-end"></div>
                        </div>
                        <div className="grouping grouping-1">
                            <h3 className="grouping-item-title">Overall Concept</h3>
                            <div className="grouping-item">
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Weathering and erosion are two processes that explain how the forces of
                                            water, ice, and wind can break down and transport rock and minerals over
                                            time to reshape the landscape.
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            The process of considering the overall concept supports learners to practice the skill of
                            categorization.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_characteristics" className="section">
                <h2>Characteristics</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="row">
                            <div className="col-md-6">
                                <h3>Characteristics of Concept A</h3>
                                <div className="box box-autofill">
                                    Does not involve movement of rock
                                </div>
                                <div className="box box-autofill">
                                    Water, wind, and ice cause weathering by breaking down rock into smaller pieces
                                </div>
                                <div className="box box-autofill">
                                    Three different types: chemical, mechanical, and biological
                                </div>
                                <div className="box box-autofill">
                                    Happens gradually over time
                                </div>
                                <div className="box box-autofill">
                                    Can result in the creation or destruction of landforms
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3>Characteristics of Concept B</h3>
                                <div className="box box-autofill">
                                    Involves movement of rock
                                </div>
                                <div className="box box-autofill">
                                    Water, wind, and ice erode the earth's surface by moving pieces of rock to a new
                                    location
                                </div>
                                <div className="box box-autofill">
                                    Two main types: chemical and physical
                                </div>
                                <div className="box box-autofill">
                                    Can happen gradually over time or somewhat quickly
                                </div>
                                <div className="box box-autofill">
                                    Can result in the creation or destruction of landforms
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Identifying key characteristics supports learners to articulate features of a concept that
                            make it distinct. Discovering and documenting the defining characteristics will help prepare
                            students to think more critically about each characteristic in the next steps.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_likeCharacteristics" className="section">
                <h2>Similar Characteristics &amp; Categories</h2>
                <div className="row">
                    <div className="review-main d-flex flex-column">
                        <div className="grouping grouping-2 grouping-match">
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Water, wind, and ice cause weathering by breaking down rock into smaller
                                            pieces
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grouping-icon">
                                <span className="fa fa-equals" aria-hidden="true"></span>
                                <span className="sr-only">is similar to</span>
                            </div>
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Water, wind, and ice erode the earth's surface by moving pieces of rock to a
                                            new location
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bracket bracket-2">
                            <div className="bracket-arrow-vertical"></div>
                            <div className="bracket-start"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-arrow"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-end"></div>
                        </div>
                        <div className="bracket-bar-vertical-stretch">
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Learners can practice analyzing characteristics for similarities, and use this analysis to
                            create pairs of characteristics across the two concepts. Engaging in this analysis will help
                            students recognize points of similarity between the two concepts, although the pairs do not
                            have to be exactly the same.
                        </div>
                    </div>
                </div>
                <div id="sec_likeCategories" className="row">
                    <div className="review-main">
                        <div className="grouping grouping-1">
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    Can be cause by wind, water, and ice
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            After identifying similar characteristics across each concept, learners can classify
                            similarities they have identified by creating a category that describes the relationships
                            between similar pairs. Creating pairs and categories help learners understand what aspects
                            of their concepts are comparable and provides an organizing system for the comparison
                            process.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="review-main">
                        <div className="grouping grouping-2 grouping-match">
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Can result in the creation or destruction of landforms
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grouping-icon">
                                <span className="fa fa-equals" aria-hidden="true"></span>
                                <span className="sr-only">is similar to</span>
                            </div>
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Can result in the creation or destruction of landforms
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bracket bracket-2">
                            <div className="bracket-arrow-vertical"></div>
                            <div className="bracket-start"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-arrow"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-end"></div>
                        </div>
                        <div className="grouping grouping-1">
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    Results of the process
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                    </div>
                </div>
            </div>

            <div id="sec_unlikeCharacteristics" className="section">
                <h2>Different Characteristics &amp; Categories</h2>
                <div className="row">
                    <div className="review-main d-flex flex-column">
                        <div className="grouping grouping-2 grouping-match">
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Does not involve movement of rock
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grouping-icon">
                                <span className="fa fa-not-equal" aria-hidden="true"></span>
                                <span className="sr-only">is different from</span>
                            </div>
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Involves movement of rock
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bracket bracket-2">
                            <div className="bracket-arrow-vertical"></div>
                            <div className="bracket-start"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-arrow"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-end"></div>
                        </div>
                        <div className="bracket-bar-vertical-stretch">
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Learners will also be able to recognize that some characteristics are not part of a pair.
                            This will help them surface points of contrast between the two concepts, where features or
                            traits diverge.
                        </div>
                    </div>
                </div>

                <div id="sec_unlikeCategories" className="row">
                    <div className="review-main">
                        <div className="grouping grouping-1">
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    Movement
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            Even though the characteristics are different, learners can deepen their thinking by
                            classifying the differences into overarching categories. Creating contrasting pairs and
                            categories supports learners to understand what aspects of their concepts are different and
                            continues to offer an organizing system for the comparison process.
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="review-main">
                        <div className="grouping grouping-2 grouping-match">
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Three different types: chemical, mechanical, and biological
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grouping-icon">
                                <span className="fa fa-not-equal" aria-hidden="true"></span>
                                <span className="sr-only">is different from</span>
                            </div>
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Two main types: chemical and physical
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bracket bracket-2">
                            <div className="bracket-arrow-vertical"></div>
                            <div className="bracket-start"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-arrow"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-end"></div>
                        </div>
                        <div className="grouping grouping-1">
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    Types
                                </div>
                            </div>
                        </div>

                        <div className="grouping grouping-2 grouping-match">
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Happens gradually over time
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grouping-icon">
                                <span className="fa fa-not-equal" aria-hidden="true"></span>
                                <span className="sr-only">is different from</span>
                            </div>
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    <div className="row">
                                        <div className="grouping-text">
                                            Can happen gradually over time or somewhat quickly
                                        </div>
                                        <div className="grouping-img"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bracket bracket-2">
                            <div className="bracket-arrow-vertical"></div>
                            <div className="bracket-start"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-arrow"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-end"></div>
                        </div>
                        <div className="grouping grouping-1">
                            <div className="grouping-item">
                                <div className="box box-autofill">
                                    How long it takes
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                    </div>
                </div>
            </div>

            <div id="sec_summary" className="section">
                <h2>Summary</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="grouping-item">
                            <div className="box box-autofill">
                                <div className="row">
                                    <div className="grouping-text">
                                        Weathering and erosion are two of the main processes that shape the surface of
                                        the Earth. These processes work together. Weathering from water, wind, and ice
                                        breaks down rock into smaller pieces. Erosion, also caused by wind, water, and
                                        ice, moves those pieces to new places and deposits them. Over time, this causes
                                        changes in the shapes of rivers, mountains, and coastlines. It can take many
                                        years for weathering to occur. Erosion can take a long time, but it can also
                                        happen quickly from things like floods or extreme winds. Weathering can be
                                        physical, chemical, or biological, depending on how the rock changes as it is
                                        broken down. Erosion can be physical or chemical. Landforms on the Earth are
                                        constantly changing because of the processes of weathering and erosion.
                                    </div>
                                    <div className="grouping-img">
                                        <img src={ hosting + '/example/comparison/comparison-1.jpg' }
                                             className="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-anno">
                        <div className="box box-unfilled">
                            <h3 className="mb-0_25">How this step supports student thinking</h3>
                            The summary offers learners the opportunity to return to the essential question and
                            synthesize what they’ve learned about the topic in terms of comparing and contrasting
                            aspects of the overall concept.
                        </div>
                    </div>
                </div>
            </div>

            <div id="sec_extensions" className="section">
                <h2>Extensions</h2>
                <div className="row">
                    <div className="review-main">
                        <div className="box box-autofill">
                            I wonder what some examples of weathering and erosion are in my area. For example, the rocks
                            at the beach are always being hit by the waves. How will the shape and structure of the
                            nearby beaches change over time? How long will these changes take before they are noticeable
                            to people who live here and visit here?
                        </div>
                    </div>
                    <div className="review-anno"></div>
                </div>
            </div>

        </main>;
    }

}

import React from 'react';
import AppContext from "../AppContext";
// import {empty} from "./textutil";
// import GetText from "./GetText";

/**
 * Displays a field from Firebase as a read-only div.
 * NOT for CodeMirror fields, just plain text
 *
 * Properties:
 *   identifier - field to display.
 *   className - added onto the div if supplied.
 */

export default class ReadOnlyText extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.getRef = this.getRef.bind(this);

        this.dbRef = null;
        this.state = { text : null };
    }

    render() {
        // CORGI-481 Review step
        let cn = this.props.className;
        let txt = this.state.text;
        /*
        let id = this.props.identifier.split('/')[0];
        if (empty(txt)) {
            cn = cn.replace("autofill","unfilled");
            txt = <GetText type={this.context.fileType} identifier={id} item={"empty"} />;
        }
         */
        return(
             <div className={cn}>
             {txt}
             {this.props.children}
              </div>
    )}

    componentDidMount() {
        this.dbRef = this.getRef(this.props.identifier);
        this.watcher = this.dbRef.on("value", data => {
            if (this.state.text !== data.val()) {
                this.setState({ text: data.val() });
            }
        });
    }

    componentWillUnmount() {
        this.dbRef.off('value', this.watcher);
    }

    getRef(identifier) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + identifier;
        return window.firebase.database().ref(path);
    }

}

import SttInput from "../components/SttInput";
import React, {Fragment} from "react";
import AppContext from "../AppContext";
import ReadOnlyField from "../ReadOnlyField";
import PreviewComponent from '../components/PreviewComponent';

export default class StepInstruction extends PreviewComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.identifier = this.props.identifier;
        this.placeholder = "Additional content for this step";
        this.state = {
            id: 0,
            firstUseTourGuide: true,
            hasInstruction: false
        }
        this.updateInstruction = this.updateInstruction.bind(this);
        this.teacherInfo = null;
        this.hasInstructionText = false;
        this.hasInstructionImage = false;
        this.hasInstructionFileName = false;
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        this.teacherRef = this.getRef(this.identifier + "/teacher");
        this.teacherRef.once("value", data => {
            if(data.val() !== null){
                this.teacherInfo = data.val();
            }
        });

        for (let field of [this.identifier, this.identifier + "/image"]) {
            this.checkHasInstruction(field);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateInstruction(){
        if(undefined === this.lastUpdateTime || null === this.lastUpdateTime) {
            this.doUpdateInstruction();
            this.lastUpdateTime = new Date().getTime();
        } else {
            let diffTime = new Date().getTime() - this.lastUpdateTime;
            if(diffTime > 60000){
                this.doUpdateInstruction();
                this.lastUpdateTime = new Date().getTime();
            }
        }
    }

    doUpdateInstruction(){
        console.log("Update Instruction");
        this.teacherInfo = this.context.currentUser;
        this.setState({id: this.state.id + 1}); // re-render to show who is editing
        this.getRef(this.identifier + "/teacher").set(this.context.currentUser);
    }

    checkHasInstruction(field) {
        if (field.includes("/image"))  {
            let dbRef = this.getRef(this.props.identifier);
            dbRef.once("value").then(data => {
                let dataVal = data.val();
                this.hasInstructionImage = dataVal && dataVal.image ? (this.hasContent(dataVal.image) ? true : false) : false;
                this.hasInstructionFileName = dataVal && dataVal.fileName ? (this.hasContent(dataVal.fileName) ? true : false) : false;
                this.updateHasInstruction();
            });
        } else {
            this.getFirepadTextPromise(field).then((data) => {
                this.hasInstructionText = this.hasContent(data) ? true : false;
                this.updateHasInstruction();
            });
        }
    }

    updateHasInstruction() {
        if (!this._isMounted) {
            return;
        }
        this.setState({
            hasInstruction: this.hasInstructionText || this.hasInstructionImage || this.hasInstructionFileName
        })
    }

    render() {
        const isTeacher = this.context.currentUser.role==='teacher';
        const canEdit = this.context.canEdit;

        if (isTeacher && canEdit) {
            // Teacher with editing permission: show the editable version of the instruction area.
            return (
                <Fragment>
                    {this.teacherInfo !== null ?
                        <div className="instruction-user">
                            <div className="media flex-items-center fs-small">
                                <span className="collab-img me-0_5">
                                    <img src={this.teacherInfo.photoURL} className="img-fluid collab-img" alt=""/>
                                </span>
                                <div className="media-body">
                                    Last edited by {this.teacherInfo.displayName}
                                </div>
                            </div>
                        </div>
                    :
                        null
                    }
                    <h2>Provide instruction/resources for learners</h2>
                    <div className="directions">
                        <p>Augment this step with additional directions, cues, or media.</p>
                    </div>
                    <div className="box box-primary">
                        <div className="form-group">
                            <SttInput updateContent={this.updateInstruction} placeholder={this.placeholder} {...this.props} inputType="text-area"
                                      identifier={this.identifier} addImage={true} singleLine={false}
                                      label="Additional content for this step" />
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            // Show read-only version
            return (
                <Fragment>
                    {this.state.hasInstruction ?
                        <Fragment>
                            <h2>{this.teacherInfo.displayName} says:</h2>
                            <ReadOnlyField identifier={this.identifier} className={"mb-1"}/>
                            <ReadOnlyField identifier={this.identifier + "/image"} className="attachment"/>
                        </Fragment>
                    :
                        <Fragment>
                            Optional teacher instruction not included.
                        </Fragment>
                    }
                </Fragment>
            );
        }
    }
}

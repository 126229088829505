const imagesLoaded = (callback) => {
    // https://stackoverflow.com/questions/11071314/javascript-execute-after-all-images-have-loaded

    Promise.all(Array.from(document.images).map(img => {
        if (img.complete) {
            return Promise.resolve(img.naturalHeight !== 'undefined');
        }
        return new Promise(resolve => {
            img.addEventListener('load', () => resolve(true));
            img.addEventListener('error', () => resolve(false));
        });
    })).then(results => {
        if (results.every(res => res))
            //console.log('all images loaded successfully');
            if (typeof callback === 'function') {
                callback();
            }
        else
            //console.log('some images failed to load, all finished loading');
            if (typeof callback === 'function') {
                callback();
            }
    });
}

export const getHashElement = () => {
    return document.getElementById(window.location.hash.substring(1));
}

const scrollToElement = (element) => {
    element.scrollIntoView({block: 'start'});
    // Scroll down to get item below header bar
    const offsetVar = getComputedStyle(document.documentElement).getPropertyValue('--header-offset');
    const offsetPx = Math.floor(parseFloat(offsetVar) * 16) * -1;
    window.scrollBy(0, offsetPx);
};

export const doHashScroll = () => {
    const element = getHashElement();

    if (element) {
        imagesLoaded(() => {
            scrollToElement(element);
        });
    }
};

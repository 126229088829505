import React, {Fragment} from "react";
import SttInput from "../../components/SttInput";
import Description from "../../components/Description";
import StepHeader from "../StepHeader";
import ReadOnlyField from "../../ReadOnlyField";
import AppContext from "../../AppContext";

export default class Concepts extends React.Component {
    static contextType = AppContext;

    render() {
        return (
            <Fragment>
                <StepHeader tooltipTitle={true} section={this.props.section} />
                <h2>Essential Question</h2>
                <ReadOnlyField identifier="essentialquestion" className="box bg-autofill" />

                <div className="row">
                    <div className="col-md-6">
                        <h2>Concept A</h2>
                        { this.context.canEdit ?
                            <Fragment>
                                <div className="box bg-primary">
                                    <SttInput identifier="concept1" inputType={"text-area"} />
                                    <Description identifier="description1" />
                                </div>
                            </Fragment>
                        :
                            <Fragment>
                                <ReadOnlyField identifier="concept1" className="box bg-autofill" />
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <ReadOnlyField identifier="description1" className="grouping-text" />
                                        <ReadOnlyField identifier="description1/image" className="grouping-img" />
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
                    <div className="col-md-6">
                        <h2>Concept B</h2>
                        { this.context.canEdit ?
                            <Fragment>
                                <div className="box bg-primary">
                                    <SttInput identifier="concept2" inputType="text-area" />
                                    <Description identifier="description2" />
                                </div>
                            </Fragment>
                        :
                            <Fragment>
                                <ReadOnlyField identifier="concept2" className="box bg-autofill" />
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <ReadOnlyField identifier="description2" className="grouping-text" />
                                        <ReadOnlyField identifier="description2/image" className="grouping-img" />
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
            </Fragment>
        );

    }

}

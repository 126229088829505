import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DeleteButton from './DeleteButton';
import DeleteModal from './DeleteModal';
import uniqueId from '../uniqueId';
import $ from 'jquery';
import T from 'i18n-react';
import SttInput from "./SttInput";
import AppContext from "../AppContext";
import ReadOnlyField from "../ReadOnlyField";

/**
 * (Abstract) Component for the "Like/Unlike Characteristics & Categories" sections.
 *
 * Subclass must set various this. properties to set messages and identifiers.
 *
 * Component is primarily a list of characteristic + category items, but this is not stored as a separate list,
 * it is based on filtering the full lists of characteristics.
 * In addition to that list, it includes a "Find (Unlike) Pair" Button, and the modal that is shown for that
 * (including the list of all UNpaired items).
 */
export default class PairedCharacteristics extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            findButtonsActive: false, // whether the "Find (Un)Like Pair" button is currently enabled
            pairButtonActive: false, // whether the "Pair" button in the modal is currently enabled.
            checkedA: null,
            checkedB: null,
            pairedItems: {},
            characteristics1: {},
            characteristics2: {}
        };

        this.canBeEmpty = true;
        this.sourceA = "characteristics1";
        this.sourceB = "characteristics2";
        this.buttonId = uniqueId();
        this.modalId = uniqueId();

        this.handleOpen = this.handleOpen.bind(this);
        this.handleChangeA = this.handleChangeA.bind(this);
        this.handleChangeB = this.handleChangeB.bind(this);
        this.isPairingPossible = this.isPairingPossible.bind(this);
        this.checkPairButton = this.checkPairButton.bind(this);
        this.handlePair = this.handlePair.bind(this);
        this.makePair = this.makePair.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
        this.getBaseNode = this.getBaseNode.bind(this);
    }

    componentWillMount() {
    }

    componentDidMount(){
        this.changeMakePairStatus();
    }

    changeMakePairStatus = () =>{
        this.setState({findButtonsActive: this.isPairingPossible()});
    }

    render() {
        const wantEditing = (undefined !== this.props.editing)? this.props.editing: true;
        const editing = this.context.canEdit && wantEditing;
        const listA = this.props.unpairedCharacteristics1;
        const listB = this.props.unpairedCharacteristics2;
        const listPairedItems = this.props.pairedCharacteristics;
        let pairedItems = [], modalItemsA = [], modalItemsB = [];
        const showCategory = undefined !== this.props.showCategory && this.props.showCategory;
        const showLabel = undefined !== this.props.showLabel && this.props.showLabel;
        const label = this.props.label? this.props.label: null;
        const header = this.props.header? this.props.header: null;
        const pairButton =
            <button id={this.buttonId} type="button" className="btn btn-primary" onClick={this.handleOpen}
                    data-cfw="modal" data-cfw-modal-target={'#' + this.modalId}
                    disabled={!this.state.findButtonsActive}>
                <span className="fa fa-plus me-0_25" aria-hidden="true"></span>&nbsp;
                {T.translate(this.buttonTextKey)}
            </button>
        ;
        // CORGI-330 add unpaired lists
        let unpairedItemsA = [];
        let unpairedItemsB = [];

        if (listPairedItems instanceof Object) {
            for (let key in listPairedItems) {
                if (listPairedItems.hasOwnProperty(key)) {
                    let pairedItem = listPairedItems[key];
                    pairedItems.push(<CharPair key={key} doc={this.props.doc} printing={'print' === this.props.doc.section}
                                               pairedID={key}
                                               idA={pairedItem["characteristic1"]}
                                               idB={pairedItem["characteristic2"]}
                                               showBoth={this.showBoth}
                                               catIdentifier={this.catIdentifier}
                                               charIdentifier={this.charIdentifier}
                                               fileType={this.context.docType}
                                               fileId={this.context.docId}
                                               showCategory={showCategory}
                                               showLabel={showLabel}
                                               editing={editing}
                                               label={label}
                                               header={header && Object.keys(pairedItems).length === 0? header: null}
                    />);
                }
            }
        }

        if (listA) {
            for (let key in listA) {
                if (listA.hasOwnProperty(key)) {
                    unpairedItemsA.push(<UnpairedItem key={key} id={key} parentNode={this.getBaseNode() + "/" + this.sourceA}/>);
                    modalItemsA.push(<CharOption key={key}
                                                 id={key}
                                                 group="groupA"
                                                 parentNode={this.getBaseNode() + "/" + this.sourceA}
                                                 checked={this.state.checkedA}
                                                 change={this.handleChangeA}/>);
                }
            }
        }
        if (listB) {
            for (let key in listB) {
                if (listB.hasOwnProperty(key)) {
                    unpairedItemsB.push(<UnpairedItem key={key} id={key} parentNode={this.getBaseNode() + "/" + this.sourceB}/>);
                    modalItemsB.push(<CharOption key={key}
                                                 id={key}
                                                 group="groupB"
                                                 parentNode={this.getBaseNode() + "/" + this.sourceB}
                                                 checked={this.state.checkedB}
                                                 change={this.handleChangeB}/>);
                }
            }
        }
        const unpaired = unpairedItemsA.length !== 0 || unpairedItemsB.length !== 0 ?
            <div className="row">
                <div className="col-md-6">
                    <h2>Characteristics of Concept A</h2>
                    {unpairedItemsA}
                </div>
                <div className="col-md-6">
                    <h2>Characteristics of Concept B</h2>
                    {unpairedItemsB}
                </div>
            </div> : null;

        return (
            <React.Fragment>
                {pairedItems}

                {pairedItems.length === 0 && showCategory && editing && this.catIdentifier === "likecategories" ?
                    <p className="directions text-center">There are no similar pairs to categorize</p>
                    : null
                }

                {pairedItems.length === 0 && showCategory && editing && this.catIdentifier === "unlikecategories" ?
                    <p className="directions text-center">There are no different pairs to categorize</p>
                    : null
                }

                {editing && !showCategory ?
                    <React.Fragment>
                        {unpaired}
                        <div className="text-center">
                            {!this.state.findButtonsActive && this.charIdentifier === "likecharacteristics" ?
                                <p className="directions text-center my-0_25">There are no similar pairs to categorize</p>
                                : null
                            }
                            {!this.state.findButtonsActive && this.charIdentifier === "unlikecharacteristics" ?
                                <p className="directions text-center my-0_25">There are no different pairs to categorize</p>
                                : null
                            }

                            {pairButton}
                            <div id={this.modalId} className="modal pair-modal">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2 className="h1 modal-title">{T.translate(this.modalTitleKey)}</h2>
                                            <button type="button" className="close" data-cfw-dismiss="modal" aria-label="Close"><span className="fa fa-times" aria-hidden="true"></span></button>
                                        </div>
                                        <div className="modal-body">
                                            <p className="directions">{this.props.directions}</p>

                                            <form>
                                                <div className="row mb-1">
                                                    <div className="col-md mb-1 mb-md-0">
                                                        <fieldset>
                                                            <legend className="h2">Concept A</legend>
                                                            <ReadOnlyField identifier="concept1" className="box bg-autofill" />
                                                            {modalItemsA}
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-md">
                                                        <fieldset>
                                                            <legend className="h2">Concept B</legend>
                                                            <ReadOnlyField identifier="concept2" className="box bg-autofill" />
                                                            {modalItemsB}
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="text-end">
                                                    <button type="button" className="btn btn-link"
                                                            data-cfw-dismiss="modal">Cancel
                                                    </button>
                                                    <button type="button" onClick={this.handlePair}
                                                            disabled={!this.state.pairButtonActive}
                                                            className="btn btn-primary">Add Pair
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    : null
                }
            </React.Fragment>
        );
    }


    // Check whether the conditions are met for the "Pair" button to be active.
    // True if there is at least one non-blank, non-paired item in each column.
    isPairingPossible() {
        const listA = this.props.unpairedCharacteristics1;
        const listB = this.props.unpairedCharacteristics2;

        var checkA = false;
        var checkB = false;
        if (listA) {
            for (let key in listA) {
                if (listA.hasOwnProperty(key)){
                    checkA = true;
                }
            }
        }

        if (listB) {
            for (let key in listB) {
                if (listB.hasOwnProperty(key)){
                    checkB = true;
                }
            }
        }

        console.log("CheckA = " + checkA + "-> CheckB = " + checkB);

        if(checkA === true && checkB === true)
            return true;
        else return false;
    }

    handleOpen() {
        this.setState({
            pairButtonActive: false,
            checkedA: null,
            checkedB: null
        });
    }

    handleChangeA(event) {
        if(event != null){
            const a = event.target.value;
            this.setState({checkedA: a});
            this.checkPairButton(a, this.state.checkedB);
        }

    }

    handleChangeB(event) {
        if(event != null){
            const b = event.target.value;
            this.setState({checkedB: b});
            this.checkPairButton(this.state.checkedA, b);
        }
    }

    checkPairButton(a, b) {
        if (a != null && b != null && !this.state.pairButtonActive)
            this.setState({pairButtonActive: true});
    }

    handlePair() {
        const idA = this.state.checkedA, idB = this.state.checkedB;
        if (idA === null || idB === null)
            return;

        this.makePair(idA, idB);
        $('#' + this.buttonId).CFW_Modal('hide');
        this.setState({
            pairButtonActive: false,
            checkedA: null,
            checkedB: null
        });
    }

    makePair(idA, idB) {
        console.log("make Pair");
        this.context.contentUpdated();
        // this.listA.remove(idA);
        let characteristic1Ref = window.firebase.database().ref(this.getBaseNode() + "/characteristics1/" + idA);
        let characteristic2Ref = window.firebase.database().ref(this.getBaseNode() + "/characteristics2/" + idB);
        characteristic1Ref.update({
            status: this.myStatus
        }, () => {
            characteristic2Ref.update({
                status: this.myStatus
            }, () => {
                let pairsRef = window.firebase.database().ref(this.getCurrentNode()).push();
                pairsRef.set({
                    "characteristic1": idA,
                    "characteristic2": idB,
                });
            })
        });
        window.tracker.trackEvent("Content", "make pair", "");
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/pairs/" + this.charIdentifier;
    }

    getBaseNode() {
        return this.context.fileType + "/" + this.context.fileId;
    }

}

class UnpairedItem extends Component {

    constructor(props) {
        super(props);
        this.getCurrentNode = this.getCurrentNode.bind(this);
        this.state = {
            text: ""
        }
    }

    render() {
        return(
            <div className="box bg-autofill">{this.state.text}</div>
        )
    }

    getCurrentNode() {
        return this.props.parentNode + "/" + this.props.id + "/text";
    }

    componentWillUnmount() {
        this.ref.off('value', this.watcher);
    }

    componentDidMount() {
        this.ref = window.firebase.database().ref(this.getCurrentNode());
        this.watcher = this.ref.on('value', () => {
            let firepadHeadless = new window.Firepad.Headless(this.ref);
            firepadHeadless.getText(text => {
                if (this.state.text !== text) {
                    this.setState({
                        text: text
                    });
                }
                firepadHeadless.dispose();
            })
        })

    }
}

/**
 * A single row in the (Un)like Characteristics listing,
 * consisting of the (read-only) characteristic and an editable Category text box,
 * with a "delete" button that just unpairs the items.
 */
class CharPair extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.handleUnpair = this.handleUnpair.bind(this);
        this.getBaseNode = this.getBaseNode.bind(this);
        this.textFieldId = uniqueId();
        this.deleteModalId = uniqueId();
        this.state = {
            textA: "",
            textB: "",
        }

    }

    render() {
        const type = "comparison"; // Only comparison has PairedCharacteristics
        const eq_icon = this.props.charIdentifier.includes("unlike")? "fa-not-equal": "fa-equals";
        const eq_text = this.props.charIdentifier.includes("unlike")? "is different from": "is similar to";
        const category = "characteristics1/" + this.props.idA + "/category";
        const bgClass = !this.props.editing ?
            (this.props.printing? "pairing print-nobreak": "mb-2")
            : !this.props.showCategory ? "bg-autofill box" : "";
        const nonEditCategory = this.props.showCategory ?
            <React.Fragment>
                {this.props.label ?
                    <h2>{T.translate(type + "." + this.props.catIdentifier + ".singleLabel")}</h2>
                    :null}
                <div className="box bg-autofill">
                    <div className={"row"}>
                        <React.Fragment>
                            <ReadOnlyField identifier={category} className={"grouping-text"}/>
                            {this.props.printing? null:
                                <ReadOnlyField identifier={category + "/image"} className={"grouping-img"} />}
                        </React.Fragment>
                    </div>
                </div>
            </React.Fragment>
            :null;
        const imageA = false? <div className={"grouping-img"} />: null;
        const imageB = false? <div className={"grouping-img"} />: null;
        const printHeaderA = this.props.printing?
            <h3><ReadOnlyField identifier={"concept1"} className={""}/></h3>
            : null;
        const printHeaderB = this.props.printing? // printHeader inside box
            <h3><ReadOnlyField identifier={"concept2"} className={""}/></h3>
            : null;
        const textA = this.props.editing? this.state.textA
            : <div className={"row"}><div className={"grouping-text"}>{printHeaderA}{this.state.textA}</div>{imageA}</div>;
        const textB = this.props.editing? this.state.textB
            : <div className={"row"}><div className={"grouping-text"}>{printHeaderB}{this.state.textB}</div>{imageB}</div>;

        return (
            <React.Fragment>
                <div className={bgClass}>
                    {(this.props.editing && !this.props.showCategory) ?
                        <>
                            <div className={"box-row-close"}>
                                <DeleteButton tooltip="Remove pair" modalId={this.deleteModalId} icon="fa-times"
                                            canDelete={this.props.editing}/>
                            </div>
                            <DeleteModal id={this.deleteModalId} method={this.handleUnpair}
                                         text={T.translate(type + "." + this.props.charIdentifier + ".delete")}
                                         instructions={T.translate(type + "." + this.props.charIdentifier + ".deleteInstructions")}
                                         yes="Unpair"/>
                        </>
                        : null
                    }

                    {this.props.header}
                    {this.props.label?
                        <h3 className="h6">{this.props.label}</h3>
                        :null}
                    <div className="grouping grouping-2 grouping-match">
                        <div className="grouping-item">
                            {this.props.showLabel ?
                                <label className="sr-only-md-up">{T.translate(type + "." + this.props.charIdentifier + ".singleLabel")}</label>
                                : null}
                            <div className="bg-autofill box">
                                {textA}
                            </div>
                        </div>

                        {!this.props.editing || ! this.props.showCategory ?
                            <div className="grouping-icon">
                                <span className={"fa "+eq_icon} aria-hidden="true"></span>
                                <span className="sr-only">{eq_text}</span>
                            </div>
                            : null}
                        <div className="grouping-item">
                            {this.props.showLabel ?
                                <label className="sr-only-md-up">{T.translate(type + "." + this.props.charIdentifier + ".singleLabel")}</label>
                                : null}
                            <div className="bg-autofill box">
                                {textB}
                            </div>
                        </div>
                    </div>

                { this.props.showCategory ?
                    <React.Fragment>
                        <div className={"bracket bracket-2"} >
                            <div className="bracket-arrow-vertical"></div>
                            <div className="bracket-start"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-arrow"></div>
                            <div className="bracket-bar"></div>
                            <div className="bracket-end"></div>
                        </div>
                        {this.props.editing && this.props.showLabel ?
                            <label htmlFor={this.textFieldId}
                                   className="sr-only-md-up">{T.translate(type + "." + this.props.catIdentifier + ".singleLabel")}</label>
                            : null}
                        {this.props.editing ?
                            <div className="box box-primary">
                                <SttInput labelClassName={"sr-only-md-up"} label={null} addImage={true}
                                          domId={this.textFieldId} inputType={"text-area"} {...this.props}
                                          identifier={category}
                                          placeholder={T.translate(this.context.fileType + "." + this.props.catIdentifier + ".placeholder")}/>
                            </div>
                        :
                            nonEditCategory
                        }

                    </React.Fragment>
                    :null
                }
                </div>
            </React.Fragment>
        );
    }

    getBaseNode() {
        return this.context.fileType + "/" + this.context.fileId;
    }

    componentWillUnmount() {
        if (this.ARef) this.ARef.off('value', this.AWatcher);
        if (this.BRef) this.BRef.off('value', this.BWatcher);
    }

    componentDidMount() {
        if ('empty' !== this.props.pairedID) {
            this.ARef = window.firebase.database().ref(this.getBaseNode() + "/characteristics1/" + this.props.idA + "/text");
            this.BRef = window.firebase.database().ref(this.getBaseNode() + "/characteristics2/" + this.props.idB + "/text");
            this.AWatcher = this.ARef.on("value", () => {
                let firepadHeadlessA = new window.Firepad.Headless(this.ARef);
                firepadHeadlessA.getText(text => {
                    if (this.state && this.state.textA !== text) {
                        this.setState({
                            textA: text
                        });
                    }
                    firepadHeadlessA.dispose();
                });
            });

            this.BWatcher = this.BRef.on("value", () => {
                let firepadHeadlessB = new window.Firepad.Headless(this.BRef);
                firepadHeadlessB.getText(text => {
                    if (this.state && this.state.textB !== text) {
                        this.setState({
                            textB: text
                        });
                    }
                    firepadHeadlessB.dispose();
                });
            });
        }

    }

    handleUnpair() {
        console.log("Unpair");
        this.context.contentUpdated();
        // remove category, if any
        window.firebase.database().ref(this.getBaseNode() + "/characteristics1/" + this.props.idA + "/category").remove();
        //Set status value to empty and delete pairs database in realtimedb
        window.firebase.database().ref(this.getBaseNode() + "/characteristics1/" + this.props.idA + "/status").set("", () => {
            window.firebase.database().ref(this.getBaseNode() + "/characteristics2/" + this.props.idB + "/status").set("", () => {
                window.firebase.database().ref(this.getBaseNode() + "/pairs/" + this.props.charIdentifier + "/" + this.props.pairedID).remove();
            });
        });
        window.tracker.trackEvent("Content", "unpair", "");
    }

}

CharPair.propTypes = {
    idA: PropTypes.string.isRequired,
    idB: PropTypes.string.isRequired,
    pairedID: PropTypes.string.isRequired,
    showBoth: PropTypes.bool.isRequired,
    charIdentifier: PropTypes.string.isRequired,
    catIdentifier: PropTypes.string.isRequired,
    showCategory: PropTypes.bool,
};

/**
 * A radio-button option in the modal window for choosing a pair of items.
 */
class CharOption extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.getCurrentNode = this.getCurrentNode.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = {
            text: ""
        }
    }

    render() {
        return (
        <div className="form-check form-checkradio">
            <input id={this.props.id} className="form-check-input" type="radio" name={this.props.group} value={this.props.id}
                   onChange={this.onChange} checked={this.props.id === this.props.checked}/>
            <label className="form-check-label" htmlFor={this.props.id}>{this.state.text}</label>
        </div>
        );
    }

    onChange(event){
        if(this.state.text === null || this.state.text ===""){
            event  = null;
        }

        this.props.change(event);
    }

    getCurrentNode() {
        return this.props.parentNode + "/" + this.props.id + "/text";
    }

    componentWillUnmount() {
        this.ref.off('value', this.watcher);
    }

    componentDidMount() {
        this.ref = window.firebase.database().ref(this.getCurrentNode());
        this.watcher = this.ref.on("value", () => {
            let firepadHeadless = new window.Firepad.Headless(this.ref);
            firepadHeadless.getText(text => {
                if (this.state.text !== text) {
                    this.setState({
                        text: text
                    });
                }
                firepadHeadless.dispose();
            })
        })

    }
}

CharOption.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.string,
    group: PropTypes.string.isRequired,
    parentNode: PropTypes.string.isRequired
};

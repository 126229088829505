import { Component } from 'react';
import AppContext from "../AppContext";

export default class PreviewComponent extends Component {
    static contextType = AppContext;

    getRef(identifier) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + identifier;
        return window.firebase.database().ref(path);
    }

    getValuePromise(path) {
		return this.getRef(path).once("value");
    }

    getFirepadTextPromise(path) {
		return new Promise((resolve, reject) => {
            let firepadHeadless = new window.Firepad.Headless(this.getRef(path));
            firepadHeadless.getText(text => {
                firepadHeadless.dispose();
                resolve(text);
            });
		});
    }

    getFirepadMapPromise(path, key) {
		return new Promise((resolve, reject) => {
            let ref = this.getRef(path);
            ref.once("value").then(list => {
                let keys = [];
                let promises = [];
                list.forEach((item) => {
                    keys.push(item.key);
                    promises.push(this.getFirepadTextPromise(path + '/' + item.key + '/' + key));
                });
                Promise.all(promises).then((values) => {
                    let map = new Map();
                    for (let i=0; i<keys.length; i++) {
                        map.set(keys[i], values[i]);
                    }
                    resolve(map);
                });
            });
		});
    }

    getTextMapPromise(path, key) {
		return new Promise((resolve, reject) => {
            let ref = this.getRef(path);
            ref.once("value").then(list => {
                let keys = [];
                let promises = [];
                list.forEach((item) => {
                    keys.push(item.key);
                    promises.push(this.getValuePromise(path + '/' + item.key + '/' + key));
                });
                Promise.all(promises).then((values) => {
                    let map = new Map();
                    for (let i=0; i<keys.length; i++) {
                        map.set(keys[i], values[i].val());
                    }
                    resolve(map);
                });
            });
		});
    }

    mapHasContent(map) {
        if (map == null) return false;
        var found = false;
        map.forEach((value,key) =>{
           if(this.hasContent(value))
               found = true;
        });
        return found;
    }

    allMapHasContent(map) {
        if (map == null) return false;
        var found = true;
        map.forEach((value,key) =>{
           if(!this.hasContent(value))
               found = false;
        });
        return found;
    }

    hasContent(text) {
        // Return a true value if text is not null and contains something other than whitespace.
        return undefined !== text && null !== text && text.trim();
    }
}
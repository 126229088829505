import React from 'react';
import AppContext from "./AppContext";
import {Redirect} from "react-router-dom";

// This is an interstitial page that is rendered briefly while a document is being copied.
// It has the responsibility to wait for the "newFileId" to be ready (the copyDocMethod should handle
// that in the background) and then redirect to the URL of the new document.
export default class CopyDocument extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        props.copyDocMethod();
    }

    render() {
        if (this.context.newFileId) {
            let url = "/"+this.context.fileType+"/"+this.context.newFileId;
            console.log("New file is ready, redirecting to ", url);
            return <Redirect to={url} />;
        }
        let message = "Copying document...";
        return (
            <main id="main_container" className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col text-center mt-2">
                            <h1 className="h4 text-body mb-2">{message}</h1>
                        </div>
                    </div>
                </div>
            </main>
        );
    }


}
import React from 'react';

const survey = "https://forms.gle/2nQVchy3Wpao3Rxv6";

export default class GetInvolved extends React.Component {

    render() {
        return ( true? null: // TODO: check if needed
            <div className="alert alert-involved">
                <div className="alert-header row flex-between m-0">
                    <div className="alert-title">
                        <a href="#alert-msg" role="button" className="open" data-cfw="collapse">
                            Co-design with us!
                            <span className="caret" aria-hidden="true"></span>
                        </a>
                    </div>
                    <button type="button" className="close" data-cfw-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div id="alert-msg" className="alert-body">
                    <div className="row">
                        <div className="col-sm">
                            <p>
                                Are you a teacher who's interested in helping us make CORGI even better?
                                We need your expertise to shape the next version of CORGI and make these tools work best
                                for you and your students.
                                <strong>We're excited to design with you!</strong>
                            </p>
                        </div>
                        <div className="col-auto mt-1 mt-sm-0">
                            <a href={survey} className="btn btn-corgi-lg btn-corgi-c2"
                               target="_blank" rel="noopener noreferrer">
                               Get Involved
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

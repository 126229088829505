import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DeleteModal extends Component {

    constructor(props) {
        super(props);
        this.yes = (this.props.yes ? this.props.yes : "Yes, Delete");
    }

    closeModal(){
        window.speechstream.speechTools.stop();
    }

    render() {
        return (
            <div id={this.props.id} className="modal confirm-modal">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="h1 modal-title">{this.props.text}</h2>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">

                                <p>{this.props.instructions}</p>
                                <button type="submit" onClick={this.props.method} data-cfw-dismiss="modal"
                                        className="btn btn-danger me-0_5">{this.yes}</button>
                                <button type="button" data-cfw-dismiss="modal"
                                        className="btn btn-secondary ms-0_5" onClick={() => this.closeModal()}>No, Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

DeleteModal.propTypes = {
    id: PropTypes.string.isRequired,
    text : PropTypes.string.isRequired,
    // instructions: PropTypes.string.isRequired,
    yes : PropTypes.string,
    method: PropTypes.func.isRequired
};

import React from "react";
import PageLink from "./PageLink";

import imgLoginWhy0 from 'url:/public/img/loginWhy0.jpg';
import imgLoginWhy1 from 'url:/public/img/loginWhy1.jpg';
import imgLoginWhy2 from 'url:/public/img/loginWhy2.jpg';
import imgLoginWhy3 from 'url:/public/img/loginWhy3.jpg';
import imgLoginResearch from 'url:/public/img/loginResearch.jpg';
import imgLoginWhatGoogle from 'url:/public/img/loginWhatGoogle.png';
import imgLoginWhatCollab from 'url:/public/img/loginWhatCollab.png';
import imgLoginWhatUDL from 'url:/public/img/loginWhatUDL.png';
import imgLoginWhatNGSS from 'url:/public/img/loginWhatNGSS.png';
import imgLoginWhatSIM from 'url:/public/img/loginWhatSIM.png';
import imgLoginWhatPresent from 'url:/public/img/loginWhatPresent.png';
import imgLoginTeach from 'url:/public/img/loginTeach.jpg';

export default class AboutContent extends React.Component {

    render() {
        return(
            <div>
                <div className="box bg-autofill pt-1 py-md-1_5">
                    <div className="container px-0 px-md-1">
                        <div className="px-1 px-md-0">
                            <h2 id="why" className="h1">Why Corgi Works</h2>
                            <p className="lead">
                                The Corgi process supports students to develop content-specific practices and habits of mind
                            </p>
                        </div>

                        <div className="row gx-0" data-cfw="slideshow">
                            <div className="col-md-4">
                                <nav aria-label="slides">
                                    <div className="list list-spaced mb-0 px-1 px-md-0">
                                        <button type="button" className="list-item list-item-action font-weight-bold" data-cfw="tab" data-cfw-tab-target="#loginWhy0">Explore</button>
                                        <button type="button" className="list-item list-item-action font-weight-bold" data-cfw="tab" data-cfw-tab-target="#loginWhy1">Organize</button>
                                        <button type="button" className="list-item list-item-action font-weight-bold" data-cfw="tab" data-cfw-tab-target="#loginWhy2">Synthesize</button>
                                        <button type="button" className="list-item list-item-action font-weight-bold" data-cfw="tab" data-cfw-tab-target="#loginWhy3">Share</button>
                                    </div>
                                </nav>
                            </div>
                            <div className="col-md">
                                <div className="tab-content login-slides">
                                    <button type="button" className="btn btn-link btn-icon fs-3xlarge login-slides-prev" data-cfw-slideshow-nav="prev">
                                        <span className="fas fa-fw fa-chevron-left" aria-hidden="true"></span>
                                        <span className="sr-only">Previous Slide</span>
                                    </button>
                                    <button type="button" className="btn btn-link btn-icon fs-3xlarge login-slides-next" data-cfw-slideshow-nav="next">
                                        <span className="fas fa-fw fa-chevron-right" aria-hidden="true"></span>
                                        <span className="sr-only">Next Slide</span>
                                    </button>

                                    <div className="tab-pane" id="loginWhy0">
                                        <img src={imgLoginWhy0} className="img-fluid" alt="A student and teacher smiling while they work together at a computer"/>
                                        <div className="login-slide-text">
                                            Explore a topic guided by your essential question
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="loginWhy1">
                                        <img src={imgLoginWhy1} className="img-fluid" alt="A student filling out a Corgi guide on a laptop"/>
                                        <div className="login-slide-text">
                                            Organize your information with the Corgi guide, step by step
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="loginWhy2">
                                        <img src={imgLoginWhy2} className="img-fluid" alt="A young student looking engaged while working on a laptop"/>
                                        <div className="login-slide-text">
                                            Dig deep into the information and make sense of your learning
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="loginWhy3">
                                        <img src={imgLoginWhy3} className="img-fluid" alt="A student presenting at a whiteboard in front of his class while his teacher looks on"/>
                                        <div className="login-slide-text">
                                            Share your learning through a variety of output options
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-1 py-md-1_5">
                    <div className="container px-0 px-md-1">
                        <div className="row gx-0 gx-md-1">
                            <div className="col-md-6 px-1">
                                <h2 id="research" className="h1">Research shows Corgi helps students learn</h2>
                                <p className="lead">
                                    Corgi is an effective tool for learning
                                </p>
                                <p>
                                    Students, including those with learning disabilities, made significant gains learning science and U.S. history content when using Corgi.
                                </p>
                                <p>
                                    <PageLink page="faq" hash="#faq05" className="link-icon">Learn about our studies and evaluations<span className="fas fa-arrow-right" title="(external site)"></span></PageLink>
                                </p>
                                <h3>Research with us!</h3>
                                <p>
                                    We're looking for research partners to try out Corgi in the classroom.{' '}
                                    <a href="mailto:corgi@cast.org">Contact us</a> to learn more.
                                </p>
                            </div>
                            <div className="col-md-6 order-md-first">
                                <img src={imgLoginResearch} className="img-fluid" alt="Two people working on computers in a classroom"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box bg-autofill pt-1 py-md-1_5">
                    <div className="container">
                        <h2 id="features" className="h1">What makes Corgi different?</h2>
                        <p className="lead">
                            These are just some of the key features that set Corgi apart:
                        </p>

                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 gx-1">
                            <div className="col d-flex">
                                <div className="card login-card">
                                    <img src={imgLoginWhatGoogle} className="img-fluid card-img-top" alt="Google Drive and Google Classroom logos"/>
                                    <div className="card-body">
                                        Integrates with Google Drive and Classroom
                                    </div>
                                    <div className="card-footer">
                                        <a href="#technology" className="link-icon">Learn how Corgi works with Google apps<span className="fas fa-arrow-right" title="(external site)"></span></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col d-flex">
                                <div className="card login-card">
                                    <img src={imgLoginWhatCollab} className="img-fluid card-img-top" alt="Three users working together on one idea"/>
                                    <div className="card-body">
                                        Supports real-time collaboration
                                    </div>
                                    <div className="card-footer">
                                        <a href="https://youtu.be/L2JDv-T-td0" className="link-icon">Watch a remote learning demo<span className="fas fa-arrow-right" title="(external site)"></span></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col d-flex">
                                <div className="card login-card">
                                    <img src={imgLoginWhatUDL} className="img-fluid card-img-top" alt="Three regions of the brain that are activated by Universal Design for Learning: representation, engagement, and action &amp; expression"/>
                                    <div className="card-body">
                                        Embeds Universal Design for Learning (UDL) to reduce barriers and increase access
                                    </div>
                                    <div className="card-footer">
                                        <a href="https://youtu.be/YSN4stu6ZgY" className="link-icon">See a walkthrough of our support features<span className="fas fa-arrow-right" title="(external site)"></span></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col d-flex">
                                <div className="card login-card">
                                    <img src={imgLoginWhatNGSS} className="img-fluid card-img-top" alt="Next Generation Science Standards logo"/>
                                    <div className="card-body">
                                        Aligns with Next Generation Science Standards
                                    </div>
                                    <div className="card-footer">
                                        <a href="https://docs.google.com/document/d/1dAB-lNMeE-E38YOxARfWgHytGgQ0Jq9f0H16WyvXqw8/edit" className="link-icon" target="_blank" rel="noopener noreferrer">Check out a science lesson<span className="fas fa-arrow-right" title="(external site)"></span></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col d-flex">
                                <div className="card login-card">
                                    <img src={imgLoginWhatSIM} className="img-fluid card-img-top" alt="Strategic Instruction Model logo"/>
                                    <div className="card-body">
                                        Builds upon the learning strategies developed by SIM
                                    </div>
                                    <div className="card-footer">
                                        <PageLink page="faq" hash="#faq08" className="link-icon">Learn how Corgi and SIM work together<span className="fas fa-arrow-right" title="(external site)"></span></PageLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col d-flex">
                                <div className="card login-card">
                                    <img src={imgLoginWhatPresent} className="img-fluid card-img-top" alt="Google Slides and PDF logos"/>
                                    <div className="card-body">
                                        Provides multiple options for learners to interact with, express, and present their learning
                                    </div>
                                    <div className="card-footer">
                                        <a href="https://youtu.be/L2JDv-T-td0?t=655" className="link-icon">Check out the presentation builder in Corgi<span className="fas fa-arrow-right" title="(external site)"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-1 py-md-1_5">
                    <div className="container px-0 px-md-1">
                        <div className="row gx-0 gx-md-1">
                            <div className="col-md-6 px-1">
                                <h2 id="teach" className="h1">Teach with Corgi in your classroom</h2>
                                <p className="lead">
                                    Lessons created for teachers, by teachers
                                </p>
                                <p>
                                    The Corgi application includes an ever growing library of community sourced units and lessons for educators to use and adapt. So take, remix and use any unit or lesson you find, then add your own learning materials and help grow the Corgi community!
                                </p>
                                <p>
                                    <a href="https://docs.google.com/document/d/1dAB-lNMeE-E38YOxARfWgHytGgQ0Jq9f0H16WyvXqw8/edit" target="_blank" rel="noopener noreferrer">Preview a sample lesson</a> or <a href="/">create an account</a> to explore the lesson library.
                                </p>
                            </div>
                            <div className="col-md-6 order-md-first">
                                <img src={imgLoginTeach} className="img-fluid" alt="Laptop with Corgi’s teacher version showing onscreen"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box bg-autofill pt-1 py-md-1_5">
                    <div className="container">
                        <h2 id="technology" className="h1">Corgi <span className="far fa-heart" aria-label="loves"></span> Google</h2>
                        <p className="lead">
                            Corgi is built with Google technology.
                        </p>

                        <div className="row row-cols-1 row-cols-sm-2 gx-1">
                            <div className="col d-flex">
                                <div className="card login-card">
                                    <div className="card-body">
                                        <div className="media login-media">
                                            <span className="fab fa-google-drive fa-fw login-media-icon" aria-hidden="true"></span>
                                            <div className="media-body">
                                                Login with your Google account. Corgi will save your work directly to your Google Drive.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col d-flex">
                                <div className="card login-card">
                                    <div className="card-body">
                                        <div className="media login-media">
                                            <span className="fas fa-users fa-fw login-media-icon" aria-hidden="true"></span>
                                            <div className="media-body">
                                                Just like Google apps, multiple users can co-edit a Corgi Guide together.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col d-flex">
                                <div className="card login-card">
                                    <div className="card-body">
                                        <div className="media login-media">
                                            <span className="fas fa-desktop fa-fw login-media-icon" aria-hidden="true"></span>
                                            <div className="media-body">
                                                Use Google Classroom? Corgi connects directly to Classroom so teachers can create assignments and students can submit their work right in the app.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col d-flex">
                                <div className="card login-card">
                                    <div className="card-body">
                                        <div className="media login-media">
                                            <span className="fas fa-chalkboard-teacher fa-fw login-media-icon" aria-hidden="true"></span>
                                            <div className="media-body">
                                                Learners can transform their completed Corgi Guides into Google Slides presentations automatically.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-1 border-t">
                    <div className="container fs-xsmall">
                        Image attributions:
                        <ul className="list-unstyled mb-0">
                            <li>"collaborative idea" by Tomas Knopp from the Noun Project</li>
                            <li>"Monitor" by karacis studio from the Noun Project</li>
                            <li>"presentation" by Calvin Goodman from the Noun Project</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

import React, {Component} from "react";
import {navOffcanvas} from "./components/BrandBar";
import $ from "jquery";
import AppContext from "./AppContext";
import ParentFolder from "./components/ParentFolder";
import PageLink from "./components/PageLink";
import DynamicComponent from "./DynamicComponent";

export default class NavSidebar extends DynamicComponent {
    static contextType = AppContext;

    constructor() {
        super();

        this.createComparison = this.createComparison.bind(this);
        this.createCauseEffect = this.createCauseEffect.bind(this);
        this.createQuestionExp = this.createQuestionExp.bind(this);
        this.createCERGuide = this.createCERGuide.bind(this);
        this.resetData = this.resetData.bind(this);
    }

    componentDidUpdate() {
        super.componentDidUpdate();
        if (this.context.fileId) {
            const driveUrl = 'https://drive.google.com/file/d/' + this.context.fileId + '/view';
            window.gapi.sharetoclassroom.render(
                'gcAssignButton',
                {
                    'url': driveUrl,
                    'title': this.context.fileName,
                    'size': 32
                }
            );
        }
        // Dispose and re-initialize the collapse item, otherwise it can be instantiated multiple times
        $('#collapseBeginNewBtn').CFW_Collapse('dispose').CFW_Collapse();
    }

    createCauseEffect() {
        $('#' + navOffcanvas).CFW_Offcanvas('hide');
        return this.props.newDocButtonMethod("cause-effect",false);
    }

    createQuestionExp() {
        return this.props.newDocButtonMethod("question-exp",false);
    }

    createComparison() {
        return this.props.newDocButtonMethod("comparison",false);
    }

    createCERGuide() {
        return this.props.newDocButtonMethod("cera",false);
    }

    closeSidebarOffcanvas(){
        window.speechstream.speechTools.stop();
    }

    resetData() {
        let userRef = window.firebase.database().ref("user/" + this.context.currentUser.uid);
        userRef.remove();
        alert('User data cleared.');
    }

    render() {
        var currentGuide = null;
        if (this.context.fileId) {
            const share = <li>
                            <a href="#share" onClick={this.props.shareMethod}>
                                <span className="fa fa-fw fa-share-square" aria-hidden="true"></span>
                                Share this guide
                            </a>
                        </li>

            const printUrl = ["", this.context.fileType, this.context.fileId, "print"].join("/");
            const print = document.location.pathname.endsWith('print')
                ? null
                : <li>
                    <a href={printUrl} onClick={(evt) => {
                        $('#' + navOffcanvas).CFW_Offcanvas('hide');
                    }}>
                        <span className="fa fa-fw fa-print" aria-hidden="true"></span>Print preview
                    </a></li>;

            const googleClassroom = (this.context.metadata && this.context.metadata.capabilities.canShare)
                ? <li>
                    Post to:<br/>
                    <div id="gcAssignButton" className="g-sharetoclassroom"></div>
                    &nbsp;Google Classroom
                </li>
                : null;

            currentGuide =
                <li className="list-item py-0_5">
                    <div className="h5">Current Guide</div>
                    <ul className="list-unstyled">
                        <li>
                            <a href="#copy" onClick={this.props.copyButtonMethod}>
                                <span className="fa fa-fw fa-copy" aria-hidden="true"></span>
                                Make editable copy
                            </a>
                        </li>
                        {share}
                        <li>
                            <ParentFolder>
                                <span className="fab fa-fw fa-google-drive" aria-hidden="true"></span>
                                Show location in Drive
                            </ParentFolder>
                        </li>
                        {print}
                        {googleClassroom}
                    </ul>
                </li>;
        }

        const resetItem = <li className="list-item py-0_5">
            <a href="#reset" onClick={this.resetData}>Reset user data</a>
        </li>;

        return (
            <div id={navOffcanvas} className="offcanvas offcanvas-start">
                <div className="offcanvas-header">
                    <h1 className="offcanvas-title">Menu</h1>
                    <button type="button" className="close" data-cfw-dismiss="offcanvas" aria-label="Close">
                        <span className="fa fa-times" aria-hidden="true"></span>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <ul className="list list-divided">
                        {currentGuide}
                        {this.context.currentUser && <li className="list-item py-0_5">
                            <ul className="list-unstyled">
                                <li>
                                    <a href="#collapseBeginNew" id="collapseBeginNewBtn" role="button">Begin a new guide<span className="caret ps-0_25"></span></a>
                                    <div id="collapseBeginNew" className="collapse">
                                        <ul className="ps-2 py-0_5">
                                            <li><a href={"#newCause-effect"} onClick={this.createCauseEffect}>Cause &amp; Effect Guide</a></li>
                                            <li><a href={"#newQuestion-exp"} onClick={this.createQuestionExp}>Question Exploration Guide</a></li>
                                            <li><a href={"#newComparison"} onClick={this.createComparison}>Comparison Guide</a></li>
                                            <li><a href={"#newCera"} onClick={this.createCERGuide}>Claim, Evidence, Reasoning Guide</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li><PageLink page={"docs"}>My recent guides</PageLink></li>
                            </ul>
                        </li>}
                        <li className="list-item py-0_5">
                            <ul className="list-unstyled">
                                <ConditionalLink page="about" text="About Corgi" />
                                <ConditionalLink page="support" text="How to use Corgi" />
                                <ConditionalLink page="faq" text="Corgi FAQ" />
                                <ConditionalLink page="attributions" text="Attributions" />
                            </ul>
                        </li>
                        { window.developmentTools && resetItem }
                    </ul>
                </div>
            </div>
        );
    }

}

class ConditionalLink extends Component {

    render() {
        if (document.location.pathname === '/'+this.props.page) {
            return null;
        } else {
            return <li className="list-item">
                <PageLink page={this.props.page} rel="noopener noreferrer" >{this.props.text}</PageLink>
            </li>;
        }
    }

}

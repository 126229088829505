import React from 'react';
import Summary from '../steps/Comparison/Summary';
import AppContext from "../AppContext";
import {empty} from "../components/textutil";
import texts from "../texts";
import Steps from "../components/Steps";
import OrganizerComponent from "./OrganizerComponent";
import Concepts from "../steps/Comparison/Concepts";
import OverallConcept from "../steps/Comparison/OverallConcept";
import CharacteristicsStep from "../steps/Comparison/CharacteristicsStep";
import SimilarCharacteristics from "../steps/Comparison/SimilarCharacteristics";
import DissimilarCharacteristics from "../steps/Comparison/DissimilarCharacteristics";
import SimilarCategories from "../steps/Comparison/SimilarCategories";
import DissimilarCategories from "../steps/Comparison/DissimilarCategories";
import EssentialQuestion from "../steps/Comparison/EssentialQuestion";
import BigPicture from "../components/BigPicture";
import PrintPreview from "../components/PrintPreview";
import Routine from "./Routine";
import Extensions from "../steps/Comparison/Extensions";
import CreateShare from "../components/CreateShare";
import Terms from '../components/Terms';
var assert = require('assert');
var util = require('util');

export default class Comparison extends OrganizerComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.emptyState = {
            baseNode: null,
            concepts: [],
            overall: {},
            characteristicsData: {
                unlikePairedCharacteristics: {},
                likePairedCharacteristics: {},
                pairedCharacteristics1: {},
                pairedCharacteristics2: {},
                unpairedCharacteristics1: {},
                unpairedCharacteristics2: {}
            },
        }
        this.props.loadDocMethod();
        this.focused = this.focused.bind(this);
        this.state = this.emptyState;

        this.childRef = React.createRef();
    }

    componentWillUnmount() {
        if (null !== this.state.baseNode) {
            // Don't need to track every listener and detach separately since there should never be more than
            // one of this type of component - it's the whole page.
            let db = window.firebase.database();
            if (this.state.baseNode) db.ref(this.state.baseNode).off();
            if (this.char1BaseRef)   db.ref(this.char1BaseRef).off();
            if (this.char2BaseRef)   db.ref(this.char2BaseRef).off();
            if (this.likeRef)        db.ref(this.likeRef).off();
            if (this.unlikeRef)      db.ref(this.unlikeRef).off();
        }
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.context.copyPending && this.context.copyDone) {
            console.log("Should reset state");
            this.restart();
        }

        if (null === this.context.fileType || null === this.context.fileId) {
            console.error("Comparison mounted with no doc context")
        } else {
            const baseNode = this.context.fileType + "/" + this.context.fileId;
            this.setState({baseNode: baseNode});
            // listen for characteristics data
            this.sourceA = "characteristics1";
            this.sourceB = "characteristics2";
            this.char1BaseRef = baseNode + "/" + this.sourceA;
            this.char2BaseRef = baseNode + "/" + this.sourceB;
            this.likeRef = baseNode + "/pairs/likecharacteristics";
            this.unlikeRef = baseNode + "/pairs/unlikecharacteristics";
            this.fetchAndListenUnPairedItems(this.char1BaseRef, "unpairedCharacteristics1");
            this.fetchAndListenUnPairedItems(this.char2BaseRef, "unpairedCharacteristics2");
            this.fetchAndListenPairedItems(this.likeRef, "likePairedCharacteristics");
            this.fetchAndListenPairedItems(this.unlikeRef, "unlikePairedCharacteristics");
        }
    }

    fetchAndListenPairedItems(baseRef, typePair) {
        let pairedNodes = window.firebase.database().ref(baseRef);
        pairedNodes.on("value",pairList => {
            const pairedItems = pairList.val();
            if (pairedItems !== this.state.characteristicsData[typePair]) {
                let newState = this.state;
                newState.characteristicsData[typePair] = pairedItems;
                console.debug("fetchAndListenPairedItems " + typePair + ": %s", util.inspect(pairedItems));
                this.setState(newState);
            }
        });

        pairedNodes.on("child_added", data => {
            let pairedKey = data.key;
            let pairedVal = data.val();
            let char1Ref = this.char1BaseRef + "/" + pairedVal["characteristic1"];
            if ("empty" === pairedKey) {
                console.error("empty characteristics pair key was added to db");
            }
            window.firebase.database().ref(char1Ref).once("value", data => {
                let charA = data.val();
                let keyA = data.key;
                let char2Ref = this.char2BaseRef + "/" + pairedVal["characteristic2"];
                if ("" === keyA) {
                    console.error("empty paired characteristic1 key found in db");
                }
                window.firebase.database().ref(char2Ref).once("value", data => {
                    let charB = data.val();
                    let keyB = data.key;
                    let cd = this.state.characteristicsData;
                    if ("" === keyB) {
                        console.error("empty paired characteristic2 key found in db");
                    }
                    if (!cd.hasOwnProperty(typePair) || cd[typePair] === null) {
                        cd[typePair] = {};
                    }
                    cd[typePair][pairedKey] = pairedVal;
                    cd.pairedCharacteristics1[keyA] = charA;
                    cd.pairedCharacteristics2[keyB] = charB;
                    this.setState({'characteristicsData': cd});
                });
            });
        });
        pairedNodes.on("child_removed", data => {
            let pairedKey = data.key;
            let pairedVal = data.val();
            let char1Ref = this.char1BaseRef + "/" + pairedVal["characteristic1"];
            if ("empty" === pairedKey) {
                console.error("empty characteristics pair key had child removed in db");
            }
            window.firebase.database().ref(char1Ref).once("value", data => {
                let keyA = data.key;
                let char2Ref = this.char2BaseRef + "/" + pairedVal["characteristic2"];
                window.firebase.database().ref(char2Ref).once("value", data => {
                    let keyB = data.key;
                    let newState = this.state;
                    delete newState.characteristicsData[typePair][pairedKey];
                    delete newState.characteristicsData.pairedCharacteristics1[keyA];
                    delete newState.characteristicsData.pairedCharacteristics2[keyB];
                    this.setState(newState);
                })
            });
        });
    }

    fetchAndListenUnPairedItems(baseRef, typeCharacter) {
        let listItemRefs = window.firebase.database().ref(baseRef).orderByChild("status").endAt("");
        listItemRefs.on("child_added", data => {
            let newState = this.state;
            newState.characteristicsData[typeCharacter][data.key] = data.val();
            this.setState(newState);
            if(('likeCharacteristics' === this.props.fileSection) || ('unlikeCharacteristics' === this.props.fileSection))
                this.childRef.current.changeMakePairStatus();
        });
        listItemRefs.on("child_removed", data => {
            let newState = this.state;
            delete newState.characteristicsData[typeCharacter][data.key];
            this.setState(newState);
            if(('likeCharacteristics' === this.props.fileSection) || ('unlikeCharacteristics' === this.props.fileSection))
                this.childRef.current.changeMakePairStatus();
        });
        listItemRefs.on("child_changed", data => {
            let newState = this.state;
            newState.characteristicsData[typeCharacter][data.key] = data.val();
            this.setState(newState);
            if(('likeCharacteristics' === this.props.fileSection) || ('unlikeCharacteristics' === this.props.fileSection))
                this.childRef.current.changeMakePairStatus();
        })
    }

    focused(section) {
        return (!empty(section) && 'complete' !== section);
    }

    trackPageView(section){
        if('toc' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:toc');
        if('essentialquestion' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:1');
        if('terms' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:2');
        if('concepts' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:3');
        if('overall' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:4');
        if('characteristics' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:5');
        if('likeCharacteristics' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:6');
        if('likeCategories' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:7');
        if('unlikeCharacteristics' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:8');
        if('unlikeCategories' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:9');
        if('summary' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:10');
        if('extensions' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:11');
        if('complete' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:review');
        if('print' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:print-preview');
        if('create-share' === section) window.tracker.trackPageView(this.context.currentUser, 'comparison:create-share');
    }

    render() {
        if (!this.context.fileIdLoaded || null === this.state.baseNode) {
            return <div>Loading file...</div>;
        }
        // else {console.debug(util.inspect(this.state));}

        const section = this.props.fileSection;
        const doc = {
            section: section,
            type: this.context.fileType,
            id: this.context.fileId,
            title: this.context.fileName,
            editing: this.context.canEdit
        };
        // assert("comparison" === doc.type);

        const sections = texts[doc.type].sections;
        assert(undefined !== sections && 0 < sections.length);

        const toc = this.focused(doc.section) ?
            <div id={sections[0].toc} className={"section"}>
                <Steps doc={doc} steps={sections}/>
            </div>
            : "";

        const essential_question = <EssentialQuestion doc={doc} section={texts.comparison.sections[1]}/>;

        const concepts =
            <div>
                <Concepts stepnum="1" doc={doc} section={texts.comparison.sections[3]}/>
            </div>;

        const overall =
            <div id={"step2"}>
                <OverallConcept doc={doc} section={texts.comparison.sections[4]} />
            </div>;

        const characteristics =
            <CharacteristicsStep doc={doc} section={texts.comparison.sections[5]} data={this.state.characteristicsData}/>

        const like_characteristics =
            <SimilarCharacteristics ref={this.childRef} doc={doc} section={texts.comparison.sections[6]} data={this.state.characteristicsData}/>;

        const unlike_characteristics =
            <DissimilarCharacteristics ref={this.childRef} doc={doc} section={texts.comparison.sections[8]} data={this.state.characteristicsData}/>;

        const like_categories =
            <SimilarCategories doc={doc} section={texts.comparison.sections[7]} data={this.state.characteristicsData}/>;

        const unlike_categories =
            <DissimilarCategories doc={doc} section={texts.comparison.sections[9]} data={this.state.characteristicsData}/>;

        const summary =
            <Summary doc={doc} section={texts.comparison.sections[10]}/>;

        const extensions =
            <Extensions doc={doc} section={texts.comparison.sections[11]}/>;

        const print_preview =
            <PrintPreview doc={doc} section={"print"} data={this.state.characteristicsData}/>;

        const createShare = <CreateShare shareMethod={this.props.shareMethod}/>;

        const terms = <Terms doc={doc} tooltipTitle={true} section={texts.comparison.sections[2]}/>;

        this.trackPageView(section);
        const content = this.focused(section) ?
            ( 'toc' === section ? toc
            : 'essentialquestion' === section ? essential_question
            : 'terms' === section ? terms
            : 'concepts' === section ? concepts
            : 'overall' === section ? overall
            : 'characteristics' === section ? characteristics
            : 'likeCharacteristics' === section ? like_characteristics
            : 'likeCategories' === section ? like_categories
            : 'unlikeCharacteristics' === section ? unlike_characteristics
            : 'unlikeCategories' === section ? unlike_categories
            : 'summary' === section ? summary
            : 'extensions' ===  section ? extensions
            : 'complete' === section ? <BigPicture section={section} data={this.state.characteristicsData}  editing={false}/>
            : 'print' === section ? print_preview
            : 'create-share' === section ? createShare
            : toc
            )

            : (
            <BigPicture section={"complete"} data={this.state.characteristicsData} editing={false}/>
            );

        return <Routine content={content} doc={doc} data={this.state.characteristicsData} {...this.props} />

    }

}

import React, {Component, Fragment} from 'react';
import AppContext from "../AppContext";
import $ from 'jquery';

export const onboardInit = (selector) =>{
    if (selector) {
        var tip_control = $(selector);
        var tip_placement = tip_control.attr('data-cfw-popover-placement');
        tip_control.CFW_Popover({
            container: '#onboarding',
            viewport: 'window',
            trigger: 'manual',
            placement: tip_placement ? tip_placement : 'bottom auto',
            popperConfig: {
                positionFixed: true
            }
        });
        tip_control.on('afterShow.cfw.popover', function() {
            tip_control[0].scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center"
            });
        });
    }
}

export const openTourGuide = (uid) =>{
    console.log("in openTourGuide uid = " + uid);
    var $curr = $(document).find('.onboard-popover:visible');
    var selector = "#onboard0";
    var $next = $(selector);

    if ($curr.length) {
        // Wait until hide animation is complete before calling show
        $curr.CFW_Popover('hide').CFW_transition(null, function() {
            $next.CFW_Popover('show');
            $next[0].focus();
        });
    } else {
        $next.CFW_Popover('show');$next[0].focus();
    }

    var userNode = "user/" + uid ;
    let userRef = window.firebase.database().ref(userNode);
    userRef.update({viewTourGuide: "true"},() =>{
        console.debug('Update user set viewTourGuide is true');
    })
    return false;
}

export default class TourGuide extends Component{
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {recognitionSupported: true};
    }

    onboardStt(selector) {
        var $btnStt = $('.stt-btn');
        var $btnSttFirst = $('.stt-btn').first();

        $btnStt.each(function() {
            var $this = $(this);
            if (!$this.data('cfw.popover')) { return; }
            $this.removeAttr('data-cfw-popover-target');
        });

        $btnSttFirst.attr('data-cfw-popover-target', selector);
        onboardInit($btnSttFirst);
        this.onboardChain(selector);
    }

    onboardChain(selector) {
        window.speechstream.speechTools.stop();
        var $curr = $(document).find('.onboard-popover:visible');
        var $next = $(selector);

        if ($curr.length) {
            // Wait until hide animation is complete before callling show
            $curr.CFW_Popover('hide').CFW_transition(null, function() {
                $next.CFW_Popover('show');
                $next[0].focus();
            });
        } else {
            $next.CFW_Popover('show');
            $next[0].focus();
        }

        return false;
    }

    componentDidMount(){
        var recognitionSupported = this.context.recognition && this.context.recognition.isSupported();
        console.log("recognitionSupported = " + recognitionSupported);
        this.setState({recognitionSupported: recognitionSupported});
    }

    render() {
        let canEdit = this.context.canEdit;

        return(
            <Fragment>
                <div id="onboard0" className="popover onboard-popover has-corgi">
                    <button type="button" className="close" data-cfw-dismiss="popover" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h3 className="popover-header">Welcome to Corgi!</h3>
                    <div className="popover-body">
                        <p>
                            Every Corgi Guide has lots of built-in tools.
                            Want to check some out?
                        </p>

                        <div className="corgi-offset">
                            <div className="row flex-between flex-items-center">
                                <div className="col-6">
                                    <button type="button" className="btn btn-link font-weight-normal" data-cfw-dismiss="popover">Not now</button>
                                </div>
                                <div className="col-6 text-end">
                                    <button onClick={this.onboardChain.bind(this,"#onboard1")} type="button" className="btn btn-light">Next <span className="fa fa-chevron-right" aria-hidden="true"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popover-arrow"></div>
                </div>

                <div id="onboard1" className="popover onboard-popover">
                    <button type="button" className="close" data-cfw-dismiss="popover" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h3 className="popover-header">Texthelp Toolbar</h3>
                    <div className="popover-body">
                        <p>
                            The TextHelp Toolbar lets you:
                        </p>
                        <ul className="list-unstyled mt-n1 mb-0">
                            <li>
                                <span className="fa-stack-custom" role="img" title="play button">
                                    <span className="fa fa-circle fa-stack-1x text-active" aria-hidden="true"></span>
                                    <span className="fa fa-play fa-stack-sm text-white" aria-hidden="true"></span>
                                </span>
                                <span className="fa-stack-custom" role="img" aria-label="stop button">
                                    <span className="fa fa-circle fa-stack-1x text-active" aria-hidden="true"></span>
                                    <span className="fa fa-stop fa-stack-sm text-white" aria-hidden="true"></span>
                                </span>
                                hear text read aloud
                            </li>
                            <li>
                                <span className="fa-stack-custom" aria-hidden="true"></span>
                                <span className="fa-stack-custom" role="img" aria-label="dictionary button">
                                    <span className="fa fa-circle fa-stack-1x text-active" aria-hidden="true"></span>
                                    <span className="fa fa-book fa-stack-sm text-white" aria-hidden="true"></span>
                                </span>
                                look up words with the dictionary
                            </li>
                            <li>
                                <span className="fa-stack-custom" aria-hidden="true"></span>
                                <span className="fa-stack-custom" role="img" aria-label="translate button" >
                                    <span className="fa fa-circle fa-stack-1x text-active" aria-hidden="true"></span>
                                    <span className="fa fa-language fa-stack-sm text-white" aria-hidden="true"></span>
                                </span>
                                translate text into Spanish
                            </li>
                        </ul>
                        <p>
                            <em>Highlight some text to try it out!</em>
                        </p>

                        <div className="row flex-between flex-items-center">
                            <div className="col-4">
                                <button onClick={this.onboardChain.bind(this,"#onboard0")} type="button" className="btn btn-link font-weight-normal ms-n0_5"><span aria-hidden="true">&lt;</span>Previous</button>
                            </div>
                            <div className="col-4 text-center onboard-dots" aria-hidden="true">
                                <span className="fa fa-circle active"></span>&nbsp;
                                {this.state.recognitionSupported && canEdit ? <span className="fa fa-circle">&nbsp;</span> : null}
                                <span className="fa fa-circle"></span>&nbsp;
                                <span className="fa fa-circle"></span>
                            </div>
                            <div className="col-4 text-end">
                                {this.state.recognitionSupported && canEdit ?
                                    <button onClick={this.onboardStt.bind(this,"#onboard2")} type="button" className="btn btn-light">Next <span className="fa fa-chevron-right" aria-hidden="true"></span></button>
                                    :<button onClick={this.onboardChain.bind(this,"#onboard3")} type="button" className="btn btn-light">Next <span className="fa fa-chevron-right" aria-hidden="true"></span></button>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="popover-arrow"></div>
                </div>

                <div id="onboard2" className="popover onboard-popover">
                    <button type="button" className="close" data-cfw-dismiss="popover" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h3 className="popover-header">Speech to Text</h3>
                    <div className="popover-body">
                        <p>
                            You can speak your response directly into a text box.
                            <br/>
                            <em>
                                Select the microphone
                                &nbsp;<span className="fa fa-microphone text-primary" role="img" aria-label="microphone button"></span>&nbsp;
                                to give it a try!
                            </em>
                        </p>

                        <div className="row flex-between flex-items-center">
                            <div className="col-4">
                                <button onClick={this.onboardChain.bind(this,"#onboard1")} type="button" className="btn btn-link font-weight-normal ms-n0_5"><span aria-hidden="true">&lt;</span>Previous</button>
                            </div>
                            <div className="col-4 text-center onboard-dots" aria-hidden="true">
                                <span className="fa fa-circle"></span>&nbsp;
                                <span className="fa fa-circle active"></span>&nbsp;
                                <span className="fa fa-circle"></span>&nbsp;
                                <span className="fa fa-circle"></span>
                            </div>
                            <div className="col-4 text-end">
                                <button onClick={this.onboardChain.bind(this,"#onboard3")} type="button" className="btn btn-light">Next <span className="fa fa-chevron-right" aria-hidden="true"></span></button>
                            </div>
                        </div>
                    </div>
                    <div className="popover-arrow"></div>
                </div>

                <div id="onboard3" className="popover onboard-popover">
                    <button type="button" className="close" data-cfw-dismiss="popover" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h3 className="popover-header">Full Guide View</h3>
                    <div className="popover-body">
                        <p>
                            Expand the panel to review the entire guide or hide it to focus on one step at a time.
                            <br/>
                            <em>
                                Press the
                                &nbsp;<span className="d-inline-block btn btn-light py-0 px-0_25 pe-none" role="img" aria-label="full routine button">
                                    <span className="fa fa-angle-double-right text-primary font-size-small" aria-hidden="true"></span>
                                </span>&nbsp;
                                button to try it out!
                            </em>
                        </p>

                        <div className="row flex-between flex-items-center">
                            <div className="col-4">
                                {this.state.recognitionSupported && canEdit ?
                                    <button onClick={this.onboardStt.bind(this,"#onboard2")} type="button" className="btn btn-link font-weight-normal ms-n0_5"><span aria-hidden="true">&lt;</span>Previous</button>
                                    : <button onClick={this.onboardChain.bind(this,"#onboard1")} type="button" className="btn btn-link font-weight-normal ms-n0_5"><span aria-hidden="true">&lt;</span>Previous</button>
                                }
                            </div>
                            <div className="col-4 text-center onboard-dots" aria-hidden="true">
                                <span className="fa fa-circle"></span>&nbsp;
                                {this.state.recognitionSupported && canEdit ? <span className="fa fa-circle">&nbsp;</span> : null}
                                <span className="fa fa-circle active"></span>&nbsp;
                                <span className="fa fa-circle"></span>
                            </div>
                            <div className="col-4 text-end">
                                <button onClick={this.onboardChain.bind(this,"#onboard4")} type="button" className="btn btn-light">Next <span className="fa fa-chevron-right" aria-hidden="true"></span></button>
                            </div>
                        </div>
                    </div>
                    <div className="popover-arrow"></div>
                </div>
                <div id="onboard4" className="popover onboard-popover">
                    <button type="button" className="close" data-cfw-dismiss="popover" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h3 className="popover-header">Share or Copy</h3>
                    <div className="popover-body">
                        <p>
                            Use the menu to share, copy, or print your Guide.  You can also locate your Guide in your Google Drive.
                            <br/>
                            <em>
                                Select the
                                &nbsp;<span className="fa fa-bars fs-large" role="img" aria-label="menu button"></span>&nbsp;
                                button to see your options!
                            </em>
                        </p>

                        <div className="row flex-between flex-items-center">
                            <div className="col-4">
                                <button type="button" onClick={this.onboardChain.bind(this,"#onboard3")} className="btn btn-link font-weight-normal ms-n0_5"><span aria-hidden="true">&lt;</span>Previous</button>
                            </div>
                            <div className="col-4 text-center onboard-dots" aria-hidden="true">
                                <span className="fa fa-circle"></span>&nbsp;
                                {this.state.recognitionSupported && canEdit ? <span className="fa fa-circle">&nbsp;</span> : null}
                                <span className="fa fa-circle"></span>&nbsp;
                                <span className="fa fa-circle active"></span>
                            </div>
                            <div className="col-4 text-end">
                                <button type="button" onClick={this.onboardChain.bind(this,"#onboard5")} className="btn btn-light">Next <span className="fa fa-chevron-right" aria-hidden="true"></span></button>
                            </div>
                        </div>
                    </div>
                    <div className="popover-arrow"></div>
                </div>
                <div id="onboard5" className="popover onboard-popover">
                    <button type="button" className="close" data-cfw-dismiss="popover" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h3 className="popover-header">We're here for you!</h3>
                    <div className="popover-body">
                        <p>
                            Retake the tour anytime by pressing the paw.
                            Visit <a href="/support">How to use Corgi</a> for more help.
                        </p>

                        <div className="row flex-between flex-items-center">
                            <div className="col-6">
                                <button type="button" onClick={this.onboardChain.bind(this,"#onboard4")} className="btn btn-link font-weight-normal ms-n0_5"><span aria-hidden="true">&lt;</span>Previous</button>
                            </div>
                            <div className="col-6 text-end">
                                <button type="button" className="btn btn-light" data-cfw-dismiss="popover">End</button>
                            </div>
                        </div>
                    </div>
                    <div className="popover-arrow"></div>
                </div>
                <div id="onboarding" className="onboarding" aria-live="assertive" aria-atomic="false">
                </div>
            </Fragment>
        )
    }
}

import React, { Fragment } from "react";
import ReadOnlyField from "../../ReadOnlyField";
import Causes from "./Causes";
import Effects from "./Effects";
import texts from "../../texts";
import KeyTerms from "../KeyTerms";
import Event from "./Event";
import ReadOnlyText from "../../components/ReadOnlyText";
import EmptyField from "../../components/EmptyField";
import Answer from "./Answer";
import AppContext from "../../AppContext";
import { Link } from "react-router-dom";
import PreviewComponent from "../../components/PreviewComponent";

export const question = (doc, fieldStatus, inCompleteStep) => {
    const routineText = texts[doc.type];
    const noImage = ['print'].includes(doc.section);

    const section = routineText.question;
    return (<Fragment>
        <div className="section">
            <h2>Topic</h2>
            {!inCompleteStep
                ? <ReadOnlyField identifier={"topic"} className="box box-autofill" />
                : fieldStatus.hasTopic
                    ?
                    <ReadOnlyField identifier={"topic"} className="box box-autofill" />
                    :
                    <EmptyField identifier={"topic"} className="box box-unfilled" />
            }
        </div>

        <div className="section">
            <h2>{section.label}</h2>
            <div className="grouping grouping-1">
                <div className="grouping-item">
                    {!inCompleteStep
                        ?
                        <div className="box box-autofill">
                            <div className="row">
                                <ReadOnlyField identifier={"question"} className={'grouping-text'} />
                                {noImage ? null :
                                    <ReadOnlyField identifier={"question/image"} className={'grouping-img'} />
                                }
                            </div>
                        </div>
                        : fieldStatus.hasEssentialQuestion ?
                            <div className="box box-autofill">
                                <div className="row">
                                    <ReadOnlyField identifier={"question"} className={'grouping-text'} />
                                    {noImage ? null :
                                        <ReadOnlyField identifier={"question/image"} className={'grouping-img'} />
                                    }
                                </div>
                            </div>
                            :
                            <EmptyField identifier={"question"} className="box bg-unfilled" />
                    }
                </div>
            </div>
        </div>
    </Fragment>
    )
}

export const causes_event_effects = (doc, fieldStatus, inCompleteStep) => {
    const routineText = texts[doc.type];
    const noImage = ['print'].includes(doc.section);
    const noStepNum = ['complete', 'print'].includes(doc.section);
    const section = noStepNum ? routineText.cause : routineText.sections[4];
    const causeGrouping = fieldStatus.hasCauses ? fieldStatus.numberCauses : 1;
    const effectGrouping = fieldStatus.hasEffects ? fieldStatus.numberEffects : 1;
    return (
        <div className="section">
            <h2>Causes, Main Event, &amp; Effects</h2>
            <div className={"grouping grouping-" + causeGrouping}>
                <h3 className="grouping-title">Causes of the Main Event</h3>
                {!inCompleteStep ?
                    <Causes doc={doc} section={section} editing={false} noImage={noImage} inSteps={true} />
                    : fieldStatus.hasCauses ?
                        <Causes doc={doc} section={section} editing={false} noImage={noImage} inSteps={true} />
                        :
                        <div className="grouping-item" >
                            <EmptyField identifier={"cause"} className="box box-unfilled" />
                        </div>
                }

            </div>

            {!fieldStatus.hasCauses ?
                <div className="bracket">
                    <div className="bracket-arrow-vertical"></div>
                </div>
                : null
            }

            {fieldStatus.hasCauses && fieldStatus.numberCauses === 1 ?
                <div className="bracket">
                    <div className="bracket-arrow-vertical"></div>
                </div>
                : null
            }

            {fieldStatus.hasCauses && fieldStatus.numberCauses === 2 ?
                <div className="bracket bracket-2">
                    <div className="bracket-arrow-vertical"></div>
                    <div className="bracket-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-arrow"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-end"></div>
                </div>
                : null
            }

            {fieldStatus.hasCauses && fieldStatus.numberCauses === 3 ?
                <div className="bracket bracket-3">
                    <div className="bracket-arrow-vertical"></div>
                    <div className="bracket-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-bar-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-arrow"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-end"></div>
                </div>
                : null
            }

            {fieldStatus.hasCauses && fieldStatus.numberCauses === 4 ?
                <div className="bracket bracket-4">
                    <div className="bracket-arrow-vertical"></div>
                    <div className="bracket-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-bar-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-arrow"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-bar-end"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-end"></div>
                </div>
                : null
            }

            {!inCompleteStep ?
                <ReadOnlyText identifier={"causes-event/connector"} className={"box connector box-autofill"} />
                : fieldStatus.hasCauseEventConnector ?
                    <ReadOnlyText identifier={"causes-event/connector"} className={"box connector box-autofill"} />
                    :
                    <EmptyField identifier={"causes-event"} className="box connector box-unfilled" />
            }

            <div className="bracket">
                <div className="bracket-arrow-vertical"></div>
            </div>

            <div className="grouping grouping-1">
                <h3 className="grouping-title">The Main Event</h3>
                <div className={"grouping-item"}>
                    {!inCompleteStep ?
                        <Event doc={doc} section={routineText.event} editing={false} noImage={noImage} />
                        : fieldStatus.hasEvent ?
                            <Event doc={doc} section={routineText.event} editing={false} noImage={noImage} />
                            :
                            <EmptyField identifier={"event"} className="box box-unfilled" />
                    }

                </div>
            </div>
            <div className="bracket">
                <div className="bracket-arrow-vertical"></div>
            </div>
            {!inCompleteStep ?
                <ReadOnlyText identifier={"event-effects/connector"} className={"box connector box-autofill"} />
                : fieldStatus.hasEffectEventConnector ?
                    <ReadOnlyText identifier={"event-effects/connector"} className={"box connector box-autofill"} />
                    :
                    <EmptyField identifier={"event-effects"} className="box connector box-unfilled" />
            }

            {!fieldStatus.hasEffects ?
                <div className="bracket">
                    <div className="bracket-arrow-vertical"></div>
                </div>
                : null
            }

            {fieldStatus.hasEffects && fieldStatus.numberEffects === 1 ?
                <div className="bracket">
                    <div className="bracket-arrow-vertical"></div>
                </div>
                : null
            }

            {fieldStatus.hasEffects && fieldStatus.numberEffects === 2 ?
                <div className="bracket bracket-down bracket-2">
                    <div className="bracket-arrow-vertical"></div>
                    <div className="bracket-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-arrow"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-end"></div>
                </div>
                : null
            }

            {fieldStatus.hasEffects && fieldStatus.numberEffects === 3 ?
                <div className="bracket bracket-down bracket-3">
                    <div className="bracket-arrow-vertical"></div>
                    <div className="bracket-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-bar-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-arrow"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-end"></div>
                </div>
                : null
            }

            {fieldStatus.hasEffects && fieldStatus.numberEffects === 4 ?
                <div className="bracket bracket-down bracket-4">
                    <div className="bracket-arrow-vertical"></div>
                    <div className="bracket-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-bar-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-arrow"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-bar-end"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-end"></div>
                </div>
                : null
            }

            <div className={"grouping grouping-" + effectGrouping}>
                <h3 className="grouping-title">Effects of the Main Event</h3>
                {!inCompleteStep ?
                    <Effects doc={doc} section={routineText.effect} editing={false} noImage={noImage} inSteps={true}/>
                    : fieldStatus.hasEffects ?
                        <Effects doc={doc} section={routineText.effect} editing={false} noImage={noImage} inSteps={true} />
                        :
                        <div className="grouping-item" >
                            <EmptyField identifier={"effect"} className="box box-unfilled" />
                        </div>
                }

            </div>
        </div>
    )
}

export const answer = (doc, fieldStatus, inCompleteStep) => {
    const routineText = texts[doc.type];

    const noStepNum = ['complete', 'print'].includes(doc.section);
    const section = noStepNum ? routineText.answer : routineText.sections[7];
    return (
        <div className="section">
            <h2 className="h2" >Summary</h2>
            {!inCompleteStep ?
                <Answer doc={doc} section={section} editing={false} />
                : fieldStatus.hasAnswer ?
                    <Answer doc={doc} section={section} editing={false} />
                    :
                    <EmptyField identifier={"answer"} className="box bg-unfilled" />
            }
        </div>
    )
}

export const extensions = (doc) => {
    const ext = <Fragment>
        <div className={"box box-autofill"} >
            <div className={"row"}>
                <ReadOnlyField identifier={"extensions"} className={"grouping-text"} />
                {'print' === doc.section ? null :
                    <ReadOnlyField identifier={"extensions/image"} className={"grouping-img"} />}
            </div>
        </div>
    </Fragment>

    return (
        <div className="section">
            <h2 className="h2">Extensions</h2>
            <Fragment>{ext}</Fragment>
        </div>
    )
}
    ;

export const keyTerms = (doc, fieldStatus, inCompleteStep) => {
    const section = texts[doc.type].terms;
    return (
        <div className="section">
            <h2>{section.title ? section.title : section.label}</h2>
            {!inCompleteStep ?
                <KeyTerms doc={doc} section={section} editing={false} secondary={true} />
                : fieldStatus.hasKeyTerms ?
                    <KeyTerms doc={doc} section={section} editing={false} secondary={true} />
                    :
                    <EmptyField identifier={"terms"} className="box bg-unfilled" />
            }
        </div>
    )
}

export const review_checklist = (doc, fieldStatus, closeChecklist, onClickFunc) => {
    return (
        fieldStatus.display ?
            <div className="section">
                <h3 className="h3">Review Checklist</h3>

                <div className="box box-unfilled">
                    <div className="box-row-close">
                        <button type="button" className="btn btn-icon btn-link" aria-label="Remove key term" title="Remove Checklist" onClick={closeChecklist}>
                            <span className="fa fa-times text-primary" aria-hidden="true"></span>
                        </button>
                    </div>
                    <ul>
                        {!fieldStatus.hasTopic ? <li>You have not entered a <b>Topic</b>. Go to <Link to="question" onClick={() => onClickFunc(1)}>Step 1</Link> to complete the section.</li> : null}
                        {!fieldStatus.hasEssentialQuestion ? <li>You have not entered an <b>Essential Question</b>. Go to <Link to="question" onClick={() => onClickFunc(1)}>Step 1</Link> to complete the section.</li> : null}
                        {!fieldStatus.hasKeyTerms ? <li>You have not entered any <b>Key Terms</b>. Go to <Link to="terms" onClick={() => onClickFunc(2)}>Step 2</Link> to define Key Terms</li> : null}
                        {!fieldStatus.hasEvent ? <li>You have not entered a <b>Main Event</b>. Go to <Link to="event" onClick={() => onClickFunc(3)}>Step 3</Link> to complete the section.</li> : null}
                        {!fieldStatus.hasCauses ? <li>You have not entered any <b>Causes of the Main Event</b>. Go to <Link to="causes" onClick={() => onClickFunc(4)}>Step 4</Link> to complete the section.</li> : null}
                        {!fieldStatus.hasCauseEventConnector ? <li>You have not entered a <b>Relationship</b> between the Cause and the Main Event. Go to <Link to="causes-event" onClick={() => onClickFunc(5)}>Step 5</Link> to complete the section.</li> : null}
                        {!fieldStatus.hasEffects ? <li>You have not entered any <b>Effects of the Main Event</b>. Go to <Link to="effects" onClick={() => onClickFunc(6)}>Step 6</Link> to complete the section.</li> : null}
                        {!fieldStatus.hasEffectEventConnector ? <li>You have not entered a <b>Relationship</b> between the Main Event and the Effects. Go to <Link to="event-effects" onClick={() => onClickFunc(7)}>Step 7</Link> to complete the section.</li> : null}
                        {!fieldStatus.hasAnswer ? <li>You have not entered a <b>Summary</b>. Go to <Link to="summary" onClick={() => onClickFunc(8)}>Step 8</Link> to complete the section.</li> : null}
                    </ul>
                </div>
            </div>
            : null
    )
}

export default class CauseEffectSteps extends PreviewComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            display: false,
            hasTopic: false,
            hasEssentialQuestion: false,
            hasKeyTerms: false,
            hasMainEvent: false,
            hasCauses: false,
            hasCauseEventConnector: false,
            hasEffects: false,
            hasEffectEventConnector: false,
            hasAnswer: false,
            numberCauses: 0,
            numberEffects: 0
        };
        this.closeChecklist = this.closeChecklist.bind(this);
        this.checkEmptyList = this.checkEmptyList.bind(this);
        this.mapHasContent = this.mapHasContent.bind(this);
    }

    closeChecklist() {
        this.setState({ display: false });
    }

    checkEmptyField(field) {
        this.getFirepadTextPromise(field).then((data) => {
            let hasContent = this.hasContent(data);
            if ("topic" === field) this.setState({ hasTopic: hasContent });
            if ("question" === field) this.setState({ hasEssentialQuestion: hasContent });
            if ("event" === field) this.setState({ hasEvent: hasContent });
            if ("answer" === field) this.setState({ hasAnswer: hasContent });
            this.updateChecklist();
        });
    }

    updateChecklist() {
        let showChecklist = false;
        if (!this.state.hasTopic) showChecklist = true;
        if (!this.state.hasEssentialQuestion) showChecklist = true;
        if (!this.state.hasKeyTerms) showChecklist = true;
        if (!this.state.hasEvent) showChecklist = true;
        if (!this.state.hasCauses) showChecklist = true;
        if (!this.state.hasCauseEventConnector) showChecklist = true;
        if (!this.state.hasEffects) showChecklist = true;
        if (!this.state.hasEffectEventConnector) showChecklist = true;
        if (!this.state.hasAnswer) showChecklist = true;

        this.setState({
            display: showChecklist
        })
    }

    checkEmptyConnector(field) {
        this.getValuePromise(field + "/connector").then((data) => {
            let hasContent = this.hasContent(data.val());
            if ("causes-event" === field) this.setState({ hasCauseEventConnector: hasContent });
            if ("event-effects" === field) this.setState({ hasEffectEventConnector: hasContent });
            this.updateChecklist();
        });
    }

    getCauseEffectMapsPromise() {
        let proms = [
            this.getFirepadMapPromise('terms', 'def'),
            this.getFirepadMapPromise('terms', 'term'),
            this.getFirepadMapPromise('cause', 'text'),
            this.getFirepadMapPromise('effect', 'text'),
        ];
        return Promise.all(proms)
            .then((maps) => {
                return {
                    'termsdef': maps[0],
                    'termsword': maps[1],
                    'cause': maps[2],
                    'effect': maps[3]
                };
            });
    }

    checkEmptyList() {
        this.getCauseEffectMapsPromise().then((valueMaps) => {
            if (valueMaps != null) {
                var hasTermOrDef = this.mapHasContent(valueMaps['termsword'])
                    || this.mapHasContent(valueMaps['termsdef']);
                let update = {
                    hasKeyTerms: hasTermOrDef,
                    hasCauses: this.mapHasContent(valueMaps['cause']),
                    hasEffects: this.mapHasContent(valueMaps['effect']),
                    numberCauses: valueMaps['cause'].size,
                    numberEffects: valueMaps['effect'].size
                };
                this.setState(update);
                this.updateChecklist();
            }
        });
    }

    componentDidMount() {
        for (let field of ['topic', 'question', 'event', 'answer']) {
            this.checkEmptyField(field);
        }
        for (let field of ['causes-event', 'event-effects']) {
            this.checkEmptyConnector(field);
        }
        this.checkEmptyList();
    }

    onClickFunc(step) {
        window.tracker.trackEvent("Navigation", "checklist link", step);
    }

    render() {
        return <Fragment>
            {this.props.inCompleteStep && this.context.canEdit ? review_checklist(this.props.doc, this.state, this.closeChecklist, this.onClickFunc) : null}
            {question(this.props.doc, this.state, this.props.inCompleteStep)}
            {keyTerms(this.props.doc, this.state, this.props.inCompleteStep)}
            {causes_event_effects(this.props.doc, this.state, this.props.inCompleteStep)}
            {answer(this.props.doc, this.state, this.props.inCompleteStep)}
            {extensions(this.props.doc)}
        </Fragment>
    }
}

import React, { Fragment } from "react";
import ReadOnlyField from "../../ReadOnlyField";
import texts from "../../texts";
import KeyTerms from "../KeyTerms";
import PreviewComponent from "../../components/PreviewComponent";
import AppContext from "../../AppContext";
import {Link} from "react-router-dom";
import EmptyField from "../../components/EmptyField";
import EvidenceList from "../../components/EvidenceList";
import ReadOnlyText from "../../components/ReadOnlyText";
import T from 'i18n-react';

export const essentialquestion = (doc,fieldStatus,inCompleteStep) =>{
    return(
        <React.Fragment>
            <div className="section">
                <h2>Topic</h2>
                {!inCompleteStep ?
                    <ReadOnlyField identifier="topic" className="box bg-autofill" />
                    : fieldStatus.hasTopic ?
                        <ReadOnlyField identifier="topic" className="box bg-autofill" />
                        :
                        <EmptyField identifier={"topic"} className="box bg-unfilled"/>
                }
            </div>

            <div className="section">
                <h2>{T.translate("question-exp.essentialquestion.title")}</h2>
                {!inCompleteStep ?
                    <div className={"box bg-autofill"} >
                        <div className={"row"}>
                            <ReadOnlyField identifier="essentialquestion" className={"grouping-text"} />
                            <ReadOnlyField identifier="essentialquestion/image" className={"grouping-img"} />
                        </div>
                    </div>
                    : fieldStatus.hasEssentialQuestion ?
                        <div className={"box bg-autofill"} >
                            <div className={"row"}>
                                <ReadOnlyField identifier="essentialquestion" className={"grouping-text"} />
                                <ReadOnlyField identifier="essentialquestion/image" className={"grouping-img"} />
                            </div>
                        </div>
                        : <EmptyField identifier={"essentialquestion"} className="box bg-unfilled"/>
                }
            </div>
        </React.Fragment>
    )
}

export const keyTerms = (doc,fieldStatus,inCompleteStep) => {
    const section = texts[doc.type].terms;
    return (
            <div className="section">
                <h2>{section.title ? section.title : section.label}</h2>
                {!inCompleteStep ?
                    <KeyTerms doc={doc} section={section} editing={false} secondary={true}/>
                        : fieldStatus.hasKeyTerms ?
                            <KeyTerms doc={doc} section={section} editing={false} secondary={true}/>
                            :
                            <EmptyField identifier={"terms"} className="box bg-unfilled"/>
                }
            </div>
    )
}

export const evidence = (doc,fieldStatus,inCompleteStep) => {
    // const section = texts[doc.type].terms;
    return (
        <div className="section">
            <h2>Evidence</h2>
            {/* <h3>Supporting Evidence</h3> */}
            {!inCompleteStep ?
                <EvidenceList doc={doc}  editing={false} identifier="evidencesupport"  canBeEmpty={false} />
                : fieldStatus.hasEvidenceSupport ?
                    <EvidenceList doc={doc} inCompleteStep={inCompleteStep} qualityTypeMaps={fieldStatus.valueMaps['supportqualitytype']} qualityNoteMaps={fieldStatus.valueMaps['supportqualitynote']}
                      editing={false} identifier="evidencesupport"  canBeEmpty={false} hLevel={3} />
                    : <EmptyField identifier={"evidencesupport"} className="box bg-unfilled"/>
            }
            <hr />
            {/* <h3>Opposing Evidence</h3> */}
            {!inCompleteStep ?
                <EvidenceList doc={doc}  editing={false} identifier="evidenceopposes"  canBeEmpty={false} />
                : fieldStatus.hasEvidenceOpposes ?
                    <EvidenceList doc={doc} inCompleteStep={inCompleteStep} qualityTypeMaps={fieldStatus.valueMaps['opposesqualitytype']}
                    qualityNoteMaps={fieldStatus.valueMaps['opposesqualitynote']}  editing={false} identifier="evidenceopposes"  canBeEmpty={false} hLevel={3} />
                    : <EmptyField identifier={"evidenceopposes"} className="box bg-unfilled"/>
            }
        </div>
    )
}

export const reasoning = (doc,fieldStatus,inCompleteStep) => {
    const reasoningHtml =<Fragment>
        <div className={"box bg-autofill"} >
                <div className={"row"}>
                    <ReadOnlyText identifier="reasoningtype" className={"grouping-text"}/>
                </div>
            </div>
        <div className={"box bg-autofill"} >
            <div className={"row"}>
                <ReadOnlyField identifier="reasoningnote" className={"grouping-text"} />
                <ReadOnlyField identifier="reasoningnote/image" className={"grouping-img"} />
            </div>
        </div>
    </Fragment>

    return (
        <div className="section">
            <h2>Reasoning</h2>

            {!inCompleteStep ?
                <Fragment>{reasoningHtml}</Fragment>
                : fieldStatus.hasReasoning ?
                    <Fragment>{reasoningHtml}</Fragment>
                    : <EmptyField identifier={"reasoningnote"} className="box bg-unfilled"/>
            }
        </div>
    )
}

export const claim = (doc,fieldStatus,inCompleteStep) => {
    return (
        <Fragment>
            {!inCompleteStep ?
                <div className="section">
                    <h2>Claim</h2>
                    <ReadOnlyField identifier="claim" className="box bg-autofill" />
                    <div className={"box bg-autofill"} >
                        <div className={"row"}>
                            <ReadOnlyField identifier="claimcontext" className={"grouping-text"} />
                            <ReadOnlyField identifier="claimcontext/image" className={"grouping-img"} />
                        </div>
                    </div>
                </div>
                :
                <div className="section">
                    <h2>Claim</h2>
                    {fieldStatus.hasClaim ?
                            <ReadOnlyField identifier="claim" className="box bg-autofill" />
                        : <EmptyField identifier={"claim"} className="box bg-unfilled"/>
                    }

                    {fieldStatus.hasClaimContext ?
                        <div className={"box bg-autofill"} >
                            <div className={"row"}>
                                <ReadOnlyField identifier="claimcontext" className={"grouping-text"} />
                                <ReadOnlyField identifier="claimcontext/image" className={"grouping-img"} />
                            </div>
                        </div>
                        : <EmptyField identifier={"claimcontext"} className="box bg-unfilled"/>
                    }
                </div>
            }
        </Fragment>

    )
}

export const review_checklist = (doc,fieldStatus,closeChecklist,onClickFunc) => {
    return (
            fieldStatus.display ?
                <div className="section">
                    <h3 className="h3">Review Checklist</h3>

                    <div className="box box-unfilled">
                        <div className="box-row-close">
                            <button type="button" className="btn btn-icon btn-link" aria-label="Remove key term" title="Remove Checklist" onClick={closeChecklist}>
                                <span className="fa fa-times text-primary" aria-hidden="true"></span>
                            </button>
                        </div>
                        <ul>
                            {!fieldStatus.hasTopic ? <li>You have not yet entered a <b>Topic</b>. Go to <Link to="essentialquestion" onClick={() => onClickFunc(1)}>Step 1</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasEssentialQuestion ? <li>You have not yet entered an <b>Essential Question</b>. Go to <Link to="essentialquestion" onClick={() => onClickFunc(1)}>Step 1</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasKeyTerms ? <li>You have not entered any <b>Key Terms</b>. Go to <Link to="terms" onClick={() => onClickFunc(2)}>Step 2</Link> to define Key Terms</li> : null}
                            {!fieldStatus.hasClaim ? <li>You have not yet entered a <b>Claim</b>. Go to <Link to="claim" onClick={() => onClickFunc(3)}>Step 3</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasClaimContext ? <li>You have not yet entered a <b>Claim Background/Context</b>. Go to <Link to="claim" onClick={() => onClickFunc(3)}>Step 3</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasEvidenceSupport ? <li>You have not yet entered any <b>Supporting Evidence</b>. Go to <Link to="evidencesupport" onClick={() => onClickFunc(4)}>Step 4</Link>  to complete the section.</li> : null}
                            {!fieldStatus.hasEvidenceOpposes ? <li>You have not yet entered any <b>Opposing Evidence</b>. Go to <Link to="evidenceopposes" onClick={() => onClickFunc(5)}>Step 5</Link>  to complete the section.</li> : null}
                            {!fieldStatus.hasEvidenceQuality ? <li>You have not entered the <b>Evidence Quality</b>. Go to <Link to="evidencequality" onClick={() => onClickFunc(6)}>Step 6</Link>  to complete the section.</li> : null}
                            {!fieldStatus.hasReasoning ? <li>You have not entered the <b>Reasoning</b>. Go to <Link to="reasoning" onClick={() => onClickFunc(7)}>Step 7</Link> to complete the section.</li> : null}
                        </ul>
                    </div>
                </div>
            : null
    )
}
export default class CERGuideSteps extends PreviewComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state ={
            display : false,
            hasEssentialQuestion: false,
            hasTopic:false,
            hasKeyTerms:false,
            hasClaim: false,
            hasClaimContext: false,
            hasEvidenceSupport: false,
            hasEvidenceOpposes: false,
            hasEvidenceQuality: false,
            hasReasoning: false,
            valueMaps: null
        };
        this.closeChecklist = this.closeChecklist.bind(this);
        this.checkEmptyList = this.checkEmptyList.bind(this);
        this.mapHasContent = this.mapHasContent.bind(this);
    }

    closeChecklist(){
        this.setState({display: false});
    }

    checkEmptyField(field){
        this.getFirepadTextPromise(field).then((data) =>{
            let hasContent = this.hasContent(data);
            if("essentialquestion" === field) this.setState({hasEssentialQuestion: hasContent});
            if("topic" === field) this.setState({hasTopic: hasContent});
            if("claim" === field) this.setState({hasClaim: hasContent});
            if("claimcontext" === field) this.setState({hasClaimContext: hasContent});
            if("reasoningnote" === field) this.setState({hasReasoning: hasContent});
            this.updateChecklist();
        });
    }

    updateChecklist(){
        let showChecklist = false;
        if(!this.state.hasTopic) showChecklist = true;
        if(!this.state.hasEssentialQuestion) showChecklist = true;
        if(!this.state.hasKeyTerms) showChecklist = true;
        if(!this.state.hasClaim) showChecklist = true;
        if(!this.state.hasClaimContext) showChecklist = true;
        if(!this.state.hasEvidenceSupport) showChecklist = true;
        if(!this.state.hasEvidenceOpposes) showChecklist = true;
        if(!this.state.hasEvidenceQuality) showChecklist = true;
        if(!this.state.hasReasoning) showChecklist = true;

        this.setState({
            display : showChecklist
        })
    }

    componentDidMount() {
        for (let field of ['topic','essentialquestion','claim','claimcontext','reasoningnote']) {
            this.checkEmptyField(field);
        }

        this.checkEmptyList();
    }

    getCERGuideMapsPromise() {
		let proms = [
			this.getFirepadMapPromise('terms', 'def'),
            this.getFirepadMapPromise('terms', 'term'),
            this.getFirepadMapPromise('evidencesupport', 'evidence'),
            this.getFirepadMapPromise('evidencesupport', 'citation'),
            this.getTextMapPromise('evidencesupport', 'qualitytype'),
            this.getFirepadMapPromise('evidencesupport', 'qualitynote'),
            this.getFirepadMapPromise('evidenceopposes', 'evidence'),
            this.getFirepadMapPromise('evidenceopposes', 'citation'),
            this.getTextMapPromise('evidenceopposes', 'qualitytype'),
            this.getFirepadMapPromise('evidenceopposes', 'qualitynote'),
		];
		return Promise.all(proms)
			.then((maps) => {
			return {
				'termsdef' : maps[0],
                'termsword' : maps[1],
                'supportevidence' : maps[2],
                'supportcitation' : maps[3],
                'supportqualitytype' : maps[4],
                'supportqualitynote' : maps[5],
                'opposesevidence' : maps[6],
                'opposescitation' : maps[7],
                'opposesqualitytype' : maps[8],
                'opposesqualitynote' : maps[9],
			};
		});
    }

    checkEmptyList(){
        this.getCERGuideMapsPromise().then((valueMaps) =>{
            if(valueMaps != null) {
                console.log(valueMaps);
                var hasTermOrDef = this.mapHasContent(valueMaps['termsword'])
                    || this.mapHasContent(valueMaps['termsdef']);
                var hasEvidenceSupport= this.mapHasContent(valueMaps['supportevidence']);
                var hasEvidenceOpposes= this.mapHasContent(valueMaps['opposesevidence']);
                var supportQuality = this.allMapHasContent(valueMaps['supportqualitytype']) && this.allMapHasContent(valueMaps['supportqualitynote']);
                var opposesQuality = this.allMapHasContent(valueMaps['opposesqualitytype']) && this.allMapHasContent(valueMaps['opposesqualitynote']);
                console.log("supportQuality = " + supportQuality + ", opposesQuality = " + opposesQuality);
                var hasEvidenceQuality = supportQuality && opposesQuality;
                let update = {
                    hasKeyTerms: hasTermOrDef,
                    hasEvidenceSupport: hasEvidenceSupport,
                    hasEvidenceOpposes: hasEvidenceOpposes,
                    hasEvidenceQuality: hasEvidenceQuality,
                    valueMaps: valueMaps
                };
                this.setState(update);
                this.updateChecklist();
            }
        });
    }

    onClickFunc(step){
        window.tracker.trackEvent("Navigation", "checklist link", step);
    }

    render(){
        return <React.Fragment>
            {this.props.inCompleteStep && this.context.canEdit ? review_checklist(this.props.doc,this.state,this.closeChecklist,this.onClickFunc) : null }
            {essentialquestion(this.props.doc,this.state,this.props.inCompleteStep)}
            {claim(this.props.doc,this.state,this.props.inCompleteStep)}
            {keyTerms(this.props.doc,this.state,this.props.inCompleteStep)}
            {evidence(this.props.doc,this.state,this.props.inCompleteStep)}
            {reasoning(this.props.doc,this.state,this.props.inCompleteStep)}
        </React.Fragment>
    }
}

import SttInput from "./SttInput";
import React from "react";

export default class Overall extends React.Component {

    constructor() {
        super();
        this.identifier = "overall";
    }

    render() {
        return (<SttInput addImage={true} inputType={"text-area"} identifier={this.identifier} />);
    }
}

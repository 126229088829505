// This file contains text for all of the instructional hints that describe how to use the various
// fields in the Content Enhancement Routines.

const texts = {

    footer: {
        nsf: {
            url: "https://www.nsf.gov",
            grant: "This material is based upon work supported by the National Science Foundation under" +
                 " grant #1813556. Any opinions, findings, conclusions, or recommendations expressed in" +
                 " this material are those of the author(s) and do not necessarily reflect the views of" +
                 " the National Science Foundation."
        },
        texthelp: {
            thanks: "CAST would like to thank TextHelp Systems Ltd. for use of the SpeechStream toolbar."
        },
        osep: {
            grant:"This material is based upon work supported by the Office of Special Education Programs under grant #H327S190008. Any opinions, findings, conclusions, or recommendations expressed in this material are those of the author(s) and do not necessarily reflect the views of the Office of Special Education Programs."
        }
    },

    "utility" : {
        footer: "Co-Organize Your Learning (CORGI) is a google application for students and teachers to use to collaboratively answer questions requiring higher order reasoning. The CORGI guides are adapted from Strategic Instruction Model (SIM) routines developed by Janis A. Bulgren, B. Keith Lenz, Donald D. Deshler, & Jean B. Schumaker at the University of Kansas. CORGI is funded by the Investing in Innovation (i3) Development Grant awarded to SRI International (#U411C14003).",
        migrationHeader: "CORGI has a new home",
        migrationBody: "The i3 Enhanced Unit project is ending and, therefore, the old CORGI site, corgi.sri.com, has been deactivated. Please bookmark the new CORGI site: "
    },

    "unit-org-2" : {

        title: "Unit Organizer",

        abbr: "UO",

        blurb: "We use the Unit Organizer to organize the critical content to be learned for each unit. We also include the unit self-test questions we are responsible for answering and the type of reasoning we will use to answer the questions. The schedule of assignments will help us see how the tasks are related to helping us answer the unit self-test questions.",

        footer: {
            sri: "Originally developed, validated and copyrighted, ‘The Unit Organizer Routine’ by B. Keith Lenz, Janis A. Bulgren, Jean B. Schumaker, Donald D. Deshler, and Daniel A. Boudah. Edge Enterprises Inc. (1994). The authors have granted their permission to SRI to adapt the Unit Organizer Routine and display and distribute the adaptation on this site via an application hosted by Google, funded by the U.S. Department of Education, Investing in Innovation (i3) Development Grant #U411C140003.  The contents of this site were developed under the i3 grant from the Department of Education. However, those contents do not necessarily represent the policy of the Department of Education, and you should not assume endorsement by the Federal Government.",
            nsf: "This material is based upon work supported by the National Science Foundation under\n" +
                "                                    grant #1813556. Any opinions, findings, conclusions, or recommendations expressed in\n" +
                "                                    this material are those of the author(s) and do not necessarily reflect the views of\n" +
                "                                    the National Science Foundation."
        },

        overall: {
            label: "Bigger Picture",
            help: "In this section, the name of the idea or theme that holds several units together is noted.",
            placeholder: "Type the bigger picture for the unit here."
        },

        unitTitle: {
            label: "Unit title",
            placeholder: "Enter the unit title here."
        },

        prevUnit: {
            label: "Previous Unit",
            placeholder: "Title of the previous unit."
        },

        nextUnit: {
            label: "Next Unit",
            placeholder: "Title of the next unit."
        },

        unitMap: {
            label: "Unit Map",
            help: "In this section, we show a visual way of organizing the information in the unit."
        },

        unitAbout: {
            label: "Unit Paraphrase",
            help: "In this section, a paraphrase is written that captures the main idea of the unit and distinguishes it from other units. It is written in student friendly language that is easy to understand.",
            placeholder: "Paraphrase what the unit is about."
        },

        connector: {
            placeholder: "Add connecting phrase here."
        },

        mainIdea: {
            label: "Main Ideas",
            help: "In this section, we list the main ideas or essential ideas of the unit, which is the critical content that you need to learn.",
            placeholder: "Add new main idea here."
        },

        questions: {
            label: "Self-test Questions",
            help: "In this section, we ask questions that, if answered by the end of the unit, show that we have mastered the critical content for the unit.",
            placeholder: "Type self-test question here."
        },

        orgtype: {
            label: "Unit Relationships",
            help: "The level and way of thinking that we want to use when working and interacting with the critical content.  The unit relationship determines how we are going to be working with and showing mastery of the content."
        },

        schedlist: {
            label: "Unit Schedule",
            help: "This section is a schedule of the most important activities and events in the unit (quizzes, tests, high impact activities).",
            datePlaceholder: "Date",
            assignmentPlaceholder: "Assignment"
        }

    },

    "cause-effect" : {

        title: "Cause & Effect Guide",

        abbr: "CEG",

        example: "The Seasons",

        blurb: " The Cause & Effect Guide helps us explain the relationship between an event, its causes, and its effects.",

        footer: "Originally developed, validated and copyrighted, 'Teaching Cause and Effect' by Janis A. Bulgren, Ph.D. University of Kansas. (2013). The authors have granted their permission to SRI to adapt the Cause and Effect Routine and display and distribute the adaptation on this site via an application hosted by Google, funded by the U.S. Department of Education, Investing in Innovation (i3) Development Grant #U411C140003.  The contents of this site were developed under the i3 grant from the Department of Education. However, those contents do not necessarily represent the policy of the Department of Education, and you should not assume endorsement by the Federal Government.",

        mnemonic: "REASON",

        mnemonicSteps: [
            "Restate the question",
            "Examine the key terms",
            "Analyze critical event & background information",
            "Specify causes and connections",
            "Organize effects and connections",
            "Nail down the answer"
        ],

        connector: {
            placeholder: "Add connecting phrase here.",
            empty: "No relationship selected",
        },

        sections: [
            {
                key: "toc",
                title : "Cause & Effect Steps"
            },
            {   stepnum: 1,
                key: "question",
                title: "What is the essential question?",
                topic: "The title for my topic is...",
                description: "Enter your essential question in the form of a question.",
                info: { label: "Essential Question",
                    text: [
                        '*Essential questions* are complex and thought-provoking questions that don’t have simple answers. They drive us to ask more questions and draw connections between events, characteristics, and features.',
                        'For example, we’ll explore the *topic* ‘The Seasons’ through the *essential question*.'
                    ],
                    image: {
                        src: '/example/cause-effect/essentialquestion.svg',
                        alt: 'Two text boxes displaying the topic and essential question. Topic: The Seasons Essential Question: How does the tilt of the Earth’s axis cause our seasons?',
                    }
                },
                tooltip:{
                    key:"essential question",
                    definition:"Essential questions don't have simple answers. They promote inquiry and critical thinking practices."
                }
            },
            {   stepnum: 2,
                key: "terms",
                title: "What are the key terms?",
                description: "Define the key terms that will help you explore your topic.",
                term: {
                    label: "Word or Phrase",
                    placeholder: "Enter key term",
                },
                def: {
                    label: "Definition",
                    placeholder: "Enter the definition",
                },
                info: {
                    label: "Key Terms",
                    text: ["Key terms are the words and phrases that will help you better understand your topic and respond to the essential question.",
                    ""],
                },
                tooltip: {
                    key: "key terms",
                    definition: "Key terms are vocabulary words and phrases that will enhance your understanding of a topic."
                }
            },
            {   stepnum: 3,
                key: "event",
                title: "What is the main event?",
                description: "Describe the critical event, topic, idea, or issue and provide background information.",
                placeholder: "The main event or idea I am exploring is...",
                info: { label: "Main event or idea",
                    text: [
                        'A *main event or idea* is the description of the critical topic that happens because of one or more causes and creates one or more effects.',
                        ''
                    ],
                    image: {
                        src: '/example/cause-effect/event.svg',
                        alt: 'Two text boxes displaying the essential question and the main event. Essential Question: How does the tilt of the Earth’s axis cause our seasons? Main event or idea: On Earth, we experience temperature changes throughout the year called seasons. The Northern Hemisphere and Southern Hemisphere have opposite seasons.',
                    }
                },
                tooltip: {
                    key: "main event",
                    definition: "The main event is the central point or idea."
                }
            },
            {   stepnum: 4,
                key: "causes",
                title: "What are the causes of the main event?",
                description: "Describe the causes of the main event. Enter up to four causes.",
                info: { label: "Causes and connections",
                    text: [
                        'The *causes and connections* have a relationship to the main event or idea. They may lead to the event or prompt it to happen. ',
                        ''
                    ],
                    image: {
                        src: '/example/cause-effect/causes.svg',
                        alt: 'Two text boxes displaying a cause and the main event connected by a vertical arrow. Cause: The Earth revolves around the sun every 365 days on a tilted axis. Main event: On Earth, we experience temperature changes throughout the year called seasons. The Northern Hemisphere and Southern Hemisphere have opposite seasons.'
                    }
                },
                tooltip: {
                    key: "causes",
                    definition: "The causes of the main event led to it or prompted it to happen."
                }
            },
            {   stepnum: 5,
                key: "causes-event",
                title: "What is the relationship to the causes?",
                description: "Select the relationship between the causes and connections and the main event or idea. Or, enter your own.",
                info: { label: "Relationship to the main event",
                    text: [
                        'The *relationship* to the main event helps to define how the causes connect to the main event.',
                        ''
                    ],
                    image: {
                        src: '/example/cause-effect/causes-event.svg',
                        alt: 'Three text boxes displaying a cause, the relationship, and the main event connected by a vertical arrow. Cause: The Earth revolves around the sun every 365 days on a tilted axis. Relationship: Produces. Main event: On Earth, we experience temperature changes throughout the year called seasons. The Northern Hemisphere and Southern Hemisphere have opposite seasons.',
                    }
                },
                tooltip: {
                    key: "relationship",
                    definition: "The relationship describes the connection between causes and the main event."
                }
            },
            {   stepnum: 6,
                key: "effects",
                title: "What are the effects of the main event?",
                description: "Describe the effects of the main event. Enter up to four effects.",
                label: "Effects of the main event",
                info: { label: "Effects of the main event",
                    text: [
                        'The *effects and connections* have a relationship to the main event or idea. They may happen directly because of the main event or due to a secondary reason.',
                        ''
                    ],
                    image: {
                        src: '/example/cause-effect/effects.svg',
                        alt: 'Two text boxes displaying the main event and an effect connected by a vertical arrow. Main event: On Earth, we experience temperature changes throughout the year called seasons. The Northern Hemisphere and Southern Hemisphere have opposite seasons. Effect: When the Northern Hemisphere is tilted toward the sun, it is experiencing summer.',
                    }
                },
                tooltip: {
                    key: "effects",
                    definition: "The effects happen as a result of the main event."
                }
            },
            {   stepnum: 7,
                key: "event-effects",
                title: "What is the relationship to the effects?",
                description: "Select the relationship between the main event or idea and its effects. Or, enter your own.",
                info: { label: "Relationship to the effects",
                    text: [
                        'The *relationship* to the main event helps to define how the main event connects to the effects.',
                        ''
                    ],
                    image: {
                        src: '/example/cause-effect/event-effects.svg',
                        alt: 'Three text boxes displaying a cause, the relationship, and the main event connected by a vertical arrow. Main event: On Earth, we experience temperature changes throughout the year called seasons. The Northern Hemisphere and Southern Hemisphere have opposite seasons. Relationship: Results in. Effect: When the Northern Hemisphere is tilted toward the sun, it is experiencing summer.',
                    }
                },
                tooltip: {
                    key: "relationship",
                    definition: "The relationship describes the connection between the main event and the effects."
                }
            },
            {   stepnum: 8,
                key: "summary",
                title: "What is the summary?",
                description: "Summarize what you’ve learned through your exploration of this cause & effect relationship.",
                info: { label: "Summary",
                    text: [
                        'Incorporate the causes, relationships, and effects of the main event or idea you have explored in the previous steps into your *summary*.',
                        ''
                    ],
                },
                tooltip: {
                    key: "summary",
                    definition: "The summary is your response to the essential question based on what you’ve learned."
                }
            },
            {   stepnum: 9,
                key: "extensions",
                title: "Extensions",
                description: "What additional questions has this exploration generated?"
            },
            {   stepnum: 10,
                key: "complete",
                title: "Review",
                description: "Check your understanding of the Cause & Effect relationship you've explored. When the guide is complete, go to the 'Create & Share' page.",
                prompts: [
                    "You have not yet entered a Topic. Go to step 1 to complete this section.",
                    "You have not yet entered an Essential Question. Go to Step 1 to complete this section.",
                    "You have not yet entered a Main Event. Go to Step 2 to complete this section.",
                    "You have not yet entered any Causes of the Main Event. Go to Step 3 to complete this section.",
                    "You have not yet selected a relationship between the Cause and the Main Event. Go to Step 4 to complete this section.",
                    "You have not yet entered any Effects of the Main Event. Go to Step 5 to complete this section.",
                    "You have not yet selected a relationship between the Main Event and the Effects. Go to Step 6 to complete this section.",
                    "You have not yet entered a Summary. Go to Step 7 to complete this section.",
                ],
                // see CORGI-481 for criteria - no check for Extensions step
                empty: {
                    topic: "Topic not yet entered",
                    question: "You have not yet entered an Essential Question.",
                    terms: "You have not yet entered any Key Terms.",
                    event: "You have not yet entered a Main Event.",
                    cause: "You have not yet entered any Causes.",
                    connector: "No relationship selected",
                    effect: "You have not yet entered any Effects of the Main Event.",
                    summary: "You have not yet entered a Summary.",
                },
            },
            {
                stepnum: 11,
                key: "create-share",
                title: "Create and Share"
            }
        ],

        topic: {
            label: "Essential Question",
            help: "In this box, we can restate a complex or unclear question into something more simple and direct.",
            placeholder: "The title for my topic is",
            empty: "Topic not yet entered",
        },
        question: {
            label: "Essential Question",
            help: "In this box, we can restate a complex or unclear question into something more simple and direct.",
            placeholder: "The essential question I am investigating is...",
            empty: "You have not yet entered an Essential Question.",
        },

        terms: {
            label: "Key Terms",
            singleLabel: "Key Terms",
            description: "Define key terms that we need to know in order to complete the cause and effect guide.",
            termPlaceholder: "Enter the key term",
            defPlaceholder: "Enter the definition",
            empty: "You have not yet entered any Key Terms.",
            term: {
                label: "Word or Phrase",
                placeholder: "Enter the key term",
            },
            def: {
                label: "Definition",
                placeholder: "Enter the definition",
            },
            info: {
                label: "Key Terms",
                text: ["Knowing key terms can help you learn content and demonstrate your understanding. Here is where you add 3-5 key terms that relate to your topic. If you understand the meaning of the terms simply add your own definition. If you need help with their meaning simply search the CORGI dictionary and then add a definition that makes sense.",
                ""],
            },
        },

        "causes-event": {
            placeholder: "Add connecting phrase here.",
            empty: "No relationship selected",
        },

        event: {
            label: "Event and Background Information",
            singleLabel: "Event",
            help: "It is helpful to fully understand the main event before we try to identify causes and effects, so, in this box, we can type words or phrases to describe the critical event from the restated question. Then, we can list background information that helps us understand details of the event.",
            placeholder: "The main event or idea I am exploring is...",
            empty: "You have not yet entered a Main Event.",
        },
        cause: {
            label: "Causes and Connections",
            singleLabel: "Cause",
            help: "In this box, we will include one or more short statements that describe critical causes of the event.  After we describe the causes of the event, we use connecting phrases to create connections between the causes and the event.  Examples include: which led to, leading to, and causing. These connecting verbs will result in complete sentences that can be read across the guide, from causes to event to effects.",
            placeholder: "A cause and connection of the main event I am exploring is...",
            empty: "You have not yet entered any Causes.",
        },

        "event-effects": {
            placeholder: "Add connecting phrase here.",
            empty: "No relationship selected",
        },

        effect: {
            label: "Effects of the Main Event",
            singleLabel: "Effect",
            help: "In box 5, we will include one or more short statements that describe critical effects of the event. After we describe the effects of the event, we use connecting phrases to create connections between the event and the effects.  Examples include: which led to, leading to, and causing. These connecting verbs will result in complete sentences that can be read across the guide, from causes to event to effects.",
            placeholder: "Organize effects and connections.",
            empty: "You have not yet entered any Effects of the Main Event.",
        },

        answer: {
            label: "Summary",
            singleLabel: "Summary",
            help: "We are finally ready to write our answer to the original question. The answer should include the name of the event, the causes and effects, and an explanation for each. It’s an opportunity to use all the information we have gathered and put our answer in our own words.",
            placeholder: "My response to the essential question is...",
            empty: "You have not yet entered a Summary.",
        },
        extensions:{
            placeholder: " I would like to learn more about…"
        }
    },
    "cera":{
        title: "Claim, Evidence, Reasoning Guide",
        abbr: "CERA",
        blurb:"The Claim, Evidence, Reasoning Guide helps us use reasoning to determine if a claim is supported by evidence.",

        footer: "Developed by CAST Inc. (2021). The contents of this site were developed under the Stepping Up grant under the Office of Special Education Programs. However, those contents do not necessarily represent the policy of the Office of Special Education Programs, and you should not assume endorsement by the Federal Government.",

        sections: [
            {
                key: "toc",
                title : "Claim, Evidence, Reasoning Guide Steps"
            },
            {   stepnum: 1,
                key: "essentialquestion",
                title: "What is the essential question?",
                description: "Enter your essential question in the form of a question.",
                tooltip:{
                    key:"essential question",
                    definition:"Essential questions don't have simple answers. They promote inquiry and critical thinking practices."
                }
            },
            {   stepnum: 2,
                key: "terms",
                title: "What are the key terms?",
                description: "Define the key terms that will help you explore your topic.",
                term: {
                    label: "Word or Phrase",
                    placeholder: "Enter key term",
                },
                def: {
                    label: "Definition",
                    placeholder: "Enter the definition",
                },
                tooltip: {
                    key: "key terms",
                    definition: "Key terms are vocabulary words and phrases that will enhance your understanding of a topic."
                }
            },
            {   stepnum: 3,
                key: "claim",
                title: "What is the claim?",
                description: "Enter a claim that responds to the essential question and add more details.",
                tooltip:{
                    key:"claim",
                    definition:"A claim is a statement that may or may not be true."
                }
            },
            {   stepnum: 4,
                key: "evidencesupport",
                title: "What evidence supports the claim?",
                description: "Enter one piece of evidence per row. Include your sources.",
                tooltip:{
                    key:"evidence",
                    definition:"Supporting evidence helps prove a claim is true or accurate."
                }
            },
            {   stepnum: 5,
                key: "evidenceopposes",
                title: "What evidence opposes the claim?",
                description: "Did you find any evidence that does not support the claim? Enter one piece of evidence per row. Include your sources.",
                tooltip:{
                    key:"opposes",
                    definition:"Opposing evidence counters or refutes the claim."
                }
            },
            {   stepnum: 6,
                key: "evidencequality",
                title: "How strong or weak is the evidence? ",
                description: "Determine if each piece of evidence is weak or strong. Explain your choice."
            },
            {   stepnum: 7,
                key: "reasoning",
                title: "What is your reasoning?",
                description: "Now that you have evaluated the evidence, does the evidence support the claim? Why or why not? Explain your reasoning.",
                tooltip: {
                    key: "reasoning",
                    definition: "Reasoning is the thought process that leads to agreeing or disagreeing with a claim and describing how evidence supports a claim."
                }
            },
            {   stepnum: 8,
                key: "complete",
                title: "Review",
                description: "Check your understanding of the Claim, Evidence, and Reasoning relationship you've explored. When the guide is complete, go to the 'Create & Share' page.",
            },
            {
                stepnum: 9,
                key: "create-share",
                title: "Create and Share"
            }
        ],
        topic:{
            title: "Topic",
            placeholder: "The title for my topic is…",
            empty:"You have not yet entered a Topic."
        },
        essentialquestion: {
            title: "Essential Question",
            placeholder: "The essential question is...",
            empty:"You have not yet entered an Essential Question."
        },
        claim: {
            title: "Claim",
            placeholder: "The claim is… ",
            empty:"You have not yet entered a Claim."
        },
        claimcontext: {
            title: "Context for the Claim",
            placeholder: "More details about the claim...",
            empty:"You have not yet entered a Claim Background/Context."
        },
        evidencesupport:{
            placeholder:"The evidence is…",
            empty:"You have not yet entered any Supporting Evidence. "
        },
        citation:{
            placeholder: "The source is..."
        },
        evidenceopposes:{
            placeholder: "The opposing evidence is...",
            empty:"You have not yet entered any Opposing Evidence."
        },
        qualitytype:{
            placeholder:"The quality of the evidence is..."
        },
        qualitynote:{
            label: 'Reason for rating',
            placeholder:"The evidence is weak/strong because...",
            empty:"You have not entered the Evidence Quality."
        },
        reasoningtype:{
            placeholder: "Select a sentence starter..."
        },
        reasoningnote:{
            placeholder: "Write your own...",
            empty:"You have not entered the Reasoning."
        },
        terms: {
            label: "Key Terms",
            description: "Define key terms that we need to know in order to complete the Claim, Evidence, Reasoning guide.",
            help: "In this section, we need to identify and understand the key terms so we can answer the questions.  If not already provided, type any Key Terms and use a dictionary or background knowledge to define them.",
            termPlaceholder: "Enter the key term",
            defPlaceholder: "Enter the definition",
            empty: "You have not yet entered any Key Terms.",
            term: {
                label: "Word or Phrase",
                placeholder: "Enter the key term",
            },
            def: {
                label: "Definition",
                placeholder: "Enter the definition",
            },
            info: {
                label: "Key Terms",
                text: ["Knowing key terms can help you learn content and demonstrate your understanding. Here is where you add 3-5 key terms that relate to your topic. If you understand the meaning of the terms simply add your own definition. If you need help with their meaning simply search the CORGI dictionary and then add a definition that makes sense.",
                ""],
            }
        }
    },

    "question-exp" : {

        title: "Question Exploration Guide",

        abbr: "QEG",

        example: "Equinox & Solstice",

        blurb: " The Question Exploration Guide helps us break down an essential question, respond to it, and relate it to our broader understandings.",

        footer: "Originally developed, validated and copyrighted, 'The Question Exploration Routine' by Janis A. Bulgren, B. Keith Lenz, Donald D. Deshler, and Jean Schumaker. Edge Enterprises Inc. (2001). The authors have granted their permission to SRI to adapt the Question Exploration Routine and display and distribute the adaptation on this site via an application hosted by Google, funded by the U.S. Department of Education, Investing in Innovation (i3) Development Grant #U411C140003.  The contents of this site were developed under the i3 grant from the Department of Education. However, those contents do not necessarily represent the policy of the Department of Education, and you should not assume endorsement by the Federal Government.",

        mnemonic: "ANSWER",

        mnemonicSteps: [
            "Ask a Critical Question",
            "Note and explain key terms",
            "Search for Supporting Questions and their answers",
            "Work out a Main Idea answer to the critical question that is concise and usable",
            "Explore the Main Idea to show deeper understanding",
            "Relate the main idea to today’s world"
        ],

        sections: [
            {
                key: "toc",
                title : "Question Exploration Steps"
            },
            {   stepnum: 1,
                key: "essentialquestion",
                title: "What is the essential question?",
                description: "Enter your essential question in the form of a question.",
                info: { label: "Essential Question",
                    text: [
                        'Essential questions are complex and thought-provoking questions that don’t have simple answers. They drive us to ask more questions and draw connections between events, characteristics, and features.',
                        '_For example, we’ll explore the *topic* ‘The Equinox & The Solstice’ through the *essential question*_'
                    ],
                    image: {
                        src: '/example/question-exp/essential-question.svg',
                        alt: 'Two text boxes containing the topic and essential question. Topic: The Equinox & The Solstice Essential Question: Why do our seasons have equinoxes and solstices?'
                    }
                },
                tooltip: {
                    key: "essential question",
                    definition: "Essential questions don't have simple answers. They promote inquiry and critical thinking practices."
                }
            },
            {   stepnum: 2,
                key: "terms",
                title: "What are the key terms?",
                description: "Define the key terms that will help you explore your topic. ",
                term: {
                    label: "Word or Phrase",
                    placeholder: "Enter key term",
                },
                def: {
                    label: "Definition",
                    placeholder: "Enter the definition",
                },
                info: {
                    label: "Key Terms",
                    text: ["Key terms are the words and phrases that will help you better understand your topic and respond to the essential question.",
                    ""],
                },
                tooltip: {
                    key: "key terms",
                    definition: "Key terms are vocabulary words and phrases that will enhance your understanding of a topic."
                }
            },
            {   stepnum: 3,
                key: "relatedquestion",
                title: "What are the supporting questions?",
                description: "Enter questions starting with 'Who', 'What', 'Where', 'When', 'Why', and ‘How’ to explore the essential question more deeply.",
                info: { label: "Supporting Questions",
                    text: [
                        'Break down your essential question into smaller, focused questions that will help you understand your topic.',
                        ''
                    ],
                    image: {
                        src: '/example/question-exp/related-question.svg',
                        alt: 'Two text boxes containing the essential question and a supporting question. Essential Question: Why do our seasons have equinoxes and solstices? Supporting Question: How do you identify the two types of equinoxes?'
                    }
                },
                tooltip: {
                    key: "supporting questions",
                    definition: "Supporting questions break down the essential question into smaller, more focused questions."
                }
            },
            {   stepnum: 4,
                key: "relatedquestionresponse",
                title: "What are your responses to the supporting questions?",
                description: "Research your supporting questions and enter your responses.",
                info: { label: "Response to the Supporting Questions",
                    text: [
                        'Think about how the responses to your supporting questions will help you respond to your essential question.',
                        ''
                    ],
                    image: {
                        src: '/example/question-exp/related-question-response.svg',
                        alt: 'Two text boxes containing a supporting question and its response. Supporting Question: How do you identify two types of equinoxes? Response: An equinox happens when the earth experiences equal amounts of daytime and nighttime hours.                        '
                    }
                }
            },
            {   stepnum: 5,
                key: "essentialquestionresponse",
                title: "What is your response to the essential question? ",
                description: "Based on your exploration, create a response to the essential question. This response should provide the main idea. Refer to your responses to the supporting questions when entering this response.",
                info: { label: "Response to the Essential Question",
                    text: [
                        'Refer to your responses to the supporting questions when entering this response. Essential questions are complex - it’s okay if your question doesn’t have a single, clear cut answer. ',
                        ''
                    ],
                    image: {
                        src: '/example/question-exp/essential-question-response.svg',
                        alt: 'Two text boxes containing the essential question and its response. Essential Question: Why do our seasons have equinoxes and solstices?'
                    }
                },
                tooltip: {
                    key: "response",
                    definition: "Essential questions are complex - it’s okay if your response doesn’t have a single, clear cut answer."
                }
            },
            {   stepnum: 6,
                key: "contextresponse",
                title: "How can you apply what you have learned in this exploration to the class or real-world?",
                description: "Describe the class or real-world context for your response.",
                info: { label: "Context Response",
                    text: [
                        'Why is the information you’ve learned through this exploration useful? How does it change or add to your understanding of this class or world?',
                        ''
                    ],
                    image: {
                        src: '/example/question-exp/context-response.svg',
                        alt: 'Two text boxes containing the response to the essential question and its real-world context. Response to the essential question: An equinox and a solstice are both caused because the Earth’s tilt toward the sun changes throughout the year. Real-world context: We can use our knowledge about equinoxes and solstices to explain why the days get longer and shorter throughout the year.'
                    }
                }
            },
            {   stepnum: 7,
                key: "extensions",
                title: "What additional questions has this exploration generated?",
                description: "Enter the new questions this exploration has surfaced.",
                info: { label: "Extensions",
                    text: [
                        'Did your exploration unlock new topics to study? Record your additional questions here.',
                        ''
                    ],
                    image: {
                        src: '/example/question-exp/extensions.svg',
                        alt: 'Two text boxes containing the response to the essential question and an extension. Response to the essential question: An equinox and a solstice are both caused because the Earth’s tilt toward the sun changes throughout the year. Extension: Why was the solstice so important to ancient civilizations such as the Ancient Greeks, the Druids, and the Mayan People?'
                    }
                }
            },
            {   stepnum: 8,
                key: "complete",
                title: "Review",
                description: "Check your understanding of the essential question you’ve explored. Review any incomplete sections. When the guide is complete, go to the 'Create & Share' page.",
            },
            {
                key: "create-share",
                title: "Create and Share"
            }
        ],

        topic:{
            title: "Topic",
            placeholder: "The title for my topic is…",
            empty:"Topic not yet entered"
        },

        essentialquestion: {
            title: "Essential Question",
            placeholder: "The essential question I am exploring is...",
            empty:"You have not yet entered an Essential Question."
        },

        relatedquestion:{
            toptitle: "Supporting Questions & Responses",
            title: "Questions",
            placeholder: "A supporting question is...",
            empty:"You have not yet entered any Supporting Question."
        },

        relatedquestionresponse:{
            title: "Responses",
            placeholder: "My response to this supporting question is...",
            empty:"You have not yet entered any Supporting Question Responses."
        },

        essentialquestionresponse:{
            title: "Response to Essential Question",
            placeholder: "My response to the essential question is...",
            empty:"You have not yet entered a Response to the Essential Question."
        },

        contextresponse:{
            title: "Context for Response",
            placeholder: "The main idea relates to the real world because...",
            empty:"You have not yet entered a Real-world Context."
        },

        extensions:{
            title: "Extensions",
            placeholder: "I would like to learn more about...",
            empty:"You have not yet entered any Extensions."
        },

        terms: {
            label: "Key Terms",
            description: "Define key terms that we need to know in order to complete the Question Exploration guide.",
            help: "In this section, we need to identify and understand the key terms so we can answer the questions.  If not already provided, type any Key Terms and use a dictionary or background knowledge to define them.",
            termPlaceholder: "Enter the key term",
            defPlaceholder: "Enter the definition",
            empty: "You have not yet entered any Key Terms.",
            term: {
                label: "Word or Phrase",
                placeholder: "Enter the key term",
            },
            def: {
                label: "Definition",
                placeholder: "Enter the definition",
            },
            info: {
                label: "Key Terms",
                text: ["Knowing key terms can help you learn content and demonstrate your understanding. Here is where you add 3-5 key terms that relate to your topic. If you understand the meaning of the terms simply add your own definition. If you need help with their meaning simply search the CORGI dictionary and then add a definition that makes sense.",
                ""],
            }
        }
    },

    "comparison" : {

        title: "Comparison Guide",

        abbr: "CT",

        example: "Lunar vs. Solar Eclipse",

        blurb: "The Comparison Guide helps us describe the similarities and differences between two concepts.",

        footer: "Originally developed, validated and copyrighted, ‘The Concept Comparison Routine’ by Janis A. Bulgren, B. Keith Lenz, Donald D. Deshler, & Jean B. Schumaker, Edge Enterprises Inc. (1995). The authors have granted their permission to SRI to adapt the Concept Comparison Routine and display and distribute the adaptation on this site via an application hosted by Google, funded by the U.S. Department of Education, Investing in Innovation (i3) Development Grant #U411C140003.  The contents of this site were developed under the i3 grant from the Department of Education. However, those contents do not necessarily represent the policy of the Department of Education, and you should not assume endorsement by the Federal Government.",

        mnemonic: "COMPARING",

        mnemonicSteps: [
            "Communicate targeted concepts",
            "Obtain the Overall Concept",
            "Make lists of known characteristics",
            "Pin down Like Characteristics",
            "Assemble Like Categories",
            "Record Unlike Characteristics",
            "Identify Unlike Categories",
            "Nail down a summary",
            "Go beyond the basics"
        ],

        sections: [
            {
                key: "toc",
                title : "Comparison Steps"
            },
            {   stepnum: 1,
                key: "essentialquestion",
                title: "What is the essential question?",
                description: "Enter your essential question in the form of a question.",
                info: { label: "Essential Question",
                    text: [
                        '*Essential questions* are complex and thought-provoking questions that don’t have a simple answer. They drive us to ask more questions and draw connections between events, characteristics, and features.',
                        'For example, the essential question “Why do solar and lunar eclipses occur?” helps us compare two different types of eclipses.'
                    ],
                    image: {
                        src: "/example/comparison/essentialquestion.svg",
                        alt: "A section of a graphic organizer with the essential question “Why do solar and lunar eclipses occur?” entered into it"
                    }
                },
                tooltip: {
                    key: "essential question",
                    definition: "Essential questions don't have simple answers. They promote inquiry and critical thinking practices."
                }
            },
            {   stepnum: 2,
                key: "terms",
                title: "What are the key terms?",
                description: "Define the key terms that will help you explore your topic.",
                term: {
                    label: "Word or Phrase",
                    placeholder: "Enter key term",
                },
                def: {
                    label: "Definition",
                    placeholder: "Enter the definition",
                },
                info: {
                    label: "Key Terms",
                    text: ["Key terms are the words and phrases that will help you better understand your topic and respond to the essential question.",
                    ""],
                },
                tooltip: {
                    key: "key terms",
                    definition: "Key terms are vocabulary words and phrases that will enhance your understanding of a topic."
                }
            },
            {   stepnum: 3,
                key: "concepts",
                title: "What two concepts are you comparing?",
                description: "Create a title and describe the two concepts you are comparing.",
                info: { label: "Concepts",
                    text: [
                        'In this step you would *list* and *define* your concept.',
                        'For example, you can list Solar Eclipse and add the definition: “A solar eclipse is when the moon blocks the sun’s rays, casting a shadow on Earth.” You can also add an image to help visualize your definition.'
                    ],
                    image: {
                        src: "/example/comparison/concepts.svg",
                        alt: "A screenshot of a graphic organizer showing the concept 'Solar Eclipse' and its description below it."
                    },
                },
                tooltip: {
                    key: "concepts",
                    definition: "A concept is a topic or an idea."
                }
            },
            {   stepnum: 4,
                key: "overall",
                title: "What's the overall concept?",
                description: "Enter a word or phrase that describes both concepts.",
                info: { label: "The Overall Concept",
                    text: [
                        'The *Overall Concept* is a label we give to a category that includes both concepts.',
                        'For example, solar and lunar eclipses are both phenomena that happen in space when one object casts a shadow on another object.' +
                        ' The overall concept for this comparison routine is “Types of space phenomena.”'
                    ],
                    image: {
                        src: "/example/comparison/overall.svg",
                        alt: 'A screenshot of a graphic organizer with two yellow text boxes at the top with terms to compare. Arrows flow from the bottom of each yellow box and join to point to a larger white text box with the phrase "Types of space phenomena."',
                    }
                },
                tooltip: {
                    key: "overall concept",
                    definition: "The overall concept is a big category that includes both concepts."
                }
            },
            {   stepnum: 5,
                key: "characteristics",
                title: "What are the key characteristics?",
                description: "List the characteristics of Concept A and Concept B. Keep in mind that you will want to list characteristics that are similar and different between the two concepts.",
                info: { label: "Characteristics",
                    text: [
                        'Solar and lunar eclipses each have *characteristics* that make them unique. Use the “Add Characteristic” button to create a list of defining characteristics under each concept.',
                        'Here, you could list the following characteristic under solar eclipse: “Solar eclipses happen during the day.”'
                    ],
                    image: {
                        src: "/example/comparison/characteristics.svg",
                        alt: 'A screenshot of a graphic organizer with “Solar Eclipse” as the heading in a yellow text box. Below is a white text box with the text “Solar eclipses happen during the day.”',
                    }
                },
                tooltip: {
                    key: "key characteristics",
                    definition: "Key characteristics describe the traits or features of a concept that make it unique."
                }
            },
            {   stepnum: 6,
                key: "likeCharacteristics",
                title: "What characteristics are similar?",
                description: "Match any characteristics that are the same or close to the same. Some characteristics may not have a match.",
                info: { label: "Like Characteristics",
                    text: [
                        'Solar and lunar eclipses have *similarities*. To connect these similarities, click the “Make similar pair” button. Click the characteristics you would like to pair and press the “Add Pair” button.',
                        'For example, “Depending on the alignment of the earth, sun, and moon, you could see a total or partial solar eclipse” and “Depending on the alignment of the earth, sun, and moon, you could see a total or partial lunar eclipse” are a *similar* pair.',
                    ],
                    image: {
                        src: "/example/comparison/likecharacteristics.svg",
                        alt: 'A screenshot of a graphic organizer. Two yellow text boxes are side by side at the top forming columns. Each yellow text box contains a concept: one box contains "Solar Eclipse" and the other box contains "Lunar Eclipse". Below each column are bulleted text. Below "Solar Eclipse" is the bulleted text: "Depending on the alignment of the earth, sun, and moon, you could see a total or partial solar eclipse." And below "Lunar Eclipse" is the bulleted text: "Depending on the alignment of the earth, sun, and moon, you could see a total or partial lunar eclipse." Below the text in the bullet points is a green button that says “Add Pair.”',
                    }
                },
                tooltip: {
                    key: "similar",
                    definition: "Similar characteristics are related or have something in common."
                }
            },
            {   stepnum: 7,
                key: "likeCategories",
                title: "What category describes each similar pair?",
                description: "Create a big category that describes how each similar pair is connected.",
                info: { label: "Like Categories",
                    text: [
                        '',
                        'For example, solar and lunar eclipses are both fully or partially visible based on the alignment of the sun, moon, and earth.  The category for this similar pair is:  “The amount of eclipse that could occur.”'
                    ],
                    image: {
                        src: "/example/comparison/likecategories.svg",
                        alt: 'A screenshot of a graphic organizer with two yellow text boxes at the top with comparative phrases. One text box has the phrase "Depending on the alignment of the earth, sun, and moon, you could see a total or partial solar eclipse." The other text box has the phrase "Depending on the alignment of the earth, sun, and moon, you could see a total or partial lunar eclipse." Arrows flow from the bottom of each yellow box and join to point to a larger white text box below with a categorizing phrase: "The amount of eclipse that could occur.”',
                    }
                },
                tooltip: {
                    key: "category",
                    definition: "The similar pair category is a label for a group that both characteristics belong to."
                }
            },
            {   stepnum: 8,
                key: "unlikeCharacteristics",
                title: "What characteristics are different?",
                description: "Match any characteristics that are different. Some characteristics may not have a match.",
                info: { label: "Unlike Characteristics",
                    text: [
                        'Solar and lunar eclipses have *differences*. To connect these differences, click the “Make pair” button. Click the characteristics you would like to pair and press the “Add Pair” button.',
                        'The statements “Solar eclipses happen during the day” and “Lunar eclipses can be seen at night” are a *different* pair.',
                    ],
                    image: {
                        src: "/example/comparison/unlikecharacteristics.svg",
                        alt: 'A screenshot of a graphic organizer. Two yellow text boxes are side by side at the top forming columns. Each yellow text box contains a concept: one box contains "Solar eclipse" and the other box contains "Lunar eclipse". Below each column are bulleted text. Below "Solar eclipse" is the bulleted text: “Solar eclipses happen during the day” and below "Lunar eclipse" is the bulleted text: “Lunar eclipses can be seen at night.” Below the text in the bullet points is a green button that says “Add Pair.”',
                    }
                },
                tooltip: {
                    key: "different",
                    definition: "Different characteristics are contrasting or opposite."
                }
            },
            {   stepnum: 9,
                key: "unlikeCategories",
                title: "What category describes each different pair?",
                description: "Create a big category that describes how each different pair is connected.",
                info: { label: "Unlike Categories",
                    text: [
                        '',
                        'For example, the characteristics “Solar eclipses happen during the day” and “Lunar eclipses can be seen at night” belong to the same category: “The time of day when an eclipse could occur.”'
                    ],
                    image: {
                        src: "/example/comparison/unlikecategories.svg",
                        alt: 'A screenshot of a graphic organizer with two yellow text boxes at the top with comparative phrases. One text box has the phrase "Solar eclipses happen during the day" The other text box has the phrase “Lunar eclipses can be seen at night.” Arrows flow from the bottom of each yellow box and join to point to a larger white text box below with a categorizing phrase: "The time of day when an eclipse could occur.”',
                    }
                },
                tooltip: {
                    key: "category",
                    definition: "The different pair category is a label for a group that both characteristics belong to."
                }
            },
            {   stepnum: 10,
                key: "summary",
                title: "What is the summary?",
                description: "Summarize what you’ve learned through your exploration of this comparison.",
                info: { label: "Summary",
                    text: [
                        'A *summary* is a brief explanation that includes main points and evidence of what you have learned.\n' +
                        'The purpose of the summary is to respond to the essential question using the comparison you’ve performed.\n' +
                        'This summary would include the similar and different characteristics between solar and lunar eclipses as well as the categories that connect those characteristics.\n',
                        ''
                    ],
                },
                tooltip: {
                    key: "summary",
                    definition: "The summary is your response to the essential question based on what you’ve learned."
                }
            },
            {   stepnum: 11,
                key: "extensions",
                title: "Extensions",
                description: "What additional questions has this exploration generated?"
            },
            {   stepnum: 12,
                key: "complete",
                title: "Review",
                description: "Check your understanding of the Comparison you've explored. When the guide is complete, go to the 'Create & Share' page.",
                likeLabel: "Similar Characteristic pair",
                unlikeLabel: "Different Characteristic pair",
            },
            {
                key: "create-share",
                title: "Create and Share"
            }
        ],

        essentialquestion:{
            placeholder: "The essential question I am exploring is...",
            empty:"You have not yet entered an Essential Question."
        },

        concept1: {
            label: "Concept A",
            singleLabel: "Concepts",
            help: "Take the example of Glaciers vs. Aquifers, we'll use these concepts to help you fill out this guide. Glaciers and aquifers are types of freshwater natural resources. In this first step you would list and define your concept.\n\n"+
            '_For example, you can list glaciers and add the definition; "A huge mass of ice and snow that forms where snow gathers faster than it melts". You can also add an image to help visualize your definition._',
            placeholder: "Concept A",
            empty:"You have not yet entered a title of Concept A"
        },

        concept2: {
            label: "Concept B",
            singleLabel: "Concepts",
            help: "Take the example of {example}, we'll use these concepts to help you fill out this guide. Glaciers and aquifers are types of freshwater natural resources. In this first step you would list and define your concept.\n\n"+
            '_For example, you can list glaciers and add the definition; "A huge mass of ice and snow that forms where snow gathersfaster than it melts”. You can also add an image to help visualize your definition._',
            placeholder: "Concept B",
            empty:"You have not yet entered a title of Concept B"
        },

        description1: {
            label: "Describe Concept A",
            singleLabel: "Concept Descriptions",
            placeholder: "I would describe Concept A as...",
            empty:"You have not yet entered a description of Concept A"
        },

        description2: {
            label: "Describe Concept B",
            placeholder: "I would describe Concept B as...",
            empty:"You have not yet entered a description of Concept B"
        },

        terms: {
            label: "Key Terms",
            singleLabel: "Key Terms",
            description: "define key terms that we need to know in order to complete the comparison.",
            termPlaceholder: "Enter the key term",
            defPlaceholder: "Enter the definition",
            empty:"You have not yet entered any Key Terms.",
            term: {
                label: "Word or Phrase",
                placeholder: "Enter the key term",
            },
            def: {
                label: "Definition",
                placeholder: "Enter the definition",
            },
            info: {
                label: "Key Terms",
                text: ["Knowing key terms can help you learn content and demonstrate your understanding. Here is where you add 3-5 key terms that relate to your topic. If you understand the meaning of the terms simply add your own definition. If you need help with their meaning simply search the CORGI dictionary and then add a definition that makes sense.",
                ""],
            }
        },

        overall: {
            label: "The Overall Concept",
            singleLabel: "The Overall Concept",
            help: "Glaciers and aquifers both contain large amounts of freshwater. The overall concept for this comparison guide is: " +
                "'*Sources of freshwater*'",
            placeholder: "Concept A and Concept B are both examples of...",
            empty:"You have not yet entered an Overall Concept",
        },

        characteristics1: {
            label: "Characteristics",
            singleLabel: "Characteristics",
            help: "Glaciers and aquifers each have characteristics that make them unique. Use the '*Add Characteristic*' button to create a list of defining characteristics under each concept.\n\n"+
                '_Here you could list the following characteristic under *glaciers*: "Huge mass of ice that moves slowly over land."_',
            placeholder: "A characteristic of Concept A is...",
            delete: "Remove this characteristic?",
            empty:"You have not yet entered any Characteristics of Concept A"
        },

        characteristics2: {
            label: "Characteristics",
            help: "Glaciers and aquifers each have characteristics that make them unique. Use the '*Add Characteristic*' button to create a list of defining characteristics under each concept.\n\n"+
                '_Here you could list the following characteristic under *glaciers*: "Huge mass of ice that moves slowly over land."_',
            placeholder: "A characteristic of Concept B is...",
            delete: "Remove this characteristic?",
            empty:"You have not yet entered any Characteristics of Concept B"
        },

        likecharacteristics: {
            label: "Like Characteristics",
            singleLabel: "Like characteristic",
            buttonText: "Make pair",
            modalTitle: "Find a similar pair",
            help: 'Glaciers and aquifers have similarities. To connect these similarities, click the "*Make similar pair*" button. Click the characteristics you would like to pair and press the "*Add Pair*" button.\n\n'+
                '_As an example "The most important resource provided by glaciers is freshwater" and "Aquifers provide an important resource of freshwater" are *similar* because they are sources of freshwater._',
            delete: "Unpair these similar characteristics?",
            deleteInstructions: "(Unpairing a characteristic pair does not remove the characteristics from the guide)",
            empty:"You have not yet entered any Similar Characteristics"
        },

        likecategories: {
            label: "Like Categories",
            singleLabel: "Category",
            help: "For example, glaciers and aquifers are both disappearing because of depletion. The category for this similar pair is:  " +
                "‘*Important resources of freshwater for people.*’",
            placeholder: "The category that describes this similar pair is...",
        },

        unlikecharacteristics: {
            label: "Unlike Characteristics",
            singleLabel: "Unlike characteristic",
            secondaryLabel: "Unlike characteristic of B",
            buttonText: "Make pair",
            modalTitle: "Find a different pair",
            help: 'Glaciers and aquifers have differences. To identify these differences, click the "*Make pair*" button. Click the characteristics you would like to pair and press the "*Add Pair*" button.\n\n'+
            '_The statements "Glaciers begin forming in places where more snow piles up each year than melts" and "Aquifers form when rain falls and snow melts and some of it sinks into the earth, and ends up inside the underlying rocks" show that both features are *different*._',
            delete: "Unpair these different characteristics?",
            deleteInstructions: "(Unpairing a characteristic pair does not remove the characteristics from the guide)",
            empty:"You have not yet entered any Different Characteristics"
        },

        unlikecategories: {
            label: "Unlike Categories",
            singleLabel: "Category",
            help: "For example, glaciers and aquifers are created differently and contain different features. The category for this pair is:\n" +
                "‘*Defining characteristics of natural features.*’",
            placeholder: "The category that describes this pair is..."
        },

        summary: {
            label: "Summary",
            singleLabel: "Summary",
            help: "A summary is a brief explanation that includes main points and evidence of what you have learned.\n\n"+
                "_This summary would include statements about the similarities and differences between glaciers and aquifers. It includes statements about their importance, their features, and the need to conserve them._",
            placeholder: "In conclusion, after comparing Concept A and Concept B, I have learned...",
            empty:"You have not yet entered a Summary"
        },

        extensions: {
            label: "Extensions",
            singleLabel: "Extensions",
            help: "Your teacher will add an extension so that we can use our understandings in new situations.",
            placeholder: "Write your extension here.",
            empty:"You have not yet entered any Extensions"
        }
    },

    library: [
        {
            title: "Chemical Reaction",
            overview: "https://docs.google.com/document/d/1CjOx1YQWrYfUrfewnIJoon7D8HN_SZR_o2UCR784SUU/edit?usp=sharing",
            level: "Grades 6-8",
            tags: [ "Physical Science"],
            description: "In this unit, students will explore matter and its interactions in the form of chemical reactions. They will understand what happens to atoms when substances react in a chemical process, how chemical reactions affect the number of atoms involved (thus the mass of reactants vs. mass of products), and what the characteristics of exothermic and endothermic chemical reactions are.",
            lessons: [
                {
                    title: "Atoms, Substances, & Chemical Reactions",
                    type: "question-exp",
                    id: "1IHXEwdoMl0aY0rnYN8iwZRGUyXaoleXG"
                },
                {
                    title: "Conservation of Mass during a Chemical Reaction",
                    type: "cause-effect",
                    id: "13I2eOchCJ24FfADppkd5hIfYEZd2cRaZ"
                },
                {
                    title: "Exothermic & Endothermic Chemical Reaction",
                    type: "comparison",
                    id: "1AUL-5ngU44Ts10K3LRTn5XGcOWVzDpX9"
                }
            ]
        },
        {
            title: "Cycles of Matter and Energy Transfer in Ecosystems",
            overview: "https://docs.google.com/document/d/1zQqvaF7d7T0BVNSdLNEoNOUqwpU2PXms9vW8Ct06Nd8/edit",
            level: "Grades 6-8",
            tags: [ "Physical Science"],
            description: "In this 3-lesson unit, students will explore how cycles of matter and energy transfer. They will understand how matter cycles through living and nonliving parts in an ecosystem, explore how energy flows, and understand the characteristics of matter cycles and energy flows in an ecosystem.",
            lessons: [
                {
                    title: "Movement of Matter and Energy in an Ecosystem",
                    type: "comparison",
                    id: "1Lq9fkDho785VDEBw-FoSJYU5HiIRw0V1"
                },
                {
                    title: "Energy Flow Within and Outside of an Ecosystem",
                    type: "question-exp",
                    id: "1tvVjprFbmmZwEquUWx8UtvJVrDSeS4da"
                },
                {
                    title: "Matter Cycles in an Ecosystem",
                    type: "question-exp",
                    id: "1G_Q6zaLJUP3FtYi-agOvlOovmkUfXVaJ"
                },
            ]
        },
        {
            title: "Earth's Place in the Universe",
            overview: "https://docs.google.com/document/d/1dAB-lNMeE-E38YOxARfWgHytGgQ0Jq9f0H16WyvXqw8/edit",
            level: "Grades 6-8",
            tags: [ "Earth Science"],
            description: "In this unit, students will explore the place Earth has within our solar system. They will understand how the relationship between the Earth, its moon, and the sun cause a variety of phenomena we experience on Earth, such as day and night, the seasons, and eclipses.",
            lessons: [
                {
                    title: "Solar vs. Lunar Eclipses",
                    type: "comparison",
                    id: "1NO9IQpQrN_nWoNreKvD-uXXRNZ1-XMz9"
                },
                {
                    title: "The Seasons",
                    type: "cause-effect",
                    id: "16RFu_D_vwdxx9oIpCnDTm7wLHtHNZdVz"
                },
                {
                    title: "The Equinox & Solstice",
                    type: "question-exp",
                    id: "1bLSSyAWJ7NTRgTSHxJr6ekir-19SbWLw"
                },
                {
                    title: "Daylight Savings Time",
                    type: "cera",
                    id: "13--h0IKpwhKR6q8Qo004M7AWlRrMG-Ke"
                }
            ]
        },
        {
            title: "Forces and Interactions",
            overview: "https://docs.google.com/document/d/1ljhy0pjVT9fhp-m4PZ2cLC4Ngr3N-vGRlpftqcUqgXQ/edit",
            level: "Grades 6-8",
            tags: [ "Physical Science"],
            description: "In this unit, students will explore Newton’s Law of Motion. They will understand how electromagnetic forces attract or repel and the interaction of gravitational force.",
            lessons: [
                {
                    title: "Newton’s Law of Motion",
                    type: "cause-effect",
                    id: "19vSj4blbjeC81YANpk7Uj22c58hYGtqQ"
                },
                {
                    title: "Electric & Magnetic Force",
                    type: "comparison",
                    id: "1cLas8HNNCsSmoWJYgqwi_GiRdtqeuUMI"
                },
                {
                    title: "Gravitational Forces",
                    type: "question-exp",
                    id: "10HWyXvDZZeHwczb16nyAD-JzOJ3DeYTj"
                },
            ]
        },
        {
            title: "Global Climate Change",
            overview: "https://docs.google.com/document/d/1tjErHzI_Zk6rhVc-jpgGQIirz67w-TRmKIWQDfIeR04/edit?usp=sharing",
            level: "Grades 6-8",
            tags: [ "Earth Science"],
            description: "In this unit, students will explore how natural processes and human activities have impacted global temperatures, as well as explore how humans can adapt to reduce their impact on global climate change for the future.",
            lessons: [
                {
                    title: "Natural Processes & Human Activities Affect Global Temperatures",
                    type: "comparison",
                    id: "1M2tFLp1WDWc7v7Xy-8u1JDOdA7BxHuZw"
                },
                {
                    title: "Human Activities Impact Climate Change",
                    type: "cera",
                    id: "1ejwAuThL32LKvjM_FYpymhjKFcIKpYkt"
                },
                {
                    title: "Human Activities Contributing to Global Warming",
                    type: "cause-effect",
                    id: "160dE_Nd3HWGjKdVVACFuhXd-Grq5SFd2"
                },
                {
                    title: "Humans Can Reduce Their Impact On Climate Change",
                    type: "question-exp",
                    id: "1jUEq2QoGdAZIZcJSdbGcn8uHAQl4gTfA"
                }
            ]
        },
        {
            title: "Heredity",
            overview: "https://docs.google.com/document/d/1wITI3vvTorlYBP7g2TrmIC92QjBaO0aZ-M_XujoBCq0/edit?usp=sharing",
            level: "Grades 6-8",
            tags: [ "Life Science"],
            description: "In this abbreviated unit, students will explore inheritance and variation of traits. They will understand the growth of organisms and how organisms inherit traits and why there are variations in traits of offspring.",
            lessons: [
                {
                    title: "Asexual & Sexual Reproduction",
                    type: "comparison",
                    id: "1PxfjRMcPC9_YbTTj0BaJyZx9TFZ5avvh"
                },
                {
                    title: "Genetic Variation in Sexually Reproducing Organisms",
                    type: "cause-effect",
                    id: "1qgDst4lMnsZ6wB0_4D49C9t415kIfbRW"
                },
                {
                    title: "Role of Genes in Making Protein Molecules",
                    type: "question-exp",
                    id: "1EYOyfsiYTnV5WnLQlfbmDma5ZGQ-mhS_"
                },
                {
                    title: "Mutations",
                    type: "cause-effect",
                    id: "1XgCJjQiSXJJ4PVdMMa8nOD_7leqYgsMi"
                }
            ]
        },
        {
            title: "Kinetic and Potential Energy",
            overview: "https://docs.google.com/document/d/12zD7dTE_krWk19xS28-4Jh7UXin3awMgB71DN7ZzScI/edit?usp=sharing",
            level: "Grades 6-8",
            tags: [ "Physical Science"],
            description: "In this unit, students will explore kinetic and potential energy. They will understand the relationship between kinetic energy, mass, and velocity, as well as the relationship between potential energy, mass, and height.",
            lessons: [
                {
                    title: "Potential Energy and Mass or Height",
                    type: "question-exp",
                    id: "16SZ-6iJycE0L-jBYD_FLwn1o_wJwrpxq"
                },
                {
                    title: "Kinetic Energy and Mass or Velocity - A",
                    type: "cause-effect",
                    id: "1WruV7BpHq9pynPQWdO18Beg1_Fx8JkUy"
                },
                {
                    title: "Kinetic Energy and Mass or Velocity - B",
                    type: "question-exp",
                    id: "1mkpx2tR2I6WcZRR7R_mCpgHwwlBUWuPn"
                },
                {
                    title: "Kinetic & Potential Energy",
                    type: "comparison",
                    id: "1AOiwi5wFOQUvqVHvuLB7VNJdn6YjKrwh"
                }
            ]
        },
        {
            title: "Natural Resources and Natural Hazards",
            overview: "https://docs.google.com/document/d/13hZMEU6dftjb_5SUKHXXg6O63gzK4J8eAY9fjp3TEwM/edit?usp=sharing",
            level: "Grades 6-8",
            tags: [ "Earth Science"],
            description: "In this 3-lesson unit, students will explore different natural resources humans depend on and map and predict future natural hazards. They will understand the relationship of geographic distributions of natural resources, renewable and nonrenewable resources of groundwater, and natural hazards.",
            lessons: [
                {
                    title: "Unequal Distribution of Earth’s Resources",
                    type: "cera",
                    id: "1QIBG6W79aQ5--gUrtCHrlemqWtliFcsQ"
                },
                {
                    title: "Renewable and Nonrenewable Groundwater",
                    type: "comparison",
                    id: "1wNuYAWKa7TMaARKZQESm3K4kvMSCUZYF"
                },
                {
                    title: "Predicting Natural Hazards",
                    type: "question-exp",
                    id: "1Wi4qKA_5kZBqv7XsVoD1Fb8L2fl19aHG"
                }
            ]
        },
        {
            title: "Natural Selection and Adaptation",
            overview: "https://docs.google.com/document/d/1d4Y7bMFfjqyPPihYhE8OeR5kLH3qFq857TtteK9ayuA/edit?usp=sharing",
            level: "Grades 6-8",
            tags: [ "Life Science"],
            description: "In this unit, students will explore how natural selection and adaptation affect biological evolution and diversity. They will understand how natural selection leads to predominance of certain traits, characteristics of natural and artificial selection, and the impacts of environmental changes on the distribution of traits in a species.",
            lessons: [
                {
                    title: "Natural Selection and Adaptation",
                    type: "question-exp",
                    id: "1oXGX8aiUPOoGF6h4vdrejzVFbDoMbfPs"
                },
                {
                    title: "Natural Selection & Artificial Selection",
                    type: "comparison",
                    id: "1eX83dKrPmKw6RVzmcN4gWylEAuhRp9hJ"
                },
                {
                    title: "Distribution of Traits in a Species",
                    type: "cause-effect",
                    id: "13NoDRdirhkeD1DZoI9RGvcWhFjYRGRwE"
                }
            ]
        },
        {
            title: "Structure and Properties of Matter and its Interactions",
            overview: "https://docs.google.com/document/d/1mwgCBJUCkSDcX-ji1kHCxwEWzYA0k40HTyCrgmFPm7w/edit?usp=sharing",
            level: "Grades 6-8",
            tags: [ "Physical Science"],
            description: "In this 4-lesson unit, students will explore and understand the fundamental principles governing the composition, structure, and behavior of matter, and how matter interacts with other substances and energy.",
            lessons: [
                {
                    title: "How Atoms Combine To Create Pure Substance",
                    type: "cause-effect",
                    id: "1EsIkkdpbRTDFDCg4DZGkXSxSiYGYl1zK"
                },
                {
                    title: "How Pressure and Temperature Affect States of Matter",
                    type: "cause-effect",
                    id: "1BxFDtr1DGjFOoPHrDztHpLmn9PMgsJtc"
                },
                {
                    title: "Crystalline & Amorphous Solids",
                    type: "comparison",
                    id: "1aYE-ZQRVxImjEpUV7o5twhImXpIIYLlV"
                },
                {
                    title: "Physical & Chemical Properties Of Pure Substances",
                    type: "question-exp",
                    id: "1eUJQl0olouEoZWry09gyPJ79_8_YsImm"
                }
            ]
        }
    ]
}

export default texts;

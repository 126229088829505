import React, {Component} from "react";
import AppContext from "../AppContext";

export default class ViewOnlyNotification extends Component {
    static contextType = AppContext;

    render() {
        let canEdit = this.context.canEdit;
        let fileId= this.context.fileId;
        return(
            fileId != null && !canEdit?
            <div className="alert alert-topbar alert-view print-none" role="alert">
                <span className="fa fa-eye me-0_25" aria-hidden="true"></span>This Corgi Guide is <strong>view only</strong>.  To edit, <a href="# " onClick={this.props.copyButtonMethod}>make a copy</a>.
                <button type="button" className="close" data-cfw-dismiss="alert" aria-label="Close"><span className="fa fa-times" aria-hidden="true"></span></button>
            </div>
            : null
        )
    }
}
import React from 'react';
import OrganizerComponent from './OrganizerComponent';
import AppContext from "../AppContext";
import {empty} from "../components/textutil";
import texts from "../texts";
import Steps from "../components/Steps";
import BigPicture from "../components/BigPicture";
import Routine from "./Routine";
import PrintPreview from "../components/PrintPreview";
import EssentialQuestion from "../steps/Question-Exploration/EssentialQuestion";
import RelatedQuestions from "../steps/Question-Exploration/RelatedQuestions";
import RelatedQuestionsResponse from "../steps/Question-Exploration/RelatedQuestionsResponse";
import EssentialQuestionResponse from "../steps/Question-Exploration/EssentialQuestionResponse";
import ContextResponse from "../steps/Question-Exploration/ContextResponse";
import Extensions from "../steps/Question-Exploration/Extensions";
import CreateShare from "../components/CreateShare";
import Terms from '../components/Terms';

var assert = require('assert');

export default class QuestionExp extends OrganizerComponent {
    static contextType = AppContext;

    constructor(props){
        super(props);
        this.emptyState = {};

        this.props.loadDocMethod();
        this.focused = this.focused.bind(this);
        this.state = this.emptyState;
    }

    trackPageView(section){
        if('toc' === section) window.tracker.trackPageView(this.context.currentUser, 'question-exp:toc');
        if('essentialquestion' === section) window.tracker.trackPageView(this.context.currentUser, 'question-exp:1');
        if('terms' === section) window.tracker.trackPageView(this.context.currentUser, 'question-exp:2');
        if('relatedquestion' === section) window.tracker.trackPageView(this.context.currentUser, 'question-exp:3');
        if('relatedquestionresponse' === section) window.tracker.trackPageView(this.context.currentUser, 'question-exp:4');
        if('essentialquestionresponse' === section) window.tracker.trackPageView(this.context.currentUser, 'question-exp:5');
        if('contextresponse' === section) window.tracker.trackPageView(this.context.currentUser, 'question-exp:6');
        if('extensions' === section) window.tracker.trackPageView(this.context.currentUser, 'question-exp:7');
        if('complete' === section) window.tracker.trackPageView(this.context.currentUser, 'question-exp:review');
        if('print' === section) window.tracker.trackPageView(this.context.currentUser, 'question-exp:print-preview');
        if('create-share' === section) window.tracker.trackPageView(this.context.currentUser, 'question-exp:create-share');
    }

    render() {
        if (!this.context.fileIdLoaded || null === this.state.baseNode) {
            return <div>Loading file...</div>;
        }
        // console.log(this.props);
        const section = this.props.fileSection;
        const doc = {
            section: section,
            type: this.context.fileType,
            id: this.context.fileId,
            title: this.context.fileName,
            editing: this.context.canEdit
        };

        const sections = texts[doc.type].sections;
        assert(undefined !== sections && 0 < sections.length);

        const toc = this.focused(doc.section) ?
            <div id={sections[0].toc} className={"section"}>
                <Steps doc={doc} steps={sections}/>
            </div>
            : "";

        const essential_question = <EssentialQuestion doc={doc} section={texts["question-exp"].sections[1]}/>;
        const related_question = <RelatedQuestions doc={doc} section={texts["question-exp"].sections[3]}/>;
        const related_question_response = <RelatedQuestionsResponse doc={doc} section={texts["question-exp"].sections[4]}/>;
        const essential_question_response = <EssentialQuestionResponse doc={doc} section={texts["question-exp"].sections[5]}/>;
        const context_response = <ContextResponse doc={doc} section={texts["question-exp"].sections[6]}/>;
        const extensions = <Extensions doc={doc} section={texts["question-exp"].sections[7]}/>;
        const print_preview =
            <PrintPreview doc={doc} section={"print"} data={this.state.characteristicsData}/>;

        const createShare = <CreateShare shareMethod={this.props.shareMethod}/>;

        const terms = <Terms doc={doc} section={texts["question-exp"].sections[2]} tooltipTitle={true}/>;

        this.trackPageView(section);

        const content = this.focused(section) ?
        ( 'toc' === section ? toc
            : 'essentialquestion' === section ? essential_question
            : 'terms' === section ? terms
            : 'relatedquestion' === section ? related_question
            : 'relatedquestionresponse' === section ? related_question_response
            : 'essentialquestionresponse' === section ? essential_question_response
            : 'contextresponse' === section ? context_response
            : 'extensions' === section ? extensions
            : 'complete' === section ? <BigPicture section={section} data={this.state.characteristicsData}  editing={false}/>
            : 'print' === section ? print_preview
            : 'create-share' === section ? createShare
            : toc
        )
        : (
            <BigPicture section={"complete"} data={this.state.characteristicsData} editing={false}/>
        );

        return <Routine content={content} doc={doc} data={this.state.characteristicsData} {...this.props} />
    }

    focused(section) {
        return (!empty(section) && 'complete' !== section);
    }
}

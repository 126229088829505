import React from "react";
import ReadOnlyField from "../../ReadOnlyField";
import KeyTerms from "../KeyTerms";
import RelatedQuestionList from "../../components/RelatedQuestionList";
import T from "i18n-react";

export const question_exp_snapshot = (doc) => {
    return(
        <React.Fragment>
            <h1 className="h3"><ReadOnlyField identifier={"topic"} /></h1>
            <div className="section">
                <h2 className="h1 text-center">Snapshot</h2>
            </div>
            <div className="section">
                <h3 className="h2">{T.translate("question-exp.essentialquestion.title")}</h3>
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"essentialquestion"} />
                </div>
            </div>
            <div className="section">
                <h3 className="h2">{T.translate("question-exp.essentialquestionresponse.title")}</h3>
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"essentialquestionresponse"} />
                </div>
            </div>
        </React.Fragment>
    )
}

export const question_exp_process = (doc) =>{
        return(
        <React.Fragment>
            <div className="section section-border-top-dashed">
                <h2 className="h1 text-center">Question Exploration Process</h2>
            </div>

            <div className="section">
                <h3 className="h2">{T.translate("question-exp.terms.label")}</h3>
                <KeyTerms doc={doc}  editing={false} printing={true} secondary={true}/>
            </div>

            <div className="section">
                <h3 className="h2">{T.translate("question-exp.relatedquestion.toptitle")}</h3>
                <div className="row d-sm-down-none" aria-hidden="true">
                <div className="col-md-6">
                    <T.div text={{key: "question-exp.relatedquestion.title"}} className="h3"></T.div>
                </div>
                <div className="col-md-6">
                    <T.div text={{key: "question-exp.relatedquestionresponse.title"}} className="h3"></T.div>
                </div>
            </div>
                <RelatedQuestionList doc={doc} identifier="relatedquestion" haveResponse={true} editing={false} print={true} canBeEmpty={false}/>
            </div>

            <div className="section">
                <h3 className="h2">{T.translate("question-exp.contextresponse.title")}</h3>
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"contextresponse"} className={"col"} />
                </div>
            </div>

            <div className="section">
                <h3 className="h2">{T.translate("question-exp.extensions.title")}</h3>
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"extensions"} className={"col"} />
                </div>
            </div>
        </React.Fragment>
    )
}
import SttInput from "../../components/SttInput";
import React, {Fragment} from "react";
import ReadOnlyField from "../../ReadOnlyField";
import StepHeader from "../StepHeader";
import AppContext from "../../AppContext";

export default class Event extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.identifier = "event";
    }

    render() {
        return (
            <Fragment>
                { this.props.editing ?
                    <Fragment>
                        <StepHeader section={this.props.section} editing={true} tooltipTitle={true}/>
                        <h2>Essential Question</h2>
                        <ReadOnlyField identifier="question" className="box bg-autofill" />

                        <h2>Main event</h2>
                        { this.context.canEdit ?
                            <Fragment>
                                <div className="box box-primary">
                                    <SttInput {...this.props} identifier={this.identifier} addImage={true} inputType={"text-area"} singleLine={false}/>
                                </div>
                            </Fragment>
                        :
                            <Fragment>
                                <div className={"box bg-autofill"}>
                                    <div className={"row"}>
                                        <ReadOnlyField identifier={this.identifier} className={"grouping-text"} />
                                        <ReadOnlyField identifier={this.identifier + "/image"} className={"grouping-img"} />
                                    </div>
                                </div>
                           </Fragment>
                        }
                    </Fragment>
                :
                    <div className="grouping-item">
                        <div className={"box box-autofill"}>
                            <div className={"row"} >
                                <ReadOnlyField identifier={this.identifier} className={"grouping-text"}/>
                                {!this.props.noImage?
                                    <ReadOnlyField identifier={this.identifier+"/image"} className={"grouping-img"} />
                                    :null
                                }
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        );
    }

}

export function getFirepadTextPromise(context, path) {
    return new Promise((resolve) => {
        let firepadHeadless = new window.Firepad.Headless(getRef(context, path));
        firepadHeadless.getText(text => {
            firepadHeadless.dispose();
            resolve(text);
        });
    });
}

export function getRef(context, identifier) {
    let path = context.fileType + "/" + context.fileId + "/" + identifier;
    return window.firebase.database().ref(path);
}

import DynamicComponent from '../DynamicComponent';
import AppContext from "../AppContext";

/**
 * (Abstract) component that holds an extendable list of some sort.
 * Used for lists of terms and definitions, questions and answers, etc.
 *
 * Subclasses must implement render(), newItem(model), and getCurrentNode().
 */
export default class ListComponent extends DynamicComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        // Set to false if at least one list element is required.
        this.canBeEmpty = (undefined !== this.props.canBeEmpty)? this.props.canBeEmpty: true;
        this.state = {
            canDelete: false,
            items: {}   // List of item
        };
        this.listItemRefs = null;
        this.allowUpdateContent = false; // This gets set to true after the mount process is done.

        this.handleAddItem = this.handleAddItem.bind(this);
        this.getContentList = this.getContentList.bind(this);
        this.handleDeleteItem = this.handleDeleteItem.bind(this);
        this.checkDeleteLegality = this.checkDeleteLegality.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.listItemRefs = window.firebase.database().ref(this.getCurrentNode());
        this.addWatcher = this.listItemRefs.on('child_added', data => {
            let currentItems = this.state.items;
            currentItems[data.key] = data.val();
            this.setState({
                items: currentItems,
                canDelete: this.checkDeleteLegality(currentItems)
            });
        });
        this.removeWatcher = this.listItemRefs.on('child_removed', data => {
            let currentItems = this.state.items;
            delete currentItems[data.key];
            this.setState({
                items: currentItems,
                canDelete: this.checkDeleteLegality(currentItems)
            });
        });
        if (!this.props.secondary) {
            if (!this.canBeEmpty) {
                // Check whether list is empty, if so add an initial item.
                this.valueWatcher = this.listItemRefs.once('value', data => {
                    if (!data.exists() || Object.keys(data.val()).length === 0) {
                        console.debug(this.getCurrentNode(), ' is empty, adding a list item');
                        this.handleAddItem();
                    }
                    this.allowUpdateContent = true;
                });
            } else {
                // List can be empty, no check needed.
                this.allowUpdateContent = true;
            }
        }
    }

    getMapValues(){
        return new Promise((resolve,reject)=>{
            const itemList = this.getContentList();
            if (itemList) {
                let keys = [];
                let promises = [];
                for (let key in itemList) {
                    if (itemList.hasOwnProperty(key)){
                        let path = this.getValuePath(key);
                        keys.push(key);
                        promises.push(this.getFirepadTextPromise(path));
                    }
                }
                Promise.all(promises).then((values) => {
                    let map = new Map();
                    for (let i=0; i<keys.length; i++) {
                        map.set(keys[i], values[i]);
                    }
                    resolve(map);
                });
            }
        })
    }

    getMapImages(){
        return new Promise((resolve,reject)=>{
            const itemList = this.getContentList();
            if (itemList) {
                let keys = [];
                let promises = [];
                for (let key in itemList) {
                    if (itemList.hasOwnProperty(key)){
                        let path = this.getImagePath(key);
                        keys.push(key);
                        promises.push(this.getImageUrlPromise(path));
                    }
                }
                Promise.all(promises).then((values) => {
                    let map = new Map();
                    for (let i=0; i<keys.length; i++) {
                        map.set(keys[i], values[i]);
                    }
                    resolve(map);
                });
            }
        })
    }

    getMapItems(){
        let proms = [
            this.getMapValues(),
            this.getMapImages()
        ];

        return Promise.all(proms)
			.then((maps) => {
			return {
				'mapText' : maps[0],
				'mapImage' : maps[1]
			};
		});
    }

    removeEmptyItem(){
        this.getMapItems().then((maps) =>{
            if(maps != null){
                let numberItems = Object.keys(this.getContentList()).length;
                let mapText = maps["mapText"];
                let mapImage = maps["mapImage"];
                mapText.forEach((value,key) =>{
                    let imageUrl = mapImage.get(key);
                    if(!this.hasContent(value) && !this.hasContent(imageUrl)){
                        if(numberItems > 1){
                            this.handleDeleteItem(key);
                            numberItems = numberItems -1;
                        }
                    }
                });
            }
        })
    }

    hasContent(text) {
        return undefined !== text && null !== text && text.trim();
    }

    componentWillUnmount() {
        this.listItemRefs.off('child_added', this.addWatcher);
        this.listItemRefs.off('child_removed', this.removeWatcher);
        this.removeEmptyItem();
    }

    componentDidUpdate(prevProps, prevState) {
        if (Object.keys(this.state.items).length !== Object.keys(prevState.items).length) {
            this.setState({
                canDelete: this.checkDeleteLegality(this.state.items)
            })
        }
    }

    // Create a new item add add it to the list
    handleAddItem() {
        if (!this.context.canEdit)
            return;
        console.debug("ListComponent.handleAddItem");
        if(this.allowUpdateContent)
            this.context.contentUpdated();
        this.newItem();
        window.tracker.trackEvent("Content", "add item", this.identifier);
    }

    handleDeleteItem(id) {
        if (!this.state.canDelete) {
            console.log("Cannot delete, would make list empty");
            return;
        }
        this.context.contentUpdated();
        window.speechstream.speechTools.stop();
        window.firebase.database().ref(this.getCurrentNode() + "/" + id).remove();
        window.tracker.trackEvent("Content", "remove item", this.identifier);
    }

    // Determine whether delete buttons should be active; return as a boolean value.
    checkDeleteLegality(items) {
        if (!this.context.canEdit || !items)
            return false;
        if (this.canBeEmpty)
            return true;
        return (Object.keys(items).length > 1);
    }

    // Get content list and listen to change
    getContentList() {
        return this.state.items;
    }

    getRef(path) {
        return window.firebase.database().ref(path);
    }

    getFirepadTextPromise(path) {
		return new Promise((resolve, reject) => {
            let firepadHeadless = new window.Firepad.Headless(this.getRef(path));
            firepadHeadless.getText(text => {
                firepadHeadless.dispose();
                resolve(text);
            });
		});
    }

    getImageUrlPromise(path) {
        if(this.hasContent(path)){
            return this.getRef(path).once("value").then(data => {
                return (data.val());
            });
        }else return new Promise((resolve, reject) =>{
            resolve("");
        })
	}
}

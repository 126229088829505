import React from 'react';
import { Component } from 'react';
import Breadcrumb from "../Breadcrumb";
import AppContext from "../AppContext";
import {doHashScroll} from "./HashScroll";
import { EmbeddedYoutube, ImageLink } from "./HowToMedia";

export default class UserSupport extends Component {
    static contextType = AppContext;

    // Some of these are videos, and some are links to slide presentations.

    static shorts = [
        {
            id: 1,
            name: 'Corgi: Graphic organizers transformed',
            video: 'Qz0T8C57fl0',
            description: 'Corgi: Graphic organizers transformed (video)',
            transcript: "short-transformed.html"
        },
        {
            id: 2,
            name: 'Corgi: Empowering Students to Think',
            video: 'wvjWh-1Xxw0',
            description: 'Corgi: Empowering Students to Think (video)',
            transcript: "short-empowering.html"
        }
//        {
//            id: 3,
//            name: 'Corgi: Supporting Students to Rise',
//            video: '_X49NGUjkdU',
//            description: 'Corgi: Supporting Students to Rise (video)',
//            transcript: "short-support.html"
//        }
    ];

    static howtos = [
        {
            id: 1,
            name: 'How to use Google Classroom with Corgi',
            video: '1TvTdI3bB90',
            description: 'How to use Google Classroom with Corgi (video)'
        },
        {
            id: 2,
            name: 'Introduction to Corgi\'s features',
            link: 'https://docs.google.com/presentation/d/1nC7AWtgtuWJDStX02Goy8Va0eswfKZtIlS3KKuq-RI4/present',
            thumbnail: 'intro-to-corgi-features.png',
            description: 'Introduction to Corgi\'s features (slide deck)'
        },
        {
            id: 3,
            name: 'What\'s in every Corgi Guide?',
            link: 'https://docs.google.com/document/d/1navsFRuY7f1T5zAfsV7GgYgjHLtqMoXXF7JHPrJQrCg/edit',
            thumbnail: 'whats-in-every-guide.png',
            description: 'What\'s in every Corgi Guide? (document)'
        },
        {
            id: 4,
            name: 'Sharing a Corgi guide',
            link: 'https://docs.google.com/presentation/d/1F3emK3ptsRgIDSn23pd-EcbOwlW8Rx1dvqBMkCP5VZM/present',
            thumbnail: 'sharing-a-corgi-guide.png',
            description: 'How to share a Corgi guide (slide deck)'
        },
        {
            id: 5,
            name: 'Sharing a Corgi guide',
            link: 'https://docs.google.com/document/d/1HVu5boJEvz_Q8pM8cOXFtw3u6-N8EB6zfP62jGsgtmw/edit',
            thumbnail: 'sharing-a-corgi-guide.png',
            description: 'How to share a Corgi guide (document)'
        },
        {
            id: 6,
            name: 'Authorizing Corgi with Google',
            link: 'https://docs.google.com/presentation/d/1z9TaKCdRZ5E5TuVvbLuBl_D-g15qaP3-_kGEy3lEZZU/present',
            thumbnail: 'how-to-authorize.png',
            description: 'How to authorize Corgi to work with your Google account (slide deck)'
        },
        {
            id: 7,
            name: 'Authorizing Corgi with Google',
            link: 'https://docs.google.com/document/d/1lxgWSJ9yo6bK5kw_P37eK0N3oQ-hI70OUj6g2QqxApw/edit',
            thumbnail: 'how-to-authorize.png',
            description: 'How to authorize Corgi to work with your Google account (document)'
        },
        {
            id: 8,
            name: 'Student Demo',
            link: 'https://docs.google.com/presentation/d/1bzyvi2lm435I7T9gX3cRtwV4dd64eo7jev5G-MbZWFQ/present',
            thumbnail: 'how-to-student-demo.png',
            description: 'Learn with Corgi - Student Demo (video)'
        }
    ];

    static plSeries = [
        {
            id: 1,
            name: 'Teaching with Corgi',
            link: 'https://docs.google.com/document/d/1ytgJ3pLu-MCOFfnGNdavmsU2LjiDNUMvKxrLKpmP-xo/edit',
            thumbnail: 'teaching-with-corgi.png',
            description: 'Teaching with Corgi (document)'
        },
        {
            id: 2,
            name: 'Corgi Tips for Teachers',
            link: 'https://docs.google.com/document/d/180wQ7OW6GgIcOLKEehAFzeUa_GJNZP3UmyjHQ051wFI/edit',
            thumbnail: 'tips-for-teachers.png',
            description: 'Corgi Tips for Teachers (document)'
        },
        {
            id: 3,
            name: 'Ten Ways Corgi Supports Quality Instruction',
            link: 'https://docs.google.com/document/d/1pIX3OFUszss_ZG5OgLFKkPICm_aKbh6ylb2Ne2PPnwY/edit',
            thumbnail: 'quality_instruction.png',
            description: 'Ten Ways Corgi Supports Quality Instruction (document)'
        },
        {
            id: 4,
            name: 'UDL + Corgi',
            link: 'https://docs.google.com/presentation/d/15yKlMbd2iGtlQIyYd1cYFlqKzT67ymV9hG1KI5y58ZE/present',
            thumbnail: 'udl_corgi.png',
            description: 'UDL + Corgi (slide deck)'
        },
        {
            id: 5,
            name: 'UDL + Corgi',
            link: 'https://docs.google.com/document/d/1RY-S60SMC00d1Watfr7afQNrcQY3Qrld0hsPqFyNxWY/edit',
            thumbnail: 'udl_corgi.png',
            description: 'UDL + Corgi (document)'
        },
        {
            id: 6,
            name: 'Determining Which Guide to Use',
            link: 'https://docs.google.com/document/d/1MY9NnRZwCYQKaeJ0-MG2ckFbieZGtxGa4k9LdhzGnU8/edit',
            thumbnail: 'determining_guide.png',
            description: 'Determining Which Guide to Use (document)'
        },
        {
            id: 7,
            name: 'Using Corgi in Different Contexts',
            link: 'https://docs.google.com/document/d/1zFdQQ0F2BcRNPMoPFHWHWI4QXJs2AKwTCoeBrAp5dNU/edit',
            thumbnail: 'different_contexts.png',
            description: 'Using Corgi in Different Contexts (document)'
        },
        {
            id: 8,
            name: 'Reviewing Corgi Guides',
            link: 'https://docs.google.com/document/d/13Xs-Ynkc9tR1E0sqT5rSWhnYUn2L8QbDvkEMx1ZZSKM/edit',
            thumbnail: 'reviewing-guides.png',
            description: 'Reviewing Corgi Guides (document)'
        }
    ];

    componentDidMount() {
        if (this.context.currentUser !== null)
            window.tracker.trackPageView(this.context.currentUser, 'user-support');

        doHashScroll();
    }

    render() {
        let shortsHtml = [];
        for (let n = 0; n < UserSupport.shorts.length; n++) {
            const item = UserSupport.shorts[n];
            if (item.hasOwnProperty('video')) {
                shortsHtml.push(<EmbeddedYoutube key={n} item={item} />);
            } else {
                shortsHtml.push(<ImageLink key={n} item={item} />);
            }
        }

        let howtoHtml = [];
        for (let n = 0; n < UserSupport.howtos.length; n++) {
            const item = UserSupport.howtos[n];
            if (item.hasOwnProperty('video')) {
                howtoHtml.push(<EmbeddedYoutube key={n} item={item} />);
            } else {
                howtoHtml.push(<ImageLink key={n} item={item} />);
            }
        }
        let plHtml = [];
        for (let n = 0; n < UserSupport.plSeries.length; n++) {
            const item = UserSupport.plSeries[n];
            if (item.hasOwnProperty('video')) {
                plHtml.push(<EmbeddedYoutube key={n} item={item} />);
            } else {
                plHtml.push(<ImageLink key={n} item={item} />);
            }
        }
        // let resourcesHtml = [];
        // for (let n=0; n < UserSupport.resources.length; n++) {
        //     const item = UserSupport.resources[n];
        //     resourcesHtml.push(<ImageLink key={n} item={item} />);
        // }

        return (
            <div className="page-wrapper">
                <div id="content" className="content row">
                    <div className="content-main col">
                        <div className="content-main-inner">
                             <Breadcrumb name="How to use Corgi" />
                             <main id="main_container">
                                <h1>How to use Corgi</h1>
                                <p>Google slide decks can present accessibility barriers. To reduce barriers Corgi has provided a matching Google doc as an alternative option for viewing.</p>

                                <div className="section section-border-top">
                                    <h2 id="video">Corgi shorts</h2>
                                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                                        {shortsHtml}
                                    </div>
                                </div>

                                <div className="section section-border-top">
                                    <h2 id="video">How to series</h2>
                                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                                        {howtoHtml}
                                    </div>
                                </div>

                                <div className="section section-border-top">
                                    <h2 id="pl">Corgi professional learning series</h2>
                                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                                        {plHtml}
                                    </div>
                                </div>

                            </main>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

import React from "react";
import ReadOnlyField from "../../ReadOnlyField";
import texts from "../../texts";
import RelatedQuestionList from "../../components/RelatedQuestionList";
import KeyTerms from "../KeyTerms";
import PreviewComponent from "../../components/PreviewComponent";
import AppContext from "../../AppContext";
import {Link} from "react-router-dom";
import EmptyField from "../../components/EmptyField";
import T from 'i18n-react';

export const topic = (doc,fieldStatus,inCompleteStep) =>{
    return(
        <React.Fragment>
            <div className="section">
                <h2>Topic</h2>
                {!inCompleteStep ?
                    <ReadOnlyField identifier="topic" className="box bg-autofill" />
                    : fieldStatus.hasTopic ?
                        <ReadOnlyField identifier="topic" className="box bg-autofill" />
                        :
                        <EmptyField identifier="topic" className="box bg-unfilled"/>
                }
            </div>

            <div className="section">
                <h2>{T.translate("question-exp.essentialquestion.title")}</h2>
                {!inCompleteStep ?
                    <div className={"box bg-autofill"} >
                        <div className={"row"}>
                            <ReadOnlyField identifier="essentialquestion" className={"grouping-text"} />
                            <ReadOnlyField identifier="essentialquestion/image" className={"grouping-img"} />
                        </div>
                    </div>
                    : fieldStatus.hasEssentialQuestion ?
                        <div className={"box bg-autofill"} >
                            <div className={"row"}>
                                <ReadOnlyField identifier="essentialquestion" className={"grouping-text"} />
                                <ReadOnlyField identifier="essentialquestion/image" className={"grouping-img"} />
                            </div>
                        </div>
                        : <EmptyField identifier={"essentialquestion"} className="box bg-unfilled"/>
                }
            </div>
        </React.Fragment>
    )
}

export const keyTerms = (doc,fieldStatus,inCompleteStep) => {
    const section = texts[doc.type].terms;
    return (
            <div className="section">
                <h2>{section.title ? section.title : section.label}</h2>
                {!inCompleteStep ?
                    <KeyTerms doc={doc} section={section} editing={false} secondary={true}/>
                : fieldStatus.hasKeyTerms ?
                    <KeyTerms doc={doc} section={section} editing={false} secondary={true}/>
                    :
                    <EmptyField identifier={"terms"} className="box bg-unfilled"/>
                }
            </div>
    )
}

export const related_question_response = (doc,fieldStatus,inCompleteStep) =>{
    return (
        <div className="section">
            <h2>{T.translate("question-exp.relatedquestion.toptitle")}</h2>
            <div className="row d-sm-down-none" aria-hidden="true">
                <div className="col-md-6">
                    <T.div text={{key: "question-exp.relatedquestion.title"}} className="h3"></T.div>
                    {inCompleteStep && !fieldStatus.hasRelatedQuestion && !fieldStatus.hasRelatedQuestionAnswer ?
                        <EmptyField identifier={"relatedquestion"} className="box bg-unfilled"/>
                        : null
                    }
                </div>
                <div className="col-md-6">
                    <T.div text={{key: "question-exp.relatedquestionresponse.title"}} className="h3"></T.div>
                    {inCompleteStep && !fieldStatus.hasRelatedQuestionAnswer && !fieldStatus.hasRelatedQuestion ?
                        <EmptyField identifier={"relatedquestionresponse"} className="box bg-unfilled"/>
                        : null
                    }
                </div>
            </div>

            {!inCompleteStep ?
                <RelatedQuestionList doc={doc} identifier="relatedquestion" haveResponse={true} editing={false} canBeEmpty={false}/>
                : fieldStatus.hasRelatedQuestion || fieldStatus.hasRelatedQuestionAnswer ?
                <RelatedQuestionList doc={doc} identifier="relatedquestion" haveResponse={true} editing={false} canBeEmpty={false}/>
                : null
            }

        </div>
    )
}

export const essential_question_response = (doc,fieldStatus,inCompleteStep) =>{
    const eqResponse = <React.Fragment>
        <div className={"box bg-autofill"} >
            <div className={"row"}>
                <ReadOnlyField identifier="essentialquestionresponse" className={"grouping-text"} />
                <ReadOnlyField identifier="essentialquestionresponse/image" className={"grouping-img"} />
            </div>
        </div>
    </React.Fragment>

    return(
        <div className="section">
            <h2>{T.translate("question-exp.essentialquestionresponse.title")}</h2>
            <div className="row gx-0_5 mt-0">
                <div className="col-sm-12 mt-0">
                    {!inCompleteStep ?
                        <React.Fragment>{eqResponse}</React.Fragment>
                        : fieldStatus.hasEssentialQuestionResponse ?
                            <React.Fragment>{eqResponse}</React.Fragment>
                            :
                            <EmptyField identifier={"essentialquestionresponse"} className="box bg-unfilled"/>
                    }
                </div>
            </div>
        </div>
    )
}

export const context_response = (doc,fieldStatus,inCompleteStep) =>{
    const contextResponse = <React.Fragment>
        <div className={"box bg-autofill"} >
            <div className={"row"}>
                <ReadOnlyField identifier="contextresponse" className={"grouping-text"} />
                <ReadOnlyField identifier="contextresponse/image" className={"grouping-img"} />
            </div>
        </div>
    </React.Fragment>

    return(
        <div className="section">
            <h2>{T.translate("question-exp.contextresponse.title")}</h2>
            {!inCompleteStep ?
                <React.Fragment>{contextResponse}</React.Fragment>
                : fieldStatus.hasContextResponse ?
                    <React.Fragment>{contextResponse}</React.Fragment>
                :   <EmptyField identifier={"contextresponse"} className="box bg-unfilled"/>
            }
        </div>
    )
}

export const extensions = (doc,fieldStatus,inCompleteStep) =>{
    const ext = <React.Fragment>
        <div className={"box bg-autofill"} >
            <div className={"row"}>
                <ReadOnlyField identifier="extensions" className={"grouping-text"} />
                <ReadOnlyField identifier="extensions/image" className={"grouping-img"} />
            </div>
        </div>
    </React.Fragment>

    return(
        <div className="section">
            <h2>{T.translate("question-exp.extensions.title")}</h2>
            <React.Fragment>{ext}</React.Fragment>
        </div>
    )
}

export const review_checklist = (doc,fieldStatus,closeChecklist,onClickFunc) => {
    return (
            fieldStatus.display ?
                <div className="section">
                    <h2 className="h3">Review Checklist</h2>

                    <div className="box box-unfilled">
                        <div className="box-row-close">
                            <button type="button" className="btn btn-icon btn-link" aria-label="Remove key term" title="Remove Checklist" onClick={closeChecklist}>
                                <span className="fa fa-times text-primary" aria-hidden="true"></span>
                            </button>
                        </div>
                        <ul>
                            {!fieldStatus.hasTopic ? <li>You have not yet entered a <b>Topic</b>. Go to <Link to="essentialquestion" onClick={() => onClickFunc(1)}>Step 1</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasEssentialQuestion ? <li>You have not yet entered an <b>Essential Question</b>. Go to <Link to="essentialquestion" onClick={() => onClickFunc(1)}>Step 1</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasKeyTerms ? <li>You have not entered any <b>Key Terms</b>. Go to <Link to="terms" onClick={() => onClickFunc(2)}>Step 2</Link> to define Key Terms</li> : null}
                            {!fieldStatus.hasRelatedQuestion ? <li>You have not entered any <b>Supporting Questions</b>. Go to <Link to="relatedquestion" onClick={() => onClickFunc(3)}>Step 3</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasRelatedQuestionAnswer ? <li>You have not entered any <b>Supporting Question Responses</b>. Go to <Link to="relatedquestionresponse" onClick={() => onClickFunc(4)}>Step 4</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasEssentialQuestionResponse ? <li>You have not entered an <b>Essential Question Response</b>. Go to <Link to="essentialquestionresponse" onClick={() => onClickFunc(5)}>Step 5</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasContextResponse ? <li>You have not entered an <b>Real-world Context</b>. Go to <Link to="contextresponse" onClick={() => onClickFunc(6)}>Step 6</Link> to complete the section.</li> : null}
                        </ul>
                    </div>
                </div>
            : null
    )
}

export default class QuestionExpSteps extends PreviewComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state ={
            display : false,
            hasTopic: false,
            hasEssentialQuestion: false,
            hasKeyTerms:false,
            hasEssentialQuestionResponse: false,
            hasContextResponse: false,
            hasRelatedQuestion: false,
            hasRelatedQuestionAnswer: false
        };
        this.closeChecklist = this.closeChecklist.bind(this);
        this.checkEmptyList = this.checkEmptyList.bind(this);
        this.mapHasContent = this.mapHasContent.bind(this);
    }

    closeChecklist(){
        this.setState({display: false});
    }

    checkEmptyField(field){
        this.getFirepadTextPromise(field).then((data) =>{
            let hasContent = this.hasContent(data);
            if("topic" === field) this.setState({hasTopic: hasContent});
            if("essentialquestion" === field) this.setState({hasEssentialQuestion: hasContent});
            if("essentialquestionresponse" === field) this.setState({hasEssentialQuestionResponse: hasContent});
            if("contextresponse" === field) this.setState({hasContextResponse: hasContent});
            this.updateChecklist();
        });
    }

    updateChecklist(){
        let showChecklist = false;
        if(!this.state.hasTopic) showChecklist = true;
        if(!this.state.hasEssentialQuestion) showChecklist = true;
        if(!this.state.hasKeyTerms) showChecklist = true;
        if(!this.state.hasEssentialQuestionResponse) showChecklist = true;
        if(!this.state.hasContextResponse) showChecklist = true;
        if(!this.state.hasRelatedQuestion) showChecklist = true;
        if(!this.state.hasRelatedQuestionAnswer) showChecklist = true;

        this.setState({
            display : showChecklist
        })
    }

    componentDidMount() {
        for (let field of ['topic','essentialquestion','essentialquestionresponse','contextresponse','extensions']) {
            this.checkEmptyField(field);
        }

        this.checkEmptyList();
    }

    getQuestionExpMapsPromise() {
		let proms = [
			this.getFirepadMapPromise('terms', 'def'),
            this.getFirepadMapPromise('terms', 'term'),
            this.getFirepadMapPromise('relatedquestion', 'question'),
            this.getFirepadMapPromise('relatedquestion', 'answer')
		];
		return Promise.all(proms)
			.then((maps) => {
			return {
				'termsdef' : maps[0],
                'termsword' : maps[1],
                'relatedQuesion' : maps[2],
                'relatedQuesionAnswer' : maps[3]
			};
		});
    }

    checkEmptyList(){
        this.getQuestionExpMapsPromise().then((valueMaps) =>{
            if(valueMaps != null) {
                console.log(valueMaps);
                var hasTermOrDef = this.mapHasContent(valueMaps['termsword'])
                    || this.mapHasContent(valueMaps['termsdef']);
                let update = {
                    hasKeyTerms: hasTermOrDef,
                    hasRelatedQuestion: this.mapHasContent(valueMaps['relatedQuesion']),
                    hasRelatedQuestionAnswer: this.mapHasContent(valueMaps['relatedQuesionAnswer'])
                };
                this.setState(update);
                this.updateChecklist();
            }
        });
    }

    onClickFunc(step){
        window.tracker.trackEvent("Navigation", "checklist link", step);
    }

    render(){
        return <React.Fragment>
            {this.props.inCompleteStep && this.context.canEdit ? review_checklist(this.props.doc,this.state,this.closeChecklist,this.onClickFunc) : null }
            {topic(this.props.doc,this.state,this.props.inCompleteStep)}
            {keyTerms(this.props.doc,this.state,this.props.inCompleteStep)}
            {related_question_response(this.props.doc,this.state,this.props.inCompleteStep)}
            {essential_question_response(this.props.doc,this.state,this.props.inCompleteStep)}
            {context_response(this.props.doc,this.state,this.props.inCompleteStep)}
            {extensions(this.props.doc,this.state,this.props.inCompleteStep)}
        </React.Fragment>
    }
}
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ListComponent from '../../components/ListComponent';
import uniqueId from '../../uniqueId';
import T from 'i18n-react';
import SttInput from "../../components/SttInput";
import AppContext from "../../AppContext";
import ReadOnlyField from "../../ReadOnlyField";
import DeleteModal from "../../components/DeleteModal";
import DeleteButton from "../../components/DeleteButton";

/**
 Effects of main events
 */
export default class Effects extends ListComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.identifier = "effect";
        this.canBeEmpty = false;

        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const editing = (undefined !== this.props.editing)? this.props.editing: true;
        const inRoutine = (undefined !== this.props.inRoutine) ? this.props.inRoutine : false;
        const itemList = this.getContentList();
        const groupingCount = Object.keys(itemList).length ? Object.keys(itemList).length : 1;
        let itemsHTML = [];
        let i = 1;
        const last = Object.keys(itemList).length;
        if (itemList) {
            for (let key in itemList) {
                if (itemList.hasOwnProperty(key)){
                    itemsHTML.push(<EffectItem key={key}
                        id={key}
                        type={this.context.fileType}
                        identifier={this.identifier}
                        parentNode={this.getCurrentNode()}
                        deleteMethod={this.handleDeleteItem}
                        canDelete={editing && this.state.canDelete}
                        editing={editing}
                        noImage={this.props.noImage}
                        inRoutine={inRoutine}
                        canEdit={this.context.canEdit}
                    />);
                    if (editing && i < last) {
                        itemsHTML.push(
                            <div key={i} className="bracket bracket-short">
                                <div className="bracket-bar-vertical"></div>
                            </div>)
                    }
                    i = i+1;
                }
            }
        }
        const disabled = 4 <= Object.keys(itemList).length;
        let addButton = editing?
            <button onClick={this.handleAddItem} type="button" className="btn btn-primary mb-1" disabled={disabled} >
                <span className="fa fa-plus me-0_25" aria-hidden="true"></span>
                Add Effect
            </button>
            : null;
        if(!this.context.canEdit) addButton = null;


        const bracket = () => {
            if (this.props.editing) {
                return (
                    <div className="bracket bracket-short">
                        <div className="bracket-arrow-vertical"></div>
                    </div>
                );
            };

            switch(groupingCount) {
                case 4: return (
                    <div className="bracket bracket-down bracket-4">
                        <div className="bracket-arrow-vertical"></div>
                        <div className="bracket-start"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-bar-start"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-arrow"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-bar-end"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-end"></div>
                    </div>
                );
                case 3: return (
                    <div className="bracket bracket-down bracket-3">
                        <div className="bracket-arrow-vertical"></div>
                        <div className="bracket-start"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-bar-start"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-arrow"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-end"></div>
                    </div>
                );
                case 2: return (
                    <div className="bracket bracket-down bracket-2">
                        <div className="bracket-arrow-vertical"></div>
                        <div className="bracket-start"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-arrow"></div>
                        <div className="bracket-bar"></div>
                        <div className="bracket-end"></div>
                    </div>
                );
                case 1:
                default: return (
                    <div className="bracket bracket-down">
                        <div className="bracket-arrow-vertical"></div>
                    </div>
                );
            }
        };

        return (
        this.props.editing?
            <Fragment>
                {this.props.children}
                <h2 className="grouping-title mt-n1_5 pt-0_25">Effects of the Main event</h2>
                {itemsHTML}
                {addButton}
            </Fragment>
        :
            this.props.inSteps ?
                <Fragment>
                    {this.props.children}
                    {this.props.children ?
                            bracket()
                        :
                            null
                        }
                    {itemsHTML}
                </Fragment>
            :
                this.props.printing ?
                    <Fragment>
                        {this.props.children}
                        {bracket()}
                        <div className={"grouping grouping-end grouping-" + groupingCount}>
                            <h3 className="h2 grouping-title">Effects and Connections of the Main Event or Idea</h3>
                            {itemsHTML}
                        </div>
                    </Fragment>
                :
                    <Fragment>
                        {this.props.children}
                        {this.props.children ?
                                bracket()
                            :
                                null
                            }
                        <div className={"grouping grouping-end grouping-" + groupingCount}>
                            <h2 className="grouping-title">Effects of the Main event</h2>
                            {itemsHTML}
                         </div>
                        {addButton}
                    </Fragment>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            text: "",
            status: ""
        });
    }

    getValuePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/text";
        return path;
    }

    getImagePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/text/image";
        return path;
    }
}


/**
 *  Component for single Effect item
 */
class EffectItem extends Component {

    constructor(props) {
        super(props);

        this.deleteModalId = uniqueId();
        this.deleteButtonId = uniqueId();
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
    }

    render () {
        const effect = this.props.identifier + "/" + this.props.id+"/text";
        const deleteMessage = "Do you really want to delete this effect?";
        const deleteModal = <DeleteModal id={this.deleteModalId} method={this.handleDeleteButton} text={deleteMessage} yes="Remove"/>
        const deleteIcon = this.props.canEdit ? <DeleteButton icon={"fa-times"} tooltip={"Remove Effect"} modalId={this.deleteModalId}
                                                                canDelete={this.props.canDelete}/> : null;

        return ( this.props.editing && this.props.canEdit ?
                <div className="box box-primary">
                    { this.props.canDelete ?
                        <div className="box-row-close">
                            {deleteModal}
                            {deleteIcon}
                        </div>
                    :
                        null
                    }
                    <div className="form-group">
                        <SttInput addImage={true}
                                  label={T.translate(this.props.type + ".effect.label")}
                                  placeholder={T.translate(this.props.type + "." + this.props.identifier + ".placeholder")}
                                  identifier={effect} />
                    </div>
                </div>
                :
                <div className="grouping-item">
                    <div className="box box-autofill">
                        <div className="row">
                            <ReadOnlyField identifier={effect} className={'grouping-text'}/>
                            {this.props.noImage? null:
                                <ReadOnlyField identifier={effect+"/image"} className={'grouping-img'}/>
                            }
                        </div>
                    </div>
                </div>
    );
    }

    handleDeleteButton() {
        this.props.deleteMethod(this.props.id);
    }

}

EffectItem.propTypes = {
    id: PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired,
    parentNode: PropTypes.string.isRequired
};

import React from 'react';
import {hosting} from '../hosting';
import PropTypes from 'prop-types';

export default function DisplayImage(props) {

    const src = (props.image.src && props.image.src.startsWith('/img'))? hosting+props.image.src: props.image.src;
    return props.image.src?
        <div className = {props.containerClassName? props.containerClassName: "attachment-media"} >
            <img className="img-fluid" src={src} alt={props.image.alt} />
        </div>: null
}

DisplayImage.propTypes = {
    image: PropTypes.object.isRequired,
};

import React, {Fragment} from "react";
import StepHeader from "../StepHeader";
import AppContext from "../../AppContext";
import ReadOnlyField from "../../ReadOnlyField";
import SimpleCharacteristicList from "../../components/SimpleCharacteristicList";

export default class CharacteristicsStep extends React.Component {
    static contextType = AppContext;

    render() {
        // maybe more than 2 concepts in future?
        return (
            <Fragment>
                <StepHeader tooltipTitle="{true}" section={this.props.section} defineKeyTerm={true}/>
                <h2>Essential Question</h2>
                <ReadOnlyField identifier="essentialquestion" className="box bg-autofill" />

                <div className="row">
                    <div className="col-md-6">
                        <h2>Concept A</h2>
                        <ReadOnlyField identifier="concept1" className="box bg-autofill" />
                        <SimpleCharacteristicList doc={this.props.doc} identifier="characteristics1" canBeEmpty={false} data={this.props.data}/>
                    </div>
                    <div className="col-md-6">
                        <h2>Concept B</h2>
                        <ReadOnlyField identifier="concept2" className="box bg-autofill" />
                        <SimpleCharacteristicList doc={this.props.doc} identifier="characteristics2" canBeEmpty={false} data={this.props.data}/>
                    </div>
                </div>
            </Fragment>
        );

    }
}

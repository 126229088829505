import React from "react";
import DynamicComponent from "../DynamicComponent";
import DocumentCard from "./DocumentCard";
import {firebaseConfig} from '../realtimedb-utils';
import DocPicker from "./DocPicker";
import AppContext from "../AppContext";
import {hosting} from "../hosting";

export default class MyDocuments extends DynamicComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {orderBy: "modifiedByMeTime"};

        this.getDocuments = this.getDocuments.bind(this);
        this.getCards = this.getCards.bind(this);
        this.changeOrderByOption = this.changeOrderByOption.bind(this);
    }

    // List the current users Google Drive CORGI app documents and runs the given callback with the result
    getDocuments(type) {

        let appId = firebaseConfig? firebaseConfig.clientId.split('-')[0]: "42"; // for OrganizerChoice test
        let m = "mimeType = 'application/vnd.google-apps.drive-sdk."+appId+"'";
        let t = "trashed = false";
        // let mod = "modifiedTime >= '"+subDays(new Date(),30).toISOString()+"'"; // check definition of recent

        return new Promise(resolve => {
            let criteria = {
                fields: "files(id,name,mimeType,properties/type,modifiedTime,trashed,description,parents)",
                orderBy: this.state.orderBy + " desc",
                pageSize: 3, // maxResults doesn't work
                q: t +" and "+m //, +" and "+mod,
            };
            if (type) {
                criteria.type = type;
            }

            if (undefined !== window.gapi)
                window.gapi.client.request(
                {
                    path: "https://www.googleapis.com/drive/v3/files",
                    method: "GET",
                    params: criteria
                }).then((response) => {
                    this.setState({documents: response.result.files});
                    resolve(response.result.files);
                },
                (err_reason) => {
                    console.log("Could not get Drive Documents: " + err_reason.result.error.message);
                    this.setState({
                        error: 1
                    })
                    if(err_reason.result.error.code === 401) {
                        this.context.handleAccessTokenExpired();
                    }
                })
            else {
                console.error('undefined window.gapi');
                this.setState({
                    error: 1
                });
            }
        })
    }

    getCards(documents) {
        if (Array.isArray(documents)) {
            if (documents.length === 0) {
                return (<p>You have no recent guides. To get started, create a new Corgi Guide below!</p>);
            } else {
                return (
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3"> {
                        documents.map((doc) =>
                            <DocumentCard key={doc.id} document={doc}/>
                        )
                    } </div>
                );
            }
        } else {
            console.error("documents is not array");
        }
    }

    changeOrderByOption(event) {
        this.state.orderBy = event.target.value;

        this.getDocuments(null).then(() => {
            this.setState({cards: this.getCards(this.state.documents)})
        });
    }

    componentWillMount() {

        this.getDocuments(null).then(() => {
            this.setState({cards: this.getCards(this.state.documents)})
        });
    }

    render() {

        let docSection;
        if (this.state.documents != null && this.state.documents.length !== 0) {
            docSection =
                <div id={"My-Documents"} className="section">
                    <h2 className="pt-2">My Recent Corgi Guides</h2>
                    <div className="mb-1">
                        <form className="form-inline">
                            <label htmlFor="display" className="form-label me-0_25"><b>Display:</b></label>
                            <select id="display" className="form-control d-inline-block"
                                    value={this.state.orderBy} onChange={this.changeOrderByOption}>
                                <option value="modifiedByMeTime">Last modified by me</option>
                                <option value="viewedByMeTime">Last opened by me</option>
                                <option value="modifiedTime">Last modified</option>
                            </select>
                        </form>
                    </div>
                    {this.state.cards}

                    <div className="d-flex flex-end">
                        <DocPicker>
                            <button className="btn btn-primary text-right">
                                See all Guides in my Google Drive
                                <span className="pages-next-icon fa fa-chevron-right" aria-hidden="true"></span>
                            </button>
                        </DocPicker>
                    </div>
                </div>;
        } else {
            docSection =
                <div className="section">
                    <h2 className="pt-2">Get Started</h2>
                    <p>It looks like you&rsquo;re new here. Check out these quick how-to guides to help you get started using Corgi:</p>
                    <div className="row flex-center">
                        <div className="col-12 col-sm-6 col-lg-4 mb-1">
                            <a href="https://docs.google.com/presentation/d/1nC7AWtgtuWJDStX02Goy8Va0eswfKZtIlS3KKuq-RI4/present" target="_blank" rel="noopener noreferrer">
                                <img className="img-fluid border" src={hosting + '/img/intro-to-corgi-features.png'} alt="Introduction to Corgi's features" />
                                Introduction to Corgi&rsquo;s features
                                <span className="fas fa-external-link-alt ms-0_25" title="(external site)"></span>
                            </a>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mb-1">
                            <a href="https://docs.google.com/presentation/d/1F3emK3ptsRgIDSn23pd-EcbOwlW8Rx1dvqBMkCP5VZM/present" target="_blank" rel="noopener noreferrer">
                                <img className="img-fluid border" src={hosting + '/img/sharing-a-corgi-guide.png'} alt="Sharing a Corgi guide" />
                                Sharing a Corgi guide
                                <span className="fas fa-external-link-alt ms-0_25" title="(external site)"></span>
                            </a>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mb-1">
                            <div id="player" className="embed-fluid embed-fluid-16x9">
                                <iframe id="ytPlayer" src="https://www.youtube-nocookie.com/embed/1TvTdI3bB90?enablejsapi=1" frameBorder="0"
                                        title="Youtube Video" aria-label="YouTube Video: How to use Google Classroom with Corgi"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                            </div>
                            <a href="https://youtu.be/1TvTdI3bB90" target="_blank" rel="noopener noreferrer">
                                How to use Google Classroom with Corgi
                                <span className="fas fa-external-link-alt ms-0_25" title="(external site)"></span>
                            </a>
                        </div>
                    </div>
                </div>;
        }

        return (
            <React.Fragment>
                <div className="doctitle">
                    <h1 id="content">Welcome, {this.context.currentUser.displayName}</h1>
                </div>
                {docSection}
            </React.Fragment>
        )}
}

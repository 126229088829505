import React from 'react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import YouTubeVideo from "./YouTubeVideo";

export default class DisplayVideo extends Component {

    render(){
        const src = this.props.video.src;

        if (src) {
            // Regex to match YouTube ID string: https://stackoverflow.com/a/27728417
            // Value is in [1]
            const regexExtractId = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
            const idString = src.match(regexExtractId)[1];

            return (
                <div className = {this.props.containerClassName? this.props.containerClassName: "attachment-media flex-grow"} >
                    <YouTubeVideo video_id={idString} />
                </div>);
        } else {
            return null;
        }
    }
}

DisplayVideo.propTypes = {
    video: PropTypes.object.isRequired,
    containerClassName: PropTypes.string
};

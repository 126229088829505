import RelatedQuestionList from "../../components/RelatedQuestionList";
import React, {Fragment} from "react";
import StepHeader from "../StepHeader";
import ReadOnlyField from "../../ReadOnlyField";

export default class RelatedQuestions extends React.Component {

    constructor(props) {
        super(props);
        this.identifier = "relatedquestion";
    }

    render() {
        return (
            <Fragment>
                <StepHeader section={this.props.section} tooltipTitle={true}/>
                <h2>Essential Question</h2>
                <ReadOnlyField identifier="essentialquestion" className="box bg-autofill flex-grow-1" />
                <RelatedQuestionList doc={this.props.doc} identifier="relatedquestion" canBeEmpty={false} data={this.props.data}/>
            </Fragment>
        );
    }

}

import React, {Component,Fragment} from 'react';
import PropTypes from 'prop-types';
import ListComponent from './ListComponent';
import uniqueId from '../uniqueId';
import SttInput from "./SttInput";
import {empty} from "./textutil";
import AppContext from "../AppContext";
import StepHeader from "../steps/StepHeader";

/**
 * List of terms and definitions.
 */
export default class Terms extends ListComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.canBeEmpty = false;
        this.editing = this.props.editing;
        this.fileType = !empty(this.props.docType)? this.props.docType: null;
        this.fileId = !empty(this.props.docId)? this.props.docId: null;
        this.tooltipTitle = (undefined !== this.props.tooltipTitle)? this.props.tooltipTitle: false;
        this.identifier = "terms";
        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const termList = this.getContentList();
        const section = this.props.section;
        if (!this.fileType) {
            this.fileType = this.context.fileType;
        }
        if (!this.fileId) {
            this.fileId = this.context.fileId;
        }
        let termsHTML = [];

        if (termList) {
            for (let key in termList) {
                if (termList.hasOwnProperty(key)) {
                    termsHTML.push(<Term key={key}
                                         id={key}
                                         deleteMethod={this.handleDeleteItem}
                                         canDelete={this.state.canDelete}
                                         fileType={this.fileType}
                                         fileId={this.fileId}
                                         editing={true}
                                         section={section}
                    />);
                }
            }
        }

        const addButton = this.context.canEdit ? <div className="row mb-1">
            <div className="col-12">
                <button type="button" onClick={this.handleAddItem} className="btn btn-primary">
                    <span className="fa fa-plus me-0_25" aria-hidden="true"></span>
                    Add Key Term
                </button>
            </div>
        </div>: null;
        const termLabel = section.term? section.term.label: "Word or Phrase";
        const defLabel = section.def? section.def.label: "Definition";

        return (
            <Fragment>
                <StepHeader section={section} tooltipTitle={this.tooltipTitle} />
                <div className="row" aria-hidden="true">
                    <div className="col-md-6">
                        <div className="h2">{termLabel}</div>
                    </div>
                    <div className="col-md-6">
                        <div className="h2">{defLabel}</div>
                    </div>
                </div>
                {termsHTML}
                {addButton}
            </Fragment>
        );
    }

    getCurrentNode() {
        return this.fileType + "/" + this.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            term: "",
            def: ""
        })
    }

    getValuePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/term";
        return path;
    }

    getImagePath(key) {
        return "";
    }
}

Terms.propTypes = {
    stepnum: PropTypes.string,
};

/**
 *  Component for single Term-Definition pair
 */
class Term extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.deleteModalId = uniqueId();
        this.termId = uniqueId();
        this.defId = uniqueId();
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
    }

    render() {
        let canEdit = this.context.canEdit;
        let boxCss = canEdit ? "box bg-primary" : "";

        return (
            <div className={boxCss}>
                <div  role="group" aria-label="Vocabulary item">
                    { this.props.canDelete?
                        <div className="box-row-close">
                            <button type="button" className={"btn btn-icon btn-link"} aria-label="Remove key term"
                                onClick={this.handleDeleteButton}
                                >
                                <span className="fa fa-times text-primary" aria-hidden="true"></span>
                            </button>
                        </div>
                        : null
                    }
                    <div className="row">
                        <div className="col-md-6">
                            <SttInput addImage={false}
                                    domId={this.termId} {...this.props}
                                    inputClassName=" "
                                    inputType={"text-area"}
                                    label="term"
                                    placeholder={this.props.section.term.placeholder}
                                    identifier={"terms/" + this.props.id + "/term"}
                            />
                        </div>
                        <div className="col-6">
                            <SttInput addImage={false}
                                    domId={this.defId} {...this.props}
                                    inputClassName=" "
                                    inputType={"text-area"}
                                    label="definition"
                                    placeholder={this.props.section.def.placeholder}
                                    identifier={"terms/" + this.props.id + "/def"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleDeleteButton(event) {
        if (!this.props.canDelete) {
            window.alert("There must be at least one entry in this list");
        } else
        if (window.confirm("Really delete this term and definition?")) {
            this.props.deleteMethod(this.props.id);
        }
        // this.props.deleteMethod(this.props.id+"/image");
    }
}

Term.propTypes = {
    id: PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired
};

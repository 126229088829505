import React from 'react';
import PropTypes from 'prop-types';
import DynamicComponent from '../DynamicComponent';
import GetInvolved from '../GetInvolved';
import MyDocuments from '../components/MyDocuments';
import NewDocument from '../components/NewDocument';
import AppContext from "../AppContext";
import {Wait} from "../components/MainContent";
import PageLink from "../components/PageLink";

/**
 * Displays a choice of the user's existing CERs
 * and information about the different CER types that can be created.
 */
export default class OrganizerChoice extends DynamicComponent {
    static contextType = AppContext;

    constructor() {
        super();
        this.typeChosen = false;
    }

    render() {
        if (this.context.createPending) {
            return <Wait loading={true}/>
        }

        let info = <div className="text-end">
            <PageLink page="support" className="btn btn-success mx-0_5">How to use Corgi</PageLink>
            <PageLink page="library" className="btn btn-success">Lesson Library</PageLink>
        </div>;

        return (
            <div className="page-wrapper">
                <div className="content row">
                    <div className="content-main col">
                        <div className="content-main-inner">
                            <main id={"main_container"} className="main">
                                {info}
                                <GetInvolved/>
                                <MyDocuments/>
                                <NewDocument newDocButtonMethod={this.props.newDocButtonMethod} authorizeWithPopUp={this.props.authorizeWithPopUp}/>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    componentDidMount() {
        super.componentDidMount();
        window.tracker.trackPageView(this.context.currentUser, 'home');
    }
}

OrganizerChoice.propTypes = {
    newDocButtonMethod: PropTypes.func.isRequired
};

import React from 'react';
import { Component } from 'react';
import { hosting } from "../hosting";
import YouTubeVideo from "./YouTubeVideo";

/* Used on PreLogin and UserSupport pages */

export class EmbeddedYoutube extends Component {

    render() {
        return (
            <div className="col mb-1">
                <YouTubeVideo video_id={this.props.item.video} video_name={this.props.item.name} />
                {/*<span className="fas fa-external-link-alt" title="(external site)"></span>*/}
                { this.props.item.hasOwnProperty('transcript') ?
                    <a href={"/transcripts/" + this.props.item.transcript}>{this.props.item.name} - Video Transcript</a>
                :
                    this.props.item.description
                }
            </div>
        );
    }
}

export class ImageLink extends Component {

    render() {
        return (
            <div className="col mb-1">
                <a href={this.props.item.link} className="link-icon" target="_blank" rel="noopener noreferrer">
                    {/* <img className="img-fluid border" src={hosting + '/img/' + this.props.item.thumbnail} alt={this.props.item.name} /> */}
                    <img className="img-fluid border" src={hosting + '/img/' + this.props.item.thumbnail} alt="" role="presentation" />
                    {this.props.item.description}
                    <span className="fas fa-external-link-alt" title="(external site)"></span>
                </a>
            </div>
        );
    }
}
// Authorize button launches the Google login popup
import DynamicComponent from "./DynamicComponent";
import PropTypes from "prop-types";
import React from "react";
import {firebaseConfig} from './realtimedb-utils';
export class AuthButton extends DynamicComponent {
    componentDidMount() {
        if(window.google) {
            this.initGSI();
        } else {
            document.getElementById("gsiClientScript").addEventListener("load", () => {
                this.initGSI();
            });
        }
    }

    initGSI() {
        window.google.accounts.id.initialize({
            client_id: firebaseConfig.clientId,
            callback: this.props.authorizeMethod,
        });
        //
        window.google.accounts.id.renderButton(
            document.getElementById("loginButton"),
            {theme: "filled_blue"}  // customization attributes
        );
    }

    render() {
        return (
            <div id="loginButton">
            </div>
        )
    }

}

AuthButton.propTypes = {
    authorizeMethod: PropTypes.func.isRequired
};

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import uniqueId from '../uniqueId';
import InputComponent from "./InputComponent";
import {codeMirrorSingleLineConfig,codeMirrorMultilineConfig} from "../codeMirrorConfig";
import ComponentHeader from "./ComponentHeader";
import GetText from "./GetText";
import {empty} from "./textutil";
import ReadOnlyField from "../ReadOnlyField";

import AppContext from "../AppContext";
import OptionalImage from "./OptionalImage";

/*
 * SttInput - text input field with speech-to-text (STT) support
 *
 * This class is attempting to abstract the STT feature separately from the input component
 * using composition, rather than inheritance.
 *
 * See https://reactjs.org/docs/composition-vs-inheritance.html
 *
 * STT functionality needs to be able to get/set the text in the input component.
 *
 * The input components in Corgi are based on firepad / CodeMirror.
 * firepad.getText() is called to record currentText in handleListen when STT is enabled
 * and this value is prepended to final/interim transcript
 * and passed back to the input component via firepad.setText()
 *
 * It is not clear that the current text of the input component needs to be accessed when STT starts.
 *
 * The TextHelp STT "recognition" object is accessed via the React "context".  This is set in the App.
 *
 * The mic toggle button will be placed in the input component, if the "recognition" object is defined.
 *
 * props that affect the STT functionality or the display of the input component include:
 *
 *   domId = a DOM id to be assigned to the input element.  defaults to a new uniqueId
 *
 *   parentNode - optional override of context fileType / fileId
 *   identifier - controls mapping of input to document element path. path also includes context fileType and fileId
 *   placeholder - will override the default attempt to find a placeholder based on identifier
 *   labelClassName - overrides the default "sr-only" class for a label that may be looked up based on identifier
 *   label - overrides lookup of label text
 *
 *   onReady - a function taking a firepad object parameter
 *     this is used in GuideTitle to use the input text to set the document name
 *
 *   size - an abbreviation for the size of the input text font, lg or xl
 *     this gets appended to a class affecting the CSS, if set to 'none' the default class ending in 'lg' will not be set
 *
 *   inputType - may be set to text-area for multiline input. added to class names for CSS
 *   singleLine - may be set which affects the CodeMirror configuration (enter key submit and no scrollbars)
 */
export default class SttInput extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.addImage = undefined !== this.props.addImage? this.props.addImage: false;
        this.showTourGuide = undefined !== this.props.showTourGuide? this.props.showTourGuide: false;
        this.formClassName = "form-group";
        this.domId = undefined !== this.props.domId? this.props.domId: uniqueId();
        this.buttonId = uniqueId();
        this.buttonClassName = "stt-btn";
        this.iconClassName = "fa fa-microphone";
        this.screenReaderText = "Use Speech to Text";
        this.labelClassName = this.props.labelClassName?this.props.labelClassName: "sr-only";
        this.inputClassName = this.props.inputClassName?this.props.inputClassName: "box bg-primary";

        this.state = {
            editing: true,
            listening: false,
        };

        this.getText = this.getText.bind(this);
        this.setText = this.setText.bind(this);

        this.toggleListen = this.toggleListen.bind(this);
        this.handleListen = this.handleListen.bind(this);
        this.handleResult = this.handleResult.bind(this);
        this.handleStopped = this.handleStopped.bind(this);

        this.sttButton = null;
        this.firepad = null;

        this.updateContent = this.props.updateContent;
    }

    componentWillUnmount() {
        this.context.recognition.quit();
    }

    render() {

        this.fileType= this.context.fileType;
        this.fileId= this.context.fileId;
        this.id = undefined !== this.identifier ? this.identifier : this.props.identifier;
        let canEdit = this.context.canEdit;

        const label = undefined !== this.props.label? this.props.label
            : <GetText type={this.fileType} identifier={this.id?this.id.split('/').join('.'):'dummy'} item={"label"} />
        const directions = this.props.directions? this.props.directions: null;
        this.sttButton = () => {
            return (this.context.recognition && this.context.recognition.isSupported() ?
                    <button onClick={this.toggleListen} id={this.buttonId} type="button" className={`${this.buttonClassName} enabled ${(this.state.listening) ? 'active' : ''}`}>
                        <span className={this.iconClassName} aria-hidden={"true"}></span>
                        <span className={"sr-only"}>{this.screenReaderText}</span>
                    </button>
                :null);
        }
        const textInput = <InputComponent domId={this.domId} type={this.fileType} {...this.props} button={this.sttButton} />;

        return (
            canEdit?
                <React.Fragment>
                    {this.renderLabel(label,this.fileType)}
                    {directions? <p>directions</p>: null}
                        <div className={this.formClassName}>
                            {textInput}
                        </div>
                        {this.addImage?
                            <OptionalImage updateContent={this.updateContent} identifier={this.id}/>
                        : null}
                </React.Fragment>
            :
            <React.Fragment>
                <div className={"box bg-autofill"} >
                    <div className={"row"}>
                        <ReadOnlyField identifier={this.id} className={"grouping-text"} />
                        {!this.addImage? null:
                            <ReadOnlyField identifier={this.id + "/image"} className={"img"} />}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderLabel(label,type) {
        // May need to wrap this in <div className="hinline" in some cases, not sure if that ever occurs in the new design.
        return (
        this.props.stepnum && '0' < this.props.stepnum ?
            <ComponentHeader stepnum={this.props.stepnum} type={type} identifier={this.id}/>
          : label ?
            <label htmlFor={this.domId} className={this.labelClassName}>{label}</label>
          : ""
        );
    }

    getIdentifier() {
        return !empty(this.identifier) ? this.identifier : this.props.identifier;
    }

    getCurrentNode() {
        let parentNode = undefined !== this.props.parentNode? this.props.parentNode: this.fileType + "/" + this.fileId;
        return parentNode + "/" + this.getIdentifier();
    }


    lookup(type,identifier,item) {
        const key = [type,identifier,item].join('.');
        let value = <GetText type={type} identifier={identifier} item={item} />;
        return value === key? "": ReactDOMServer.renderToString(value);
    }
    componentDidMount() {
        if(this.context.canEdit){
            const placeholder = empty(this.props.placeholder)
                ? this.lookup( this.fileType, this.getIdentifier(),'placeholder')
                : this.props.placeholder;
            let firepadRef = window.firebase.database().ref(this.getCurrentNode());

            this.codeMirror = window.CodeMirror(document.getElementById(this.domId),
                this.props.singleLine?
                    codeMirrorSingleLineConfig(!this.state.editing, placeholder? placeholder: "")

                :   codeMirrorMultilineConfig(!this.state.editing, placeholder? placeholder: "")
            );
            this.firepad = window.Firepad.fromCodeMirror(firepadRef, this.codeMirror, {
                richTextShortcuts: false,
                richTextToolbar: false
            });

            if (this.props.onReady) {
                this.firepad.on("ready", () => this.props.onReady(this.firepad));
                this.currentText = this.firepad.getText();
            }

            this.firepad.on("ready", () => {
                let text = this.firepad.getText();
                if(null !== text){
                    let trimText = text.trim();
                    if(trimText !== text)
                        this.firepad.setText(text);
                }
            });

            this.firepad.on("synced", () => {
                this.context.contentUpdated();
                if(undefined !== this.updateContent) this.updateContent();
            });
        }
    }

    getText() {
        if (!this.firepad) {
            return "";
        }
        return this.firepad.getText();
    }

    setText(output) {
        return this.firepad.setText(output);
    }

    toggleListen() {
        if (this.state.listening) {
            this.context.recognition.stopListening();
        } else {
            this.setState({ listening: true },
                this.handleListen);
        }
    }

    handleListen() {
        this.currentText = this.getText();
        this.finalTranscript = '';
        this.context.recognition.startListening(this.handleResult, this.handleStopped);
    }

    handleStopped() {
        this.setState({listening: false});
    }

    handleResult(event) {
        if (!this.state.listening) return;

        let interimTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
                this.finalTranscript += event.results[i][0].transcript;
            } else {
                interimTranscript += event.results[i][0].transcript;
            }
        }

        let output = this.currentText
            + this.finalTranscript
            + interimTranscript;
        this.setText(output);
    }
}

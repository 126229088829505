// Portions from:
// https://github.com/googleworkspace/md2googleslides
// https://github.com/googleworkspace/md2googleslides/blob/main/src/layout/presentation_helpers.ts
// https://github.com/googleworkspace/md2googleslides/blob/main/src/layout/generic_layout.ts

// Typescript has been removed or converted from source

/**
 * Locates a page by ID
 *
 * @param presentation
 * @param {string} pageId Object ID of page to find
 * @returns {Object} Page or null if not found
 */
export function findPage(presentation, pageId) {
    if (!presentation.slides) {
        return undefined;
    }
    return presentation.slides.find(function(p) {
        return p.objectId === pageId;
    });
}


/**
 * Find a named placeholder on the page.
 *
 * @param presentation
 * @param {string} pageId Object ID of page to find element on
 * @param name Placeholder name.
 * @returns {Array} Array of placeholders
 */
export function findPlaceholder(presentation, pageId, name) {
    const page = findPage(presentation, pageId);
    if (!page) {
        throw new Error(`Can't find page ${pageId}`);
    }

    const placeholders = [];
    if (!page.pageElements) {
        return undefined;
    }

    for (const element of page.pageElements) {
        if (
            element.shape &&
            element.shape.placeholder &&
            name === element.shape.placeholder.type
        ) {
            placeholders.push(element);
        }
    }

    if (placeholders.length) {
        return placeholders;
    }

    return undefined;
}


/**
 * Find a named placeholder by the content placeholder string
 * Narrow down by page if possible so we don't have to parse entire slide presentation
 *
 * @param presentation
 * @param {string} pageId Object ID of page to find element on
 * @param name Placeholder name.
 * @returns {Array} Array of placeholders items - slideId and pageElement
 */
export function findPlaceholderByContent(presentation, pageId, name) {
    let pages = [];
    const placeholders = [];

    if (pageId !== null) {
        const page = findPage(presentation, pageId);
        if (!page) {
            throw new Error(`Can't find page ${pageId}`);
        }
        pages.push(page);
    } else {
        pages = presentation.slides;
    }

    for (let i=0; i < pages.length; i++) {
        if (pages[i].pageElements.length) {
            let page = pages[i];

            for (let j=0; j < page.pageElements.length; j++) {
                let pageElement = page.pageElements[j];

                if (
                    pageElement.shape &&
                    pageElement.shape.text &&
                    pageElement.shape.text.textElements &&
                    pageElement.shape.text.textElements.length
                ) {
                    for (let k=0; k < pageElement.shape.text.textElements.length; k++) {
                        let textElement = pageElement.shape.text.textElements[k];

                        if (
                            textElement.textRun &&
                            textElement.textRun.content &&
                            textElement.textRun.content.includes(name)
                        ) {
                            placeholders.push({
                                slideId: page.objectId,
                                pageElement: pageElement
                            });
                        }
                    }
                }
            }
        }
    }

    if (!placeholders.length > 0) {
        return undefined;
    }

    return placeholders;
}


// Get bounding box for a pageElement
export function calculateBoundingBox(element) {
    const height = element.size.height.magnitude;
    const width = element.size.width.magnitude;
    const scaleX = element.transform && element.transform.scaleX ? element.transform.scaleX : 1;
    const scaleY = element.transform && element.transform.scaleY ? element.transform.scaleY : 1;
    const shearX = element.transform && element.transform.shearX ? element.transform.shearX : 0;
    const shearY = element.transform && element.transform.shearY ? element.transform.shearY : 0;

    return {
        width: scaleX * width + shearX * height,
        height: scaleY * height + shearY * width,
        x: element.transform && element.transform.translateX ? element.transform.translateX : 0,
        y: element.transform && element.transform.translateY ? element.transform.translateY : 0,
    };
}


// Create requests to inject a video
// Video is added to page, scaled to placeholder element's size,
// then gets positioned 'on top' of the placeholder element
export function getCreateVideoRequests(videoId, slideId, placeholderElm) {
    const requests = [];
    const videoWidth = 640;
    const videoHeight = 360; // 16:9 aspect
    const box = calculateBoundingBox(placeholderElm);

    const scaleRatio = Math.min(
        box.width / videoWidth,
        box.height / videoHeight
    );

    const scaledWidth = videoWidth * scaleRatio;
    const scaledHeight = videoHeight * scaleRatio;

    const translateX = box.x + (box.width - scaledWidth) / 2;
    const translateY = box.y + (box.height - scaledHeight) / 2;

    const objectId = String(Date.now());

    requests.push({
        createVideo: {
            source: 'YOUTUBE',
            objectId: objectId,
            id: videoId,
            elementProperties: {
                pageObjectId: slideId,
                size: {
                    height: {
                        magnitude: scaledHeight,
                        unit: 'EMU'
                    },
                    width: {
                        magnitude: scaledWidth,
                        unit: 'EMU'
                    },
                },
                transform: {
                    scaleX: 1,
                    scaleY: 1,
                    translateX: translateX,
                    translateY: translateY,
                    shearX: 0,
                    shearY: 0,
                    unit: 'EMU'
                }
            }
        }
    });
    requests.push({
        updateVideoProperties: {
            objectId: objectId,
            fields: 'autoPlay',
            videoProperties: {
                autoPlay: false
            }
        }
    });

    return requests;
}
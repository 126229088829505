// CORGI-104 pre-login
import { AuthButton } from "./AuthButton";
import React from "react";
import AppContext from "./AppContext";
import { hosting } from './hosting';
import initUI from "./ui";
import PageLink from "./components/PageLink";
import { EmbeddedYoutube, ImageLink } from "./components/HowToMedia";

export default class PreLogin extends React.Component {
    static contextType = AppContext;

    static howtos = [
        {
            id: 1,
            name: 'Authorizing Corgi with Google',
            link: 'https://docs.google.com/document/d/1lxgWSJ9yo6bK5kw_P37eK0N3oQ-hI70OUj6g2QqxApw/edit',
            thumbnail: 'how-to-authorize.png',
            description: 'Authorizing Corgi with Google (Doc)'
        },
        {
            id: 2,
            name: 'What\'s in every Corgi Guide?',
            link: 'https://docs.google.com/document/d/1navsFRuY7f1T5zAfsV7GgYgjHLtqMoXXF7JHPrJQrCg/edit',
            thumbnail: 'whats-in-every-guide.png',
            description: 'What\'s in every Corgi Guide? (Doc)'
        },
        {
            id: 3,
            name: 'Sharing a Corgi guide',
            link: 'https://docs.google.com/document/d/1HVu5boJEvz_Q8pM8cOXFtw3u6-N8EB6zfP62jGsgtmw/edit',
            thumbnail: 'sharing-a-corgi-guide.png',
            description: 'Sharing a Corgi guide (Doc)'
        },
    ]

    componentDidMount() {
        initUI();
    }

    render() {
        let howtoHtml = [];
        for (let n = 0; n < PreLogin.howtos.length; n++) {
            const item = PreLogin.howtos[n];
            if (item.hasOwnProperty('video')) {
                howtoHtml.push(<EmbeddedYoutube key={n} item={item} />);
            } else {
                howtoHtml.push(<ImageLink key={n} item={item} />);
            }
        }

        return (
            <div className="page-wrapper">
                <div id="content" className="content row">
                    <div className="content-main col">
                        <div className="content-main-inner">
                            <main>
                                <div className="login-splash">
                                    <div className="login-splash-overlay"></div>
                                    <div className="container-fluid">
                                        <nav className="navbar navbar-expand-md login-splash-nav">
                                            <button type="button" className="btn btn-icon btn-link text-dark d-md-none" title="Toggle page menu" data-cfw="collapse" data-cfw-collapse-target="#loginNavbar">
                                                <span className="fa fa-bars" aria-hidden="true"></span>
                                            </button>

                                            <div className="navbar-collapse collapse" id="loginNavbar">
                                                <ul className="navbar-nav">
                                                    <li className="nav-item dropdown">
                                                        <button type="button" className="nav-link" data-cfw="dropdown">About Corgi<span className="caret ms-0_25" aria-hidden="true"></span></button>
                                                        <ul className="dropdown-menu">
                                                            <li><PageLink page="about" hash="#why">Why Corgi works</PageLink></li>
                                                            <li><PageLink page="about" hash="#research">Research</PageLink></li>
                                                            <li><PageLink page="about" hash="#technology">Technology</PageLink></li>
                                                            <li><PageLink page="about" hash="#features">Key features</PageLink></li>
                                                        </ul>
                                                    </li>
                                                    <li className="nav-item dropdown">
                                                        <button type="button" className="nav-link" data-cfw="dropdown">Teach with Corgi<span className="caret ms-0_25" aria-hidden="true"></span></button>
                                                        <ul className="dropdown-menu">
                                                            <li><a href="/sample/annotated/comparison">Sample Guide</a></li>
                                                            <li><a href="https://docs.google.com/document/d/1dAB-lNMeE-E38YOxARfWgHytGgQ0Jq9f0H16WyvXqw8/edit" target="_blank" rel="noopener noreferrer">Sample Lesson</a></li>
                                                            <li><PageLink page="support" hash="#video">How to Videos</PageLink></li>
                                                            <li><PageLink page="support" hash="#pl">P.L. Resources</PageLink></li>
                                                        </ul>
                                                    </li>
                                                    {/*
                                                    <li className="nav-item"><a href="#get-started" className="nav-link">Get Started</a></li>
                                                    <li className="nav-item"><a href="#technology" className="nav-link">Technology</a></li>
                                                    */}
                                                    <li className="nav-item"><PageLink page="faq" className="nav-link">FAQ</PageLink></li>
                                                </ul>
                                            </div>
                                        </nav>

                                        <div className="row">
                                            <div className="col-md-7">
                                                <h1 className="login-splash-logo" id="main_container">
                                                    <img src={hosting + "/img/mark.svg"} className="login-splash-logo-img" alt="" />
                                                    <span className="login-splash-logo-text">Corgi</span>
                                                </h1>
                                                <div className="login-splash-lead">
                                                    Digital graphic organizers for building higher-order thinking skills
                                                </div>
                                                <div id="login" className="lead font-weight-bold">
                                                    <p><span className="pe-0_25">Corgi is <em>free!</em></span> Sign up to get started:</p>
                                                    <div>
                                                        <AuthButton authorizeMethod={this.props.authorizeMethod} />
                                                    </div>
                                                </div>
                                                <div className="fs-small">
                                                    Don't have a Google account? <br />
                                                    <a href="https://mail.google.com/" className="link-icon">Create a free <span aria-hidden="true">one</span> <span className="sr-only">Google account</span> here<span className="fas fa-external-link-alt" title="(external site)"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-autofill">
                                    <div className="container px-0 px-md-1">
                                        <div className="row px-1 px-md-0">
                                            <div className="col-md-6 pt-1 py-md-1_5">
                                                <div className="embed-fluid embed-fluid-16x9">
                                                    <iframe src="https://www.youtube-nocookie.com/embed/Qz0T8C57fl0?enablejsapi=1" frameBorder="0"
                                                        title="Youtube Video" aria-label="Youtube Video: Corgi: Graphic organizers transformed"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                                    {/*
                                                    <iframe src="https://www.youtube-nocookie.com/embed/ViQjOM3NK7c?enablejsapi=1" frameBorder="0"
                                                        title="Youtube Video" aria-label="Youtube Video: Meet Corgi"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                                    */}
                                                </div>
                                                <a href="/transcripts/short-transformed.html">Corgi: Graphic organizers transformed - Video Transcript</a>
                                            </div>
                                            <div className="col-md-6 pt-1 py-md-1_5">
                                                <h2 className="h1">Meet Corgi</h2>
                                                <p className="lead">Learn how Corgi transforms traditional graphic organizers into flexible, accessible digital tools for learning.</p>
                                            </div>
                                        </div>
                                        <div className="pb-1">
                                            <h2 className="h1">Research with us!</h2>
                                            <p>
                                                We're looking for research partners to try out Corgi in the classroom.
                                                <br />
                                                <a href="mailto:corgi@cast.org">Contact us</a> to learn more.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-1 py-md-1_5">
                                    <div className="container">
                                        <h2 id="get-started" className="h1">Ready to begin using Corgi?</h2>

                                        <p className="lead">Our quick how-tos will help you get started</p>

                                        <p>If you are new to Corgi, check out our series of self-guided tutorials to learn more about
                                            Corgi’s innovative features, connecting Corgi to your Google account, using Corgi with
                                            Google Classroom, and more!</p>

                                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                                            {howtoHtml}
                                        </div>
                                        {/*
                                        <div className="row flex-center">
                                            <div className="col-12 col-sm-6 col-lg-4 mb-1">
                                                <a href="https://docs.google.com/presentation/d/1z9TaKCdRZ5E5TuVvbLuBl_D-g15qaP3-_kGEy3lEZZU/present" target="_blank" rel="noopener noreferrer">
                                                    <img className="img-fluid border" src={hosting + '/img/how-to-authorize.png'} alt="Authorizing Corgi with Google" />
                                                </a>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4 mb-1">
                                                <a href="https://docs.google.com/presentation/d/1nC7AWtgtuWJDStX02Goy8Va0eswfKZtIlS3KKuq-RI4/present" target="_blank" rel="noopener noreferrer">
                                                    <img className="img-fluid border" src={hosting + '/img/intro-to-corgi-features.png'} alt="Introduction to Corgi's features" />
                                                </a>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4 mb-1">
                                                <a href="https://docs.google.com/presentation/d/1F3emK3ptsRgIDSn23pd-EcbOwlW8Rx1dvqBMkCP5VZM/present" target="_blank" rel="noopener noreferrer">
                                                    <img className="img-fluid border" src={hosting + '/img/sharing-a-corgi-guide.png'} alt="Sharing a Corgi guide" />
                                                </a>
                                            </div>
                                        </div>
                                        */}

                                        <div>
                                            <PageLink page="support" className="link-icon">Find more on how to use Corgi<span
                                                className="fas fa-arrow-right"></span></PageLink>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-active text-white py-1">
                                    <div className="container">
                                        Looking for the previous version of Corgi? <a href="https://corgi1.cast.org" className="text-white link-icon">Login <span className="sr-only">to the previous Corgi site</span> here<span className="fas fa-arrow-right" title="(external site)"></span></a>
                                    </div>
                                </div>

                            </main>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

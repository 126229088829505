import React, {Component} from "react";

import imgBowl from 'url:/public/img/bowl.png';

export default class FileNotFound extends Component {

    render() {
        return(
            <div className="container-max">
                <div className="page-wrapper">
                    <div id="content" className="content row">
                        <div className="content-main col">
                            <div className="content-main-inner">
                                <main id="main_container">
                                    <div className="text-center pt-2">
                                        <h1>Ruh-roh!</h1>
                                        <img src={imgBowl} className="img-fluid mt-1 mb-2" alt="" />
                                        <h2>We couldn't find that page!</h2>
                                        <div>To open a recent guide of begin a new one, go <a href="/"><span className="fa fa-home me-0_25" aria-hidden="true"></span>home</a></div>
                                        <div>Need help? Visit <a href="/support">user support</a> or contact <a href="mailto:corgi@cast.org"><span className="fa fa-envelope me-0_25" aria-hidden="true"></span>corgi@cast.org</a></div>
                                    </div>

                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import PairedCharacteristics from './PairedCharacteristics';

export default class UnlikeCharacteristics extends PairedCharacteristics {

    constructor(props) {
        super(props);

        this.charIdentifier = "unlikecharacteristics";
        this.catIdentifier = "unlikecategories";
        this.myStatus = "unlike";
        this.buttonTextKey = "comparison.unlikecharacteristics.buttonText";
        this.modalTitleKey = "comparison.unlikecharacteristics.modalTitle";
        this.showBoth = undefined !== props.showBoth? props.showBoth: false;
        this.showLabel = undefined !== props.showLabel? props.showLabel: true;
    }

}

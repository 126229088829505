import React, { Component } from "react";
import ReadOnlyField from "../../ReadOnlyField";
import SimilarCharacteristics from "./SimilarCharacteristics";
import texts from "../../texts";
import DissimilarCharacteristics from "./DissimilarCharacteristics";
import KeyTerms from "../KeyTerms";
import AppContext from "../../AppContext";

import imgBracketSmall from 'url:/public/img/bracket-small.svg';

const similarHeader = <h3 id="step4-5" className="h2">Similar Characteristics &amp; Categories</h3>;
const dissimilarHeader = <h3 id="step6-7" className="h2">Different Characteristics &amp; Categories</h3>;
const complete = texts.comparison.sections[texts.comparison.sections.length-1];

export const comparison_snapshot = (doc) => {
    return(
        <React.Fragment>
            <div className="section">
                <h2 className="h1 text-center">Snapshot</h2>

                <div className="section">
                    <h3 className="h2">Essential Question</h3>
                    <div className="box bg-autofill">
                        <ReadOnlyField identifier={"essentialquestion"} className={"col"} />
                    </div>
                </div>

                <div className="section">
                    <h3 className="h2">Summary</h3>
                    <div className="box bg-autofill">
                        <ReadOnlyField identifier={"summary"} className={"col"} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export const comparison_process = (doc,characteristicsData) => {
    return(
        <React.Fragment>
            <div className="section section-border-top-dashed">
                <h2 className="h1 text-center">Comparison Process</h2>
            </div>

            <div className="section">
                <h3 className="h2">Concepts &amp; Overall Concept</h3>
                <div className="grouping grouping-2">
                    <div className="grouping-item">
                        <h4 className="h3 grouping-item-title"><ReadOnlyField identifier={"concept1"} className={""}/></h4>
                        <div className="box bg-autofill">
                            <ReadOnlyField identifier={"description1"} className={"col"}/>
                        </div>
                    </div>
                    <div className="grouping-item">
                        <h4 className="h3 grouping-item-title"><ReadOnlyField identifier={"concept2"} className={""}/></h4>
                        <div className="box bg-autofill">
                            <ReadOnlyField identifier={"description2"} className={"col"}/>
                        </div>
                    </div>
                </div>
                <div className="bracket bracket-2">
                    <div className="bracket-arrow-vertical"></div>
                    <div className="bracket-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-arrow"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-end"></div>
                </div>
                <div className="bracket bracket-print">
                    <img className="img-fluid" src={imgBracketSmall} width="274" height="66" alt="pairing bracket image" aria-hidden="true" />
                </div>
                <div className="grouping grouping-1">
                    <h4 className="h3 grouping-title">Overall Concept</h4>
                    <div className="grouping-item">
                        <div className="box bg-autofill">
                            <ReadOnlyField identifier={"overall"} className={"col"} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <h3 className="h2">Key Terms</h3>
                <KeyTerms doc={doc}  editing={false} printing={true} secondary={true}/>
            </div>

            <div className="section">
                <SimilarCharacteristics editing={false} showBoth={true} showCategory={true} showLabel={false} header={similarHeader}
                    doc={doc} section={complete} data={characteristicsData} />
            </div>

            <div className="section">
                <DissimilarCharacteristics editing={false} showBoth={true} showCategory={true} showLabel={false} header={dissimilarHeader}
                    doc={doc} section={complete} data={characteristicsData} />
            </div>

            <div className="section">
                <h3 className="h2">Extensions</h3>
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"extensions"} className={"col"} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default class ComparisonTitle extends Component{
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.getRef = this.getRef.bind(this);

        this.dbRef1 = null;
        this.headless1 = null;
        this.dbRef2 = null;
        this.headless2 = null;

        this.state = {
            concept1 : "",
            concept2: ""
        };
    }

    componentDidMount() {
        this.dbRef1 = this.getRef("concept1");
        this.callback1 = this.dbRef1.on('value', () => {
            if (null === this.headless1) {
                this.headless1 = new window.Firepad.Headless(this.dbRef1);
            }
            this.headless1.getText(text => {
                if (this.state.concept1 !== text) {
                    this.setState({concept1: text});
                }
            })
        });

        this.dbRef2 = this.getRef("concept2");
        this.callback2 = this.dbRef2.on('value', () => {
            if (null === this.headless2) {
                this.headless2 = new window.Firepad.Headless(this.dbRef2);
            }
            this.headless2.getText(text => {
                if (this.state.concept2 !== text) {
                    this.setState({concept2: text});
                }
            })
        });
    }

    componentWillUnmount() {
        if (this.headless1) {
            this.headless1.dispose();
            this.headless1 = null;
        }
        if (this.callback1) {
            this.dbRef1.off('value', this.callback1);
            this.callback1 = null;
        }

        if (this.headless2) {
            this.headless2.dispose();
            this.headless2 = null;
        }
        if (this.callback2) {
            this.dbRef2.off('value', this.callback2);
            this.callback2 = null;
        }
    }

    getRef(identifier) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + identifier;
        return window.firebase.database().ref(path);
    }

    render() {
        return(
            <React.Fragment>
                <h1 className="h3">{this.state.concept1} vs {this.state.concept2}</h1>
            </React.Fragment>
        );
    }
}
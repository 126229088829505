import DynamicComponent from "../../DynamicComponent";
import React, {Fragment} from "react";
import $ from "jquery";
import AppContext from "../../AppContext";

import imgRatingNever from 'url:/public/img/rating-never.svg';
import imgRatingSometimes from 'url:/public/img/rating-sometimes.svg';
import imgRatingUsually from 'url:/public/img/rating-usually.svg';
import imgRatingAlways from 'url:/public/img/rating-always.svg';
import imgRatingDontKnow from 'url:/public/img/rating-dontknow.svg';

const TEACHER_RATING_QUESTIONS = ["Corgi is easy for my students to use.",
    "Corgi is easy for me to use.",
    "Corgi helps my students learn.",
    "My students like learning more when they use Corgi."];
const STUDENT_RATING_QUESTIONS = ["Corgi is easy for me to use.",
    "Corgi helps me learn.",
    "I like learning more when I use Corgi."];
const RATES = ['never', 'sometimes', 'usually', 'always', 'idk'];

export default class RatingModal extends DynamicComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {currentStatementNo: 0};
    }

    stopReadingModal(){
        window.speechstream.speechTools.stop();
    }

    render() {
        this.statements = this.context.currentUser.role === 'teacher' ? TEACHER_RATING_QUESTIONS : STUDENT_RATING_QUESTIONS;
        let statement = this.statements[this.state.currentStatementNo];

        return (
            <Fragment>
                <button style={{display: "none"}} data-cfw="modal" data-cfw-modal-target="#modalRating">Rate Corgi</button>

                <div id="modalRating" className="modal create-modal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="h1 modal-title">Tell us how it is going!</h2>
                                <button type="button" className="close" data-cfw-dismiss="modal" aria-label="Close"
                                    onClick={() => this.stopReadingModal()}>
                                    <span className="fa fa-times" aria-hidden="true"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>{statement}</p>
                                    <div className="row rating">
                                        <div className="col-2">
                                            <label className="rating-label">
                                                <input id="rating-1" className="rating-check" type="radio"
                                                       onClick={() => this.selectRate(1, statement)}
                                                       name="rating0"
                                                       value="1"/>
                                                <div className="rating-img">
                                                    <img src={imgRatingNever} className="img-fluid"
                                                         alt=""/>
                                                </div>
                                                <div className="rating-txt">
                                                    Never true
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-2">
                                            <label className="rating-label">
                                                <input id="rating-2" className="rating-check" type="radio"
                                                       onClick={() => this.selectRate(2, statement)}
                                                       name="rating0"
                                                       value="2"/>
                                                <div className="rating-img">
                                                    <img src={imgRatingSometimes} className="img-fluid"
                                                         alt=""/>
                                                </div>
                                                <div className="rating-txt">
                                                    Sometimes true
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-2">
                                            <label className="rating-label">
                                                <input id="rating-3" className="rating-check" type="radio"
                                                       onClick={() => this.selectRate(3, statement)}
                                                       name="rating0"
                                                       value="3"/>
                                                <div className="rating-img">
                                                    <img src={imgRatingUsually} className="img-fluid"
                                                         alt=""/>
                                                </div>
                                                <div className="rating-txt">
                                                    Usually true
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-2">
                                            <label className="rating-label">
                                                <input id="rating-4" className="rating-check" type="radio"
                                                       onClick={() => this.selectRate(4, statement)}
                                                       name="rating0"
                                                       value="4"/>
                                                <div className="rating-img">
                                                    <img src={imgRatingAlways} className="img-fluid"
                                                         alt=""/>
                                                </div>
                                                <div className="rating-txt">
                                                    Always true
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-2">
                                            <label className="rating-label">
                                                <input id="rating-5" className="rating-check" type="radio"
                                                       onClick={() => this.selectRate(5, statement)}
                                                       name="rating0"
                                                       value="5"/>
                                                <div className="rating-img">
                                                    <img src={imgRatingDontKnow} className="img-fluid"
                                                         alt=""/>
                                                </div>
                                                <div className="rating-txt">
                                                    I don’t know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-between">
                                        <div>
                                            <button type="button" className="btn btn-link"
                                                    onClick={() => this.markDontShowAgain()}>Don’t show this again
                                            </button>
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-link" data-cfw-dismiss="modal"
                                                    onClick={() => this.markShowLater()}>
                                                Maybe later
                                            </button>
                                            <button type="button" className="btn btn-primary"
                                                    onClick={() => this.nextStatement()}>Next<span
                                                className="pages-next-icon fa fa-chevron-right ms-0_25"
                                                aria-hidden="true"></span></button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    selectRate(rateNumber, statement) {
        window.tracker.trackEvent("Rating", RATES[rateNumber - 1], statement);

        let userRef = window.firebase.database().ref("user/" + this.context.currentUser.uid);
        userRef.update({answeredRatingModal: true});
    }

    markDontShowAgain() {
        window.tracker.trackEvent("Rating", 'don\'t show again');

        let userRef = window.firebase.database().ref("user/" + this.context.currentUser.uid);
        userRef.update({dontShowRatingModalAgain: true});

        $('#modalRating').CFW_Modal("hide");
        this.stopReadingModal();
    }

    markShowLater() {
        window.tracker.trackEvent("Rating", 'later');

        $('#modalRating').CFW_Modal("hide");
        this.stopReadingModal();
    }

    nextStatement() {
        $('#modalRating').find('input.rating-check').each((index, element) => {
            element.checked = false;
        })
        this.stopReadingModal();
        if (this.state.currentStatementNo < this.statements.length - 1) {
            this.setState({currentStatementNo: this.state.currentStatementNo + 1});
        } else {
            this.setState({currentStatementNo: 0});
            $('#modalRating').CFW_Modal("hide");
        }
    }
}

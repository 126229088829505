// Google Custom Search Engine configured for full web safe image search
import React from "react";
import uniqueId from "../../uniqueId";
import {firebaseConfig} from "../../realtimedb-utils";
import $ from "jquery";
import util from "util";
import DynamicComponent from "../../DynamicComponent";
import {empty} from "../textutil";

const DEFAULT_REQUEST_IMAGE_COUNT = 3*3; // 10 is api default/max, but we want three rows
const GOOGLE_SEARCH_BASE_URL = "https://www.googleapis.com/customsearch/v1";
const emptySearch = {
    error: null,
    terms: "",
    preSearchTerms:"",
    start: 1,
    results: [],
    allFetchedImages: [],
    totalImg: 0
};

const noImage = {src: '', alt: '' };
const maxMB = 50;
const max_start = 190; // errors after this?
const maxResolution = 25; //megapixels

function validImage(url) {
    // TODO: actually try to fetch?
    return ["http:","https:"].includes(url.protocol)
        && url.toString().length <= 2048
        && url.hostname !== 'lookaside.fbsbx.com'
        ;
}

function alt(image) {
    if (image.alt) {
        return image.alt;
    }

    let alt = image.description;
    if (empty(alt)) {
        alt = "no image description :(";
    }
    return alt;
}

export default class SearchWebModal extends DynamicComponent {

    constructor(props) {
        super(props);
        this.fetchSearchResults = this.fetchSearchResults.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.resetInput = this.resetInput.bind(this);
        this.handleSearchSelect = this.handleSearchSelect.bind(this);
        this.addImage = this.addImage.bind(this);
        this.requestedImagesCount = this.props.count? this.props.count: DEFAULT_REQUEST_IMAGE_COUNT;
        this.setSearchResults = (results,extendResult,isNewSearch,searchTerm) => {
            if(isNewSearch){ // reset paging when click button Search
                this.setState({
                    search: {results: [],allFetchedResults:[],totalImg:0,start:1}});
            }
            let allFetchedResults = this.state.search.allFetchedResults;
            if(extendResult)
                allFetchedResults = this.state.search.allFetchedResults.concat(results);
            let totalImg = allFetchedResults.length;
            let startIndex = totalImg - results.length + 1;
            this.setState({
                search: {results: results,allFetchedResults:allFetchedResults,start: startIndex,totalImg:totalImg,preSearchTerms: searchTerm}});
        };
        this.state = {
            error: {message: null},
            image: noImage,
            search: emptySearch,
        };
    }
    componentWillMount() {
        this.modalImageSearchButtonId = uniqueId();
        this.addImageSearchId = uniqueId();
    }

    resetInput() {
        this.setState({
            search: emptySearch,
            error: null
        });

        $('#' + this.addImageSearchId).val(emptySearch.terms);
    }

    fetchSearchResults(isNewSearch) {
        return new Promise((resolve, reject) => {
            const apiKey = firebaseConfig ? firebaseConfig.apiKey : '42';
            const cx = firebaseConfig ? firebaseConfig.cx : '002858309614930574590:4aiyhkyi_1g';

            let termSearch = this.state.search.terms;
            let start;
            if(isNewSearch){
                this.setState({
                    search: emptySearch,
                    error: null
                });
                start = 1;
            }else{
                start = 0 === this.state.search.results.length? this.state.search.start:
                    (this.state.search.start+this.requestedImagesCount) % max_start;
                const term = this.state.search.preSearchTerms;
                if(term !== null){
                    $('#' + this.addImageSearchId).val(term);
                    termSearch = term;
                }
            }

            const params = {
                key: apiKey,
                cx: cx,
                searchType: "image",
                q: termSearch,
                start: start,
                num: this.requestedImagesCount
            }

            let url = GOOGLE_SEARCH_BASE_URL;
            const query = Object.keys(params)
                .map((k) => {
                    if (Array.isArray(params[k])) {
                        return params[k]
                            .map((val) => `${encodeURIComponent(k)}[]=${encodeURIComponent(val)}`)
                            .join('&');
                    }
                    return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`;
                })
                .join('&');

            url += `?${query}`;

            const listMime = ["image/jpeg","image/png","image/gif"];

            fetch(url).then(res => res.json())
                .then(
                    (response) => {
                        console.log(response);

                        if (!parseInt(response.searchInformation.totalResults, 10) > 0) {
                            this.setState({
                                error: {message: "No images found."}
                            });
                            reject("No images found.");
                            return;
                        }

                        let results = [];
                        const images = response.items.filter(image => {
                            console.log(image);
                            return listMime.includes(image.mime);
                        });
                        images.forEach(img => {
                            if (validImage(new URL(img.link))) {
                                let imageSize = img.image.byteSize / 1024 / 1024;
                                let resolution = img.image.width * img.image.height / 1000000;
                                if(imageSize < maxMB && resolution < maxResolution)
                                    results.push({src: img.link, mimeType: img.mime, description: img.title});
                                else console.log("Image size too big --> don't use");
                            } else {
                                console.log("Image Search ignoring invalid %s",img.link.substr(0,20));
                            }
                        });
                        const newSearch = {...this.state.search, ...{preSearchTerms:termSearch,results: results, start: params.start}};
                        const newState = {...this.state, ...{search: newSearch}};
                        this.setState(newState,() => {
                            console.log("%d results for %s image search with start %d",
                                this.state.search.results.length, termSearch,this.state.search.start);
                        });

                        this.setSearchResults(results,true,isNewSearch, termSearch);
                        resolve(results);
                    },
                    (err_reason) => {
                        console.log("Could not get image search results: " + err_reason.error.message);
                        this.setState({
                            error: {message: "Could not get image search results"}
                        });
                        reject(err_reason);
                    }
                )
        });
    }

    nextPage(event) {
        let nextStartIndex = this.state.search.start + DEFAULT_REQUEST_IMAGE_COUNT;
        let allFetchedResults = this.state.search.allFetchedResults;

        event.preventDefault();

        if (nextStartIndex < allFetchedResults.length) {
            let listImages = [];
            for (let i = nextStartIndex - 1; i < nextStartIndex + DEFAULT_REQUEST_IMAGE_COUNT - 1; i++) {
                if (i < allFetchedResults.length)
                    listImages.push(this.state.search.allFetchedResults[i]);
            }

            this.setState({
                search: {
                    ...this.state.search, ...{
                        results: listImages,
                        allFetchedResults: allFetchedResults,
                        start: nextStartIndex,
                        totalImg: allFetchedResults.length
                    }
                }
            });
        } else {
            console.log("from = " + nextStartIndex + ",totalImg = " + allFetchedResults.length);
            this.fetchSearchResults(false).then(r => console.log(r));
        }
    }

    previousPage(event) {
        let previousStartIndex = this.state.search.start - DEFAULT_REQUEST_IMAGE_COUNT;
        if (previousStartIndex < 1) previousStartIndex = 1;
        let totalImg = this.state.search.allFetchedResults.length;

        event.preventDefault();

        let listImages = [];
        for (let i = previousStartIndex - 1; i < previousStartIndex + DEFAULT_REQUEST_IMAGE_COUNT - 1; i++) {
            if (i >= 0)
                listImages.push(this.state.search.allFetchedResults[i]);
        }

        this.setState({
            search: {
                ...this.state.search, ...{
                    results: listImages,
                    allFetchedResults: this.state.search.allFetchedResults,
                    start: previousStartIndex,
                    totalImg: totalImg
                }
            }
        });
    }

    handleSearchSelect(e) {
        let img = e.target.nextElementSibling.firstChild;
        let src = img.getAttribute('src');
        let alt = img.getAttribute('alt');

        if (this.validImage(new URL(src))) {
            // TODO: remove query?
            this.setState({image: {src: src, alt: alt? alt: ""}},
                () => console.log('selected image %s',util.inspect(this.state.image)));
        } else {
            e.preventDefault();
            console.log('skipping invalid URL %s',src);
        }
    }

    validImage(url) {
        // TODO: actually try to fetch?
        return ["http:","https:"].includes(url.protocol)
            && url.toString().length <= 2048
            && url.hostname !== 'lookaside.fbsbx.com'
            ;
    }

    addImage() {
        console.log("save selected web image: %s", util.inspect(this.state.image));
        this.props.saveMethod(this.state.image);
        this.resetInput();
        window.tracker.trackEvent("Content", "add image", "search");
    }

    render() {
        const disableSearch = !(undefined !== this.state.search.terms && 0 < this.state.search.terms.length);
        let searchButton =
            <button id={this.modalImageSearchButtonId} type="button" className="btn btn-primary" disabled={disableSearch}
                    onClick={() => {
                        this.fetchSearchResults(true).then(r => console.log(r), ()=>{});
                    }}
            >Search</button>
        ;

        let resultImages = [];
        for (const [index, value] of this.state.search.results.entries()) {
            let id = util.inspect(index);
            resultImages.push(
                <div key={index} className="col mb-1">
                    <div className="form-check form-checkradio addimg-radio">
                        <input id={"addimg2-"+id} name="addimg2" className="form-check-input" type="radio"
                               onClick={(event)=> {
                                   this.handleSearchSelect(event)
                               }}
                        />
                        <label htmlFor={"addimg2-"+id} className="form-check-label">
                            <img src={value.src} className="img-fluid"
                                 alt={alt(value)}
                                 onError={(err) => {
                                     const message = "AddImageButton onError img "+index.toString()+" "+err.currentTarget.currentSrc;
                                     console.log(message);
                                     // err.target.style.visibility = "hidden";
                                     let results = this.state.search.results;
                                     results.splice(index,1);
                                     this.setSearchResults(results,false);
                                 }
                                 } // doesn't work, why?
                            />
                        </label>
                    </div>
                </div>);
        }

        let saveSearchButton =
            <button type="button" className="btn btn-primary" disabled={('' === this.state.image.src)}
                    onClick={()=> {
                        this.addImage();
                    }}
            >Add Image</button>
        ;

        return <div id={this.props.id} className="modal">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role={"document"}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="h1 modal-title">Search for images</h2>
                        <button type="button" className="close" data-cfw-dismiss="modal" aria-label="Close"
                                onClick={(event)=> {
                                    event.preventDefault();
                                    this.resetInput();
                                }}>
                            <span className="fa fa-times" aria-hidden="true"></span>
                        </button>
                    </div>
                    <div className="modal-extra">
                        <form className="row gx-0_5" role="search">
                            <div className="col">
                                <label htmlFor={this.addImageSearchId} className="fs-small sr-only">Search term</label>

                                <input id={this.addImageSearchId} type="search" className="form-control" placeholder="Enter a search term"
                                       onClick={() => {
                                           // event.target.value = null; // no, per BG edit existing
                                       }}

                                       onKeyPress={(event) => {
                                           if (event.key === "Enter") {
                                               event.preventDefault();
                                               // Trigger the search button element with a click
                                               $('#' + this.modalImageSearchButtonId).click();
                                           }
                                       }}

                                       onInput={(event) => {
                                           const searchTerms = event.target.value;
                                           const preSearchTerms = this.state.search.preSearchTerms;
                                           try {
                                               this.setState({
                                                   search: {
                                                       terms: searchTerms,
                                                       preSearchTerms:preSearchTerms,
                                                       start: this.state.search.start,
                                                       results: this.state.search.results,
                                                   }
                                               });
                                           } catch (e) {
                                               this.setState({error: {message: "Sorry, " + e.message}});
                                           }
                                       }}
                                />
                            </div>
                            <div className="col-sm-auto mt-0_5 mt-sm-0">
                                {searchButton}
                            </div>
                        </form>
                    </div>
                    {/* <!-- When error is indicated --> */}
                    {this.state.error && this.state.error.message ?
                        <div className="modal-body pt-0_25">
                            <span className="fa fa-exclamation-circle text-danger fs-xlarge me-0_25" aria-hidden="true"></span>
                            {this.state.error.message}
                        </div>
                        : null
                    }
                    {!this.state.search.results.length ? null :
                        <div className="modal-body bg-autofill">
                            <div className="row row-cols-2 row-cols-md-3 gx-1">
                                {resultImages}
                            </div>
                            <div className="text-center">
                                {this.state.search.start === 1 ?
                                    <span className="text-muted"><span aria-hidden="true">&lt;</span> Previous</span>
                                    : <a href="# " onClick={this.previousPage}><span aria-hidden="true">&lt;</span>Previous</a>
                                }
                                <strong className="ps-1">Results </strong><strong>{this.state.search.start}</strong><strong >-</strong><strong className="pe-1">{this.state.search.start + this.state.search.results.length - 1}</strong>
                                <a href="# " onClick={this.nextPage}>Next <span aria-hidden="true">&gt;</span></a>
                            </div>
                        </div>
                    }
                    {!this.state.search.results.length ? null :
                        <div className="modal-footer">
                            <button type="button" className="btn btn-link" data-cfw-dismiss="modal"
                                onClick={(event)=> {
                                    event.preventDefault();
                                    this.resetInput();
                                }}>Cancel</button>
                            {saveSearchButton}
                        </div>
                    }
                </div>
            </div>
        </div>




    }
}

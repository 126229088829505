/**
 * @fileoverview Fire store util replaces realtime util
 */

import firebase from 'firebase/app';
import 'firebase/firestore';

const utils = {};

let firebaseConfig = window.firebaseConfig;

utils.ReatimeDbUtils = function() {
    this.init();
    window.realtimeUtils = this;
};

utils.ReatimeDbUtils.prototype = {

    init: function() {
        firebase.initializeApp(firebaseConfig);
    },

    createDoc: function(docType, id, initData) {
        return firebase.database().ref(docType+"/"+id).set(initData);
    },

    addCollaborativeUser: function(docType, docId, user) {
        return firebase.database().ref(docType+"/"+docId+"/users/"+user.uid).set(user)
    },

    removeCollaborativeUser: function(docType, docId, user) {
        return user? firebase.database().ref(docType+"/"+docId+"/users/"+user.uid).remove():null;
    },

    getCollaborativeListUser: function(docType, docId) {
        return firebase.database().ref(docType+"/"+docId+"/users")
    },

    getDoc: function(docType, docId) {
        return firebase.database().ref(docType+"/"+docId)
    },

    getListDocs: function (user) {

    }
};

const getParam = function(urlParam) {
    // This handles parsing URLs like /type/id/section
    let path = window.location.pathname.split('/');
    let match = null;

    if (path.length > 3 && 'section' === urlParam) {
        match = path[3];
    } else if (path.length >= 2 && 'id' === urlParam) {
        match = path[2];
    } else if (path.length >= 1 && 'type' === urlParam) {
        match = path[1];
        if ('unit-organizer' === match) {
            match = 'unit-org-2';
        }
    }
    return match;
};

export {firebaseConfig, getParam}

export default utils;

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import AppContext from "../AppContext";

export const isValidTitle = (name) => {
    return name && 0 < name.trim().length;
}

export default class GuideTitle extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.setEditTitle = this.setEditTitle.bind(this);
        this.unsetEditTitle = this.unsetEditTitle.bind(this);
        this.saveTitle = this.saveTitle.bind(this);

        this.state = {editTitle: false};

    }

    render() {
        const editing = this.context.canEdit && this.state.editTitle;
        return (
                !editing?
                    <Fragment>
                        <span>{this.context.fileName}</span>
                        {this.context.canEdit ? <a href="#rename" className="fs-small ms-0_25" onClick={this.setEditTitle} >rename</a> : null}
                    </Fragment>
                :
                    <TitleForm cancelMethod={this.unsetEditTitle} saveMethod={this.saveTitle} />
        );
    }

    setEditTitle() {
        this.setState({editTitle: true});
    }

    unsetEditTitle() {
        this.setState({editTitle: false});
    }

    saveTitle(newName) {
        const name = newName.trim();
        if (isValidTitle(name)) {
            this.props.renameDocMethod(newName)
                .then(() => {
                    this.setState({editTitle: false});
                    this.context.updateFileName(newName);
                },
                    () => {
                    alert('Rename failed');
            });
        } else {
            alert('An empty guide name is not allowed.  Please name the guide.');
        }
    }
}

GuideTitle.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    renameDocMethod: PropTypes.func.isRequired
};

class TitleForm extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = { value: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        //document.getElementById('save').disabled = ! isValidTitle(this.context.fileName);
        this.setState({ value: this.context.fileName });
    }

    handleChange(event) {
        //document.getElementById('save').disabled = ! isValidTitle(event.target.value);
        this.setState({ value: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.saveMethod(this.state.value);
    }

    render() {
        return(
            <form onSubmit={this.handleSubmit} className="row gx-0_5">
                <div className="col">
                    <input id="filename" value={this.state.value} onChange={this.handleChange} type="text" className="form-control"/>
                </div>
                <div className="col-sm-auto mt-0_5 mt-sm-0">
                    <button id="save" onClick={this.handleSubmit} type="submit" className="btn btn-success me-0_5"
                            //active={isValidTitle(this.state.value)}
                    >Save</button>
                    <button onClick={this.props.cancelMethod} type="button" className="btn btn-light">Cancel</button>
                </div>
            </form>
        );
    }

}

// eslint-disable-next-line
import React, {useContext} from "react";
import DynamicComponent from "../DynamicComponent";
import PreLogin from "../PreLogin";
import AppContext from "../AppContext";
import {Redirect, Route, Switch, useLocation, useParams} from "react-router-dom";
import UserSupport from "./UserSupport";
import CreateDocument from "../CreateDocument";
import CopyDocument from "../CopyDocument";
import OrganizerChoice from "../organizers/OrganizerChoice";
import Unsupported from "../organizers/Unsupported";
import CauseEffect from "../organizers/CauseEffect";
import Comparison from "../organizers/Comparison";
import QuestionExp from "../organizers/QuestionExp";
import CERGuide from "../organizers/CERGuide";
import FileNotFound from "./FileNotFound";
import Attributions from "./Attributions";
import About from "./About";
import Faq from "./Faq";
import UnitLibrary from "./UnitLibrary";
import SampleGuide from "./SampleGuide";

export default class MainContent extends DynamicComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.doDocs = this.doDocs.bind(this);
        this.doDocType = this.doDocType.bind(this);
        this.doCreate = this.doCreate.bind(this);
        this.createDocument = this.createDocument.bind(this);
    }

    render() {
        // const doc = this.context;
        if (this.props.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        if (this.context.loading  && !this.context.loginRequired) {
            return <Wait loading={true}/>
        }


        if (this.context.authorized) {
            // Routes available to logged-in users.

            if (this.context.fileIdLoaded) {
                // Routes available when doc loaded
                return(
                <Switch>
                    <Route path="/support">
                        <UserSupport />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/faq">
                        <Faq />
                    </Route>
                    <Route path="/attributions">
                        <Attributions/>
                    </Route>
                    <Route path="/sample/:style/:type" component={this.doSample} />
                    <Route path="/docs"  component={this.doDocs} />
                    <Route path="/copy">
                        <CopyDocument copyDocMethod={this.props.copyDocMethod} />
                    </Route>
                    <Route path="/create" component={this.createDocument}/>
                    <Route path="/:docType/:docId/:section" component={this.doDocType}/>
                    <Redirect from="/:docType/:docId" to="/:docType/:docId/toc"/>
                    <Redirect to="/docs"/>
                </Switch>);
            }
            return ( // logged-in, but doc not loaded yet
                <Switch>
                    <Route path="/support">
                        <UserSupport />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/faq">
                        <Faq />
                    </Route>
                    <Route path="/attributions">
                        <Attributions/>
                    </Route>
                    <Route path="/library">
                        <UnitLibrary/>
                    </Route>
                    <Route path="/sample/:style/:type" component={this.doSample} />
                    <Route path="/docs"  component={this.doDocs} />
                    <Route path="/copy">
                        <CopyDocument copyDocMethod={this.props.copyDocMethod} />
                    </Route>
                    <Route path="/:docType/:docId/:section">
                        <Wait loading={this.context.loading} doCreate={this.doCreate} context={this.context} {...this.props} />
                    </Route>
                    <Redirect from="/:docType/:docId" to="/:docType/:docId/toc"/>
                    <Redirect to="/docs"/>
                </Switch>
            );
        } else {
            // Routes available before login.
            return (
                <Switch>
                    <Route path="/support">
                        <UserSupport />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/faq">
                        <Faq />
                    </Route>
                    <Route path="/attributions">
                        <Attributions/>
                    </Route>
                    <Route path="/library">
                        <UnitLibrary/>
                    </Route>
                    <Route path="/sample/:style/:type" component={this.doSample} />
                    <Route path="/login">
                        <PreLogin authorizeMethod={this.props.authorizeWithPopUp}/>
                    </Route>
                    <Redirect to="/login" />
                </Switch>
            );
        }
    }

    doSample() {
        let params = useParams();
        return <SampleGuide type={params.type} style={params.style} />;
    }

    doDocType() {
        let params = useParams();
        const doc = {
            type: params.docType,
            id: params.docId,
            section: params.section,
        }
        console.debug("MainContent.doDocType "+doc.type+" section: "+doc.section);

        if (this.context.copyPending) {
            return <Redirect to={"/copy"} />;
        }else if(this.context.createPending){
            return <Redirect to={"/create"} />;
        } else if ("comparison" === doc.type) {
            return <Comparison fileSection={params.section}
                               loadDocMethod={this.props.loadDocMethod}
                               renameDocMethod={this.props.renameDocMethod}
                               shareMethod={this.props.shareMethod}
                               />;
        } else if ("cause-effect" === doc.type) {
            return <CauseEffect doc={doc} canEdit={this.context.canEdit}
                                loadDocMethod={this.props.loadDocMethod}
                                renameDocMethod={this.props.renameDocMethod}
                                shareMethod={this.props.shareMethod}
                                />;
        }else if ("question-exp" === doc.type) {
            return <QuestionExp fileSection={params.section}
                                loadDocMethod={this.props.loadDocMethod}
                                renameDocMethod={this.props.renameDocMethod}
                                shareMethod={this.props.shareMethod}
                                />;
        }else if ("cera" === doc.type) {
            return <CERGuide fileSection={params.section}
                                loadDocMethod={this.props.loadDocMethod}
                                renameDocMethod={this.props.renameDocMethod}
                                shareMethod={this.props.shareMethod}
                                />;
        }else {
            return <Unsupported />;
        }
    }

    createDocument(){
        const type = this.context.fileType;
        return <CreateDocument docType={type} createDocMethod={this.props.createDocMethod} />;
    }

    doDocs() {
        let location = useLocation();
        if (location.hash.startsWith('#new')) {
            const type = location.hash.substr(4).toLowerCase();
            if (0 < type.length) {
                return this.doCreate(type);
            }
        }
        return <OrganizerChoice newDocButtonMethod={this.props.newDocButtonMethod} authorizeWithPopUp={this.props.authorizeWithPopUp}/>
    }
    doCreate(type) {
        /*
        let params = useParams();
        let location = useLocation();
        if (params) {
            // console.log(util.inspect(params));
        }
        if (location) {
            // console.log(util.inspect(location));
        }
         */
        console.debug("MainContent.doCreate "+type);
        return <CreateDocument docType={type} createDocMethod={this.props.createDocMethod} />;
    }

}

/**
 * If loading, shows "loading...".
 * If a copy operation is in progress, shows that message.
 * It's important to have this intermediate stage since the operation can take
 * a few seconds, during which editing should not be allowed; and as a way of making
 * sure that the entire page is redrawn to remove any bound text input fields that
 * were connected to the previous document.
 */
export function Wait(props) {
    let doc = useContext(AppContext);
    let params = useParams();

    if (!doc.loading && params.docId && doc.error !== 1) {
        console.log("Wait: calling loadDocMethod");
        props.loadDocMethod();
    }

    if (props.copyPending && props.copyDone) {
        let newUrl = "/" + doc.fileType + "/" + doc.copyPending;
        console.log("Redirecting to copied file: " + newUrl);
        return <Redirect to={newUrl} />;
    }

    let message = (doc.copyPending ? "Copying document"
        : doc.createPending? "Creating document"
            :"Loading") + "...";
    return (
        doc.error === 1 ? <FileNotFound/> :
        <main id="main_container" className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col text-center mt-2">
                        <h1 className="h4 text-body mb-2">{message}</h1>
                    </div>
                </div>
            </div>
        </main>
    );
}

import React from "react";
import ReadOnlyField from "../../ReadOnlyField";
import Causes from "./Causes";
import Effects from "./Effects";
import texts from "../../texts";
import KeyTerms from "../KeyTerms";
import Event from "./Event";
import ReadOnlyText from "../../components/ReadOnlyText";

export const cause_effect_snapshot = (doc) => {
    return(
        <React.Fragment>
            <div className="section">
                <h1 className="text-center">Snapshot</h1>
            </div>

            <div className="section">
                <h3 className="h2">Essential Question</h3>
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"question"} className={"col"} />
                </div>
            </div>

            <div className="section">
                <h3 className="h2">Summary</h3>
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"answer"} className={"col"} />
                </div>
            </div>

            <div className="section">
                <h3 className="h2">Extensions</h3>
                <div className="box bg-autofill">
                    <ReadOnlyField identifier={"extensions"} className={"col"} />
                </div>
            </div>
        </React.Fragment>
    )
}

export const cause_effect_proccess = (doc) => {
    const routineText = texts[doc.type];
    const section = routineText.cause;
    return(
        <React.Fragment>
            <div className="section section-border-top-dashed">
                <h2 className="h1 text-center">Cause &amp; Effect Process</h2>
            </div>

            <div className="section">
                <h3 className="h2">Key Terms</h3>
                <KeyTerms doc={doc}  editing={false} printing={true} secondary={true}/>
            </div>

            <div className="section">
                <h3 className="h2">Causes and Connections of the Main Event or Idea</h3>
                <Causes doc={doc} section={section} editing={false} noImage={true} />

                <ReadOnlyText identifier={"causes-event/connector"} className={"box connector box-autofill"}/>
                <div className="bracket">
                    <div className="bracket-arrow-vertical"></div>
                </div>

                <div className="grouping grouping-1">
                    <h3 className="h2 grouping-title">Main Event or Idea</h3>
                    <div className="grouping-item">
                        <Event doc={doc} section={routineText.event} editing={false} noImage={true} />
                    </div>
                </div>

                <div className="bracket">
                    <div className="bracket-arrow-vertical"></div>
                </div>
                <ReadOnlyText identifier={"event-effects/connector"} className={"box connector bg-autofill"}/>

                <Effects doc={doc} section={routineText.effect} editing={false} noImage={true} printing={true} />
            </div>
        </React.Fragment>
    )
}
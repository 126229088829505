import React from "react";

import {formatRelative, parseISO} from 'date-fns';

import DynamicComponent from "../DynamicComponent";
import T from "i18n-react";

import {to} from "./Go";
import DocLink from "./DocLink";

export default class DocumentCard extends DynamicComponent {

    constructor(props) {
        super(props);

        this.edited = this.edited.bind(this);
    }

    edited(m) {
        const i = parseISO(m);

        return (
            i? <p className="card-text">Last edited: {formatRelative(i, new Date())}</p>
             : ""
        )
    }

    render() {
        if (!this.props.document.properties) {
            console.log("Document has no properties: ", this.props.document);
            return null;
        }
        const t = T.translate(this.props.document.properties.type + ".title");
        const modified = this.edited(this.props.document.modifiedTime);
        const doc = {type: this.props.document.properties.type,
            id: this.props.document.id, section: 'toc'};

        if (t && this.props.document.id && this.props.document.name) {
            return (
                <div className="col">
                    <div className="card card-recent">
                    <div className="card-header">{T.translate(this.props.document.properties.type + ".title")}</div>
                        <div className="card-body">
                            <h3><DocLink to={to(doc.type,doc.id,doc.section)}>{this.props.document.name}</DocLink></h3>
                            {modified}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }
}

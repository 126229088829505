
export function codeMirrorMultilineConfig(readOnly, placeholder) {
    return {
        readOnly: readOnly,
        tabMode: "default",
        scrollbarStyle: "null",
        height: "auto",
        lineWrapping: true,
        viewportMargin: Infinity,
        placeholder: placeholder,
        screenReaderLabel: placeholder,
        inputStyle: "contenteditable",
        spellcheck: true,
        extraKeys: {
            "Tab": false,
            "Shift-Tab": false
        }
    };
}


export function codeMirrorSingleLineConfig(readOnly, placeholder) {
    return {
        readOnly: readOnly,
        tabMode: "default",
        extraKeys: {
            "Enter": function(editor) {
                editor.replaceSelection("")
            },
            "Tab": false,
            "Shift-Tab": false
        },
        scrollbarStyle: "null",
        placeholder: placeholder,
        screenReaderLabel: placeholder,
        inputStyle: "contenteditable",
        spellcheck: true
    };

}

import React, {Component, Fragment} from 'react';
import PageInfoPopover from '../components/PageInfoPopover';
import GuideTitle from '../components/GuideTitle';
import AppContext from "../AppContext";
import {empty} from "../components/textutil";
import texts from "../texts";
import Steps from "../components/Steps";
import DocLink from "../components/DocLink";
import OrganizerComponent from "./OrganizerComponent";
import Breadcrumb from "../Breadcrumb";
import BigPicture from "../components/BigPicture";
import Aside from "../components/Aside";
import PrintPreview from "../components/PrintPreview";
import KeyTerms from "../steps/KeyTerms";
import {getFirepadTextPromise, getRef} from '../utils/TextfieldUtils';
import $ from "jquery";
import RatingModal from "../components/modals/RatingModal";

const assert = require('assert');

export default class Routine extends OrganizerComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.getMain = this.getMain.bind(this);
        this.props.loadDocMethod();
        this.focused = this.focused.bind(this);
        this.content = null; // specify content in parent
        this.section = props.doc.section;
        this.state = {
            firstUseTourGuide:true,
            sidebarOpen: false,
            sidebarExpand: false,
            instructionTab: false,
            organizerTab: false,
            hasInstruction: false
        };

        this.hasInstructionText = false;
        this.hasInstructionImage = false;
        this.hasInstructionFileName = false;

        this._isMounted = false;

        if (this.props.doc.section !== "print")
            document.body.classList.add("has-bottomnav");
    }

    componentDidMount() {
        super.componentDidMount();
        this._isMounted = true;
        if (this.context.copyPending && this.context.copyDone) {
            console.log("Should reset state");
        }

        var section = this.props.doc.section;

        // Open TourGuide if user has not already seen it.
        // Disabled for now since it needs updating. CORGI-1108
        // if("toc" !== section && "print" !== section && "complete" !== section && "create-share" !==  section){
        //     var userNode = "user/" + this.context.currentUser.uid ;
        //     let userRef = window.firebase.database().ref(userNode);
        //     let self = this;
        //     userRef.once('value', function(snapshot) {
        //         if(snapshot.exists()){
        //             if(null !== snapshot.val().viewTourGuide && undefined !== snapshot.val().viewTourGuide)
        //                 self.setState({firstUseTourGuide:false});
        //             if(self.state.firstUseTourGuide) openTourGuide(self.context.currentUser.uid);
        //         }
        //     });
        // }

        this.onChangeSection(section);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        if (isCompletedSection(this.props.doc.section) && this.context.canEdit) {
            let userRef = window.firebase.database().ref("user/" + this.context.currentUser.uid);
            userRef.once('value', function (snapshot) {
                if (snapshot.exists()) {
                    const val = snapshot.val();
                    if (true !== val.dontShowRatingModalAgain && true !== val.answeredRatingModal) {
                        $('#modalRating').CFW_Modal("show");
                    }
                }
            });
        }
    }

    focused(section) {
        return (!empty(section) && 'complete' !== section);
    }

    focused1(section) {
        return (!empty(section));
    }

    render() {

        if (!this.context.fileIdLoaded) {
            return <div>Loading file...</div>;
        }

        const doc = {
            title: this.context.fileName,
            section: this.section,
            type: this.context.fileType,
            id: this.context.fileId,
            editing: this.context.canEdit
        };

        const sections = texts[doc.type].sections;
        assert(undefined !== sections && 0 < sections.length);

        this.toc = this.focused(doc.section) ?
            <div id={sections[0].toc} className={"section"}>
                <Steps doc={doc} steps={sections}/>
            </div>
            : "";

        return this.getMain(this.props.content); // specify content in parent

    }

    setSidebarOpen = (flg) => {
        this.setState({sidebarOpen: flg});
    }

    setSidebarExpand = (flg) => {
        this.setState({sidebarExpand: flg});
    }

    setSidebarTab = (tabName) => {
        this.setState({
            instructionTab: tabName === "Instruction",
            organizerTab: tabName === "Organizer"
        })
    };

    hasContent(text) {
        // Return a true value if text is not null and contains something other than whitespace.
        return undefined !== text && null !== text && text.trim();
    }

    onChangeSection = (section) => {
        this.hasInstructionText = false;
        this.hasInstructionImage = false;
        this.hasInstructionFileName = false;

        getFirepadTextPromise(this.context, "instructions/" + section).then((instructionContent) => {
            this.hasInstructionText = this.hasContent(instructionContent) ? true : false;
            this.changeSectionUpdate();
        });

        let dbRef = getRef(this.context, "instructions/" + section);
        dbRef.once("value").then(data => {
            let dataVal = data.val();
            this.hasInstructionImage = dataVal && dataVal.image ? (this.hasContent(dataVal.image) ? true : false) : false;
            this.hasInstructionFileName = dataVal && dataVal.fileName ? (this.hasContent(dataVal.fileName) ? true : false) : false;
            this.changeSectionUpdate();
        });
    }

    changeSectionUpdate = () => {
        if (this.hasInstructionText || this.hasInstructionImage || this.hasInstructionFileName) {
            this.setState({sidebarExpand: false, sidebarOpen: true, instructionTab: true, organizerTab: false, hasInstruction: true})
            // Due to the sideBar status changing from closed to be open
            // The input text will be changed from display: none to display: block
            // we need to invoke the CodeMirror.refresh() function to make the text content appear and display properly
            const codeMirrorInputText = document.getElementById("sidebarPanelInstruction").querySelector('.CodeMirror');
            if(codeMirrorInputText)
                codeMirrorInputText.CodeMirror.refresh();
        } else {
            if (this._isMounted) {
                this.setState({sidebarExpand: false, hasInstruction: false});
            }
        }
    }

    getMain(content) {
        let doc = this.props.doc;
        doc.editing = true;

        const titleLabel = texts[doc.type].title; // ReactDOMServer.renderToString(<GetText type={doc.type} item={"title"}/>);
        const title = this.focused1(doc.section) ?
            <GuideTitle id={"title"}
                        label={titleLabel}
                        placeholder={"Untitled "+titleLabel}
                        renameDocMethod={this.props.renameDocMethod}/>
            : <h1 className="h2">{titleLabel}</h1>;
        const info = this.focused(doc.section) ? null :
            <PageInfoPopover video="comparison-tour"
                             socExample="CT_Soc.pdf"
                             sciExample="CT_Sci.pdf"
                             doc={doc}
            />;
        const sections = texts[doc.type].sections;
        const last = sections.length-2;
        const nav =
            //<React.Fragment>
                // {'toc' === doc.section
                // ?     <ForwardLink doc={doc}/>
                // :     null}
                <Nav doc={doc} steps={sections.slice(1,last)} onChangeSection={this.onChangeSection}/>
            //</React.Fragment>

        if (!content && this.toc) {
            content = this.toc;
        }

        return ('print' === doc.section ?
                <React.Fragment>
                    <div className="page-wrapper">
                        <div className="content row">
                            <div className="content-main col">
                                <div className="content-main-inner">
                                    <Breadcrumb name={texts[doc.type].title}>
                                        {title}
                                    </Breadcrumb>
                                    <PrintPreview doc={doc} section={"print"} data={this.props.data}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {nav}
                </React.Fragment>
            : this.focused(doc.section) ?
                <React.Fragment>
                    <div className="page-wrapper">
                        <div id="content" className={`content row has-sidebar ${this.state.sidebarOpen ? 'has-sidebar-open': ''} ${this.state.sidebarExpand ? 'has-sidebar-expand': ''}`}>
                            {"create-share" !== doc.section ? <Aside instructionTab={this.state.instructionTab} organizerTab={this.state.organizerTab} setSidebarTab={this.setSidebarTab} setSidebarOpen={this.setSidebarOpen} setSidebarExpand={this.setSidebarExpand} section={doc.section} data={this.props.data} hasInstruction={this.state.hasInstruction}/> : null}
                            <div className="content-main col">
                                <div className="content-main-inner">
                                    {info}
                                    <Breadcrumb name={texts[doc.type].title}>
                                        {title}
                                    </Breadcrumb>
                                    <main id={"main_container"} className="main">
                                        {content}
                                    </main>
                                </div>
                            </div>
                        </div>
                        <KeyTerms editing={doc.editing} />
                    </div>
                    {nav}

                </React.Fragment>

            : <React.Fragment>
                <div className="page-wrapper">
                    <div className="content row">
                        <div className="content-main col">
                            <div className="content-main-inner">
                                <Breadcrumb name={texts[doc.type].title}>
                                    {title}
                                </Breadcrumb>
                                <BigPicture section={"complete"} data={this.props.data}>{nav}</BigPicture>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

class Nav extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.trackEvent = this.trackEvent.bind(this);
    }

    // `step` are the step number except for the case of `toc`, `create-share` and `review`
    trackEvent(step){
        window.speechstream.speechTools.stop();
        window.tracker.trackEvent("Navigation", "footer link", step);
        if(step === 'toc' || step === 'create-share' || step === 'review') {
            this.props.onChangeSection(step);
        } else {
            const sections = texts[this.props.doc.type].sections;
            const newSection = sections.find(section => step === section.stepnum);
            if (newSection) this.props.onChangeSection(newSection.key);
        }
    }

    render() {
        const sections = texts[this.props.doc.type].sections;

        const current = this.props.doc.section;

        let currentIndex = sections.findIndex(section => current === section.key);
        let nextIndex = currentIndex + 1;
        let prevIndex = currentIndex - 1;

        if (nextIndex === sections.length) {
            nextIndex = -1;
            // Alternatively, this would create a link to slides output rather than regular navigation.
            // Reconsider once slides output is implemented.
            // return <ForwardLink doc={this.props.doc}/>;
        }

        let prevlink = (prevIndex >= 0)
            ? <DocLink section={sections[prevIndex].key} className={"page-link"} onClick={() => this.trackEvent(prevIndex)}>
                <span className="pages-prev-icon fa fa-chevron-left" aria-hidden="true"></span>
                <span className="sr-only-xs-down">Previous</span>
              </DocLink>
            : <span className="page-link disabled" aria-disabled="true">
                <span className="pages-prev-icon fa fa-chevron-left" aria-hidden="true"></span>
                <span className="sr-only-xs-down">Previous</span>
              </span>;

        let nextlink = (nextIndex >= 0)
            ? <DocLink section={sections[nextIndex].key} className="page-link" onClick={() => this.trackEvent(nextIndex)}>
                <span className="sr-only-xs-down">Next</span>
                <span className="pages-next-icon fa fa-chevron-right" aria-hidden="true"></span>
              </DocLink>
            : <span className="page-link disabled" aria-disabled="true">
                <span className="sr-only-xs-down">Next</span>
                <span className="pages-next-icon fa fa-chevron-right" aria-hidden="true"></span>
              </span>;
        const toc_class = (current === 'toc')? " active": "";
        const complete_class = (current === 'complete')? " active": "";
        const create_share_class = (current === 'create-share')? " active": "";
        // Temporarily turned off: CORGI-1108
        const showTourGuide = false;
        //const showTourGuide = (current !== 'toc' && current !== 'complete' && current !== 'create-share');
        return (
            <React.Fragment>
            <nav className="footer-pages is-fixed" aria-label="pages">
                <div className="pagination pagination-group">
                    <div className={"pages-prev page-item"}>
                        { prevlink }
                    </div>
                </div>
                <ul className="pages-num pagination pagination-group">
                    <li className="pages-toc page-item">
                        <DocLink className={"page-link" + toc_class} section={'toc'} onClick={() => this.trackEvent("toc")}>
                            <span className="fa fa-list-ol" aria-hidden="true"></span>
                            <span className="sr-only">Table of Content</span>
                        </DocLink>
                    </li>

                    {this.props.steps.map((step, i) => {
                            const key = step.key;
                            if (key === current) {
                                return (
                                    <li key={key} className="pages-active page-item">
                                        <span className="page-link active" aria-current="page">{i + 1}</span>
                                    </li>
                                );
                            } else {
                                return (
                                    <li key={key} className="page-item">
                                        <DocLink className="page-link" section={key} onClick={() => this.trackEvent(i + 1)}>
                                            {i + 1}
                                        </DocLink>
                                    </li>
                                );
                            }
                    })}

                    <li className="pages-bpv page-item">
                        <DocLink className={"page-link"+complete_class} section={'complete'} onClick={() => this.trackEvent("review")}>
                            <span className="fa fa-table" aria-hidden="true"></span>
                            <span className="sr-only">{sections[sections.length-2].title}</span>
                        </DocLink>
                    </li>
                    <li className="page-item">
                        <DocLink className={"page-link"+create_share_class} section={'create-share'} onClick={() => this.trackEvent("create-share")}>
                            <span className="fa fa-file-export" aria-hidden="true"></span>
                            <span className="sr-only">Create &amp; Share</span>
                        </DocLink>
                    </li>
                </ul>
                <div className="pagination pagination-group">
                    <div className={"pages-next page-item"}>
                        { nextlink }
                    </div>
                </div>
                { showTourGuide ?
                    <Fragment>
                        <span className="end-tour-guide" data-cfw="popover" data-cfw-popover-target="#onboard5" data-cfw-popover-placement="top"></span>
                        <div className="footer-pages-tour">
                            <button type="button" className="btn btn-round btn-tts pages-onboard d-sm-down-none" data-cfw="popover" data-cfw-popover-target="#onboard0" title="Start Corgi Tools Tour">
                                <span className="fa fa-paw fs-2xlarge" aria-hidden="true"></span>
                            </button>
                        </div>

                    </Fragment>

                    : null
                }
            </nav>
            {(isCompletedSection(current) && this.context.canEdit) ?
                <Fragment>
                    <RatingModal/>
                </Fragment>
                : null
            }
            </React.Fragment>
        );

    }
}

class ForwardLink extends Component {

    constructor(props) {
        super(props);
        this.trackEvent = this.trackEvent.bind(this);
    }

    render() {
        const sections = texts[this.props.doc.type].sections;

        const current = this.props.doc.section;
        const currentIndex = sections.findIndex(section => current === section.key);
        const nextIndex = currentIndex + 1;
        const section = sections[nextIndex];

        return (
            <div className="text-center">
                <DocLink section={section.key} className="btn btn-primary" onClick={() => this.trackEvent()}>
                    Get Started
                    <span className="ms-0_5 fa fa-arrow-right" aria-hidden="true"></span>
                </DocLink>
            </div>
        )
    }

    trackEvent(){
        window.tracker.trackEvent("Navigation", "get started", "");
    }
}

function isCompletedSection(section) {
    return "complete" === section || "review" === section;
}

import './ui';
import 'firebase/auth';
import md5 from "md5";

let _paq = [];
window._paq = _paq;

export default class Track {

    constructor() {
        var _paq = window._paq || [];
        // tracker methods like "setCustomDimension" should be called before "trackPageView"
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        // accurately measure the time spent on the last pageview of a visit
        _paq.push(['enableHeartBeatTimer']);
        ((u, id) => {
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', id]);
            var d = window.document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0];
            g.type = 'text/javascript';
            g.async = true;
            g.src = u+'matomo.js';
            s.parentNode.insertBefore(g, s);
        })(window.matomoUrl, window.matomoSiteId);
        this.trackEvent = this.trackEvent.bind(this);
        this.trackPageView = this.trackPageView.bind(this);

        console.log('Initialized tracker; url=', window.matomoUrl, ', siteId=', window.matomoSiteId);
    }

    trackEvent(category, action, name) {
        console.log("TrackEvent with: category = " + category + ", action = " + action + ", name = " + name);
        window._paq.push(['trackEvent', category, action, name]);
    }

    trackPageView(user, pageTitle) {
        var user_id = md5(user.emailAddress);
        console.log("Track PageView: " + user_id + " / " + pageTitle);
        window._paq.push(['setUserId', user_id]);  // Must be set for every page view
        window._paq.push(['setCustomDimension', 1, user.role ]);
        window._paq.push(['setDocumentTitle', pageTitle]);
        window._paq.push(['setCustomUrl', window.location.pathname]);
        window._paq.push(['trackPageView']);
    }

}

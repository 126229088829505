import React, {useEffect} from 'react';
import initUI from "./ui";
import CollabUser from "./components/CollabUser";
import PrintButton from "./components/PrintButton";

export default function Header(props) {

    useEffect(() => {
        initUI();
    });

    return (
        <header className="site-header">
            {props.children}
            <div className="site-header-end">
                <PrintButton/>
                <CollabUser/>
            </div>
        </header>
    );
}

import SttInput from "../../components/SttInput";
import React, {Fragment} from "react";
import StepHeader from "../StepHeader";
import ReadOnlyField from "../../ReadOnlyField";
import AutocompleteArrow from "../../components/AutocompleteArrow";
import AppContext from "../../AppContext";
import ReadOnlyText from "../../components/ReadOnlyText";
import T from 'i18n-react';

export default class Reasoning extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.identifier = "reasoningnote";
    }

    getParentNode() {
        let parentNode = this.context.fileType + "/" + this.context.fileId;
        return parentNode;
    }

    render() {
        const reasoningtype = this.context.canEdit ?
                                    <div className="form-group">
                                        <AutocompleteArrow
                                            identifier={"reasoningtype"}
                                            parentNode={this.getParentNode()}
                                            placeholder={T.translate(this.props.doc.type + ".reasoningtype.placeholder")}
                                            label="Evidence Quality"
                                            autocompleteOptions={
                                                [ 'This evidence supports the claim because...'
                                                , 'This evidence does not support the claim because...'
                                                , 'Based on the evidence, I conclude that…']}
                                        />
                                    </div>
                                :
                                    <div className={"box bg-autofill"} >
                                        <ReadOnlyText identifier="reasoningtype" className={"col"}/>
                                    </div>
        return (
            <Fragment>
                <StepHeader section={this.props.section} tooltipTitle={true} />
                <h2>Claim</h2>
                <ReadOnlyField identifier="claim" className="box bg-autofill" />
                { this.context.canEdit ?
                    <Fragment>
                    <div className="box bg-primary">
                        {reasoningtype}
                        <SttInput {...this.props} inputType={"text-area"} identifier={this.identifier} addImage={true} singleLine={false} />
                    </div>
                    </Fragment>
                :
                    <Fragment>
                    {reasoningtype}
                    <SttInput {...this.props} inputType={"text-area"} identifier={this.identifier} addImage={true} singleLine={false} />
                    </Fragment>
                }
            </Fragment>
        );
    }

}

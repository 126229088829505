import React from "react";
import DynamicComponent from "../DynamicComponent";
import {firebaseConfig} from '../realtimedb-utils';
import GooglePicker from "react-google-picker";
import AppContext from "../AppContext";

export default class FilePicker extends DynamicComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.picked = this.picked.bind(this);
        this.file = {src: "", id: "", name: ""};
    }

    picked(data) {
        if ('picked' === data.action) {
            console.log(data);
            this.file.name = data.docs[0].name;
            this.file.id = data.docs[0].id;
            this.file.src = data.docs[0].url;

            if (this.file.id)
                this.props.saveMethod(this.file);
        }
    }

    render() {
        let clientId = firebaseConfig ? firebaseConfig.clientId : '42';
        let apiKey = firebaseConfig ? firebaseConfig.apiKey : '42';
        let scopes = firebaseConfig ? firebaseConfig.scopes : [];

        return (
            <GooglePicker id={"picker"}
                          clientId={clientId}
                          developerKey={apiKey}
                          scope={scopes}
                          onChange={data => this.picked(data)}
                          onAuthFailed={data => console.log('on auth failed:', data)}
                          onAuthenticate={token => this.context.checkAccessTokenBeforeInitGooglePicker(token)}
                          multiselect={false}
                          authImmediate={false}
                          mimeTypes={[]}
                          viewId={'DOCS'}>
                {this.props.children}
            </GooglePicker>
        )
    }
}

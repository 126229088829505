import { Component } from 'react';
import React from "react";
import AppContext from "../AppContext";
import $ from "jquery";

import imgLogo from 'url:/public/img/mark.svg';

export const navMenuButtonId = "navMenu";
export const navOffcanvas = "offcanvasNavbar";

export default class BrandBar extends Component {
    static contextType = AppContext;

    render() {
        return (
            <div className="navbar">
                {this.context.currentUser &&
                <button id={navMenuButtonId} type="button" className="btn btn-icon btn-link header-menu-btn" title="Toggle menu"
                        data-cfw-offcanvas-target={"#"+navOffcanvas}>
                    <span className="fa fa-fw fa-bars" aria-hidden="true"></span>
                    <span className="sr-only">Toggle menu</span>
                </button>}
                <span data-cfw="popover" data-cfw-popover-target="#onboard4" data-cfw-popover-placement="bottom"></span>
                <a href={"/"} className="header-brand">
                    <img src={imgLogo} className="header-logo" alt=""/>
                    <span className="header-title sr-only-xs-down">Corgi</span>
                    <span className="header-tagline sr-only-sm-down">Co-organize your learning</span>
                </a>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.initButton();
    }

    componentDidMount() {
        this.initButton();
    }

    initButton() {
        const button = $('#' + navMenuButtonId);
        if (button) {
            button.CFW_Offcanvas();
            // RemoveAttr needed to prevent double-initialization of button by later CFW_Init().
            // button.removeAttr('data-cfw');
        }
    }

}

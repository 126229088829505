import React, { Fragment } from "react";
import ReadOnlyField from "../../ReadOnlyField";
import SimpleCharacteristicList from "../../components/SimpleCharacteristicList";
import SimilarCharacteristics from "./SimilarCharacteristics";
import texts from "../../texts";
import DissimilarCharacteristics from "./DissimilarCharacteristics";
import GetText from "../../components/GetText";
import PreviewComponent from "../../components/PreviewComponent";
import KeyTerms from "../KeyTerms";
import AppContext from "../../AppContext";
import {Link} from "react-router-dom";
import EmptyField from "../../components/EmptyField";

const similarHeader = <h2>Similar Characteristics &amp; Categories</h2>;
const dissimilarHeader = <h2>Different Characteristics &amp; Categories</h2>;
const complete = texts.comparison.sections[texts.comparison.sections.length-1];

export const concepts = (doc,fieldStatus,inCompleteStep) => {
    const cssSticky = inCompleteStep ? "content-main-sticky-top" : "content-sidebar-sticky-top";

    const question = <React.Fragment>
        <div className={"box bg-autofill"} >
            <div className={"row"}>
                <ReadOnlyField identifier={"essentialquestion"} className={"grouping-text"} />
                <ReadOnlyField identifier={"essentialquestion/image"} className={"grouping-img"} />
            </div>
        </div>
    </React.Fragment>
    return(
        <React.Fragment>
            <div className="section">
                <h2>Essential Question</h2>
                {!inCompleteStep ?
                    <Fragment>{question}</Fragment>
                    : fieldStatus.hasEssentialQuestion ?
                        <Fragment>{question}</Fragment>
                        :
                        <EmptyField identifier={"essentialquestion"} className="box bg-unfilled"/>
                }
            </div>


            <div className={cssSticky}>
                <div className="section">
                    <h2>Concepts</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <h3><GetText type={doc.type} identifier={"concept1"} item={"label"} /></h3>
                            {!inCompleteStep ?
                                <ReadOnlyField identifier={"concept1"} className={'box bg-autofill'} />
                                : fieldStatus.hasConcept1 ?
                                    <ReadOnlyField identifier={"concept1"} className={'box bg-autofill'} />
                                    :
                                    <EmptyField identifier={"concept1"} className="box bg-unfilled"/>
                            }
                        </div>
                        <div className="col-md-6">
                            <h3><GetText type={doc.type} identifier={"concept2"} item={"label"} /></h3>
                            {!inCompleteStep ?
                                <ReadOnlyField identifier={"concept2"} className={'box bg-autofill'} />
                                : fieldStatus.hasConcept2 ?
                                    <ReadOnlyField identifier={"concept2"} className={'box bg-autofill'} />
                                    :
                                    <EmptyField identifier={"concept2"} className="box bg-unfilled"/>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="row">
                    <div className="col-md-6">
                        <h3>Description of Concept A</h3>
                        {!inCompleteStep ?
                            <div className={"box bg-autofill"} >
                                <div className={"row"}>
                                    <ReadOnlyField identifier={"description1"} className={"grouping-text"} />
                                    {'print' === doc.section? null:
                                        <ReadOnlyField identifier={"description1/image"} className={"grouping-img"} />}
                                </div>
                            </div>
                            : fieldStatus.hasDescription1?
                                <div className={"box bg-autofill"} >
                                    <div className={"row"}>
                                        <ReadOnlyField identifier={"description1"} className={"grouping-text"} />
                                        {'print' === doc.section? null:
                                            <ReadOnlyField identifier={"description1/image"} className={"grouping-img"} />}
                                    </div>
                                </div>
                                :
                                <EmptyField identifier={"description1"} className="box bg-unfilled"/>
                        }
                    </div>
                    <div className="col-md-6">
                        <h3>Description of Concept B</h3>
                        {!inCompleteStep ?
                            <div className={"box bg-autofill"} >
                                <div className={"row"}>
                                    <ReadOnlyField identifier={"description2"} className={"grouping-text"} />
                                    {'print' === doc.section? null:
                                        <ReadOnlyField identifier={"description2/image"} className={"grouping-img"} />}
                                </div>
                            </div>
                            : fieldStatus.hasDescription2 ?
                                <div className={"box bg-autofill"} >
                                    <div className={"row"}>
                                        <ReadOnlyField identifier={"description2"} className={"grouping-text"} />
                                        {'print' === doc.section? null:
                                            <ReadOnlyField identifier={"description2/image"} className={"grouping-img"} />}
                                    </div>
                                </div>
                                :
                                <EmptyField identifier={"description2"} className="box bg-unfilled"/>
                        }

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export const overall = (doc,fieldStatus,inCompleteStep) => {
    const catBox = <Fragment>
        <div className="grouping grouping-1">
            <h3 className="grouping-title">Overall Concept</h3>
            <div className={"grouping-item"}>
                <div className="box box-autofill">
                    <div className="row">
                        <ReadOnlyField identifier={"overall"} className={"grouping-text"} />
                        <ReadOnlyField identifier={"overall/image"} className={"grouping-img"} />
                    </div>
                </div>
            </div>
        </div>
    </Fragment>;

    const overall = <React.Fragment>
            <div className="grouping grouping-2">
                <div className="grouping-item">
                    <h3 className="grouping-item-title">Concept A</h3>
                    <div className="box bg-autofill">
                        <div className="row">
                            <ReadOnlyField identifier={"concept1"} className={"grouping-text"}/>
                        </div>
                    </div>
                </div>
                <div className="grouping-item">
                    <h3 className="grouping-item-title">Concept B</h3>
                    <div className="box bg-autofill" >
                        <div className="row">
                            <ReadOnlyField identifier={"concept2"} className={"grouping-text"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bracket bracket-2">
                <div className="bracket-arrow-vertical"></div>
                <div className="bracket-start"></div>
                <div className="bracket-bar"></div>
                <div className="bracket-arrow"></div>
                <div className="bracket-bar"></div>
                <div className="bracket-end"></div>
            </div>
            {catBox}
    </React.Fragment>

    return(
        <div className="section">
            <h2>Overall Concept</h2>
            {!inCompleteStep || fieldStatus.hasOverall
                ? overall
                : <EmptyField identifier={"overall"} className="box bg-unfilled"/>
            }
        </div>
    )
};

export const characteristics = (doc,characteristicsData,fieldStatus,inCompleteStep) => {
    return(
        <div className="section">
            <h2>Characteristics</h2>
            <div className="row">
                <div className="col-md-6">
                    <h3>Characteristics of Concept A</h3>
                    {!inCompleteStep?
                        <SimpleCharacteristicList doc={doc} identifier="characteristics1" editing={false} data={characteristicsData} />
                        : fieldStatus.hasCharacteristics1 ?
                            <SimpleCharacteristicList doc={doc} identifier="characteristics1" editing={false} data={characteristicsData} />
                        : <EmptyField identifier={"characteristics1"} className="box bg-unfilled"/>
                    }
                </div>
                <div className="col-md-6">
                    <h3>Characteristics of Concept B</h3>
                    {!inCompleteStep?
                        <SimpleCharacteristicList doc={doc} identifier="characteristics2" editing={false} data={characteristicsData} />
                        : fieldStatus.hasCharacteristics2 ?
                            <SimpleCharacteristicList doc={doc} identifier="characteristics2" editing={false} data={characteristicsData} />
                        : <EmptyField identifier={"characteristics2"} className="box bg-unfilled"/>
                    }
                </div>
            </div>
        </div>
    )
}
export const similar = (doc,characteristicsData,fieldStatus,inCompleteStep) => {
    const showLabel = false;
    return(
        !inCompleteStep ?
            <div className="section">
                <SimilarCharacteristics editing={false} showBoth={true} showCategory={true} showLabel={showLabel} header={similarHeader}
                                        doc={doc} section={complete} data={characteristicsData} />
            </div>
            : fieldStatus.hasSimilarCharacteristics ?
                <div className="section">
                    <SimilarCharacteristics editing={false} showBoth={true} showCategory={true} showLabel={showLabel} header={similarHeader}
                                            doc={doc} section={complete} data={characteristicsData} />
                </div>
                :
                <div className="section">
                    {similarHeader}
                    <EmptyField identifier={"likecharacteristics"} className="box bg-unfilled"/>
                </div>

    )}

export const different = (doc,characteristicsData,fieldStatus,inCompleteStep) => {
    const showLabel = false;
    return(
        !inCompleteStep ?
            <div className="section">
                <DissimilarCharacteristics editing={false} showBoth={true} showCategory={true} showLabel={showLabel} header={dissimilarHeader}
                                        doc={doc} section={complete} data={characteristicsData} />
            </div>
            : fieldStatus.hasDifferentCharacteristics ?
                <div className="section">
                    <DissimilarCharacteristics editing={false} showBoth={true} showCategory={true} showLabel={showLabel} header={dissimilarHeader}
                                            doc={doc} section={complete} data={characteristicsData} />
                </div>
            :
                <div className="section">
                    {dissimilarHeader}
                    <EmptyField identifier={"unlikecharacteristics"} className="box bg-unfilled"/>
                </div>
    )}

export const summary = (doc,fieldStatus,inCompleteStep) => {
    const sum = <React.Fragment>
        <div className="box bg-autofill">
            <div className={"row"}>
                <ReadOnlyField identifier={"summary"} className={"grouping-text"} />
                    {'print' === doc.section? null:
                        <ReadOnlyField identifier={"summary/image"} className={"grouping-img"} />}
            </div>
        </div>
    </React.Fragment>

    return(
        <section className="section mb-1">
            <h2 className="h2">Summary</h2>
                {!inCompleteStep ?
                    <React.Fragment>{sum}</React.Fragment>
                    : fieldStatus.hasSummary ?
                        <React.Fragment>{sum}</React.Fragment>
                    : <EmptyField identifier={"summary"} className="box bg-unfilled"/>
                }
        </section>
    )}
;
export const extensions = (doc,fieldStatus,inCompleteStep) => {
    const ext = <React.Fragment>
        <div className={"box bg-autofill"} >
            <div className={"row"}>
                <ReadOnlyField identifier={"extensions"} className={"grouping-text"} />
                    {'print' === doc.section? null:
                <ReadOnlyField identifier={"extensions/image"} className={"grouping-img"} />}
            </div>
        </div>
    </React.Fragment>

    return(
        <div className="section">
            <h2 className="h2">Extensions</h2>
            <React.Fragment>{ext}</React.Fragment>
        </div>
    )}
;


export const keyTerms = (doc,fieldStatus,inCompleteStep) => {
    const section = texts[doc.type].terms;
    return (
        <section className="section mb-1">
            <h2>{section.title ? section.title : section.label}</h2>
            {!inCompleteStep ?
                <KeyTerms doc={doc} section={section} editing={false} secondary={true}/>
                : fieldStatus.hasKeyTerms ?
                    <KeyTerms doc={doc} section={section} editing={false} secondary={true}/>
                    :
                    <EmptyField identifier={"terms"} className="box bg-unfilled"/>
            }
        </section>
    )
}

export const review_checklist = (doc,fieldStatus,closeChecklist,onClickFunc) => {
    return (
            fieldStatus.display ?
                <section className="section-step">
                    <h3 className="h3">Review Checklist</h3>

                    <div className="box box-unfilled">
                        <div className="box-row-close">
                            <button type="button" className="btn btn-icon btn-link" aria-label="Remove key term" title="Remove Checklist" onClick={closeChecklist}>
                                <span className="fa fa-times text-primary" aria-hidden="true"></span>
                            </button>
                        </div>
                        <ul>
                            {!fieldStatus.hasEssentialQuestion ? <li>You have not entered an <b>Essential Question</b>. Go to <Link to="essentialquestion" onClick={() => onClickFunc(1)}>Step 1</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasKeyTerms ? <li>You have not entered any <b>Key Terms</b>. Go to <Link to="terms" onClick={() => onClickFunc(2)}>Step 2</Link> to define Key Terms</li> : null}
                            {!fieldStatus.hasConcept1 ? <li>You have not entered an <b>Concept A</b>. Go to <Link to="concepts" onClick={() => onClickFunc(3)}>Step 3</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasConcept2 ? <li>You have not entered an <b>Concept B</b>. Go to <Link to="concepts" onClick={() => onClickFunc(3)}>Step 3</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasDescription1 ? <li>You have not entered an <b>Description of Concept A</b>. Go to <Link to="concepts" onClick={() => onClickFunc(3)}>Step 3</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasDescription2 ? <li>You have not entered an <b>Description of Concept B</b>. Go to <Link to="concepts" onClick={() => onClickFunc(3)}>Step 3</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasOverall ? <li>You have not entered an <b>Overall Concept</b>. Go to <Link to="overall" onClick={() => onClickFunc(4)}>Step 4</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasCharacteristics1 ? <li>You have not entered any <b>Characteristics of Concept A</b>. Go to <Link to="characteristics" onClick={() => onClickFunc(5)}>Step 5</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasCharacteristics2 ? <li>You have not entered any <b>Characteristics of Concept B</b>. Go to <Link to="characteristics" onClick={() => onClickFunc(5)}>Step 5</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasSimilarCharacteristics ? <li>You have not entered any <b>Similar Characteristics</b>. Go to <Link to="likeCharacteristics" onClick={() => onClickFunc(6)}>Step 6</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasDifferentCharacteristics ? <li>You have not entered any <b>Different Characteristics</b>. Go to <Link to="unlikeCharacteristics" onClick={() => onClickFunc(8)}>Step 8</Link> to complete the section.</li> : null}
                            {!fieldStatus.hasSummary ? <li>You have not entered a <b>Summary</b>. Go to <Link to="summary" onClick={() => onClickFunc(10)}>Step 10</Link> to complete the section.</li> : null}
                        </ul>
                    </div>
                </section>
            : null
    )
}

export default class ComparisonSteps extends PreviewComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state ={
            display : false,
            hasEssentialQuestion: false,
            hasConcept1: false,
            hasConcept2: false,
            hasDescription1: false,
            hasDescription2: false,
            hasKeyTerms: false,
            hasOverall: false,
            hasCharacteristics1: false,
            hasCharacteristics2: false,
            hasSimilarCharacteristics: false,
            hasDifferentCharacteristics: false,
            hasSummary: false
        };
        this.closeChecklist = this.closeChecklist.bind(this);
        this.checkEmptyList = this.checkEmptyList.bind(this);
        this.mapHasContent = this.mapHasContent.bind(this);
        this.onClickFunc = this.onClickFunc.bind(this);
    }

    closeChecklist(){
        this.setState({display: false});
    }

    checkPairCharacteristics(type){
        let path = "pairs/" + type;
        let ref = this.getRef(path);
        ref.once("value").then(list => {
            let pairedItems = list.val();
            let haveContent = pairedItems != null ? true : false;
            if("likecharacteristics" === type) this.setState({hasSimilarCharacteristics: haveContent});
            if("unlikecharacteristics" === type) this.setState({hasDifferentCharacteristics: haveContent});
        });
    }

    getComparisonMapsPromise() {
		let proms = [
			this.getFirepadMapPromise('terms', 'def'),
            this.getFirepadMapPromise('terms', 'term'),
            this.getFirepadMapPromise('characteristics1', 'text'),
			this.getFirepadMapPromise('characteristics2', 'text')
		];
		return Promise.all(proms)
			.then((maps) => {
			return {
				'termsdef' : maps[0],
                'termsword' : maps[1],
                'characteristics1' : maps[2],
				'characteristics2' : maps[3]
			};
		});
    }

    checkEmptyList(){
        this.getComparisonMapsPromise().then((valueMaps) =>{
            if(valueMaps != null) {
                var hasTermOrDef = this.mapHasContent(valueMaps['termsword'])
                    || this.mapHasContent(valueMaps['termsdef']);
                let update = {
                    hasKeyTerms: hasTermOrDef,
                    hasCharacteristics1: this.mapHasContent(valueMaps['characteristics1']),
                    hasCharacteristics2: this.mapHasContent(valueMaps['characteristics2'])
                };
                this.setState(update);
                this.updateChecklist();
            }
        });
    }

    updateChecklist(){
        let showChecklist = false;
        if(!this.state.hasEssentialQuestion) showChecklist = true;
        if(!this.state.hasConcept1) showChecklist = true;
        if(!this.state.hasConcept2) showChecklist = true;
        if(!this.state.hasDescription1) showChecklist = true;
        if(!this.state.hasDescription2) showChecklist = true;
        if(!this.state.hasKeyTerms) showChecklist = true;
        if(!this.state.hasOverall) showChecklist = true;
        if(!this.state.hasCharacteristics1) showChecklist = true;
        if(!this.state.hasCharacteristics2) showChecklist = true;
        if(!this.state.hasSimilarCharacteristics) showChecklist = true;
        if(!this.state.hasDifferentCharacteristics) showChecklist = true;
        if(!this.state.hasSummary) showChecklist = true;

        this.setState({
            display : showChecklist
        })
    }

    checkEmptyField(field){
        this.getFirepadTextPromise(field).then((data) =>{
            let hasContent = this.hasContent(data);
            if("essentialquestion" === field) this.setState({hasEssentialQuestion: hasContent});
            if("concept1" === field) this.setState({hasConcept1: hasContent});
            if("concept2" === field) this.setState({hasConcept2: hasContent});
            if("description1" === field) this.setState({hasDescription1: hasContent});
            if("description2" === field) this.setState({hasDescription2: hasContent});
            if("overall" === field) this.setState({hasOverall: hasContent});
            if("summary" === field) this.setState({hasSummary: hasContent});
            this.updateChecklist();
        });
    }

    componentDidMount() {
        for (let field of ['essentialquestion','concept1','concept2','description1','description2','overall','summary','extensions']) {
            this.checkEmptyField(field);
        }

        for (let field of ['likecharacteristics','unlikecharacteristics']) {
            this.checkPairCharacteristics(field);
        }
        this.checkEmptyList();
    }

    onClickFunc(step){
        window.tracker.trackEvent("Navigation", "checklist link", step);
    }

    render(){
        return <React.Fragment>
            {this.props.inCompleteStep && this.context.canEdit ? review_checklist(this.props.doc,this.state,this.closeChecklist,this.onClickFunc) : null }
            {concepts(this.props.doc,this.state,this.props.inCompleteStep)}
            {keyTerms(this.props.doc,this.state,this.props.inCompleteStep)}
            {overall(this.props.doc,this.state,this.props.inCompleteStep)}
            {characteristics(this.props.doc,this.props.data,this.state,this.props.inCompleteStep)}
            {similar(this.props.doc,this.props.data,this.state,this.props.inCompleteStep)}
            {different(this.props.doc,this.props.data,this.state,this.props.inCompleteStep)}
            {summary(this.props.doc,this.state,this.props.inCompleteStep)}
            {extensions(this.props.doc,this.state,this.props.inCompleteStep)}
        </React.Fragment>
    }
}

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ListComponent from './ListComponent';
import uniqueId from '../uniqueId';
import AppContext from "../AppContext";
import ReadOnlyField from "../ReadOnlyField";
import T from 'i18n-react';
import DeleteModal from "./DeleteModal";
import DeleteButton from "./DeleteButton";
import SttInput from "./SttInput";

export default class RelatedQuestionList extends ListComponent{
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.identifier = this.props.identifier;
        this.identifierRef = null;

        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const editing = (undefined !== this.props.editing)? this.props.editing: true;
        const print = (undefined !== this.props.print)? this.props.print: false;
        const haveResponse = (undefined !== this.props.haveResponse)? this.props.haveResponse: false;
        const itemList = this.getContentList();
        let itemsHTML = [];

        if (itemList) {
            for (let key in itemList) {
                if (itemList.hasOwnProperty(key)){
                    itemsHTML.push(<Question key={key}
                                              id={key}
                                              identifier={this.identifier}
                                              doc={this.props.doc}
                                              deleteMethod={this.handleDeleteItem}
                                              canDelete={editing && this.state.canDelete}
                                              editing={editing}
                                              print={print}
                                              haveResponse={haveResponse}
                                              data={this.props.data}
                    />);
                }
            }
        }
        let addButton = editing && !haveResponse?
            <button onClick={this.handleAddItem} type="button" className="btn btn-primary mb-2">
                <span className="fa fa-plus me-0_25" aria-hidden="true"></span>
                Add Question
            </button>
        : null;

        if(!this.context.canEdit) addButton = null;

        return (
            <Fragment>
                {itemsHTML}
                {addButton}
            </Fragment>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            question: "",
            answer: ""
        });
    }

    getValuePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/question";
        return path;
    }

    getImagePath(key) {
        let path = this.context.fileType + "/" + this.context.fileId + "/" + this.identifier + "/" + key + "/question/image";
        return path;
    }
}

class Question extends Component{
    static contextType = AppContext;
    constructor(props) {
        super(props);

        this.id = props.id;
        this.identifier = props.identifier;
        this.deleteButtonId = uniqueId();
        this.deleteModalId = uniqueId();
        this.getBaseNode = this.getBaseNode.bind(this);
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
    }

    getBaseNode() {
        return this.props.doc.type + "/" + this.props.doc.id;
    }

    render() {
        const mquestion = this.identifier + "/" + this.id+"/question";
        const manswer = this.identifier + "/" + this.id+"/answer";
        const deleteMessage = "Do you want to delete question?";
        const deleteModal = <DeleteModal id={this.deleteModalId} method={this.handleDeleteButton} text={deleteMessage} yes="Remove"/>
        const deleteIcon = <DeleteButton icon={"fa-times"} tooltip={"Remove Question"} modalId={this.deleteModalId}
                             canDelete={this.props.canDelete}/>

        return(this.props.editing ?
            <Fragment>
                { !this.props.haveResponse ?
                    <Fragment>
                        { this.context.canEdit ?
                            <Fragment>
                                <div className="box bg-primary">
                                    { this.props.canDelete ?
                                        <div className="box-row-close">
                                            {deleteModal}
                                            {deleteIcon}
                                        </div>
                                    :
                                        null
                                    }
                                    <div className="form-group">
                                        <SttInput {...this.props} inputType={"text-area"} placeholder={T.translate(this.props.doc.type + "." + this.identifier + ".placeholder")} identifier={mquestion} addImage={true} singleLine={false} />
                                    </div>
                                </div>
                            </Fragment>
                        :
                            <Fragment>
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <ReadOnlyField identifier={mquestion} className="grouping-text" />
                                        <ReadOnlyField identifier={mquestion + "/image"} className="grouping-img" />
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </Fragment>
                :
                    <Fragment>
                        <div className="row">
                            <div className="col-md-6">
                                <ReadOnlyField identifier={mquestion} className="box bg-autofill" />
                            </div>
                            <div className="col-md-6">
                                { this.context.canEdit ?
                                    <Fragment>
                                        <div className="box bg-primary">
                                            <div className="form-group">
                                                <SttInput {...this.props} inputType={"text-area"}
                                                    placeholder={T.translate(this.props.doc.type + ".relatedquestionresponse.placeholder")}
                                                    identifier={manswer} addImage={true} singleLine={false} />
                                            </div>
                                        </div>
                                    </Fragment>
                                :
                                    <Fragment>
                                        <div className="box bg-autofill">
                                            <div className="row">
                                                <ReadOnlyField identifier={manswer} className="grouping-text" />
                                                <ReadOnlyField identifier={manswer + "/image"} className="grouping-img" />
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </Fragment>
                }
            </Fragment>
            :
            <Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <div className={"box bg-autofill"} >
                            <div className={"row"}>
                                <ReadOnlyField identifier={mquestion} className={"grouping-text"} />
                                {!this.props.print ? <ReadOnlyField identifier={mquestion+"/image"} className={"grouping-img"} /> : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={"box bg-autofill"} >
                            <div className={"row"}>
                                <ReadOnlyField identifier={manswer} className={"grouping-text"} />
                                {!this.props.print ? <ReadOnlyField identifier={manswer+"/image"} className={"grouping-img"} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    handleDeleteButton() {
        this.props.deleteMethod(this.id);
    }
}

Question.propTypes = {
    id: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    doc: PropTypes.object.isRequired,
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired
};
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import uniqueId from '../uniqueId';
import T from 'i18n-react';
import SttInput from "./SttInput";
import ReadOnlyField from "../ReadOnlyField";
import AppContext from "../AppContext";
import DeleteModal from "./DeleteModal";
import DeleteButton from "./DeleteButton";


/**
 *  Component for single cause item
 */
export default class Cause extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.deleteModalId = uniqueId();
        this.deleteButtonId = uniqueId();
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
    }

    render () {
        const cause = this.props.identifier + "/" + this.props.id+"/text";
        const canEdit = this.context.canEdit;
        const deleteMessage = "Do you really want to delete this cause?";
        const deleteModal = <DeleteModal id={this.deleteModalId} method={this.handleDeleteButton} text={deleteMessage} yes="Remove"/>
        const deleteIcon = this.context.canEdit ? <DeleteButton icon={"fa-times"} tooltip={"Remove Cause"} modalId={this.deleteModalId}
                                                                canDelete={this.props.canDelete}/> : null;

        return ( this.props.editing && canEdit ?
                <div className="box bg-primary">
                    { this.props.canDelete ?
                        <div className="box-row-close">
                            {deleteModal}
                            {deleteIcon}
                        </div>
                    :
                        null
                    }
                    <div className="form-group">
                        <SttInput addImage={true}
                                  placeholder={T.translate(this.props.type + "." + this.props.identifier + ".placeholder")}
                                  identifier={cause} />
                    </div>
                </div>
                :
                <div className={"grouping-item"} >
                    <div className={"box bg-autofill"} >
                        <div className={"row"} >
                        <ReadOnlyField identifier={cause} className={"grouping-text"} />
                        {!this.props.noImage?
                            <ReadOnlyField identifier={cause+"/image"} className={"grouping-img"} />
                            :null
                        }
                        </div>
                    </div>
                </div>
        );
    }

    handleDeleteButton() {
        this.props.deleteMethod(this.props.id);
    }

}

Cause.propTypes = {
    id: PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired,
};


import React, {Fragment} from "react";
import StepHeader from "../StepHeader";
import Overall from "../../components/Overall";
import AppContext from "../../AppContext";
import ReadOnlyField from "../../ReadOnlyField";

export default class OverallConcept extends React.Component {
    static contextType = AppContext;

    render() {
        return (
            <Fragment>
                <StepHeader tooltipTitle={true} section={this.props.section} />
                <h2>Essential Question</h2>
                <ReadOnlyField identifier="essentialquestion" className="box bg-autofill" />
                <div className="grouping grouping-2">
                    <div className="grouping-item">
                        <h2 className="grouping-item-title">Concept A</h2>
                        <ReadOnlyField identifier="concept1" className="box bg-autofill" />
                    </div>
                    <div className="grouping-item">
                        <h2 className="grouping-item-title">Concept B</h2>
                        <ReadOnlyField identifier="concept2" className="box bg-autofill" />
                    </div>
                </div>
                <div className="bracket bracket-2">
                    <div className="bracket-arrow-vertical"></div>
                    <div className="bracket-start"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-arrow"></div>
                    <div className="bracket-bar"></div>
                    <div className="bracket-end"></div>
                </div>
                <div className="grouping grouping-1">
                    <div className="grouping-item">
                        { this.context.canEdit ?
                            <Fragment>
                                <div className="box bg-primary">
                                    <div className="form-group">
                                        <Overall />
                                    </div>
                                </div>
                            </Fragment>
                        :
                            <Fragment>
                                <div className="box bg-autofill">
                                    <div className="row">
                                        <ReadOnlyField identifier="overall" className="grouping-text" />
                                        <ReadOnlyField identifier="overall/image" className="grouping-img" />
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
            </Fragment>
        );

    }
}

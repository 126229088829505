import React from 'react';
import AppContext from "../AppContext";
import {empty} from "../components/textutil";
import texts from "../texts";
import Steps from "../components/Steps";
import OrganizerComponent from "./OrganizerComponent";
import BigPicture from "../components/BigPicture";
import Routine from "./Routine";
import CreateShare from "../components/CreateShare";
import EssentialQuestion from "../steps/CER-Guide/EssentialQuestion";
import Claim from '../steps/CER-Guide/Claim';
import Terms from '../components/Terms';
import EvidenceSupport from '../steps/CER-Guide/EvidenceSupport';
import EvidenceOpposes from '../steps/CER-Guide/EvidenceOpposes';
import EvidenceQuality from '../steps/CER-Guide/EvidenceQuality';
import Reasoning from '../steps/CER-Guide/Reasoning';

var assert = require('assert');

export default class CERGuide extends OrganizerComponent {
    static contextType = AppContext;

    constructor(props){
        super(props);
        this.emptyState = {};

        this.props.loadDocMethod();
        this.focused = this.focused.bind(this);
        this.state = this.emptyState;
    }

    trackPageView(section){
        if('toc' === section) window.tracker.trackPageView(this.context.currentUser, 'cera:toc');
        if('essentialquestion' === section) window.tracker.trackPageView(this.context.currentUser, 'cera:1');
        if('terms' === section) window.tracker.trackPageView(this.context.currentUser, 'cera:2');
        if('claim' === section) window.tracker.trackPageView(this.context.currentUser, 'cera:3');
        if('evidencesupport' === section) window.tracker.trackPageView(this.context.currentUser, 'cera:4');
        if('evidenceopposes' === section) window.tracker.trackPageView(this.context.currentUser, 'cera:5');
        if('evidencequality' === section) window.tracker.trackPageView(this.context.currentUser, 'cera:6');
        if('reasoning' === section) window.tracker.trackPageView(this.context.currentUser, 'cera:7');
        if('complete' === section) window.tracker.trackPageView(this.context.currentUser, 'cera:review');
        if('print' === section) window.tracker.trackPageView(this.context.currentUser, 'cera:print-preview');
        if('create-share' === section) window.tracker.trackPageView(this.context.currentUser, 'cera:create-share');
    }

    render(){
        if (!this.context.fileIdLoaded || null === this.state.baseNode) {
            return <div>Loading file...</div>;
        }
        // console.log(this.props);
        const section = this.props.fileSection;
        const doc = {
            section: section,
            type: this.context.fileType,
            id: this.context.fileId,
            title: this.context.fileName,
            editing: this.context.canEdit
        };

        const sections = texts[doc.type].sections;
        assert(undefined !== sections && 0 < sections.length);

        const toc = this.focused(doc.section) ?
            <div id={sections[0].toc} className={"section"}>
                <Steps doc={doc} steps={sections}/>
            </div>
            : "";

        const essential_question = <EssentialQuestion doc={doc} section={texts["cera"].sections[1]}/>;
        const terms = <Terms doc={doc} tooltipTitle={true} section={texts["cera"].sections[2]} />;
        const claim = <Claim doc={doc} section={texts["cera"].sections[3]}/>;
        const evidence_support = <EvidenceSupport doc={doc} section={texts["cera"].sections[4]}/>;
        const evidence_opposes = <EvidenceOpposes doc={doc} section={texts["cera"].sections[5]}/>;
        const evidence_quality = <EvidenceQuality doc={doc} section={texts["cera"].sections[6]}/>;
        const reasoning = <Reasoning doc={doc} section={texts["cera"].sections[7]}/>;
        const createShare = <CreateShare shareMethod={this.props.shareMethod}/>;

        this.trackPageView(section);

        const content = this.focused(section) ?
        ( 'toc' === section ? toc
            : 'essentialquestion' === section ? essential_question
            : 'terms' === section ? terms
            : 'claim' === section ? claim
            : 'evidencesupport' === section ? evidence_support
            : 'evidenceopposes' === section ? evidence_opposes
            : 'evidencequality' === section ? evidence_quality
            : 'reasoning' === section ? reasoning
            : 'create-share' === section ? createShare
            : toc
        )
        : (
            <BigPicture section={"complete"} editing={false}/>
        );

        return <Routine content={content} doc={doc} {...this.props} />
    }

    focused(section) {
        return (!empty(section) && 'complete' !== section);
    }
}

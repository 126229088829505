import React, {useEffect} from 'react';
import NavSidebar from "../NavSidebar";
import Header from "../Header";
import initUI from "../ui";
import BrandBar from "./BrandBar";
import TTS from "./TTS";
import ViewOnlyNotification from "./ViewOnlyNotification";
import TourGuide from "../steps/TourGuide";

export default function Page(props) {

    useEffect(() => {
        initUI();
    });

    const header = <Header >
        <BrandBar/>
    </Header>;
    const tts = <TTS/>;
    const nav = <NavSidebar copyButtonMethod={props.copyButtonMethod}
                            shareMethod={props.shareMethod} newDocButtonMethod={props.newDocButtonMethod} />
    const viewOnlyNotice = <ViewOnlyNotification copyButtonMethod={props.copyButtonMethod}/>;
    return (
        <React.Fragment>
            <TourGuide/>
            {header}
            {tts}
            {viewOnlyNotice}
            {props.main}
            {props.footer}
            {nav}
        </React.Fragment>
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from '../uniqueId';
import ImagePicker from "./ImagePicker";
import FilePicker from "./FilePicker";
import OrganizerComponent from "../organizers/OrganizerComponent";
import {empty} from "./textutil";
import AppContext from "../AppContext";

const noImage = {src: '', alt: '' };
const maxMB = 50;
const maxResolution = 25; //megapixels

export default class AddImageButton extends OrganizerComponent {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = { image: noImage};

        this.handleSubmit = this.handleSubmit.bind(this);
        this.process = this.process.bind(this);
        this.handleSaveVideo = this.handleSaveVideo.bind(this);
        this.alt = this.alt.bind(this);

        this.child = React.createRef();
    }

    componentWillMount() {
        this.addImageButtonId = uniqueId();
        this.menuId = uniqueId();
        this.uploadImageInputId = uniqueId();
        this.addImageSearchButtonId = uniqueId();
        this.addImageUrlButtonId = uniqueId();
        this.addImgUrlInputId = uniqueId();
        this.addYouTubeUrlButtonId = uniqueId();
    }

    handleSubmit(event) {
        // only image Enter URL
        event.preventDefault();

        let target = event.target;
        if (undefined === target.src && target[0]) {
            target = event.target[0];
        }
        this.process(target);
    }

    alt(image) {
        if (image.alt) {
            return image.alt;
        }

        let alt = image.description;
        if (empty(alt)) {
            alt = "no image description :(";
        }
        return alt;
    }

    process(target) {

        let image = target.src;
        if ("" === image && undefined !== target.files) {
            // image file upload
            image = target.files[0];
            image.src = URL.createObjectURL(image);
            image.mimeType = image.type;
            image.alt = this.alt(image);
        }

        let mImage = new Image();
        mImage.src = image.src;
        let self = this;
        mImage.onload = function() {
            let resolution = mImage.width * mImage.height / 1000000;
            let fileSize = image.size / 1024 / 1024;
            if(fileSize < maxMB && resolution < maxResolution)
                self.props.saveMethod(image);
            else console.log("Image size too big");
        }
    }

    handleSaveVideo(url) {
        console.log('handleSaveVideo', url, this.props.saveMethod);
        this.props.saveMethod(url);
        window.tracker.trackEvent("Content", "add video", url);
    }

    render() {
        const saveUpload = this.handleSubmit;

        return (
            <React.Fragment>
                <div className="dropdown">
                    <button id={this.addImageButtonId} type="button" className="btn btn-primary" data-cfw="dropdown"
                            onSubmit={ (e) => this.handleSubmit(e) }>
                        <span className="fa fa-plus text-primary me-0_25" aria-hidden="true"></span>
                        Add Media
                    </button>

                    <ul id={this.menuId} className="dropdown-menu">
                        <li>
                            <a href="# "><span className="fa fa-image text-primary me-0_25" aria-hidden="true"></span> Add image</a>
                            <ul>
                                <li>
                                    <input id={this.uploadImageInputId} type="file" accept={"image/gif,image/jpeg,image/png"} name="myFile" className="form-file-dropdown"
                                        onChange={(event)=> {
                                            saveUpload(event)
                                        }}
                                        onClick={(event)=> {
                                            event.target.value = null
                                        }}

                                    />
                                    <button className="dropdown-item" onClick={() => {
                                        document.getElementById(this.uploadImageInputId).click();
                                    }}>
                                        <span className="fa fa-fw fa-upload me-0_25" aria-hidden="true"/>
                                        Upload from device
                                    </button>
                                </li>
                                <li>
                                    <button id={this.addImageSearchButtonId} type="button" className="dropdown-item"
                                            onClick={(e) => {this.context.openSearchWebModal(this.addImageSearchButtonId, this.props.saveMethod);}}>
                                        <span className="fa fa-fw fa-search me-0_25" aria-hidden="true"></span>Search the
                                        web
                                    </button>
                                </li>
                                <li>
                                    <button id={this.addImageUrlButtonId} type="button" className="dropdown-item"
                                            onClick={(e) => {this.context.openEnterURLModal(this.addImageUrlButtonId, this.props.saveMethod);}}>
                                        <span className="fa fa-fw fa-link me-0_25"  aria-hidden="true" />
                                        Enter a URL
                                    </button>
                                </li>
                                <li>
                                    <ImagePicker saveMethod={this.props.saveMethod} >
                                        <button type="button" className="dropdown-item">
                                            <span className="fab fa-fw fa-google-drive me-0_25" aria-hidden="true"></span>
                                            Insert from Drive
                                        </button>
                                    </ImagePicker>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <button id={this.addYouTubeUrlButtonId} type="button" className="dropdown-item"
                                    onClick={(e) => {this.context.openYouTubeModal(this.addYouTubeUrlButtonId, this.handleSaveVideo);}} >
                                <span className="fab fa-youtube me-0_25"  aria-hidden="true" />
                                Embed YouTube Video
                            </button>
                        </li>
                        <li>
                            <FilePicker saveMethod={this.props.saveFile} >
                                <button type="button" className="dropdown-item">
                                    <span className="fab fa-fw fa-google-drive me-0_25" aria-hidden="true"></span>
                                    Link file from Drive
                                </button>
                            </FilePicker>
                        </li>
                    </ul>
                </div>

            </React.Fragment>);
    }
}

AddImageButton.propTypes = {
    saveMethod: PropTypes.func.isRequired,
};


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfoButton from '../components/InfoButton';
import GetText from './GetText';
import {empty} from './textutil';
import AppContext from "../AppContext";

export default class ComponentHeader extends Component {
    static contextType = AppContext;

    render() {
        const type= !empty(this.context.fileType) ? this.context.fileType: this.props.docType;
        //const number = <span className="hnum">{this.props.stepnum}</span>
        const numberHeaderSep = ". ";
        const header = <GetText type={type} identifier={this.props.identifier} item={'label'} />;
        return (
                <React.Fragment>
                    <h2 className="h2">{this.props.stepnum}{numberHeaderSep}{header}</h2>
                    <InfoButton docType={type} identifier={this.props.identifier} />
                </React.Fragment>
        );
    }

}

ComponentHeader.propTypes = {
    stepnum: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired
};

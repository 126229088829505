const empty = (string) => {
    return (undefined === string) || (null === string) || (0 === string.trim().length);
}
export {empty};

const maybeAdd = (key,fragment,sep) => {
    const prefix = empty(key)? '': sep;
    return (key + (!empty(fragment)? prefix+fragment: ''));
}
export {maybeAdd};

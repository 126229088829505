import AppContext from "../AppContext";
import {Link} from "react-router-dom";
import React, {useContext} from "react";
import * as Go from "./Go";

// pass props to (type,id,section,hash) or just section for doc in context
export default function DocLink(props) {
    const doc = useContext(AppContext);
    const to = props.to? props.to
        : Go.to(doc.fileType,doc.fileId,props.section);
        
    return (
            <Link {...props} to={to}>
                {props.children}
            </Link>
        );
}
import React from 'react';
import T from 'i18n-react';
import {empty,maybeAdd} from './textutil';

// type.identifier.item
const GetText = (props) => {
    var key = "";
    if (empty(props.key)) {
        key = maybeAdd(key, props.type, '.');
        key = maybeAdd(key, props.identifier, '.')
        key = maybeAdd(key, props.item, '.');
    } else {
        key = props.key;
    }

    return props.tag
        ? <T.text tag={props.tag} text={key} />
        : T.translate(key);
}
export default GetText;

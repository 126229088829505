import React from 'react'
//import PropTypes from "prop-types";
import AppContext from "../AppContext";

// Simplified version of TextAreaComponent, using an <input> rather than <textarea> and simple label.
export default class InputComponent extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.domId = this.props.domId;
        this.wrapperClassName = "stt-wrapper";
        this.size = this.props.size? this.props.size: "lg";
        this.inputType = this.props.inputType? this.props.inputType+" ": "";
    }

    render() {
        return (
                <div className={this.wrapperClassName}>
                    <div className={this.inputType+"firepad-form form-control form-control-"+this.size} id={this.domId} autoComplete={"off"}>
                    </div>
                    {undefined !== this.props.button ? this.props.button() : ""}
                </div>
        )
    }
}
